import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const getUser = token => httpClient.get(`${URL.BASE_URL}/${URL.USERS.INDEX}/${token}`);

export const restoreUser = token =>
	httpClient.post(`${URL.BASE_URL}/${URL.ADVISOR.INDEX}/${token}/restore`); // ! new may 1

export const getUsers = params =>
	httpClient.get(`${URL.BASE_URL}/${URL.USERS.INDEX}/search`, {params});

export const updateUser = body => httpClient.put(`${URL.BASE_URL}/${URL.USERS.INDEX}`, body);

export const updateUserEmail = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.USERS.INDEX}/setEmail`, body);

export const sendEmailCode = (
	params:
		| {
				username: string;
				isFromPortal: false;
				deepLink: string;
		  }
		| {
				username: string;
				isFromPortal: true;
				deepLink?: never;
		  },
) => {
	return httpClient.put(`${URL.BASE_URL}/${URL.USERS.INDEX}/sendEmailCode`, params);
};

export const resetPasswordByCode = body =>
	httpClient.post(`${URL.BASE_URL}/${URL.USERS.INDEX}/resetPasswordByCode`, body);

export const getUserAnswers = token =>
	httpClient.get(`${URL.BASE_URL}/${URL.USERS.INDEX}/${token}/answers`);

export const setBlockedUser = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.USERS.INDEX}/${URL.USERS.BLOCKED}`, body);

export const setIsActive = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}/${URL.USERS.ACTIVATED}`, body);

export const deleteAccount = async (data: {userToken: string}) =>
	httpClient.delete(`${URL.BASE_URL}/${URL.USERS.INDEX}`, {data});

export const setFreshdeskRestoreId = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.ADVISOR.INDEX}/${URL.USERS.SET_FRESHDESK}`, body);

export const resendEmailConfirmation = (email: string) =>
	httpClient.post(
		`${URL.BASE_URL}/${URL.ADVISOR.INDEX}/${URL.USERS.RESEND_EMAIL_CONFIRMATION}`,
		undefined,
		{
			params: {
				email,
				isFromPortal: true,
			},
		},
	);
