import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {ReactSVG} from 'react-svg';

//general UI components
import PFButton from '../../../shared/components/PF-Button';
import PFHeader from '../../../shared/components/PF-Header';
import PFLink from '../../../shared/components/PF-Link';
import PFCopyright from '../../../shared/components/PF-Copyright';

//styles, assets
import check from '../../../assets/circle-check.svg';
import failIcon from '../../../assets/circle-error.svg';
import './style.scss';

import PFInput from '../../../shared/components/PF-Input';
import {useEmailConfirmation} from '../../../shared/hooks/email';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import PUBLIC_PATHS from '../../../const/url-paths/public';
import {hints, validations} from '../../../utils/futureTS/validations';
import {useErrorHelper} from '../../../utils/futureTS/generalUtils';
import {Amplitude, ToastDispatcher} from 'utils';

const ConfirmEmail = () => {
	const {search} = useLocation();
	const confirmEmail = useEmailConfirmation();
	const {
		register,
		formState: {errors, isValid},
		handleSubmit,
	} = useForm({mode: 'onChange'});
	const [isSuccess, setIsSuccess] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [confirmedEmail, setConfirmedEmail] = useState(null);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);

	const verifyEmail = () => {};

	useEffect(() => {
		setConfirmedEmail(null);
		const params = Object.fromEntries(new URLSearchParams(search).entries());
		confirmEmail({key: params.k, token: params.t})
			.then(res => {
				setConfirmedEmail(res?.user?.email);
				setIsSuccess(true);

				Amplitude.track('Email_Verification_Completed', {
					verificated: true,
					email: res?.user?.email,
				});
			})
			.catch(e => {
				const status = e?.response?.status;

				if (status === 400) {
					const msg = e?.response?.data?.message;

					if (msg) {
						ToastDispatcher.success(msg);

						Amplitude.track('Email_Verification_Completed', {
							verificated: true,
							email: e?.response?.data?.email,
						});

						navigate(PUBLIC_PATHS.SIGN_IN);
					}
				} else {
					Amplitude.track('Email_Verification_Completed', {
						verificated: false,
					});

					errorHelper(e);
					setIsSuccess(false);
				}
			})
			.finally(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	return (
		<div className="pf-confirm-email">
			<PFHeader hideUserDetails />
			{!isLoading && (
				<div className="pf-confirm-email__container">
					<div className="pf-confirm-email__card">
						{isSuccess ? (
							<>
								<ReactSVG src={check} className="pf-confirm-email__icon"></ReactSVG>
								<h4 className="pf-confirm-email__title">
									You Have Verified Your Email!
								</h4>
								<p className="text-lg-center pf-confirm-email___text">
									Open the Pocket Finance mobile app to log in.
								</p>
								<p className="text-lg-center pf-confirm-email___text or">
									<span></span> <b>OR</b> <span></span>
								</p>
								<p className="text-lg-center pf-confirm-email___text">
									If you’re an advisor,{' '}
									<PFLink to={PUBLIC_PATHS.SIGN_IN}>click here</PFLink> to start
									working with the Advisor Portal.
								</p>
							</>
						) : (
							<>
								<ReactSVG
									src={failIcon}
									className=" pf-confirm-email__icon pf-confirm-email__icon--fail"></ReactSVG>
								<h4 className="pf-confirm-email__title">Error</h4>
								<p className="text-lg-center pf-confirm-email___text">
									The link to verify your email address has expired. To complete
									your sign up, please verify your email address again
								</p>
								<form onSubmit={handleSubmit(verifyEmail)}>
									<PFInput
										{...register('email', {
											required: hints.email.required,
											validate: validations.email,
										})}
										error={errors.email?.message}
										label="Email"
									/>
									<PFButton
										disabled={!isValid}
										onClick={() =>
											alert('This feature is currently unavailable.')
										}
										type="submit">
										Send
									</PFButton>
								</form>
							</>
						)}
					</div>
				</div>
			)}
			<PFCopyright />
		</div>
	);
};

export default ConfirmEmail;
