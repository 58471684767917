import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {usersService} from 'api/services';
import {PORTAL_PATHS} from 'const';
import {AnswersType} from 'types/userProfile';
import {useErrorHelper} from 'utils';

import arrow from '../../../assets/buttons/icon-view-all.svg';

import './style.scss';

type PropsTypes = {
	userToken: string;
	maxItems?: number | undefined;
	name?: string;
};

const UserPersonalDataBloc: FC<PropsTypes> = ({userToken, name, maxItems}) => {
	const navigate = useNavigate();
	const [answers, setAnswers] = useState<AnswersType[] | null>(null);
	const errorHelper = useErrorHelper(navigate);

	const getAnswersItems = (data: AnswersType[]): JSX.Element[] | JSX.Element | string => {
		if (data.length !== 0) {
			const answersItems = data.map(item => {
				return (
					<div key={`item-${item.questionText}`} className="personalData__item">
						<span>{item.questionText}</span>
						<span>{item.answer}</span>
					</div>
				);
			});
			if (maxItems) {
				return answersItems.slice(0, maxItems);
			}
			return answersItems;
		}
		return <div className="empty">The client profile is not completed.</div>;
	};

	const fetchUserAnswers = async (token: string): Promise<any> => {
		try {
			const response = await usersService.getUserAnswers(token);
			setAnswers(response.data);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const goToViewAll = (): void => {
		navigate(
			`${PORTAL_PATHS.OTHER_PATHS.VIEW_ALL_PERSONAL_DATA}?token=${userToken}&name=${
				name ?? ''
			}`,
		);
	};

	useEffect(() => {
		fetchUserAnswers(userToken);
	}, [userToken]);

	return (
		<div className="personalData">
			{answers && getAnswersItems(answers)}
			{maxItems && answers && answers?.length > maxItems && (
				<div className="personalData__viewAllButton">
					<button onClick={goToViewAll} type="button">
						View All
						<ReactSVG wrapper="span" className="pf-arrowSvg" src={arrow} />
					</button>
				</div>
			)}
		</div>
	);
};

export default UserPersonalDataBloc;
