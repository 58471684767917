import React, {useEffect, useState} from 'react';

import {PFButton} from 'shared/components';
import {PlanInfo} from 'types/subscriptions';

import {PricingData} from '../PriceTable';

import {getCurrentPricing, getPeriodName} from './utils';

import styles from './PriceActions.styles.module.scss';

const determinePrice = (isAnnual: boolean, plan: PlanInfo, currentPricing?: PricingData) => {
	if (!currentPricing) {
		return '$0.00';
	}
	if (isAnnual) {
		return currentPricing.annual || '$0.00';
	}
	if (plan.id === 2) {
		return currentPricing.monthly || '$0.00';
	}
	return '$0.00';
};

export const PriceActions: React.FC<{
	isLoading: boolean;
	canUpdateSubscription: boolean;
	plan: PlanInfo;
	isAnnual: boolean;
	onSwitchPlanPeriod: () => void;
	onUpdateSubscription: () => void;
}> = ({
	isLoading,
	canUpdateSubscription,
	plan,
	isAnnual,
	onSwitchPlanPeriod,
	onUpdateSubscription,
}) => {
	const [currentPricing, setCurrentPricing] = useState<PricingData | null>(null);

	useEffect(() => {
		const fetchPricing = async () => {
			const pricing = await getCurrentPricing();
			setCurrentPricing(pricing || null);
		};

		fetchPricing();
	}, [isAnnual]);

	if (!currentPricing) {
		return <div>Loading...</div>;
	}

	const price = determinePrice(isAnnual, plan, currentPricing);

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div>
					{isAnnual && !!plan.annualBeforeDiscount && (
						<div className={styles.beforeDiscount}>
							{currentPricing.annualBeforeDiscount || plan.annualBeforeDiscount}
						</div>
					)}
					<div className={styles.price}>{price}</div>
				</div>

				<div>
					<div className={styles.period}>
						<span>
							{getPeriodName(isAnnual, false)} <span>billing</span>
						</span>
						{isAnnual && !!plan.annualDiscount && (
							<span className={styles.discountBadge}>
								{currentPricing.annualDiscount || plan.annualDiscount}
							</span>
						)}
					</div>
					<div
						className={styles.periodButton}
						onClick={onSwitchPlanPeriod}
						aria-hidden
						tabIndex={0}
						role="button">
						Switch to {getPeriodName(isAnnual, true)}
						{!isAnnual && !!plan.annualDiscount && (
							<span className={styles.discountBadge}>
								{currentPricing.annualDiscount || plan.annualDiscount}
							</span>
						)}
					</div>
				</div>
			</div>

			{canUpdateSubscription && (
				<PFButton
					type="button"
					className={styles.submit}
					onClick={onUpdateSubscription}
					disabled={isLoading}>
					Update Subscription
				</PFButton>
			)}
		</div>
	);
};
