import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const getInsurancesByToken = token =>
	httpClient.get(`${URL.BASE_URL}/${URL.INSURANCE.INDEX}?userToken=${token}`);

export const setLifeInsurance = body =>
	httpClient.post(`${URL.BASE_URL}/${URL.INSURANCE.INDEX}/lifeInsurance`, body);

export const updateLifeInsurance = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.INSURANCE.INDEX}/lifeInsurance`, body);

export const setCriticalIllnessInsurance = body =>
	httpClient.post(`${URL.BASE_URL}/${URL.INSURANCE.INDEX}/criticalIllnessInsurance`, body);

export const updateCriticalIllnessInsurance = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.INSURANCE.INDEX}/criticalIllnessInsurance`, body);

export const setDisabilityInsurance = body =>
	httpClient.post(`${URL.BASE_URL}/${URL.INSURANCE.INDEX}/disabilityInsurance`, body);

export const updateDisabilityInsurance = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.INSURANCE.INDEX}/disabilityInsurance`, body);

export const deleteInsurance = body =>
	httpClient.delete(
		`${URL.BASE_URL}/${URL.INSURANCE.INDEX}?token=${body.token}&type=${body.type}`,
	);
