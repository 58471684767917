import React, {ReactNode} from 'react';

import classNames from 'classnames';

import './style.scss';

interface BaseCheckboxProps {
	onClick: React.MouseEventHandler<HTMLInputElement>;
	name: string;
	value?: string;
	label?: ReactNode | string;
	disabled?: boolean;
	style?: any;
	className?: string;
	id?: string;
}

interface RegisterControlCheckboxProps extends BaseCheckboxProps {
	registerControl: true;
	checked?: never;
}

interface NotControlCheckboxProps extends BaseCheckboxProps {
	registerControl?: false;
	checked: boolean;
}

type PropsTypes = RegisterControlCheckboxProps | NotControlCheckboxProps;

type RefType = HTMLInputElement;

const PFCheckbox = React.forwardRef<RefType, PropsTypes>(
	(
		{
			onClick,
			name,
			value,
			registerControl,
			checked,
			label,
			disabled = false,
			style,
			className,
			id,
			...props
		},
		ref,
	) => {
		return (
			<div className={classNames('form-check', className, {disabled})} style={style}>
				<label className="form-check__label" htmlFor={`pf-checkbox-${id || ''}`}>
					{registerControl ? (
						<input
							id={`pf-checkbox-${id || ''}`}
							name={name}
							value={value}
							type="checkbox"
							className="form-check-input"
							ref={ref}
							onClick={onClick}
							onChange={() => {}}
							disabled={disabled}
							{...props}
						/>
					) : (
						<input
							id={`pf-checkbox-${id || ''}`}
							type="checkbox"
							name={name}
							checked={checked}
							className="form-check-input"
							ref={ref}
							onClick={onClick}
							onChange={() => {}}
							disabled={disabled}
							{...props}
						/>
					)}
					<div className="form-check__label-text">{label && label}</div>
				</label>
			</div>
		);
	},
);

export default PFCheckbox;
