import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const getApi = params => httpClient.get(`${URL.BASE_URL}/${URL.API.INDEX}/search`, {params});

export const getApiByToken = token => httpClient.get(`${URL.BASE_URL}/${URL.API.INDEX}/${token}`);

export const deleteApi = token => httpClient.delete(`${URL.BASE_URL}/${URL.API.INDEX}/${token}`);

export const setApi = body => httpClient.post(`${URL.BASE_URL}/${URL.API.INDEX}`, body);

export const editApi = body => httpClient.put(`${URL.BASE_URL}/${URL.API.INDEX}`, body);

export const restoreApi = token =>
	httpClient.post(`${URL.BASE_URL}/${URL.API.INDEX}/${token}/restore`); // ! new may 1
