import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CurrentItem} from 'types';

interface CurrentState {
	current: CurrentItem[];
	fullCount: number;
}

type SetCurrentDataActionType = {
	body: CurrentItem[];
	fullCount: number;
};

type RemoveCurrentItemActionType = {
	tokens: string[];
};

type SetClientsTagsActionType = {
	userToken: string;
	tags: string[];
};

const initialState: CurrentState = {
	current: [],
	fullCount: 0,
};

export const currentSlice = createSlice({
	name: 'current',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setCurrentData: (state, action: PayloadAction<SetCurrentDataActionType>) => {
			state.current = action.payload.body;
			state.fullCount = action.payload.fullCount;
		},
		cleanUpCurrentData: state => {
			state.current = initialState.current;
			state.fullCount = initialState.fullCount;
		},
		removeCurrentItem: (state, action: PayloadAction<RemoveCurrentItemActionType>) => {
			state.current = state.current.filter(
				item => !action.payload.tokens.includes(item.token),
			);
		},
		setClientsTags: (state, action: PayloadAction<SetClientsTagsActionType>) => {
			if (state.current?.length) {
				state.current.forEach(item => {
					if (item.token === action.payload.userToken) {
						item.customClientTags = action.payload.tags;
					}
				});
			}
		},
	},
});

export default currentSlice.reducer;
