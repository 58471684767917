import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {advisorClientsService, leadsService} from 'api/services';
import {
	accept_button_icon,
	deepLinkIcon,
	leadsAddIcon,
	leadsAddIconMobile,
	leadsTableIcon,
	leadsTableIconMobile,
	reject_button_icon,
	scheduleMeetingIcon,
} from 'assets';
import upgradePlanBadgeIcon from 'assets/header/upgrade-plan-badge-icon.svg';
import {
	EmptyLayout,
	RejectLeadModal,
	SnackActionButton,
	TeamCellItem,
	UserCellItem,
} from 'components';
import {PATHS, PORTAL_PATHS} from 'const';
import moment from 'moment';
import {ColumnProps, Table} from 'rsuite';
import {PFButton, PFSortArrow, PFTable, PFTableBar} from 'shared/components';
import {useBooleanState, useLeadsData} from 'shared/hooks';
import {useAppDispatch, useAppSelector} from 'store';
import {
	cleanedUpTableData,
	cleanUpLeadsData,
	removeCheckedItems,
	removeLeadsItem,
	setCurrentPageTitle,
	setOpenDeepLinkPopup,
} from 'store/actions';
import {fetchCounts} from 'store/reducers/SubscriptionReducer';
import {
	leadsSelectors,
	myProfileSelectors,
	SubscriptionSelectors,
	tableSelectors,
} from 'store/selectors';
import {
	Amplitude,
	findByToken,
	isCompletedProfile,
	mapQueryParams,
	parseUrlQuery,
	ToastDispatcher,
	useErrorHelper,
} from 'utils';

import {UpgradePlanBar, UpgradePlanModal} from './components';

import './style.scss';

type AcceptButtonPropsTypes = {
	action: () => void;
	disabled: boolean;
};

export const AcceptButton: FC<AcceptButtonPropsTypes> = ({action, disabled}) => {
	return (
		<PFButton
			type="button"
			variant="green"
			prefixIcon={
				<ReactSVG wrapper="span" className="pf-icon-accept" src={accept_button_icon} />
			}
			className="pf-leads-accept-button"
			disabled={disabled}
			onClick={action}>
			<span className="button-text">Accept & Move to Current</span>
		</PFButton>
	);
};

export const UpgradePlanButton: FC<{
	action: () => void;
}> = ({action}) => {
	return (
		<PFButton
			type="button"
			variant="primary"
			prefixIcon={
				<ReactSVG wrapper="span" className="pf-icon-accept" src={upgradePlanBadgeIcon} />
			}
			className="pf-leads-accept-button"
			hasArrow
			onClick={action}>
			<span className="button-text">Upgrade to Accept This Invitation</span>
		</PFButton>
	);
};

export const RejectButton = ({action}) => {
	return (
		<PFButton
			type="button"
			variant="white-alert"
			prefixIcon={
				<ReactSVG wrapper="span" className="pf-icon-reject" src={reject_button_icon} />
			}
			className="pf-leads-reject-button"
			onClick={action}>
			<span className="button-text">Reject & Remove From Leads</span>
		</PFButton>
	);
};

export const ScheduleMeetingButton = ({action, disabled}) => {
	return (
		<PFButton
			type="button"
			variant="secondary"
			disabled={disabled}
			prefixIcon={
				<ReactSVG wrapper="span" className="pf-icon-reject" src={scheduleMeetingIcon} />
			}
			className="scheduleMeetingButton"
			onClick={action}>
			<span className="button-text">Schedule a Meeting</span>
		</PFButton>
	);
};

type ColumnsProps = ColumnProps & {name: string; title: string};

const Leads = () => {
	const [isLoading, setIsLoading] = useState(true);
	const {Column, HeaderCell, Cell} = Table;
	const [update, setUpdate] = useState(true);
	const [updateParam, setUpdateParam] = useState('');
	const tableData = useAppSelector(leadsSelectors.selectLeadsData);
	const fullTableDataLength = useAppSelector(leadsSelectors.selectLeadsFullCount);
	const checkedKeys = useAppSelector(tableSelectors.selectTableCheckedData);
	const searchProp = useAppSelector(tableSelectors.selectTableSearchData);
	const resetProp = useAppSelector(tableSelectors.selectTableResetData);
	const reverseProp = useAppSelector(tableSelectors.selectTableReverseData);
	const urlParams = parseUrlQuery(document.location);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const [isShowRejectLeadModal, setIsShowRejectLeadModalInfo] = useState<boolean>(false);
	const [isSubmittingAcceptLeadsToCurrent, setIsSubmittingAcceptLeadsToCurrent] =
		useBooleanState(false);
	const [isSubmittingScheduleMeeting, setIsSubmittingScheduleMeeting] = useBooleanState(false);
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const calendlyLink = useAppSelector(myProfileSelectors.selectMyCalendlyLink);

	const hasSubscription = useAppSelector(SubscriptionSelectors.selectHasSubscription);
	const currentCount = useAppSelector(SubscriptionSelectors.selectCurrentCount);
	const leadsCount = useAppSelector(SubscriptionSelectors.selectLeadsCount);

	const hasCurrentCountRestriction = !hasSubscription && currentCount >= 5;
	const hasLeadsViewRestriction = !hasSubscription && leadsCount >= 51;

	const [isUpgradePlanModalVisible, setIsUpgradePlanModalVisible] = useState(false);

	const params = {
		type: 3,
		...mapQueryParams(urlParams),
	};
	const fetchLeadsData = useLeadsData(params);
	const dispatch = useAppDispatch();
	const columns: ColumnsProps[] = [
		{
			name: 'name',
			title: 'Name',
			flexGrow: 2,
			minWidth: 216,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'contactInfo',
			title: 'Contact info',
			flexGrow: 2,
			minWidth: 190,
			align: 'left',
			sortable: false,
			verticalAlign: 'middle',
		},
		{
			name: 'lastViewed',
			title: 'Last viewed',
			flexGrow: 1,
			minWidth: 200,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'team',
			title: 'Client Team',
			flexGrow: 2,
			minWidth: 180,
			align: 'left',
			sortable: false,
			verticalAlign: 'middle',
		},
	];
	const tableColumns = columns.map((item, index) => {
		const switchParam = param => {
			switch (param) {
				case 'name':
					return (
						<Cell dataKey="Name">
							{rowData => (
								<UserCellItem
									lastName={rowData?.lastName}
									firstName={rowData?.firstName}
									avatarImage={rowData?.profileImage}
									avatarSize={40}
									isDeleted={false}
									restoreToken={rowData?.token}
								/>
							)}
						</Cell>
					);
				case 'contactInfo':
					return (
						<Cell dataKey="contactInfo">
							{rowData => (
								<a className="pf-tableText" href={`mailto:${rowData.email}`}>
									{rowData.email}
								</a>
							)}
						</Cell>
					);
				case 'lastViewed':
					return (
						<Cell dataKey="lastViewed">
							{rowData => (
								<div className="pf-tableText">
									{rowData.lastVisited
										? moment.utc(rowData.lastVisited).local().fromNow(true)
										: 'Never'}
								</div>
							)}
						</Cell>
					);
				case 'team':
					return (
						<Cell dataKey="teamCount">
							{rowData => <TeamCellItem teamCount={rowData?.teamCount} />}
						</Cell>
					);
				default:
					return null;
			}
		};

		return (
			<Column
				key={item.name}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						{item.sortable ? <PFSortArrow /> : null}
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const openPlanModalIfCantSwitchPage = (
		page: number,
		itemsCount: number,
		callback: Function,
	) => {
		const skip = (page - 1) * itemsCount;
		const take = itemsCount;

		if (!hasSubscription && take + skip > 50) {
			setIsUpgradePlanModalVisible(true);
		} else {
			callback();
		}
	};

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;

		try {
			setIsLoading(true);
			await fetchLeadsData();
			// @ts-ignore
			await dispatch(fetchCounts());
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const updateCallback = () => {
		if (updateParam === 'blocked') {
			checkedKeys.forEach(key => {
				const foundBlockedCompany = findByToken(tableData, key);
				if (foundBlockedCompany) foundBlockedCompany.isBlocked = true;
			});
			setUpdateParam('');
		} else if (updateParam === 'unblocked') {
			checkedKeys.forEach(key => {
				const foundBlockedCompany = findByToken(tableData, key);
				if (foundBlockedCompany) foundBlockedCompany.isBlocked = false;
			});
			setUpdateParam('');
		}
	};

	const linkIcon = <ReactSVG wrapper="span" className="me-2 whiteIcon" src={deepLinkIcon} />;

	const firstRender = async () => {
		dispatch(setCurrentPageTitle('Leads'));

		try {
			setIsLoading(true);
			if (isCompletedProfile(profile?.advisor)) {
				await fetchLeadsData();
				// @ts-ignore
				await dispatch(fetchCounts());
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const emptyPageParam = {
		title: 'Don’t See Your Leads Here?',
		subtitle: 'Follow the steps below to invite your clients to Pocket Finance.',
		steps: [
			{
				description: (
					<div>
						<b>Click the “My Profile Link” button</b> 👇 to copy your profile link to
						your clipboard. You can then send it to your client.
					</div>
				),
				content: (
					<PFButton
						type="button"
						prefixIcon={linkIcon}
						className="deepLink"
						onClick={() => {
							if (profile)
								dispatch(
									setOpenDeepLinkPopup({
										advisorToken: profile.advisor.token,
										isMyLink: true,
									}),
								);
						}}>
						My Profile Link
					</PFButton>
				),
			},
			{
				description: (
					<div>
						<b>After opening your profile link</b>, the client will be directed to your
						profile in the Pocket Finance mobile app. They should then tap the
						<b>“Add to My Team”</b> button:
					</div>
				),
				content: (
					<picture>
						<source media="(max-width: 992px)" srcSet={leadsAddIconMobile} />
						<img src={leadsAddIcon} alt="Portal table" />
					</picture>
				),
			},
			{
				description: (
					<div>
						<b>That’s it!</b> You can now see your client in your Leads tab!
					</div>
				),
				content: (
					<picture>
						<source media="(max-width: 992px)" srcSet={leadsTableIconMobile} />
						<img src={leadsTableIcon} alt="Portal table" />
					</picture>
				),
			},
		],
		note: (
			<p>
				<b>
					The Leads tab will also show you the users who have asked to connect with you
					via the Pocket Finance app —
				</b>{' '}
				just click on their name and choose if you’d like to accept or reject their
				connection request.
			</p>
		),
	};
	const searchEmptyParam = {
		variant: 'primary',
		className: '',
		pageName: 'Leads',
		buttonText: 'Back to Leads',
		isButton: true,
	};

	const acceptLeadsToCurrentAction = async (tokens: string[]) => {
		try {
			setIsSubmittingAcceptLeadsToCurrent(true);
			await leadsService.acceptRequestLeadsToCurrent(tokens);

			Amplitude.track('Moved_To_Current', {
				clients: tokens,
			});

			dispatch(removeLeadsItem({tokens}));
			dispatch(removeCheckedItems(tokens));

			// @ts-ignore
			await dispatch(fetchCounts());

			ToastDispatcher.success('User moved to Current.', snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Open"
					onClickAction={async () => navigate(PATHS.PORTAL.SIDEBAR.CURRENT)}
				/>
			));
		} catch (e) {
			ToastDispatcher.error(`Failed to move user to Current. Please try again`);
			throw e;
		} finally {
			setIsSubmittingAcceptLeadsToCurrent(false);
		}
	};

	const sendScheduleMeeting = async (userTokens: string[]) => {
		try {
			setIsSubmittingScheduleMeeting(true);
			await advisorClientsService.sendScheduleMeeting(userTokens);

			Amplitude.track('Scheduled_Meeting', {
				source: 'Leads Table',
				clients: userTokens,
			});

			ToastDispatcher.success(
				'The clients will receive your calendar link to suggest the meeting date and time.',
			);
		} catch (e) {
			ToastDispatcher.error(`Failed to schedule a meeting. Please check your calendar link.`);
			throw e;
		} finally {
			setIsSubmittingScheduleMeeting(false);
		}
	};

	const goToManageSubscription = () => {
		Amplitude.track('Opened_Upgrade', {
			source: 'Leads',
		});

		navigate(PORTAL_PATHS.OTHER_PATHS.MANAGE_SUBSCRIPTION);
	};

	const tableBarButtons = [
		!hasSubscription && checkedKeys.length + currentCount > 5 ? (
			<UpgradePlanButton key="UpgradePlanButton" action={goToManageSubscription} />
		) : (
			<AcceptButton
				key="AcceptLeadsButton"
				disabled={isSubmittingAcceptLeadsToCurrent}
				action={() => acceptLeadsToCurrentAction(checkedKeys)}
			/>
		),
		<RejectButton key="RejectLeadsButton" action={() => setIsShowRejectLeadModalInfo(true)} />,
		<ScheduleMeetingButton
			key="ScheduleMeetingButton"
			disabled={isSubmittingScheduleMeeting || !calendlyLink}
			action={() => sendScheduleMeeting(checkedKeys)}
		/>,
	];

	useEffect(() => {
		firstRender();
	}, [searchProp, resetProp, reverseProp, profile]);

	useEffect(() => {
		Amplitude.track('Opened_Leads');
	}, []);

	useEffect(() => {
		return () => {
			dispatch(cleanUpLeadsData());
			dispatch(cleanedUpTableData());
		};
	}, []);

	return (
		<div className="leadsPage">
			<div className={hasLeadsViewRestriction ? 'beforeTableHeader' : ''}>
				{hasLeadsViewRestriction && (
					<UpgradePlanBar
						numberOfLeadsMore={leadsCount - 50}
						onClick={goToManageSubscription}
					/>
				)}
				<PFTableBar
					tableData={tableData}
					checkedKeys={checkedKeys}
					tableBarButtons={tableBarButtons}
					modal="leads"
					className={hasLeadsViewRestriction ? 'tableBarOverride' : ''}
				/>
			</div>
			<PFTable
				tableColumns={tableColumns}
				tableData={tableData}
				isLoading={isLoading}
				modal="leads"
				fetchMoreData={fetchMoreData}
				fullTableDataLength={fullTableDataLength}
				checkedKeys={checkedKeys}
				update={update}
				updateCallback={updateCallback}
				parseUrlQuery={parseUrlQuery}
				updateDataForUseOnModal={updateData}
				emptyComponent={
					<EmptyLayout
						emptyPageParam={emptyPageParam}
						searchEmptyParam={searchEmptyParam}
					/>
				}
				hasCurrentCountRestriction={hasCurrentCountRestriction}
				hasLeadsViewRestriction={hasLeadsViewRestriction}
				// @ts-ignore
				switchPageConditionRunner={openPlanModalIfCantSwitchPage}
			/>
			{isShowRejectLeadModal && (
				<RejectLeadModal
					tokens={checkedKeys}
					visible={isShowRejectLeadModal}
					onCancel={() => setIsShowRejectLeadModalInfo(false)}
				/>
			)}
			<UpgradePlanModal
				visible={isUpgradePlanModalVisible}
				onCancel={() => setIsUpgradePlanModalVisible(false)}
				numberOfLeadsMore={leadsCount - 50}
			/>
		</div>
	);
};

export default Leads;
