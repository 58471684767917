import 'react-phone-input-2/lib/style.css';
import React from 'react';
import classNames from 'classnames';
import {getClassNamesWithVariant} from '../../../utils';
import {Controller} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import './style.scss';

const PFPhoneInput = ({
	showAsterisk,
	labelSufix,
	label,
	control,
	name,
	variant,
	className,
	error,
	hint,
	rules,
	defaultValue,
}) => {
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			defaultValue={defaultValue}
			render={({field: {onChange, value}}) => (
				<div className={getClassNamesWithVariant('pf-input', variant, className)}>
					<div className="pf-input__label-wrapper">
						<label className="pf-input__label">
							{showAsterisk && <span className="pf-input__label-asterisk">*</span>}
							{label}
						</label>
						{labelSufix}
					</div>
					<PhoneInput
						enableSearch
						className=""
						value={value?.value ?? value} //if onChange was triggered, value = value.value, otherwise default value.
						onChange={(value, phoneObj, _event, formattedValue) => {
							onChange({
								value: '+' + value,
								...phoneObj,
								formattedValue: formattedValue,
							});
						}}
						inputClass="pf-input__field"
						country={'ca'}
						disableSearchIcon
					/>
					<p
						className={classNames('pf-input__hint', {
							'pf-input__hint--error': error,
						})}>
						{error || hint}
					</p>
				</div>
			)}
		/>
	);
};

PFPhoneInput.defaultProps = {};

export default PFPhoneInput;
