import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {setCurrentPageTitle} from '../../../../store/actions';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import {useChangeCompanyPopup, useIsSuspendUserPopup} from '../../../../shared/hooks/companies';
import {useDeletedUserPopup, useRestoreUser} from '../../../../shared/hooks/users';
import {Table} from 'rsuite';
import PORTAL_PATHS from '../../../../const/url-paths/portal';
import {SuperAdminEmptyPage, UserCellItem} from '../../../../components';
import {findByToken} from '../../../../utils/table';
import {PFButton, PFSortArrow, PFTable} from '../../../../shared/components';
import * as usersService from '../../../../api/services/users';
import iconChangeCompany from '../../../../assets/icon-changeCompany.svg';
import iconDelete from '../../../../assets/icon-delete.svg';
import {
	cleanedUpAdvisorClientsData,
	setCheckedData,
	cleanedUpTableData,
	cleanedUpCompanyProfileData,
} from '../../../../store/actions';
import plus from '../../../../assets/icon-plus.svg';
import './style.scss';
import {useAdvisorsData} from '../../../../shared/hooks/advisor';
import * as advisorService from '../../../../api/services/advisor';
import {mapQueryParams, parseUrlQuery, ToastDispatcher} from '../../../../utils';
import SearchEmptyPage from '../../../../components/EmptyPages/EmptySearch';
import {useErrorHelper, getCamelCaseToText} from '../../../../utils/futureTS/generalUtils';
import PFTableBar from '../../../../shared/components/PF-TableBar';
import iconBlock from '../../../../assets/icon-block.svg';
import iconUnblock from '../../../../assets/icon-unblock.svg';
// import {OverlayTrigger, Tooltip} from 'react-bootstrap'; //! Раскоментируй когда скажут добавить тултип

const Advisors = props => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [update, setUpdate] = useState(true);
	const [updateParam, setUpdateParam] = useState('');
	const {Column, HeaderCell, Cell} = Table;
	const dispatch = useDispatch();
	const tableData = props.advisors.advisors;
	const fullTableDataLength = props.advisors.fullCount;
	const checkedKeys = props.table.checkedData;
	const {showIsSuspendUserConfirm, ConfirmIsSuspendUserModal} = useIsSuspendUserPopup();
	const {showDeletedUserConfirm, ConfirmDeleteUserModal} = useDeletedUserPopup();
	const {showChangeCompanyConfirm, ConfirmChangeCompanyModal} = useChangeCompanyPopup();
	const urlParams = parseUrlQuery(document.location);
	const [isEmptyPage, setIsEmptyPage] = useState(false);
	const restoreUser = useRestoreUser();
	const errorHelper = useErrorHelper(navigate);

	const params = {
		RoleIds: [3, 4, 5],
		...mapQueryParams(urlParams),
	};

	const restore = async token => {
		try {
			await restoreUser(token);
			const foundDeletedUser = findByToken(tableData, token);
			if (foundDeletedUser) foundDeletedUser.isDeleted = false;
			updateData();
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const fetchAdvisorsData = useAdvisorsData(params);
	const [columns] = useState([
		{
			name: 'user',
			title: 'User',
			flexGrow: 1,
			minWidth: 200,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'company',
			title: 'Company',
			flexGrow: 2,
			minWidth: 180,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'email',
			title: 'Email',
			flexGrow: 1,
			minWidth: 180,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'companyRole',
			title: 'Company role',
			flexGrow: 1,
			minWidth: 100,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
	]);
	const tableColumns = columns.map((item, index) => {
		const switchParam = param => {
			switch (param) {
				case 'user':
					return (
						<Cell dataKey="LastName">
							{rowData => (
								<UserCellItem
									lastName={rowData?.lastName}
									firstName={rowData?.firstName}
									avatarImage={rowData?.advisor?.profileImage}
									avatarSize={40}
									//! restore={restore} // пока не работает т.е. удаляется из базя полностью
									isDeleted={rowData?.isDeleted}
									restoreToken={rowData?.token}
								/>
							)}
						</Cell>
					);
				case 'company':
					return (
						<Cell dataKey="CompanyName">
							{rowData => (
								<div className={`pf-tableText`}>
									{rowData?.advisor?.companyName}
								</div>
							)}
						</Cell>
					);
				case 'email':
					return (
						<Cell dataKey="Email">
							{rowData => (
								//! Раскоментируй когда скажут добавить тултип
								// <OverlayTrigger
								// 	placement="auto"
								// 	overlay={
								// 		<Tooltip id="button-tooltip-2">{rowData?.email}</Tooltip>
								// 	}>
								<div className="tableEmailCell">
									<a
										className={`pf-tableText`}
										href={`mailto:${
											rowData?.advisor?.contactEmail ||
											rowData?.advisor?.email
										}`}>
										{rowData?.advisor?.contactEmail || rowData?.advisor?.email}
									</a>
									{!rowData?.isEmailConfirmed && (
										<p className="unconfirmedEmail">Unconfirmed</p>
									)}
								</div>
								// </OverlayTrigger>
							)}
						</Cell>
					);
				case 'companyRole':
					return (
						<Cell dataKey="Role">
							{rowData => (
								<div className={`pf-tableText`}>
									{getCamelCaseToText(rowData?.roles[0])}
								</div>
							)}
						</Cell>
					);
				default:
					break;
			}
		};

		return (
			<Column
				key={index}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						{<PFSortArrow />}
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;
		try {
			setIsLoading(true);
			await fetchAdvisorsData();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const fetchIsSuspendUsers = isBlocked => {
		const count = checkedKeys.length;
		const toastMessage = `${count} user${count > 1 ? 's' : ''} ${
			isBlocked ? 'suspended' : 'reactivated'
		}`;

		try {
			Promise.all(
				checkedKeys.map(token => {
					const body = {
						token,
						isBlocked,
					};
					return usersService.setBlockedUser(body);
				}),
			)
				.then(response => {
					ToastDispatcher.success(toastMessage);
					setUpdateParam(isBlocked ? 'suspended' : 'reactivated');
					updateData();
				})
				.catch(e => {
					errorHelper(e);
				});
		} catch (e) {
			throw e;
		}
	};

	const fetchDeletedUsers = checkedKeys => {
		const count = checkedKeys.length;
		const toastMessage = `${count > 1 ? count : ''} ${count > 1 ? 'u' : 'U'}ser${
			count > 1 ? 's' : ''
		} deleted`;
		try {
			Promise.all(checkedKeys.map(item => usersService.deleteAccount({userToken: item})))
				.then(response => {
					ToastDispatcher.success(toastMessage);
					setUpdateParam('deleted');
					updateData();
				})
				.catch(e => {
					errorHelper(e);
				});
		} catch (e) {
			throw e;
		}
	};

	const fetchChangeUsersCompany = async (checkedKeys, companyToken, companyName) => {
		const count = checkedKeys.length;
		const toastMessage = `Company changed for ${count} user${count > 1 ? 's' : ''}`;
		try {
			await advisorService.updateAdvisorCompany({userTokens: checkedKeys, companyToken});
			ToastDispatcher.success(toastMessage);
			checkedKeys.forEach(key => {
				const foundUser = findByToken(tableData, key);
				if (foundUser) foundUser.advisor.companyName = companyName;
			});
			dispatch(setCheckedData([]));
			updateData();
		} catch (e) {
			errorHelper(e);
		}
	};

	const updateCallback = () => {
		switch (updateParam) {
			case 'suspended': {
				checkedKeys.forEach(key => {
					const foundBlockedUser = findByToken(tableData, key);
					if (foundBlockedUser) foundBlockedUser.isBlocked = true;
				});
				setUpdateParam('');
				break;
			}
			case 'reactivated': {
				checkedKeys.forEach(key => {
					const foundBlockedUser = findByToken(tableData, key);
					if (foundBlockedUser) foundBlockedUser.isBlocked = false;
				});
				setUpdateParam('');
				break;
			}
			case 'deleted': {
				checkedKeys.forEach(key => {
					const foundDeletedUser = findByToken(tableData, key);
					if (foundDeletedUser) foundDeletedUser.isDeleted = true;
				});
				dispatch(setCheckedData([]));
				setUpdateParam('');
				break;
			}
			default: {
				return;
			}
		}
	};

	const ChangeCompanyButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG
						wrapper="span"
						className="pf-icon-request-report"
						src={iconChangeCompany}
					/>
				}
				className={'pf-consumer-block-button'}
				onClick={showChangeCompanyConfirm}>
				<span className={'button-text'}>Change Company</span>
			</PFButton>
		);
	};

	const DeleteButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG
						wrapper="span"
						className="pf-icon-request-report pf-icon-delete"
						src={iconDelete}
					/>
				}
				className={'pf-consumer-delete-button'}
				onClick={showDeletedUserConfirm}>
				<span className={'button-text'}>Delete</span>
			</PFButton>
		);
	};

	const BlockButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG wrapper="span" className="pf-icon-request-report" src={iconBlock} />
				}
				className={'pf-consumer-block-button'}
				onClick={showIsSuspendUserConfirm}
				type="button">
				<span className={'button-text'}>Suspend</span>
			</PFButton>
		);
	};

	const UnblockButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG wrapper="span" className="pf-icon-request-report" src={iconUnblock} />
				}
				className={'pf-consumer-block-button'}
				onClick={() => fetchIsSuspendUsers(false)}
				type="button">
				<span className={'button-text'}>Reactivate</span>
			</PFButton>
		);
	};

	const firstRender = async () => {
		try {
			setIsLoading(true);
			const AdvisorsData = await fetchAdvisorsData();
			if (Boolean(AdvisorsData.fullCount)) {
				setIsEmptyPage(false);
			} else {
				setIsEmptyPage(true);
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const goToAddNewUser = () => {
		navigate(PORTAL_PATHS.OTHER_PATHS.ADD_NEW_ADVISOR);
	};

	const emptyPageParam = {
		title: 'The List is Empty',
		subtitle: 'Click the button to add new users:',
		button: {
			variant: 'primary', // "secondary" or "primary"
			onClick: goToAddNewUser,
			icon: false, // false or component
			isShowIcon: true, // true or false
			text: 'Add New User',
		},
		className: '',
	};

	const searchValue = useSelector(state => state.table.search);
	const searchEmptyParam = {
		variant: 'primary',
		className: '',
		pageName: 'Advisors',
		buttonText: 'Back to Advisors',
	};
	const tableBarButtons = [
		<BlockButton key={1} />,
		<UnblockButton key={2} />,
		<ChangeCompanyButton key={3} />,
		<DeleteButton key={4} />,
	];

	useEffect(() => {
		firstRender();
	}, [props.table.search, props.table.reset, props.table.reverse]);

	useEffect(() => {
		dispatch(setCurrentPageTitle('Advisors'));
		dispatch(cleanedUpCompanyProfileData());
		return () => {};
	}, []);

	useEffect(() => {
		return () => {
			dispatch(cleanedUpAdvisorClientsData());
			dispatch(cleanedUpTableData());
		};
	}, []);

	return (
		<>
			<PFTableBar
				tableData={tableData}
				checkedKeys={checkedKeys}
				modal={'companyProfile'}
				page={'Advisor'}
				tableBarButtons={tableBarButtons}
			/>
			<div className={`advisors`}>
				<PFTable
					tableColumns={tableColumns}
					tableData={tableData}
					isLoading={isLoading}
					fetchMoreData={fetchMoreData}
					fullTableDataLength={fullTableDataLength}
					checkedKeys={checkedKeys}
					update={update}
					updateCallback={updateCallback}
					updateDataForUseOnModal={updateData}
					urlParams={urlParams}
					parseUrlQuery={parseUrlQuery}
					modal={'companyProfile'}
					emptyComponent={
						searchValue ? (
							<SearchEmptyPage {...searchEmptyParam} />
						) : (
							<SuperAdminEmptyPage {...emptyPageParam} />
						)
					}
				/>

				<button
					className={`newCompany_button add-new_button ${isEmptyPage ? 'd-none' : ''}`}
					onClick={goToAddNewUser}>
					<ReactSVG wrapper="span" className={`icon_plus`} src={plus} />
					<span className={`newCompany_button_text`}>Add New User</span>
				</button>
				<ConfirmIsSuspendUserModal
					onSubmit={() => fetchIsSuspendUsers(true)}
					className={'pf-confirmIsActiveUserModal'}
					checkedKeys={checkedKeys}
				/>
				<ConfirmDeleteUserModal
					className={'pf-confirmDeleteModal'}
					checkedKeys={checkedKeys}
					onSubmit={() => fetchDeletedUsers(checkedKeys)}
				/>
				<ConfirmChangeCompanyModal
					className={'pf-confirmChangeCompany'}
					checkedKeys={checkedKeys}
					onSubmit={fetchChangeUsersCompany}
				/>
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		advisors: state.advisors,
		table: state.table,
	};
};

export default connect(mapStateToProps)(Advisors);
