enum FormInputKeys {
	email = 'email',
	firstName = 'firstName,',
	lastName = 'lastName',
	companyToken = 'companyToken',
	newCompanyCheck = 'newCompanyCheck',
	companyName = 'companyName',
	companyLocationToken = 'companyLocationToken',
	password = 'password',
	confirmPassword = 'confirmPassword',
	companyLogo = 'companyLogo',
}

export default FormInputKeys;
