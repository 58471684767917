import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import classNames from 'classnames';
import {
	HASHTAG_TYPES,
	INACTIVE_REASON_OPTIONS_TYPES,
	NULL_TOKEN,
	SPECIALIZATION_OPTIONS_TYPES,
} from 'const';
import PFButton from 'shared/components/PF-Button';
import PFRadioGroup from 'shared/components/PF-RadioGroup';
import PFSelect from 'shared/components/PF-Select';
import PFTabsContainer from 'shared/components/PF-TabsContainer';
import {useAppSelector} from 'store';
import {selectRoles} from 'store/selectors';
import {AdvisorsRole} from 'types/enums';
import {Amplitude, isPhoneEmpty, matchYouTubeLink, useErrorHelper} from 'utils';

import ContactInformationFormBloc from '../ContactInformationFormBloc';
import LoginDataFormBloc from '../LoginDataFormBloc';
import UserDataFormBloc from '../UserDataFormBloc';

import FormInputKeys from './constants';

import './style.scss';

const EditProfileForm = ({advisor, updateProfile, goBack}) => {
	const role = useAppSelector(selectRoles);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const formProps = useForm({
		mode: 'onChange',
	});
	const {
		register,
		trigger,
		getValues,
		watch,
		formState,
		setValue,
		control,
		unregister,
		setError,
		handleSubmit,
		...props
	} = formProps;

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isVisibleReasonInactiveField, setIsVisibleReasonInactiveField] = useState(false);
	const [hintValue, setHintValue] = useState(0);
	const [companyImage, setCompanyImage] = useState<string | null>(null);

	const [tabIndex, setTabIndex] = useState(0);

	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	// We pass this variable the current specializations received from the API, if none exist, it'll return an empty array
	const specializationDefaultValue = advisor?.advisorSpecializationTypes?.map(type =>
		SPECIALIZATION_OPTIONS_TYPES.find(spec => {
			return spec.type === type;
		}),
	);

	// The className variable below adds a bit of margin after each field.
	const fullWidthClass = 'col-12 mb-4';

	const onSubmit = async valueObj => {
		const {...values} = valueObj;
		const {hashtags, location, token, ...rest} = advisor; // Here we're ignoring the values within the brackets from being sent to the API call.

		const hashtagTokens = Object.values(HASHTAG_TYPES)
			.map(tag => {
				return (
					// if the hashtag.key exists, return it, otherwise if it's undefined, we'll print out the default value (filtering out to just the token)
					(values[tag.key] && values[tag.key]?.map(item => item.token)) ??
					hashtags.filter(({type}) => type === tag.value)?.map(item => item.token)
				);
			})
			.flat()
			.filter(Boolean);

		const getVideoPresentationLink = str => {
			const youTubeLinkMatchArray = matchYouTubeLink(str);
			if (youTubeLinkMatchArray && !!youTubeLinkMatchArray.length) {
				return youTubeLinkMatchArray[5].length
					? youTubeLinkMatchArray[0].slice(0, -youTubeLinkMatchArray[5].length)
					: youTubeLinkMatchArray[0];
			}
			return null;
		};

		const getIsActiveValue = value => {
			if (value) {
				return value === 'true';
			}
			return advisor.isActive;
		};

		const profile = {
			...rest,
			// using default values we got from fetchProfile API call
			firstName: valueObj[FormInputKeys.firstName].trim(),
			lastName: valueObj[FormInputKeys.lastName].trim(),
			description: valueObj[FormInputKeys.description].trim(),
			profileImage: valueObj[FormInputKeys.avatar],
			phoneNumber: isPhoneEmpty(valueObj[FormInputKeys.phoneNumber])
				? '' // ph# is not required, so send an empty string if it's empty.
				: valueObj[FormInputKeys.phoneNumber]?.formattedValue ||
				  valueObj[FormInputKeys.phoneNumber], // if the phoneNumber has been changd, we send a formatted ph#, otherwise the existing ph#.
			calendlyLink: valueObj[FormInputKeys.calendlyLink],
			webSite: valueObj[FormInputKeys.webSite],
			instagram: valueObj[FormInputKeys.instagram],
			linkedin: valueObj[FormInputKeys.linkedin],
			youtube: valueObj[FormInputKeys.youtube],
			facebook: valueObj[FormInputKeys.facebook],
			twitter: valueObj[FormInputKeys.twitter],
			videoPresentationLink: getVideoPresentationLink(
				valueObj[FormInputKeys.videoPresentationLink],
			),
			certificateTokens: valueObj[FormInputKeys.financialCertificates]?.map(
				item => item.token,
			),
			locationToken: valueObj[FormInputKeys.location]?.token, // if the user doesn't change their location, we pass their current location's token.
			companyToken: !valueObj[FormInputKeys.newCompanyCheck]
				? valueObj[FormInputKeys.company]?.token
				: null, // !
			companyName: valueObj[FormInputKeys.newCompanyCheck]
				? valueObj[FormInputKeys.companyName]
				: null, // !
			companyLocationToken: valueObj[FormInputKeys.newCompanyCheck]
				? valueObj[FormInputKeys.companyLocationToken]?.token
				: null, // !
			companyImage: valueObj[FormInputKeys.newCompanyCheck]
				? valueObj[FormInputKeys.companyLogo]
				: null, // !
			hashtagTokens,
			specializationTypes: valueObj[FormInputKeys.specializationValue]?.map(
				item => item.type,
			),
			token,
			contactEmail: valueObj[FormInputKeys.contactEmail],
		};
		if (role[0] === AdvisorsRole.SuperAdmin) {
			profile.isActive = getIsActiveValue(valueObj[FormInputKeys.isActive]);
			profile.email = valueObj[FormInputKeys.email];
			profile.reasonInactive =
				valueObj.isActive === 'true' ? null : valueObj[FormInputKeys.reasonInactive];
		}

		try {
			setIsSubmitting(true);
			await updateProfile(profile);

			Amplitude.track('Updated_Avisor_Profile');

			goBack();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === FormInputKeys.specializationValue) {
				await trigger(FormInputKeys.specializationValue);
			}
			if (name === FormInputKeys.description) {
				if (data?.description) setHintValue(data?.description?.length);
				await trigger(FormInputKeys.description);
			}
			if (name === FormInputKeys.company) {
				setCompanyImage(data.company?.image); //!
				await trigger(FormInputKeys.company);
			}
			if (name === FormInputKeys.videoPresentationLink) {
				await trigger(FormInputKeys.videoPresentationLink);
			}
			if (name === FormInputKeys.location) {
				await trigger(FormInputKeys.location);
			}
			if (name === FormInputKeys.financialCertificates) {
				await trigger(FormInputKeys.financialCertificates);
			}
			if (name === FormInputKeys.avatar) {
				await trigger(FormInputKeys.avatar);
			}
			if (role[0] === AdvisorsRole.SuperAdmin) {
				if (name === FormInputKeys.email) {
					await trigger(FormInputKeys.email);
				}
				if (name === FormInputKeys.reasonInactive) {
					await trigger(FormInputKeys.reasonInactive);
				}
				if (name === FormInputKeys.isActive) {
					if (data.isActive === 'true') {
						setIsVisibleReasonInactiveField(false);
					}
					if (data.isActive === 'false') {
						setIsVisibleReasonInactiveField(true);
					}
					await trigger(FormInputKeys.reasonInactive);
				}
			}
			if (name === FormInputKeys.contactEmail) {
				await trigger(FormInputKeys.contactEmail);
			}
			if (name === FormInputKeys.newCompanyCheck) {
				//!
				await trigger(FormInputKeys.newCompanyCheck);
			}
			if (name === FormInputKeys.companyLocationToken) {
				//!
				await trigger(FormInputKeys.companyLocationToken);
			}
			if (name === FormInputKeys.companyName) {
				//!
				await trigger(FormInputKeys.companyName);
			}
			if (name === FormInputKeys.companyLogo) {
				//!
				await trigger(FormInputKeys.companyLogo);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	useEffect(() => {
		// тут ставить стартовые значения, что бы корректно работала валидация и устанавливались defaultValue
		if (advisor) {
			setValue(FormInputKeys.firstName, advisor?.firstName);
			setValue(FormInputKeys.lastName, advisor?.lastName);
			setValue(FormInputKeys.company, {
				token: advisor?.companyToken,
				name: advisor?.companyName,
				image: advisor?.companyImage,
			});
			setCompanyImage(advisor?.companyImage); //!
			setValue(
				FormInputKeys.videoPresentationLink,
				advisor?.videoPresentationLink ? advisor?.videoPresentationLink : '',
			);
			setValue(FormInputKeys.location, advisor?.location);

			setValue(FormInputKeys.contactEmail, advisor?.contactEmail || '');
			setValue(FormInputKeys.financialCertificates, advisor?.financialCertificates);
			if (specializationDefaultValue)
				setValue(FormInputKeys.specializationValue, specializationDefaultValue);
			Object.values(HASHTAG_TYPES)?.forEach(tag => {
				setValue(
					tag.key,
					advisor?.hashtags?.filter(({type}) => type === tag.value),
				);
			});
			setValue(FormInputKeys.description, advisor?.description || '');
			setValue(FormInputKeys.avatar, advisor?.profileImage);
			if (role[0] === AdvisorsRole.SuperAdmin) {
				setValue(FormInputKeys.email, advisor?.email);
				setValue(FormInputKeys.isActive, `${advisor?.isActive}`);
				setValue(FormInputKeys.reasonInactive, advisor?.reasonInactive);
			}
		}
		setValue(FormInputKeys.newCompanyCheck, false); //!
	}, [advisor]);

	const isValidUserDataTab = () => {
		return (
			!!formProps.getValues(FormInputKeys.firstName) &&
			!formState.errors[FormInputKeys.firstName] &&
			!!formProps.getValues(FormInputKeys.lastName) &&
			!formState.errors[FormInputKeys.lastName] &&
			!!formProps.getValues(FormInputKeys.company) &&
			!formState.errors[FormInputKeys.company] &&
			!!formProps.getValues(FormInputKeys.avatar) &&
			!formState.errors[FormInputKeys.avatar] &&
			!!formProps.getValues(FormInputKeys.specializationValue).length &&
			!formState.errors[FormInputKeys.specializationValue] &&
			!!Object.keys(formProps.getValues(FormInputKeys.company)).length &&
			formProps.getValues(FormInputKeys.company)?.token !== NULL_TOKEN &&
			!formState.errors[FormInputKeys.company] &&
			(formProps.getValues(FormInputKeys.newCompanyCheck)
				? !!formProps.getValues(FormInputKeys.companyLocationToken) &&
				  !!formProps.getValues(FormInputKeys.companyName) &&
				  !!formProps.getValues(FormInputKeys.companyLogo) &&
				  !formState.errors[FormInputKeys.newCompanyCheck] &&
				  !formState.errors[FormInputKeys.companyLocationToken] &&
				  !formState.errors[FormInputKeys.companyName] &&
				  !formState.errors[FormInputKeys.companyLogo]
				: true)
		);
	};

	const isValidContactInformationTab = () => {
		return (
			!!formProps.getValues(FormInputKeys.location) &&
			!formState.errors[FormInputKeys.location] &&
			!formState.errors[FormInputKeys.contactEmail]
		);
	};

	const isValidLoginDataTab = () => {
		return !!formProps.getValues(FormInputKeys.email) && !formState.errors[FormInputKeys.email];
	};

	const labels =
		role[0] === AdvisorsRole.SuperAdmin
			? [
					<div
						className={classNames('tabsContainer__label', {
							alertTab: !isValidUserDataTab(),
						})}
						key="UserDataTab">
						User Data
					</div>,
					<div
						className={classNames('tabsContainer__label', {
							alertTab: !isValidContactInformationTab(),
						})}
						key="ContactInformationTab">
						Contact Information
					</div>,
					<div
						className={classNames('tabsContainer__label', {
							alertTab: !isValidLoginDataTab(),
						})}
						key="LoginDataTab">
						Login Data
					</div>,
			  ]
			: [
					<div
						className={classNames('tabsContainer__label', {
							alertTab: !isValidUserDataTab(),
						})}
						key="UserDataTab">
						User Data
					</div>,
					<div
						className={classNames('tabsContainer__label', {
							alertTab: !isValidContactInformationTab(),
						})}
						key="ContactInformationTab">
						Contact Information
					</div>,
			  ];

	const isUserDataSubmitDisabled = !formState.isValid || !isValidUserDataTab();
	const isContactInformationSubmitDisabled =
		!formState.isValid || !isValidContactInformationTab();
	const isAdminSubmitDisabled =
		!formState.isValid ||
		(role[0] === AdvisorsRole.SuperAdmin ? !isValidLoginDataTab() : false);

	const getIsSubmitDisabled = () => {
		if (tabIndex === 0) {
			return isUserDataSubmitDisabled;
		}

		if (tabIndex === 1) {
			if (labels.length === 2) {
				return isUserDataSubmitDisabled || isContactInformationSubmitDisabled;
			}

			return isContactInformationSubmitDisabled;
		}

		if (tabIndex === 2) {
			return (
				isUserDataSubmitDisabled ||
				isContactInformationSubmitDisabled ||
				isAdminSubmitDisabled
			);
		}

		return true;
	};

	const isSubmitDisabled = getIsSubmitDisabled() || isSubmitting;

	return (
		<form className="pf-profileEdit__form">
			<PFTabsContainer tabIndex={tabIndex} onTabIndexChage={setTabIndex} labels={labels}>
				<UserDataFormBloc
					advisor={advisor}
					formProps={formProps}
					hintValue={hintValue}
					companyImage={companyImage}
				/>
				<ContactInformationFormBloc advisor={advisor} formProps={formProps} />
				{role[0] === AdvisorsRole.SuperAdmin && (
					<div>
						<LoginDataFormBloc advisor={advisor} formProps={formProps} />
						{advisor && (
							<>
								<div className="col-12">
									<h4>Is Active</h4>
								</div>
								<div className={`${fullWidthClass} isActive`}>
									<PFRadioGroup
										control={control}
										name={FormInputKeys.isActive}
										// @ts-ignore
										disabled={false}
										variant="horizontal"
										defaultValue={`${advisor.isActive}`}
										options={[
											{
												value: 'true',
												label: 'Yes',
												disabled: false,
											},
											{
												value: 'false',
												label: 'No',
												disabled: false,
											},
										]}
										error={
											formState.errors[FormInputKeys.isActive]?.message &&
											formState.errors[FormInputKeys.isActive]?.message
										}
									/>
								</div>
								<div
									className={classNames(`${fullWidthClass} reasonInactive`, {
										disabled: !isVisibleReasonInactiveField,
									})}>
									<PFSelect
										{...register(FormInputKeys.reasonInactive, {
											required: isVisibleReasonInactiveField,
										})}
										// @ts-ignore
										defaultValue={
											(!!formProps.getValues(
												FormInputKeys.reasonInactive,
											) && {
												name: formProps.getValues(
													FormInputKeys.reasonInactive,
												),
											}) ||
											(advisor?.reasonInactive && {
												name: advisor?.reasonInactive,
											}) ||
											null
										}
										showAsterisk={false}
										label={false}
										placeholder="State the reason"
										disabled={false}
										isMulti={false}
										// @ts-ignore
										closeMenuOnSelect
										isAsync={false}
										onChange={value => {
											setValue(FormInputKeys.reasonInactive, value.name);
										}}
										getOptionValue={item => item.name}
										options={INACTIVE_REASON_OPTIONS_TYPES}
										formatOptionLabel={option => option.name}
										defaultOptions
										error={
											formState.errors[FormInputKeys.reasonInactive]
												?.message &&
											formState.errors[FormInputKeys.reasonInactive]?.message
										}
									/>
								</div>
							</>
						)}
					</div>
				)}
			</PFTabsContainer>

			<div className="d-flex justify-content-end">
				<PFButton
					onClick={goBack}
					className="pf-profileEdit__form-button"
					variant="secondary"
					type="button">
					Cancel
				</PFButton>
				<PFButton
					disabled={isSubmitDisabled}
					//! disabled={!formState.isValid || isSubmitting}
					className="pf-profileEdit__form-button pf-profileEdit__form-button--submit"
					type="button"
					onClick={
						tabIndex === labels.length - 1
							? handleSubmit(onSubmit)
							: () => {
									setTabIndex(prevTabIndex => {
										return prevTabIndex + 1;
									});
							  }
					}>
					{tabIndex === labels.length - 1 ? 'Save' : 'Next'}
				</PFButton>
			</div>
		</form>
	);
};

export default EditProfileForm;
