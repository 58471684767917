import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';
import axios from 'axios';

export const getFinancialReportsByToken = token =>
	httpClient.get(`${URL.BASE_URL}/${URL.FINANCIAL_REPORTS.INDEX}/${token}`);

export const requestReport = body =>
	httpClient.post(`${URL.BASE_URL}/${URL.FINANCIAL_REPORTS.INDEX}/request`, body);

export const resetIsNewReport = token =>
	httpClient.post(`${URL.BASE_URL}/${URL.FINANCIAL_REPORTS.INDEX}/resetIsNew/${token}`);

export const getFinancialReportPdf = token =>
	axios.get(`${URL.FINANCIAL_REPORTS_SERVER_URL}/pdf-reports/${token}.pdf`, {
		responseType: 'blob',
	});
