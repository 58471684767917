import {Specialization} from '../../../types/enums';
import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
export type SendDeepLinkParamsType = {
	email: string;
	deepLink: string;
	advisorTokenWhoseLink: string | null;
	types: Specialization[];
};

export const confirm = ({key, token}) => {
	return httpClient.put(`${URL.BASE_URL}/${URL.EMAIL.INDEX}`, {
		key,
		token,
	});
};

export const inviteAdvisors = body =>
	httpClient.post(`${URL.BASE_URL}/${URL.EMAIL.INDEX}/inviteAdvisors`, body);

export const inviteAdvisor = body =>
	httpClient.post(`${URL.BASE_URL}/${URL.EMAIL.INDEX}/inviteAdvisor`, body);

export const create = body => httpClient.post(`${URL.BASE_URL}/${URL.EMAIL.INDEX}`, body);

export const updateMyEmail = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.EMAIL.INDEX}/setEmail`, body);

export const sendDeepLink = (body: SendDeepLinkParamsType) =>
	httpClient.post(`${URL.BASE_URL}/${URL.EMAIL.INDEX}/sendDeepLink`, body);
