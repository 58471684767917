import React, {FC} from 'react';

import classNames from 'classnames';

import './style.scss';

type PropsTypes = {
	isDeleted: boolean;
	token: string;
	onClick: (string) => void;
};

const RestoreButton: FC<PropsTypes> = ({isDeleted, token, onClick}) => {
	return (
		<button
			type="button"
			className={classNames('restore', {
				deleted: isDeleted,
			})}
			onClick={() => onClick(token)}>
			Restore
		</button>
	);
};

export default RestoreButton;
