import React, {FC, ReactNode} from 'react';
import {ReactSVG} from 'react-svg';

import PFLink from 'shared/components/PF-Link';

import './style.scss';

type PropsTypes = {
	link: string;
	children: ReactNode;
	icon: string;
};

const AdvisorProfileSocialLink: FC<PropsTypes> = ({link, children, icon}) => {
	const isAnExternalLink = str =>
		str?.startsWith('https://') || str?.startsWith('http://') || str?.startsWith('//');

	const newLink = isAnExternalLink(link) ? link : `//${link}`;

	return (
		<div className="social__item">
			<ReactSVG className="social__icon" wrapper="span" src={icon} />
			<PFLink className="social__link" target="_blank" href={newLink}>
				{children}
			</PFLink>
		</div>
	);
};

export default AdvisorProfileSocialLink;
