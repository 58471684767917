import {generateCopyToClipboardHandler} from '../../../utils/futureTS/generalUtils';
import {Amplitude, ToastDispatcher} from '../../../utils';

export const useHandleCopyDeepLink = () => {
	return generateCopyToClipboardHandler(
		() => {
			ToastDispatcher.success('Link copied to clipboard');
		},
		() => {
			ToastDispatcher.error('Link has not been copied. Please, try again.');
		},
	);
};

export const useHandleCopyCalendarLink = () => {
	return generateCopyToClipboardHandler(
		() => {
			ToastDispatcher.success('Calendar link copied to your clipboard');
		},
		() => {
			ToastDispatcher.error('Error');
		},
	);
};

export const useHandleCopyApiKey = () => {
	return generateCopyToClipboardHandler(
		() => {
			ToastDispatcher.success('API key copied to the clipboard');
		},
		() => {
			ToastDispatcher.error('Error');
		},
	);
};

export const useHandleCopyRegistrationLink = () => {
	return generateCopyToClipboardHandler(
		() => {
			ToastDispatcher.success('Registration link copied to your clipboard');
		},
		() => {
			ToastDispatcher.error('Error');
		},
	);
};
