import React from 'react';
import {ReactSVG} from 'react-svg';
import './style.scss';

const PFDropdownItem = ({children, icon, className, onClick}) => {
	return (
		<div className={`dropdownItem ${className}`} onClick={onClick}>
			{icon && <ReactSVG wrapper="div" src={icon} />}
			<span>{children}</span>
		</div>
	);
};

export default PFDropdownItem;
