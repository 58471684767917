import {
	CLEANED_UP_TABLE_DATA,
	SET_SELECTED_TABLE_ROW_DATA,
	SET_CHECKED_DATA,
	SET_PAGE,
	SET_DISPLAY_LENGTH,
	SET_SEARCH,
	SET_SORT_PARAM,
	SET_RESET,
	REMOVE_CHECKED_ITEMS,
	SET_SHOW_SEARCH,
} from '../types/Types';
import {updateURL} from '../../utils';

const INITIAL_STATE = {
	selectedTableRow: {},
	modalData: {},
	checkedData: [],
	page: 1,
	displayLength: 25,
	search: '',
	showSearch: false,
	predicate: '',
	reverse: false,
	reset: false,
};

const tableReducer = (state = INITIAL_STATE, {payload, type}) => {
	switch (type) {
		case CLEANED_UP_TABLE_DATA:
			return {
				...state,
				selectedTableRow: {},
				modalData: {...state.modalData}, // было без деструктуризации и фигурных скобок
				checkedData: [],
				page: 1,
				displayLength: 25,
				search: state.search,
				showSearch: false,
				predicate: '',
				reverse: false,
				reset: state.reset,
			};

		case SET_SELECTED_TABLE_ROW_DATA:
			return {
				...state,
				selectedTableRow: payload,
				modalData: payload,
			};
		case SET_CHECKED_DATA:
			return {
				...state,
				checkedData: payload,
			};
		case REMOVE_CHECKED_ITEMS:
			return {
				...state,
				checkedData: state.checkedData.filter(token => !payload.includes(token)), //!
			};
		case SET_PAGE:
			return {
				...state,
				page: payload,
			};
		case SET_DISPLAY_LENGTH:
			return {
				...state,
				displayLength: payload,
			};
		case SET_SHOW_SEARCH:
			return {
				...state,
				showSearch: payload,
			};
		case SET_SEARCH:
			updateURL(1, state.displayLength, state.predicate, state.reverse, payload);
			return {
				...state,
				page: 1,
				search: payload,
			};
		case SET_SORT_PARAM:
			updateURL(1, state.displayLength, payload, !state.reverse, state.search);
			return {
				...state,
				page: 1,
				predicate: payload,
				reverse: !state.reverse,
			};
		case SET_RESET:
			updateURL(1, 25, '', false, '');
			return {
				...INITIAL_STATE,
				reset: !state.reset,
			};
		default:
			return state;
	}
};

export default tableReducer;
