import React, {useEffect, useRef, useState} from 'react';
import {ReactSVG} from 'react-svg';
import {Virtuoso} from 'react-virtuoso';

import {advisorClientsService, ecosystemService} from 'api/services';
import {closeIcon, loaderIcon} from 'assets';
import {PORTAL_PATHS} from 'const';
import {Input, InputGroup} from 'rsuite';
import PFButton from 'shared/components/PF-Button';
import PFLink from 'shared/components/PF-Link';
import PFPopup from 'shared/components/PF-Popup';
import {useAppDispatch, useAppSelector} from 'store';
import {setRecommendCheckedKey} from 'store/actions';
import {selectRecommendPopupCheckedKeys} from 'store/selectors';
import {CurrentUser} from 'types';
import {Amplitude, ToastDispatcher} from 'utils';

import EmptyScreen from './EmptyScreen';
import VirtuosoFooter from './VirtuosoFooter';
import VirtuosoItem from './VirtuosoItem';

import './style.scss';
import style from './style.module.scss';

const RecommendPopup: React.FC<{
	visible: boolean;
	onCancel: () => void;
	title: string;
	advisorTokens: string[];
	onSubmit?: () => void;
	className?: string;
}> = ({visible, onCancel, title, advisorTokens, onSubmit, className}) => {
	const dispatch = useAppDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [debouncedValue, setDebouncedValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [currentUsers, setCurrentUsers] = useState<CurrentUser[]>([]);
	const [isFull, setIsFull] = useState<boolean>(false);
	const checkedKeys = useAppSelector(selectRecommendPopupCheckedKeys);
	const inputEl = useRef(null);

	const onChangeSearchText = text => {
		const searchValue = text.replace(/^\s*/g, '');
		setSearchText(searchValue);
	};

	const onRemoveSearchText = () => {
		setSearchText('');
	};

	const handleClose = () => {
		dispatch(setRecommendCheckedKey(0));
		onCancel();
	};

	const onRecommend = async () => {
		try {
			setIsSubmitting(true);
			await ecosystemService.recommendAdvisorToUsers({
				advisorRecommendedTokens: advisorTokens,
				userTokens: checkedKeys,
			});

			Amplitude.track('Recommended_Advisor', {
				advisors: advisorTokens,
				clients: checkedKeys,
			});

			dispatch(setRecommendCheckedKey(0));
			ToastDispatcher.success(`Recommendation sent.`);
			await onSubmit?.();
			onCancel();
			// eslint-disable-next-line no-useless-catch
		} catch (e: any) {
			ToastDispatcher.error(`Failed sent recommendation. Please try again`);
		} finally {
			setIsSubmitting(false);
		}
	};

	const searchCurrentUsers = async () => {
		const params = {
			type: 2,
			take: 10,
			skip: currentUsers.length,
			predicate: '',
			reverse: false,
			searchText,
		};
		try {
			const response = await advisorClientsService.getAdvisorClients(params);
			if (
				currentUsers.length + response.data.body.length >= response.data.fullCount ||
				!response.data.body.length
			) {
				setIsFull(true);
			}
			if (response.data.body.length) {
				setCurrentUsers([...currentUsers, ...response.data.body]);
			}
			// eslint-disable-next-line no-useless-catch
		} catch (e) {
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const getVirtuosoItem = (index, item) => {
		return <VirtuosoItem key={item.token} data={item} />;
	};

	const loadMore = async () => {
		if (!isFull) {
			await searchCurrentUsers();
		}
	};

	useEffect(() => {
		setCurrentUsers([]);
		setIsFull(false);
		setIsLoading(true);
		if (visible) searchCurrentUsers();
	}, [debouncedValue, visible]);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(searchText);
		}, 500);
		return () => {
			clearTimeout(handler);
		};
	}, [searchText]);

	return (
		<PFPopup
			isShow={visible}
			handleClose={handleClose}
			isShowFooter={false}
			submitBtnText="Recommend"
			primaryVariant="alert"
			isCloseButton
			isShowCancelBtn={false}
			title={title}
			onSubmit={() => false}
			subtitle="Select the clients you would like to recommend this advisor to:"
			className={{
				root: style.recommendModal,
				paper: style.recommendModal_paper,
				contentBody: style.recommendModal_contentBody,
			}}>
			<div className={style.recommendModal_main}>
				<div className={style.searchSection}>
					<InputGroup
						inside
						className="recommendModal-inputGroup"
						id="example-collapse-text">
						<Input
							className="recommendModal-inputGroup__input"
							placeholder="Search..."
							onChange={onChangeSearchText}
							value={searchText}
							inputRef={inputEl}
							maxLength={85}
							autoFocus={false}
						/>
						<InputGroup.Addon
							className="recommendModal-inputGroup__addon"
							onClick={onRemoveSearchText}>
							<div>
								<ReactSVG wrapper="span" src={closeIcon} />
							</div>
						</InputGroup.Addon>
					</InputGroup>
				</div>
				<div className={style.searchResultSection}>
					{!isLoading && (
						<Virtuoso
							style={{height: '100%', width: '100%'}}
							data={currentUsers}
							endReached={loadMore}
							itemContent={getVirtuosoItem}
							components={!isFull ? {Footer: VirtuosoFooter} : {}}
						/>
					)}
					{!isLoading && !currentUsers.length && (
						<EmptyScreen
							description={
								debouncedValue !== '' ? (
									`No results were found for your search.\n Refine your request`
								) : (
									<div>
										You have no clients in your Current list.
										<br />{' '}
										<PFLink to={PORTAL_PATHS.SIDEBAR.LEADS}>
											Visit Leads
										</PFLink>{' '}
										to get new clients.
									</div>
								)
							}
						/>
					)}
					{isLoading && (
						<div className={style.loadingWrapper}>
							<ReactSVG src={loaderIcon} className={style.spinnerIcon} />
						</div>
					)}
				</div>
				<div className={style.searchActionsSection}>
					<div className={style.selectedCount}>{checkedKeys.length} selected</div>
					<button
						type="button"
						className={style.clearAllButton}
						onClick={() => dispatch(setRecommendCheckedKey(0))}>
						<ReactSVG
							wrapper="span"
							src={closeIcon}
							className={style.clearAllButton__icon}
						/>
						<span className={style.clearAllButton__text}>Clear All</span>
					</button>
				</div>
			</div>
			<div className={style.recommendModal_footer}>
				<div className={style.buttonsWrapper}>
					<PFButton onClick={handleClose} variant="secondary" type="button">
						Cancel
					</PFButton>
					<PFButton
						disabled={isSubmitting || !checkedKeys.length}
						variant="primary"
						onClick={onRecommend}
						className={style.recommendButton}
						type="button">
						Recommend
					</PFButton>
				</div>
			</div>
		</PFPopup>
	);
};

export default RecommendPopup;
