import React, {FC, useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {COMPANY_OPTIONS} from 'const';
import PFInput from 'shared/components/PF-Input';
import PFRadioGroup from 'shared/components/PF-RadioGroup';
import PFSelect from 'shared/components/PF-Select';
import {LifeInsuranceType} from 'types';
import {
	generateValidator,
	getAmountInsuranceValue,
	getDays,
	getYears,
	parseDate,
	validations,
} from 'utils';

import FormInputKeys, {
	INSURANCE_PAYER_OPTIONS,
	MONTHS,
	TYPE_BY_DURATION_OPTIONS,
} from '../constants';

import {BeneficiariesForm} from './components';

type PropsTypes = {
	formProps: UseFormReturn;
	lifeInsurance?: LifeInsuranceType;
};

const GeneralLifeInsuranceFormBloc: FC<PropsTypes> = ({lifeInsurance, formProps}) => {
	const halfClass = 'col-lg-6 mb-4';
	const fullClass = 'col-12 mb-4';
	const yearOptions = getYears(10);
	const daysOptions = getDays(31);
	const lifeInsuranceDate = parseDate(lifeInsurance?.policyRenewalDate);

	const companies = useMemo(() => {
		const options = [...COMPANY_OPTIONS].filter(option => !option.isNew);

		if (lifeInsurance?.customInsuranceCompanyName) {
			options.push({
				value: lifeInsurance?.customInsuranceCompanyName,
				label: lifeInsurance?.customInsuranceCompanyName,
				isNew: true,
			});
		}

		return options;
	}, [lifeInsurance?.customInsuranceCompanyName]);

	return (
		<>
			<h4 className="mt-0">Is this through your employer or independently purchased?</h4>
			<div className={`${fullClass} insurancePayer`}>
				<PFRadioGroup
					control={formProps.control}
					name={FormInputKeys.insurancePayer}
					// @ts-ignore
					disabled={false}
					defaultValue={
						formProps.getValues(FormInputKeys.insurancePayer) ||
						(lifeInsurance &&
							typeof lifeInsurance?.insurancePayer === 'number' &&
							String(lifeInsurance?.insurancePayer)) //!
					}
					options={INSURANCE_PAYER_OPTIONS}
				/>
			</div>
			<h4>Type by duration</h4>
			<div className={`${fullClass} typeByDuration`}>
				<PFRadioGroup
					control={formProps.control}
					name={FormInputKeys.typeByDuration}
					// @ts-ignore
					disabled={false}
					defaultValue={
						formProps.getValues(FormInputKeys.typeByDuration) ||
						(lifeInsurance &&
							typeof lifeInsurance?.typeByDuration === 'number' &&
							String(lifeInsurance?.typeByDuration))
					}
					options={TYPE_BY_DURATION_OPTIONS}
				/>
			</div>
			{formProps.getValues(FormInputKeys.typeByDuration) === '1' && (
				<div className="policyRenewalDate">
					<div className="month">
						<PFSelect
							{...formProps.register(FormInputKeys.month, {
								required: false,
							})}
							// @ts-ignore
							defaultValue={
								MONTHS.find(
									item => item.value === formProps.getValues(FormInputKeys.month),
								) ||
								MONTHS.find(item => item.value === lifeInsuranceDate?.month) ||
								''
							}
							showAsterisk={false}
							label="Policy renewal date"
							placeholder="Month"
							disabled={false}
							isMulti={false}
							closeMenuOnSelect
							isAsync={false}
							onChange={value => {
								formProps.setValue(FormInputKeys.month, value.value);
							}}
							getOptionValue={option => option.value}
							options={MONTHS}
							formatOptionLabel={option => option.label}
							defaultOptions
						/>
					</div>
					<div className="day">
						<PFSelect
							{...formProps.register(FormInputKeys.day, {
								required: false,
							})}
							// @ts-ignore
							defaultValue={
								daysOptions.find(
									item => item.value === formProps.getValues(FormInputKeys.day),
								) ||
								daysOptions.find(item => item.value === lifeInsuranceDate?.day) ||
								''
							}
							showAsterisk={false}
							label={false}
							placeholder="Day"
							disabled={false}
							isMulti={false}
							closeMenuOnSelect
							isAsync={false}
							onChange={value => {
								formProps.setValue(FormInputKeys.day, value.value);
							}}
							getOptionValue={option => option.value}
							options={daysOptions}
							formatOptionLabel={option => option.label}
							defaultOptions
						/>
					</div>
					<div className="year">
						<PFSelect
							{...formProps.register(FormInputKeys.year, {
								required: false,
							})}
							// @ts-ignore
							defaultValue={
								yearOptions.find(
									item => item.value === formProps.getValues(FormInputKeys.year),
								) ||
								yearOptions.find(item => {
									return item.value === lifeInsuranceDate?.year;
								}) ||
								''
							}
							showAsterisk={false}
							label={false}
							placeholder="Year"
							disabled={false}
							isMulti={false}
							closeMenuOnSelect
							isAsync={false}
							onChange={value => {
								formProps.setValue(FormInputKeys.year, value.value);
							}}
							getOptionValue={option => option.value}
							options={yearOptions}
							formatOptionLabel={option => option.label}
							defaultOptions
						/>
					</div>
				</div>
			)}
			{formProps.getValues(FormInputKeys.typeByDuration) === '2' && (
				<>
					<div>
						<h4 className="mt-0">Does this policy have cash?</h4>
						<div className={`${fullClass} isCashInThePolicy`}>
							<PFRadioGroup
								control={formProps.control}
								name={FormInputKeys.isCashInThePolicy}
								// @ts-ignore
								disabled={false}
								defaultValue={
									formProps.getValues(FormInputKeys.isCashInThePolicy) ||
									(lifeInsurance &&
										typeof lifeInsurance?.isCashInThePolicy === 'boolean' &&
										String(lifeInsurance?.isCashInThePolicy)) ||
									'true'
								}
								options={[
									{
										value: 'true',
										label: 'Yes',
										disabled: false,
									},
									{
										value: 'false',
										label: 'No',
										disabled: false,
									},
								]}
							/>
						</div>
						{formProps.getValues(FormInputKeys.isCashInThePolicy) === 'true' && (
							<div className={`${fullClass} amountCashInThePolicy`}>
								<PFInput
									{...formProps.register(FormInputKeys.amountCashInThePolicy, {
										required: false,
									})}
									// @ts-ignore
									showAsterisk={false}
									label="Cash worth"
									placeholder="Enter amount, $"
									onChange={e => {
										const newValue = getAmountInsuranceValue(e.target.value);
										e.target.value = newValue;
										formProps.setValue(
											FormInputKeys.amountCashInThePolicy,
											newValue,
										);
									}}
									defaultValue={
										formProps.getValues(FormInputKeys.amountCashInThePolicy) ||
										(lifeInsurance && lifeInsurance?.amountCashInThePolicy) ||
										''
									}
									error={
										formProps.formState.errors[
											FormInputKeys.amountCashInThePolicy
										]?.message &&
										formProps.formState.errors[
											FormInputKeys.amountCashInThePolicy
										]?.message
									}
								/>
							</div>
						)}
					</div>
					<div>
						<h4>Does this policy have a loan against it?</h4>
						<div className={`${fullClass} isLoanAgainstPolicy`}>
							<PFRadioGroup
								control={formProps.control}
								name={FormInputKeys.isLoanAgainstPolicy}
								// @ts-ignore
								disabled={false}
								defaultValue={
									formProps.getValues(FormInputKeys.isLoanAgainstPolicy) ||
									(lifeInsurance &&
										typeof lifeInsurance?.isLoanAgainstPolicy === 'boolean' &&
										String(lifeInsurance?.isLoanAgainstPolicy)) ||
									'true'
								}
								options={[
									{
										value: 'true',
										label: 'Yes',
										disabled: false,
									},
									{
										value: 'false',
										label: 'No',
										disabled: false,
									},
								]}
							/>
						</div>
						{formProps.getValues(FormInputKeys.isLoanAgainstPolicy) === 'true' && (
							<div className={`${fullClass} amountLoanAgainstPolicy`}>
								<PFInput
									{...formProps.register(FormInputKeys.amountLoanAgainstPolicy, {
										required: false,
									})}
									// @ts-ignore
									showAsterisk={false}
									label="Outstanding loan value approx."
									placeholder="Enter amount, $"
									onChange={e => {
										const newValue = getAmountInsuranceValue(e.target.value);
										e.target.value = newValue;
										formProps.setValue(
											FormInputKeys.amountLoanAgainstPolicy,
											newValue,
										);
									}}
									defaultValue={
										formProps.getValues(
											FormInputKeys.amountLoanAgainstPolicy,
										) ||
										(lifeInsurance && lifeInsurance?.amountLoanAgainstPolicy) ||
										''
									}
									error={
										formProps.formState.errors[
											FormInputKeys.amountLoanAgainstPolicy
										]?.message &&
										formProps.formState.errors[
											FormInputKeys.amountLoanAgainstPolicy
										]?.message
									}
								/>
							</div>
						)}
					</div>
				</>
			)}
			<h4>General Information</h4>
			<div className={`${halfClass} amountOfCoverage`}>
				<PFInput
					{...formProps.register(FormInputKeys.amountOfCoverage, {
						required: false,
					})}
					// @ts-ignore
					showAsterisk={false}
					label="What is the amount of the coverage?"
					placeholder="Enter amount, $"
					onChange={e => {
						const newValue = getAmountInsuranceValue(e.target.value);
						e.target.value = newValue;
						formProps.setValue(FormInputKeys.amountOfCoverage, newValue);
					}}
					defaultValue={
						formProps.getValues(FormInputKeys.amountOfCoverage) ||
						(lifeInsurance && lifeInsurance?.amountOfCoverage) ||
						''
					}
					error={
						formProps.formState.errors[FormInputKeys.amountOfCoverage]?.message &&
						formProps.formState.errors[FormInputKeys.amountOfCoverage]?.message
					}
				/>
			</div>
			<div className={`${fullClass} insuranceCompany`}>
				<PFSelect
					{...formProps.register(FormInputKeys.insuranceCompany, {
						required: false,
					})}
					// @ts-ignore
					defaultValue={
						companies.find(
							item =>
								item.value === formProps.getValues(FormInputKeys.insuranceCompany),
						) ||
						companies.find(item => item.value === lifeInsurance?.insuranceCompany) ||
						companies.find(
							item =>
								item.value ===
								formProps.getValues(FormInputKeys.customInsuranceCompanyName),
						) ||
						companies.find(
							item => item.value === lifeInsurance?.customInsuranceCompanyName,
						) ||
						''
					}
					showAsterisk={false}
					label="Insurance company"
					placeholder="Select company from the list or type your own option"
					disabled={false}
					isMulti={false}
					closeMenuOnSelect
					isAsync={false}
					isClearable
					onChange={value => {
						if (value) {
							if (typeof value.value === 'number') {
								formProps.setValue(FormInputKeys.insuranceCompany, value.value);
								formProps.setValue(FormInputKeys.customInsuranceCompanyName, null);
							}
							if (typeof value.value === 'string') {
								formProps.setValue(
									FormInputKeys.customInsuranceCompanyName,
									value.value,
								);
								formProps.setValue(FormInputKeys.insuranceCompany, value.value);
							}
						} else {
							formProps.setValue(FormInputKeys.insuranceCompany, null);
							formProps.setValue(FormInputKeys.customInsuranceCompanyName, null);
						}
					}}
					getOptionValue={option => option.value}
					options={companies}
					formatOptionLabel={option => option.label}
					defaultOptions
					error={
						formProps.formState.errors[FormInputKeys.insuranceCompany]?.message &&
						formProps.formState.errors[FormInputKeys.insuranceCompany]?.message
					}
				/>
			</div>
			<div className={`${halfClass} policyNumber`}>
				<PFInput
					{...formProps.register(FormInputKeys.policyNumber, {
						validate: generateValidator(false, validations.textFields.policyNumber),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Policy Number"
					placeholder="Enter the number"
					defaultValue={
						formProps.getValues(FormInputKeys.policyNumber) ||
						(lifeInsurance && lifeInsurance?.policyNumber) ||
						''
					}
					error={
						formProps.formState.errors[FormInputKeys.policyNumber]?.message &&
						formProps.formState.errors[FormInputKeys.policyNumber]?.message
					}
				/>
			</div>
			<div className={`${fullClass} insured`}>
				<PFInput
					{...formProps.register(FormInputKeys.insured, {
						required: false,
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Who is the policy for?"
					placeholder="Who is the insured?"
					defaultValue={
						formProps.getValues(FormInputKeys.insured) ||
						(lifeInsurance && lifeInsurance?.insured) ||
						''
					}
					error={
						formProps.formState.errors[FormInputKeys.insured]?.message &&
						formProps.formState.errors[FormInputKeys.insured]?.message
					}
				/>
			</div>
			<div className="col-12 purposeOfPlan">
				<PFInput
					{...formProps.register(FormInputKeys.purposeOfPlan, {
						required: false,
					})}
					// @ts-ignore
					showAsterisk={false}
					label="What is the purpose of this plan?"
					placeholder="Enter the purpose"
					defaultValue={formProps.getValues(FormInputKeys.purposeOfPlan)}
				/>
			</div>
			<BeneficiariesForm formProps={formProps} />
			<div className={`${fullClass}`}>
				<PFInput
					{...formProps.register(FormInputKeys.trusteeName, {
						required: false,
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Trustee"
					placeholder="Enter client’s trustee full name"
					defaultValue={formProps.getValues(FormInputKeys.trusteeName)}
				/>
			</div>
		</>
	);
};

export default GeneralLifeInsuranceFormBloc;
