import axios from 'axios';
import URL from '../url-paths';
import * as Qs from 'qs';
import Storage from '../../utils/futureTS/localStorage';
import { ToastDispatcher } from '../../utils/ToastDispatcher';
import { AUTH_TOKEN, CHANGED_ROLE_ERROR_MSG, REFRESH_TOKEN } from './apiConst';

const httpClient = axios.create({
	timeout: 50000,
	baseURL: URL.BASE_URL,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
		'Cache-Control': 'no-cache',
		Pragma: 'no-cache',
		Expires: '0',
	},
	paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

httpClient.interceptors.request.use(
	async config => {
		const token = await Storage.getData(AUTH_TOKEN);
		if (token && config.headers) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	},
);

httpClient.interceptors.response.use(
	response => response,
	async error => {
		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			const refreshToken = await Storage.getData(REFRESH_TOKEN);

			if (error.response.data === CHANGED_ROLE_ERROR_MSG) {
				ToastDispatcher.success(
					'Your company role has been changed. Please sign in again.',
				);

				return Promise.reject(error);
			} else {
				return await httpClient
					.put(`${URL.BASE_URL}/${URL.AUTH.INDEX}`, {
						refreshToken,
					})
					.then(res => {
						if (res.status === 200) {
							// Update tokens in local storage
							Storage.saveData(AUTH_TOKEN, res.data.authToken);
							Storage.saveData(REFRESH_TOKEN, res.data.refreshToken);
							// Retry original request
							return httpClient(originalRequest);
						}
					})
					.catch(err => {
						ToastDispatcher.success('Session expired. Please sign in again');

						// Clear local storage
						console.log('Clearing local storage due to session expiration');
						Storage.cleanStorage();

						// Redirect to login screen
						console.log('Redirecting to login screen due to session expiration');
						window.location.href = '/login';
						throw err;
					});
			}
		}
		return Promise.reject(error);
	},
);

export default httpClient;
