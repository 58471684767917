import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AdvisorType} from 'types';

interface AdvisorsForEcoState {
	advisors: AdvisorType[];
	fullCount: number;
}
type SetAdvisorsDataActionType = {
	body: any[];
	fullCount: number;
};

type RemoveAdvisorsItemActionType = {
	tokens: string[];
};

const initialState: AdvisorsForEcoState = {
	advisors: [],
	fullCount: 0,
};

export const advisorsForEcoSlice = createSlice({
	name: 'advisorsForEco',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setAdvisorsData: (state, action: PayloadAction<SetAdvisorsDataActionType>) => {
			state.advisors = action.payload.body;
			state.fullCount = action.payload.fullCount;
		},
		cleanUpAdvisorsData: state => {
			state.advisors = initialState.advisors;
			state.fullCount = initialState.fullCount;
		},
		removeAdvisorsItem: (state, action: PayloadAction<RemoveAdvisorsItemActionType>) => {
			state.advisors = state.advisors.filter(
				advisor => !action.payload.tokens.includes(advisor.token),
			);
		},
	},
});

export default advisorsForEcoSlice.reducer;
