import {useDispatch} from 'react-redux';
import * as APIInstancesService from '../../../api/services/apiInstances';
import {setAPIData} from '../../../store/actions';
import React, {useState} from 'react';
import PFPopup from '../../components/PF-Popup';
import {ToastDispatcher} from '../../../utils';

export const useApiData = params => {
	const dispatch = useDispatch();

	return async (payload = params) => {
		try {
			const response = await APIInstancesService.getApi(params);
			dispatch(setAPIData(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useRestoreApi = token => {
	return async (payload = token) => {
		try {
			const response = await APIInstancesService.restoreApi(payload);
			ToastDispatcher.success('API restored');
			return response.data;
		} catch (e) {
			ToastDispatcher.error(`${e.message}`);
			throw e;
		}
	};
};

export const useApiDataByToken = token => {
	return async (payload = token) => {
		try {
			const response = await APIInstancesService.getApiByToken(payload);
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useDeleteApi = token => {
	return async (payload = token) => {
		try {
			const response = await APIInstancesService.deleteApi(payload);
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useAddNewApi = () => {
	return async body => {
		try {
			const res = await APIInstancesService.setApi(body);
			ToastDispatcher.success('API instance added');
			return res;
		} catch (e) {
			ToastDispatcher.error(`${e.message}`);
			throw e;
		}
	};
};

export const useEditApi = () => {
	return async body => {
		try {
			const res = await APIInstancesService.editApi(body);
			ToastDispatcher.success('API instance updated');
			return res;
		} catch (e) {
			ToastDispatcher.error(`${e.message}`);
			throw e;
		}
	};
};

export const useDeletedApiPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showDeletedAPIConfirm = () => setShowConfirmation(true);
	const cancelDeletedAPIConfirm = () => setShowConfirmation(false);
	return {
		ConfirmDeleteAPIModal: ({onSubmit, checkedKeys, className, name}) => {
			let message = `Delete API Instance?`;
			if (checkedKeys && checkedKeys.length >= 1) {
				message = `Delete ${checkedKeys.length > 1 ? checkedKeys.length : ''} API Instance${
					checkedKeys.length > 1 ? 's' : ''
				}?`;
			}
			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelDeletedAPIConfirm}
					submitBtnText={'Delete'}
					primaryVariant="alert"
					isCloseButton
					title={message}
					subtitle={false}
					onSubmit={() => {
						onSubmit?.(); //if we pass any onSubmit function, it'll call this, otherwise we'll just signout the user.
						cancelDeletedAPIConfirm();
					}}
					className={{
						root: `${className || ''}`,
					}}
				/>
			);
		},
		showConfirmation,
		showDeletedAPIConfirm,
		cancelDeletedAPIConfirm,
	};
};

export const useDeletedAloneApiPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showDeletedAloneAPIConfirm = () => setShowConfirmation(true);
	const cancelDeletedAloneAPIConfirm = () => setShowConfirmation(false);
	return {
		ConfirmDeleteAloneAPIModal: ({onSubmit, className}) => {
			let message = `Delete API Instance?`;
			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelDeletedAloneAPIConfirm}
					submitBtnText={'Delete'}
					primaryVariant="alert"
					isCloseButton
					title={message}
					subtitle={false}
					onSubmit={() => {
						onSubmit?.(); //if we pass any onSubmit function, it'll call this, otherwise we'll just signout the user.
						cancelDeletedAloneAPIConfirm();
					}}
					className={{
						root: `${className || ''}`,
					}}
				/>
			);
		},
		showConfirmation,
		showDeletedAloneAPIConfirm,
		cancelDeletedAloneAPIConfirm,
	};
};
