import React, {useEffect, useState} from 'react';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {ecosystemService} from 'api/services';
import urlPaths from 'api/url-paths';
import {
	addToEcosystem,
	iconBack,
	iconCompany,
	iconDot,
	iconDots,
	iconEmail,
	iconLocation,
	iconPhone,
	invitation_apple,
	invitation_google,
	mobileAppPreview,
	noteBottomGradient,
	noteBottomGradientMb,
	noteTopGradient,
	noteTopGradientMb,
	profileLink,
	recommend,
	referBusiness,
	removeFromEcosystem,
} from 'assets';
import classNames from 'classnames';
import {
	AdvisorProfileDescription,
	AdvisorProfileGeneralInfoBloc,
	AdvisorProfileSocialInfoBloc,
	SnackActionButton,
} from 'components';
import {DEFAULT_COMPANY_AVATAR, PATHS, PORTAL_PATHS, SPECIALIZATION_OPTIONS_TYPES} from 'const';
import {
	PFAvatar,
	PFBadge,
	PFButton,
	PFCopyright,
	PFLink,
	PFNote,
	PFTooltip,
} from 'shared/components';
import {useAdvisorInfo, useBooleanState, useNoAuthorizedAdvisorInfo} from 'shared/hooks';
import {useAppSelector} from 'store';
import {
	setCurrentPageTitle,
	setIsOpenReferPopup,
	setOpenDeepLinkPopup,
	setReferAdvisorCheckedKey,
} from 'store/actions';
import {myProfileSelectors, selectIsAuth} from 'store/selectors';
import {AdvisorType} from 'types';
import {AdvisorsRole} from 'types/enums';
import {Amplitude, getFullName, renderLocation, ToastDispatcher} from 'utils';

import {RecommendPopup} from '../MyEcosystem/components';
import RemoveFromEcoPopup from '../MyEcosystem/components/RemoveFromEcoPopup/RemoveFromEcoPopup';

import './style.scss';

const EcoAdvisorProfile = () => {
	const accountRole = useAppSelector(myProfileSelectors.selectMyRole);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const advisorToken = window.location.hash.slice(1);
	const [selectedAdvisor, setSelectedAdvisor] = useState<AdvisorType | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isShowRemovePopup, setIsShowRemovePopup] = useBooleanState(false);
	const [isShowRecommendPopup, setIsShowRecommendPopup] = useBooleanState(false);
	const fetchAdvisorInfo = useAdvisorInfo();
	const fetchNoAuthorizedAdvisorInfo = useNoAuthorizedAdvisorInfo();
	const auth = useAppSelector(selectIsAuth);
	const isMobileScreen = useMediaQuery({query: '(max-width: 767px)'});

	const getAdvisorInfo = async () => {
		try {
			const data = auth
				? await fetchAdvisorInfo(advisorToken)
				: await fetchNoAuthorizedAdvisorInfo(advisorToken);
			setSelectedAdvisor(data);
		} catch (e) {
			navigate(PATHS.COMMON.ERROR);
		}
	};

	const addToMyEcoAction = async (tokens: string[]) => {
		try {
			setIsSubmitting(true);
			await ecosystemService.addAdvisorsToEco({advisorPartTokens: tokens});

			Amplitude.track('Added_Advisor_To_Ecosystem', {
				advisors: tokens,
			});

			ToastDispatcher.success(`${tokens.length} advisors added to Your Network`);
			await getAdvisorInfo();
		} catch (e) {
			ToastDispatcher.error(
				`An advisor has not been added to Your Network. Please try again.`,
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onRemoveFromEco = async (tokens: string[]) => {
		try {
			setIsSubmitting(true);
			await ecosystemService.removeAdvisorsFromEco({advisorTokens: tokens});

			Amplitude.track('Removed_From_Ecosystem', {
				advisors: tokens,
			});

			ToastDispatcher.success(`User removed`, snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Undo"
					onClickAction={async () => {
						await ecosystemService.addAdvisorsToEco({advisorPartTokens: tokens});
					}}
				/>
			));
		} catch (e) {
			ToastDispatcher.error(`Failed to remove. Please try again`);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onRefer = () => {
		dispatch(setReferAdvisorCheckedKey(advisorToken));
		dispatch(setIsOpenReferPopup('Network'));
	};

	const emptyFieldMessage =
		accountRole === AdvisorsRole.SuperAdmin ? (
			<div className="pf-advisorProfile__emptyMessage">
				This section is empty.{' '}
				<PFLink to={`${PORTAL_PATHS.OTHER_PATHS.ADVISOR_PROFILE_EDIT}#${advisorToken}`}>
					Edit profile
				</PFLink>{' '}
				to complete it.
			</div>
		) : (
			<div className="pf-advisorProfile__emptyMessage">This section is empty.</div>
		);

	const goBack = () => {
		navigate(-1);
	};

	useEffect(() => {
		dispatch(setCurrentPageTitle('My Network'));
	}, [selectedAdvisor]);

	useEffect(() => {
		getAdvisorInfo();
	}, []);

	return (
		<div>
			{selectedAdvisor && (
				<div className="ecoAdvisorProfile">
					<div className="grid-wrapper">
						<div className="header">
							{auth ? (
								<PFButton
									className="goBack-button"
									prefixIcon={
										<ReactSVG wrapper="span" className="me-2" src={iconBack} />
									}
									variant="plain"
									onClick={goBack}
									type="button">
									Back
								</PFButton>
							) : (
								<PFNote
									className="invitation-note"
									style={{
										background: `url(${
											isMobileScreen ? noteTopGradientMb : noteTopGradient
										}) left top / ${
											isMobileScreen ? '100%' : '70%'
										} 100% no-repeat, url(${
											isMobileScreen
												? noteBottomGradientMb
												: noteBottomGradient
										}) right bottom / ${
											isMobileScreen ? '100%' : '70%'
										} 100% no-repeat, #3349B5`,
									}}>
									<div className="note-wrapper">
										<div className="note-main">
											<div className="note-text">
												Keep all your financial professionals just a tap
												away. Download Pocket Finance mobile app for free
												and easy access to this professional’s business card
												any time!.
											</div>
											<div className="note-appLinks">
												<a
													href="https://play.google.com/store/apps/details?id=com.pocketfinanceinc.pocketfinancemobile"
													target="_blank"
													rel="noreferrer">
													<img
														src={invitation_google}
														alt="Google Play"
														className="googleImg"
													/>
												</a>
												<a
													href="https://apps.apple.com/by/app/pocket-finance-mobile/id1539785018"
													target="_blank"
													rel="noreferrer">
													<img
														src={invitation_apple}
														alt="App Store"
														className="appleImg"
													/>
												</a>
											</div>
										</div>
										{!isMobileScreen && (
											<img
												src={mobileAppPreview}
												alt="Mobile App Preview"
												className="note-img"
											/>
										)}
									</div>
								</PFNote>
							)}
						</div>
						<div className="contact-info">
							<div className="contact-info__avatar-wrapper">
								<PFAvatar
									className="contact-info__avatar"
									src={
										selectedAdvisor?.profileImage &&
										`${urlPaths.BASE_IMAGES_URL}${selectedAdvisor?.profileImage}`
									}
									size={90}
								/>
								<div className="contact-info__name-wrapper">
									<h3 className="contact-info__name">
										{getFullName(
											selectedAdvisor?.firstName,
											selectedAdvisor?.lastName,
										)}
									</h3>
									<div className="isActive-wrapper">
										<ReactSVG
											src={iconDot}
											className={classNames('isActive-dot', {
												activeDot: selectedAdvisor?.isActive,
												inactiveDot: !selectedAdvisor?.isActive,
											})}
										/>
										<div className="text">
											{selectedAdvisor?.isActive ? 'Active' : 'Inactive'}
										</div>
										{selectedAdvisor?.reasonInactive && (
											<span style={{marginLeft: '14px', color: '#A4ABB7'}}>
												{selectedAdvisor?.reasonInactive}
											</span>
										)}
									</div>
									<span className="pl-3 contact-info__certificate">
										{selectedAdvisor?.financialCertificates
											?.map(item => item.name)
											.join(', ')}
									</span>
								</div>
							</div>

							<div className="contact-info__contact">
								{selectedAdvisor?.companyName && (
									<p className="contact-info__info companyName">
										{selectedAdvisor?.companyImage ? (
											<PFAvatar
												src={`${urlPaths.BASE_IMAGES_URL}${selectedAdvisor?.companyImage}`}
												size={90}
												defaultAvatar={DEFAULT_COMPANY_AVATAR}
												variant="rounded-square"
												className="companyLogo"
											/>
										) : (
											<ReactSVG
												wrapper="span"
												className="contact-info__info-icon"
												src={iconCompany}
											/>
										)}
										{selectedAdvisor?.companyName}
									</p>
								)}
								{selectedAdvisor?.location && (
									<p className="contact-info__info">
										<ReactSVG
											wrapper="span"
											className="contact-info__info-icon"
											src={iconLocation}
										/>
										{renderLocation(selectedAdvisor?.location)}
									</p>
								)}
								{(selectedAdvisor?.contactEmail || selectedAdvisor?.email) && (
									<p className="contact-info__info">
										<ReactSVG
											wrapper="span"
											className="contact-info__info-icon"
											src={iconEmail}
										/>
										<PFLink
											href={`mailto:${
												selectedAdvisor?.contactEmail ||
												selectedAdvisor?.email
											}`}>
											{selectedAdvisor?.contactEmail ||
												selectedAdvisor?.email}
										</PFLink>
									</p>
								)}
								{selectedAdvisor?.phoneNumber && (
									<p className="contact-info__info">
										<ReactSVG
											wrapper="span"
											className="contact-info__info-icon"
											src={iconPhone}
										/>
										{selectedAdvisor?.phoneNumber}
									</p>
								)}
							</div>
							<div className="btnContainer">
								<div className="btnGroup">
									{auth && (
										<PFButton
											type="button"
											variant="primary"
											buttonHeight={40}
											disabled={isSubmitting}
											prefixIcon={
												<ReactSVG
													src={
														selectedAdvisor?.isInEcosystem
															? recommend
															: addToEcosystem
													}
												/>
											}
											className="button recommendOrAddButton"
											onClick={
												selectedAdvisor?.isInEcosystem
													? () => setIsShowRecommendPopup(true)
													: () => addToMyEcoAction([advisorToken])
											}>
											{selectedAdvisor?.isInEcosystem
												? 'Recommend'
												: 'Add to My Network'}
										</PFButton>
									)}
									<div className="shareProfileButtonWrapper">
										<PFButton
											type="button"
											variant={auth ? 'secondary' : 'primary'}
											buttonHeight={40}
											prefixIcon={
												<ReactSVG wrapper="div" src={profileLink} />
											}
											className={classNames(`button shareProfileButton`, {
												noAuth: !auth,
											})}
											onClick={() =>
												dispatch(
													setOpenDeepLinkPopup({
														advisorToken,
													}),
												)
											}>
											Share Profile Link
										</PFButton>
										<PFTooltip
											tooltipBody="Click this button to share this advisor profile link with your clients and colleagues."
											arrow
											className={{
												clickAria: 'shareProfileTooltip',
												tooltipBody: 'ecoAdvisorProfile_tooltipBody',
											}}
										/>
									</div>
								</div>

								{selectedAdvisor?.isInEcosystem && auth && (
									<DropdownButton
										placement="bottom-end"
										align="end"
										title={<ReactSVG wrapper="div" src={iconDots} />}
										id="bg-nested-dropdown">
										<Dropdown.Item
											disabled={false}
											onClick={onRefer}
											eventKey="2">
											<div className="dropdownItemText">
												<ReactSVG wrapper="div" src={referBusiness} />
												<span>Refer Business</span>
											</div>
										</Dropdown.Item>

										<hr />
										<Dropdown.Item
											disabled={isSubmitting}
											onClick={() => setIsShowRemovePopup(true)}
											eventKey="3">
											<div className="dropdownItemText removeText">
												<ReactSVG wrapper="div" src={removeFromEcosystem} />
												<span>Remove From Network</span>
											</div>
										</Dropdown.Item>
									</DropdownButton>
								)}
							</div>
							{!isMobileScreen && (
								<AdvisorProfileSocialInfoBloc
									emptyFieldMessage={emptyFieldMessage}
									linkedin={selectedAdvisor?.linkedin}
									facebook={selectedAdvisor?.facebook}
									instagram={selectedAdvisor?.instagram}
									youtube={selectedAdvisor?.youtube}
									twitter={selectedAdvisor?.twitter}
								/>
							)}
							{selectedAdvisor?.advisorPartnerSpecializationTypes && (
								<>
									<AdvisorProfileDescription
										labelClassName="mt-4"
										label="MY PRO TEAM"
									/>
									<div className="general-bloc__tags">
										{selectedAdvisor?.advisorPartnerSpecializationTypes?.map(
											item => (
												<PFBadge key={`Specialization-${item}`}>
													{
														SPECIALIZATION_OPTIONS_TYPES.find(spec => {
															return spec.type === item;
														})?.name
													}
												</PFBadge>
											),
										)}
									</div>
								</>
							)}
						</div>

						<div className="general-info">
							<AdvisorProfileGeneralInfoBloc
								videoPresentationLink={selectedAdvisor?.videoPresentationLink}
								description={selectedAdvisor?.description}
								emptyFieldMessage={emptyFieldMessage}
								advisorSpecializationTypes={
									selectedAdvisor?.advisorSpecializationTypes
								}
								hashtags={selectedAdvisor?.hashtags}
								auth={auth}
							/>
							{isMobileScreen && (
								<AdvisorProfileSocialInfoBloc
									emptyFieldMessage={emptyFieldMessage}
									linkedin={selectedAdvisor?.linkedin}
									facebook={selectedAdvisor?.facebook}
									instagram={selectedAdvisor?.instagram}
									youtube={selectedAdvisor?.youtube}
									twitter={selectedAdvisor?.twitter}
								/>
							)}
						</div>
					</div>
					<PFCopyright />
				</div>
			)}
			{selectedAdvisor && (
				<RemoveFromEcoPopup
					visible={isShowRemovePopup}
					onCancel={() => setIsShowRemovePopup(false)}
					onSubmit={() => onRemoveFromEco([advisorToken])}
					title={`Remove ${getFullName(
						selectedAdvisor?.firstName,
						selectedAdvisor?.lastName,
					)} from your Network?`}
				/>
			)}
			{selectedAdvisor && (
				<RecommendPopup
					visible={isShowRecommendPopup}
					onCancel={() => setIsShowRecommendPopup(false)}
					title={`Recommend ${getFullName(
						selectedAdvisor?.firstName,
						selectedAdvisor?.lastName,
					)}`}
					advisorTokens={[selectedAdvisor?.token]}
				/>
			)}
		</div>
	);
};

export default EcoAdvisorProfile;
