import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {iconDot, iconEdit} from 'assets';
import classNames from 'classnames';
import {useAppSelector} from 'store';
import {myProfileSelectors} from 'store/selectors';
import {AdvisorsRole} from 'types/enums';
import {findByToken, useErrorHelper} from 'utils';

import {useIsActiveUser, useIsActiveUserPopup} from '../../hooks';
import PFButton from '../PF-Button';

import './style.scss';

type PropsTypes = {
	isActive: boolean;
	userToken?: string;
	advisorToken: string;
	name?: string;
	reasonInactive: string;
	tableData?: any;
	onUpdateSelectedAdvisorData?: (active: boolean, reason: string | null) => any;
	onUpdateData?: () => any;
};

const PFisActiveButton: FC<PropsTypes> = ({
	isActive,
	userToken,
	advisorToken,
	name,
	reasonInactive,
	tableData,
	onUpdateSelectedAdvisorData,
	onUpdateData,
}) => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const role = useAppSelector(myProfileSelectors.selectMyProfileData)?.roles[0];
	const activatedUser = useIsActiveUser();
	const editIcon = <ReactSVG src={iconEdit} className="isActiveButton__edit-icon" />;
	const dotIcon = (
		<ReactSVG
			src={iconDot}
			className={classNames('isActiveButton__isActive-dot', {
				activeDot: isActive,
				inactiveDot: !isActive,
			})}
		/>
	);
	const {showIsActiveUserConfirm, ConfirmIsActiveUserModal} = useIsActiveUserPopup();
	const isAdminOrAccountAdministrator =
		role === AdvisorsRole.Administrator || role === AdvisorsRole.AccountAdministrator;

	const onIsActive = async (active, token, userName = name, reason = null) => {
		const update = () => {
			if (
				onUpdateData &&
				typeof onUpdateData === 'function' &&
				!!tableData.length &&
				!!userToken
			) {
				const foundActiveUser = findByToken(tableData, userToken);
				if (foundActiveUser?.advisor) {
					foundActiveUser.advisor.isActive = active;
					foundActiveUser.advisor.reasonInactive = reason;
				}
				onUpdateData();
			}
			if (onUpdateSelectedAdvisorData && typeof onUpdateSelectedAdvisorData === 'function') {
				onUpdateSelectedAdvisorData(active, reason);
			}
			return false;
		};
		const body = {
			token,
			isActive: active,
			reasonInactive: reason,
		};
		try {
			await activatedUser(body, update, userName);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	return (
		<div className="isActive">
			{isAdminOrAccountAdministrator && (
				<div className="isActiveButton-wrapper">
					<PFButton
						className="isActiveButton"
						type="button"
						onClick={showIsActiveUserConfirm}
						suffixIcon={editIcon}
						prefixIcon={dotIcon}
						variant="plain">
						<span>{isActive ? 'Active' : 'Inactive'}</span>
						{reasonInactive && (
							<span style={{marginLeft: '14px', color: '#A4ABB7'}}>
								{reasonInactive}
							</span>
						)}
					</PFButton>
					<ConfirmIsActiveUserModal
						isActive={isActive}
						onSubmit={onIsActive}
						className="pf-confirmIsActiveUserModal"
						token={advisorToken}
						name={name}
						reason={reasonInactive}
						title={null}
					/>
				</div>
			)}
			{!isAdminOrAccountAdministrator && (
				<div className="isActive-wrapper">
					{dotIcon}
					<div className="isActive-wrapper__text">{isActive ? 'Active' : 'Inactive'}</div>
					{reasonInactive && (
						<span style={{marginLeft: '14px', color: '#A4ABB7'}}>{reasonInactive}</span>
					)}
				</div>
			)}
		</div>
	);
};

export default PFisActiveButton;
