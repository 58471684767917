import Compressor from 'compressorjs';

const imageCompressor = payload => {
	const {file, onSuccess, onError, quality} = {
		quality: 0.6,
		...payload,
	};

	if (!file) {
		return;
	}
	new Compressor(file, {
		quality,
		success: onSuccess,
		error: onError,
	});
};

export default imageCompressor;
