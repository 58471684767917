import {connect, useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {setCurrentPageTitle} from '../../../../store/actions';
import {Table} from 'rsuite';
import {
	useBlockedUserPopup,
	useDeletedUserPopup,
	useRestoreUser,
} from '../../../../shared/hooks/users';
import {SuperAdminEmptyPage, TeamCellItem, UserCellItem} from '../../../../components';
import {PFSortArrow, PFTable} from '../../../../shared/components';
import PFButton from '../../../../shared/components/PF-Button';
import {ReactSVG} from 'react-svg';
import iconBlock from '../../../../assets/icon-block.svg';
import iconUnblock from '../../../../assets/icon-unblock.svg';
import iconDelete from '../../../../assets/icon-delete.svg';
import * as usersService from '../../../../api/services/users';
import {
	findByToken,
	useErrorHelper,
	mapQueryParams,
	parseUrlQuery,
	ToastDispatcher,
} from '../../../../utils';
import {useConsumersData} from '../../../../shared/hooks/consumers';
import {cleanedUpConsumersData} from '../../../../store/actions';
import {cleanedUpTableData} from '../../../../store/actions';
import {setCheckedData} from '../../../../store/actions';
import SearchEmptyPage from '../../../../components/EmptyPages/EmptySearch';
import PFTableBar from '../../../../shared/components/PF-TableBar';
import {useNavigate} from 'react-router-dom';

import './style.scss';

const Consumers = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [update, setUpdate] = useState(true);
	const [updateParam, setUpdateParam] = useState('');
	const {Column, HeaderCell, Cell} = Table;
	const tableData = props.consumers.consumers;
	const fullTableDataLength = props.consumers.fullCount;
	const checkedKeys = props.table.checkedData;
	const urlParams = parseUrlQuery(document.location);
	const [isEmptyPage, setIsEmptyPage] = useState(false);
	const restoreUser = useRestoreUser();
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const params = {
		type: 2,
		RoleIds: [1],
		...mapQueryParams(urlParams),
	};

	const restore = async token => {
		// ! new may 1
		try {
			await restoreUser(token);
			const foundDeletedUser = findByToken(tableData, token);
			if (foundDeletedUser) foundDeletedUser.isDeleted = false;
			updateData();
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const fetchConsumersData = useConsumersData(params);
	const dispatch = useDispatch();
	const [columns] = useState([
		{
			name: 'consumer',
			title: 'Consumer',
			flexGrow: 1,
			minWidth: 216,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'contact info',
			title: 'Contact info',
			flexGrow: 1,
			minWidth: 190,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'team',
			title: 'Client Team',
			flexGrow: 1,
			minWidth: 180,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
	]);
	const tableColumns = columns.map((item, index) => {
		const switchParam = param => {
			switch (param) {
				case 'consumer':
					return (
						<Cell dataKey="LastName">
							{rowData => (
								<UserCellItem
									lastName={rowData?.lastName}
									firstName={rowData?.firstName}
									avatarImage={rowData?.profileImage}
									avatarSize={40}
									//! restore={restore}  пока нет рестора
									isDeleted={rowData?.isDeleted}
									restoreToken={rowData?.token}
								/>
							)}
						</Cell>
					);
				case 'contact info':
					return (
						<Cell dataKey="Email">
							{rowData => (
								<div className="tableEmailCell">
									<a className={`pf-tableText`} href={`mailto:${rowData?.email}`}>
										{rowData.email}
									</a>
									{!rowData?.isEmailConfirmed && (
										<p className="unconfirmedEmail">Unconfirmed</p>
									)}
								</div>
							)}
						</Cell>
					);
				case 'team':
					return (
						<Cell dataKey="TeamCount">
							<TeamCellItem dataKey="TeamCount" />
						</Cell>
					);
				default:
					break;
			}
		};

		return (
			<Column
				key={index}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						{<PFSortArrow />}
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;
		try {
			setIsLoading(true);
			await fetchConsumersData();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const fetchBlockedUsers = (checkedKeys, isBlocked) => {
		const count = checkedKeys.length;
		const toastMessage = `${count > 1 ? count : ''} ${count > 1 ? 'u' : 'U'}ser${
			count > 1 ? 's' : ''
		} ${isBlocked ? 'suspended' : 'reactivated'}`;
		try {
			Promise.all(
				checkedKeys.map(item => {
					const body = {
						token: item,
						isBlocked,
					};
					return usersService.setBlockedUser(body);
				}),
			)
				.then(response => {
					ToastDispatcher.success(toastMessage);
					setUpdateParam(isBlocked ? 'blocked' : 'unblocked');
					updateData();
				})
				.catch(e => {
					errorHelper(e);
				});
		} catch (e) {
			throw e;
		}
	};

	const fetchDeletedUsers = checkedKeys => {
		const count = checkedKeys.length;
		const toastMessage = `${count > 1 ? count : ''} ${count > 1 ? 'u' : 'U'}ser${
			count > 1 ? 's' : ''
		} deleted`;
		try {
			Promise.all(checkedKeys.map(item => usersService.deleteAccount({userToken: item})))
				.then(response => {
					ToastDispatcher.success(toastMessage);
					setUpdateParam('deleted');
					updateData();
				})
				.catch(e => {
					errorHelper(e);
				});
		} catch (e) {
			throw e;
		}
	};

	const updateCallback = () => {
		if (updateParam === 'blocked') {
			checkedKeys.forEach(key => {
				const foundBlockedUser = findByToken(tableData, key);
				if (foundBlockedUser) foundBlockedUser.isBlocked = true;
			});
			setUpdateParam('');
		} else if (updateParam === 'unblocked') {
			checkedKeys.forEach(key => {
				const foundBlockedUser = findByToken(tableData, key);
				if (foundBlockedUser) foundBlockedUser.isBlocked = false;
			});
			setUpdateParam('');
		} else if (updateParam === 'deleted') {
			checkedKeys.forEach(key => {
				const foundDeletedUser = findByToken(tableData, key);
				if (foundDeletedUser) foundDeletedUser.isDeleted = true;
			});
			dispatch(setCheckedData([])); //! new
			setUpdateParam('');
		}
	};

	const {showBlockedUserConfirm, ConfirmBlockedUserModal} = useBlockedUserPopup();
	const {showDeletedUserConfirm, ConfirmDeleteUserModal} = useDeletedUserPopup();

	const BlockButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG wrapper="span" className="pf-icon-request-report" src={iconBlock} />
				}
				className={'pf-consumer-block-button'}
				onClick={showBlockedUserConfirm}>
				<span className={'button-text'}>Suspend</span>
			</PFButton>
		);
	};

	const UnblockButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG wrapper="span" className="pf-icon-request-report" src={iconUnblock} />
				}
				className={'pf-consumer-block-button'}
				onClick={() => fetchBlockedUsers(checkedKeys, false)}>
				<span className={'button-text'}>Reactivate</span>
			</PFButton>
		);
	};

	const DeleteButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG
						wrapper="span"
						className="pf-icon-request-report pf-icon-delete"
						src={iconDelete}
					/>
				}
				className={'pf-consumer-delete-button'}
				onClick={showDeletedUserConfirm}>
				<span className={'button-text'}>Delete</span>
			</PFButton>
		);
	};

	const firstRender = async () => {
		dispatch(setCurrentPageTitle('Consumers'));

		try {
			setIsLoading(true);
			const ConsumersData = await fetchConsumersData();
			if (Boolean(ConsumersData.fullCount)) {
				setIsEmptyPage(false);
			} else {
				setIsEmptyPage(true);
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const emptyPageParam = {
		title: 'The List is Empty',
	};

	const searchValue = useSelector(state => state.table.search);
	const searchEmptyParam = {
		variant: 'secondary',
		className: '',
		pageName: 'Consumers',
		buttonText: 'Back to Consumers',
	};
	const tableBarButtons = [
		<BlockButton key={1} />,
		<UnblockButton key={2} />,
		<DeleteButton key={3} />,
	];

	useEffect(() => {
		firstRender();
	}, [props.table.search, props.table.reset, props.table.reverse]);

	useEffect(() => {
		return () => {
			dispatch(cleanedUpConsumersData());
			dispatch(cleanedUpTableData());
		};
	}, []);

	return (
		<>
			<PFTableBar
				tableData={tableData}
				checkedKeys={checkedKeys}
				tableBarButtons={tableBarButtons}
			/>
			<div className={`consumers`}>
				<PFTable
					tableColumns={tableColumns}
					tableData={tableData}
					isLoading={isLoading}
					modal={'consumer'}
					fetchMoreData={fetchMoreData}
					fullTableDataLength={fullTableDataLength}
					checkedKeys={checkedKeys}
					update={update}
					updateCallback={updateCallback}
					updateDataForUseOnModal={updateData}
					urlParams={urlParams}
					parseUrlQuery={parseUrlQuery}
					emptyComponent={
						searchValue ? (
							<SearchEmptyPage {...searchEmptyParam} />
						) : (
							<SuperAdminEmptyPage {...emptyPageParam} />
						)
					}
				/>
				<ConfirmBlockedUserModal
					onSubmit={() => fetchBlockedUsers(checkedKeys, true)}
					checkedKeys={checkedKeys}
				/>
				<ConfirmDeleteUserModal
					onSubmit={() => fetchDeletedUsers(checkedKeys)}
					checkedKeys={checkedKeys}
				/>
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		consumers: state.consumers,
		table: state.table,
	};
};

export default connect(mapStateToProps)(Consumers);
