import React from 'react';
import {useNavigate} from 'react-router-dom';
import {PFButton} from '../../../../shared/components';
import {useLoginEditForm} from './utils';
import ProfileFormContainer from '../../../../components/ProfileFormContainer';
import './style.scss';

const EditLoginSettings = () => {
	const navigate = useNavigate();
	const {
		fields,
		handleSubmit,
		onSubmit,
		formState: {isValid},
		isSubmitting,
	} = useLoginEditForm();

	const goBack = () => {
		navigate(-1);
	};

	return (
		<div className="editLoginSettings">
			<ProfileFormContainer title={'Edit Your Login Settings'} goBack={goBack}>
				<form onSubmit={handleSubmit(onSubmit)} className="editLoginSettings__form">
					<div className="row">
						{fields.map(
							(
								{error, isSectionLabel, Component, className, ...fieldProps},
								index,
							) => (
								<div key={`${fieldProps.name}-${index}`} className={className}>
									<Component {...fieldProps} error={error?.message} />
								</div>
							),
						)}
					</div>
					<div className="editLoginSettings__buttons-wrapper">
						<PFButton
							onClick={goBack}
							className="pf-profileEdit__form-button "
							variant="secondary"
							type="button">
							Cancel
						</PFButton>
						<PFButton
							disabled={!isValid || isSubmitting}
							className="pf-profileEdit__form-button pf-profileEdit__form-button--submit"
							type="submit">
							Save
						</PFButton>
					</div>
				</form>
			</ProfileFormContainer>
		</div>
	);
};

export default EditLoginSettings;
