import React from 'react';
import {ReactSVG} from 'react-svg';

import {iconDot} from 'assets';
import classNames from 'classnames';
import PFContactsBlock from 'shared/components/PF-ContactsBlock';
import PFUserDescription from 'shared/components/PF-UserDescription';
import {useAppSelector} from 'store';
import {tableSelectors} from 'store/selectors';
import {getFullName} from 'utils';

import urlPaths from '../../api/url-paths';

import AdditionalEcosystemInfo from './AdditionalEcosystemInfo';

import './style.scss';

const EcosystemModal = () => {
	const rowData = useAppSelector(tableSelectors.selectTableModalData);
	const advisorName = getFullName(rowData?.firstName, rowData?.lastName);

	return (
		<div className={classNames('ecosystemModal')}>
			<PFUserDescription name={advisorName} avatarPhoto={rowData?.profileImage}>
				<div className="isActiveSection">
					<ReactSVG
						src={iconDot}
						className={classNames('isActiveSection__isActive-dot', {
							activeDot: rowData?.isActive,
							inactiveDot: !rowData?.isActive,
						})}
					/>
					<div className="isActiveSection__text">
						{rowData?.isActive ? 'Active' : 'Inactive'}
					</div>
					<span style={{marginLeft: '14px', color: '#A4ABB7'}}>
						{rowData?.reasonInactive}
					</span>
				</div>
				<div className="descriptionInfo">
					{rowData?.financialCertificates &&
						rowData.financialCertificates?.map(item => item?.name).join(', ')}
				</div>
			</PFUserDescription>
			<PFContactsBlock
				companyName={rowData?.companyName}
				companyImage={rowData?.companyImage}
				phone={rowData?.phoneNumber}
				email={rowData?.contactEmail || rowData?.email}
				location={rowData?.location}
			/>
			<AdditionalEcosystemInfo
				advisorToken={rowData?.token}
				advisorName={advisorName}
				specializations={rowData?.advisorSpecializationTypes}
				isInEcosystem={rowData?.isInEcosystem}
			/>
		</div>
	);
};

export default EcosystemModal;
