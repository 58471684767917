import React, {FC} from 'react';
import {useMediaQuery} from 'react-responsive';

import {Tooltip, Whisper} from 'rsuite';
import {Specialization} from 'types/enums';

import './style.scss';

/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
type PropsTypes = {
	advisorSpecializationTypes: Specialization[];
	token?: string;
};

const SpecializationsCellItem: FC<PropsTypes> = ({advisorSpecializationTypes, token}) => {
	const trigger = useMediaQuery({query: '(max-width: 992px)'}) ? 'click' : 'hover';
	const specBadges = advisorSpecializationTypes.map(type => (
		<div className="specBadge" key={type}>
			{Specialization[type]}
		</div>
	));

	return (
		<div className="specializationsCellContainer" id={token}>
			{advisorSpecializationTypes.length > 1 ? (
				<Whisper
					enterable
					preventOverflow
					trigger={trigger}
					placement="auto"
					controlId={`control-${token}-auto`}
					speaker={
						<Tooltip arrow={false} className="pf-tooltip">
							{specBadges}
						</Tooltip>
					}>
					<div
						id={`control-${token}-auto`}
						className="specializationsCellContainer__content">
						{specBadges[0]}
						<span className="specDots">...</span>
					</div>
				</Whisper>
			) : (
				specBadges
			)}
		</div>
	);
};

export default SpecializationsCellItem;
