import React from 'react';
import {ReactSVG} from 'react-svg';

import arrowUpIcon from 'assets/arrow-up-icon.svg';
import cn from 'classnames';
import {useBooleanState} from 'shared';

import styles from './PriceTable.styles.module.scss';

export interface PricingData {
	quarter: string;
	annual: string;
	monthly: string;
	annualBeforeDiscount: string;
	annualDiscount: string;
	discount: string;
	startDate: Date;
	endDate: Date;
}

export const PRICE_TABLE_DATA: PricingData[] = [
	{
		quarter: 'Q2 2023',
		annual: '$25.00',
		monthly: '$2.50',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$475.00 OFF',
		discount: '95% OFF',
		startDate: new Date('2023-04-01'),
		endDate: new Date('2023-06-30'),
	},
	{
		quarter: 'Q3 2023',
		annual: '$48.00',
		monthly: '$5.00',
		annualBeforeDiscount: '$480.00',
		annualDiscount: '$432.00 OFF',
		discount: '90% OFF',
		startDate: new Date('2023-07-01'),
		endDate: new Date('2023-09-30'),
	},
	{
		quarter: 'Q4 2023',
		annual: '$60.00',
		monthly: '$6.00',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$440.00 OFF',
		discount: '88% OFF',
		startDate: new Date('2023-10-01'),
		endDate: new Date('2023-12-31'),
	},
	{
		quarter: 'Q1 2024',
		annual: '$80.00',
		monthly: '$8.00',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$420.00 OFF',
		discount: '84% OFF',
		startDate: new Date('2024-01-01'),
		endDate: new Date('2024-03-31'),
	},
	{
		quarter: 'Q2 2024',
		annual: '$100.00',
		monthly: '$10.00',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$400.00 OFF',
		discount: '80% OFF',
		startDate: new Date('2024-04-01'),
		endDate: new Date('2024-06-30'),
	},
	{
		quarter: 'Q3 2024',
		annual: '$125.00',
		monthly: '$12.50',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$375.00 OFF',
		discount: '75% OFF',
		startDate: new Date('2024-07-01'),
		endDate: new Date('2024-09-30'),
	},
	{
		quarter: 'Q4 2024',
		annual: '$150.00',
		monthly: '$15.00',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$350.00 OFF',
		discount: '70% OFF',
		startDate: new Date('2024-10-01'),
		endDate: new Date('2024-12-31'),
	},
	{
		quarter: 'Q1 2025',
		annual: '$250.00',
		monthly: '$25.00',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$250.00 OFF',
		discount: '50% OFF',
		startDate: new Date('2025-01-01'),
		endDate: new Date('2025-03-31'),
	},
	{
		quarter: 'Q2 2025',
		annual: '$300.00',
		monthly: '$30.00',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$200.00 OFF',
		discount: '40% OFF',
		startDate: new Date('2025-04-01'),
		endDate: new Date('2025-06-30'),
	},
	{
		quarter: 'Q3 2025',
		annual: '$350.00',
		monthly: '$35.00',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$150.00 OFF',
		discount: '30% OFF',
		startDate: new Date('2025-07-01'),
		endDate: new Date('2025-09-30'),
	},
	{
		quarter: 'Q4 2025',
		annual: '$400.00',
		monthly: '$40.00',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$100.00 OFF',
		discount: '20% OFF',
		startDate: new Date('2025-10-01'),
		endDate: new Date('2025-12-31'),
	},
	{
		quarter: 'Q1 2026',
		annual: '$500.00',
		monthly: '$50.00',
		annualBeforeDiscount: '$500.00',
		annualDiscount: '$0.00',
		discount: '',
		startDate: new Date('2026-01-01'),
		endDate: new Date('2026-03-31'),
	},
];

export const PriceTable: React.FC = () => {
	const [expanded, toggleExpanded] = useBooleanState(false);

	const handleClick = () => {
		toggleExpanded();
	};

	return (
		<div className={styles.container}>
			<span
				className={styles.button}
				onClick={handleClick}
				aria-hidden
				tabIndex={0}
				role="button">
				<span>View the table</span>
				<ReactSVG
					className={cn(styles.icon, {
						[styles.iconExpanded]: expanded,
					})}
					wrapper="span"
					src={arrowUpIcon}
				/>
			</span>
			{expanded && (
				<table>
					<thead>
						<tr>
							<th>Quarter</th>
							<th colSpan={2}>Yearly Pricing</th>
						</tr>
					</thead>
					<tbody>
						{PRICE_TABLE_DATA.map(data => (
							<tr key={data.quarter}>
								<td>{data.quarter}</td>
								<td>{data.annual}</td>
								<td>{!!data.discount && <span>{data.discount}</span>}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
