import React from 'react';

import './style.scss';
import {ReactSVG} from 'react-svg';
import plus from '../../../assets/icon-plus.svg';
import NothingFoundAnimation from '../../../shared/components/PF-NothingFoundAnimation';

const SuperAdminEmptyPage = ({title, subtitle, button, className}) => {
	return (
		<div className={`emptySuperadminPage__container ${className ? className : ''}`}>
			<div className={`emptySuperadminPage__container__wrapper`}>
				<div className={'emptySuperadminPage__header'}>
					<NothingFoundAnimation width={120} height={120} />
					<h1>{title}</h1>
					<h2>{subtitle}</h2>
				</div>
				<div className={'emptySuperadminPage__main'}>
					{button && (
						<button
							className={`emptyButton ${button?.variant ? button?.variant : ''}`}
							onClick={button.onClick}>
							{button.isShowIcon &&
								(button?.icon ? (
									button?.icon
								) : (
									<ReactSVG wrapper="span" className="pf-icon-plus" src={plus} />
								))}
							<span className={'emptyButton-text'}>{button.text}</span>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default SuperAdminEmptyPage;
