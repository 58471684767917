import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {advisorClientsService, leadsService} from 'api/services';
import {accept_button_icon, reject_button_icon, scheduleMeetingIcon} from 'assets';
import upgradePlanBadgeIcon from 'assets/header/upgrade-plan-badge-icon.svg';
import {SnackActionButton} from 'components/Snackbar/components';
import PORTAL_PATHS from 'const/url-paths/portal';
import PFButton from 'shared/components/PF-Button';
import {useBooleanState} from 'shared/hooks';
import {useAppDispatch, useAppSelector} from 'store';
import {removeCheckedItems, removeLeadsItem, setSideModal} from 'store/actions';
import {fetchCounts} from 'store/reducers/SubscriptionReducer';
import {myProfileSelectors} from 'store/selectors';
import {Amplitude, ToastDispatcher} from 'utils';

import PFLink from '../../../shared/components/PF-Link';
import PFNote from '../../../shared/components/PF-Note';
import UserProfileTeamBloc from '../../UserProfile/UserProfileTeamBloc';
import RejectLeadModal from '../components/rejectLeadModal/rejectLeadModal';

import './style.scss';

type PropsTypes = {
	userToken: string;
	hasCurrentCountRestriction: boolean;
};

const AdditionalLeadsInfo: FC<PropsTypes> = ({userToken, hasCurrentCountRestriction}) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [isShowRejectLeadModal, setIsShowDeleteTodoModalInfo] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useBooleanState(false);
	const [isSubmittingScheduleMeeting, setIsSubmittingScheduleMeeting] = useBooleanState(false);
	const calendlyLink = useAppSelector(myProfileSelectors.selectMyCalendlyLink);

	const acceptLeadsToCurrentAction = async (tokens: string[]) => {
		try {
			setIsSubmitting(true);
			await leadsService.acceptRequestLeadsToCurrent(tokens);

			Amplitude.track('Moved_To_Current', {
				clients: tokens,
			});

			// @ts-ignore
			await dispatch(fetchCounts());

			setIsShowDeleteTodoModalInfo(false);
			dispatch(setSideModal(false));
			dispatch(removeLeadsItem({tokens}));
			dispatch(removeCheckedItems(tokens));
			ToastDispatcher.success('User moved to Current.', snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Open"
					onClickAction={async () => navigate(PORTAL_PATHS.SIDEBAR.CURRENT)}
				/>
			));
		} catch (e) {
			ToastDispatcher.error(`Failed to move user to Current. Please try again`);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const sendScheduleMeeting = async (userTokens: string[]) => {
		try {
			setIsSubmittingScheduleMeeting(true);
			await advisorClientsService.sendScheduleMeeting(userTokens);

			Amplitude.track('Scheduled_Meeting', {
				source: 'Leads Modal',
				clients: userTokens,
			});

			ToastDispatcher.success(
				'The client will receive your calendar link to suggest the meeting date and time.',
			);
		} catch (e) {
			ToastDispatcher.error(`Failed to schedule a meeting. Please check your calendar link.`);
			throw e;
		} finally {
			setIsSubmittingScheduleMeeting(false);
		}
	};

	const goToManageSubscription = () => {
		Amplitude.track('Opened_Upgrade', {
			source: 'Leads',
		});

		navigate(PORTAL_PATHS.OTHER_PATHS.MANAGE_SUBSCRIPTION);
	};

	return (
		<div className="pf-leads-info">
			<div className="pf-leads-info__buttonsWrapper">
				{hasCurrentCountRestriction ? (
					<PFButton
						type="button"
						variant="primary"
						prefixIcon={
							<ReactSVG
								wrapper="span"
								className="pf-icon-accept"
								src={upgradePlanBadgeIcon}
							/>
						}
						hasArrow
						className="pf-leads-accept-button"
						onClick={goToManageSubscription}>
						<span className="button-text">Upgrade to Accept This Invitation</span>
					</PFButton>
				) : (
					<PFButton
						type="button"
						variant="green"
						prefixIcon={
							<ReactSVG
								wrapper="span"
								className="pf-icon-accept"
								src={accept_button_icon}
							/>
						}
						className="pf-leads-accept-button"
						disabled={isSubmitting}
						onClick={() => acceptLeadsToCurrentAction([userToken])}>
						<span className="button-text">Accept & Move to Current</span>
					</PFButton>
				)}

				<PFButton
					type="button"
					variant="white-alert"
					prefixIcon={
						<ReactSVG
							wrapper="span"
							className="pf-icon-reject"
							src={reject_button_icon}
						/>
					}
					className="pf-leads-reject-button"
					onClick={() => setIsShowDeleteTodoModalInfo(true)}>
					<span className="button-text">Reject & Remove From Leads</span>
				</PFButton>
				<PFButton
					type="button"
					variant="secondary"
					disabled={isSubmittingScheduleMeeting || !calendlyLink}
					prefixIcon={
						<ReactSVG
							wrapper="span"
							className="pf-icon-reject"
							src={scheduleMeetingIcon}
						/>
					}
					className="pf-leads-scheduleMeeting-button"
					onClick={() => sendScheduleMeeting([userToken])}>
					<span className="button-text">Schedule a Meeting</span>
				</PFButton>
				{!calendlyLink && (
					<PFNote className="sendScheduleMeetingNote">
						<div>
							<PFLink to={PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT}>
								Add your calendar link
							</PFLink>{' '}
							to easily schedule appointments for your leads with a click of a button.
						</div>
					</PFNote>
				)}
			</div>
			<div className="pf-leads-info__teamWrapper">
				<h3 className="pf-leads-info__teamWrapper__title">Client Team</h3>
				<UserProfileTeamBloc userToken={userToken} isCompanyNames={false} />
			</div>
			{isShowRejectLeadModal && (
				<RejectLeadModal
					tokens={[userToken]}
					visible={isShowRejectLeadModal}
					onCancel={() => setIsShowDeleteTodoModalInfo(false)}
				/>
			)}
		</div>
	);
};

export default AdditionalLeadsInfo;
