import React, {FC, ReactNode} from 'react';
import {ReactSVG} from 'react-svg';

import {SnackbarProvider} from 'notistack';

import approvedIcon from '../../assets/icon-approved.svg';
import attention from '../../assets/icon-attention.svg';

import {CustomSnack} from './components';

import './style.scss';

type PropsTypes = {
	children: ReactNode | ReactNode[];
};

const Snackbar: FC<PropsTypes> = ({children}) => {
	// todo: callback function, accepts a "snackId" and returns action buttons
	// const action: SnackbarAction = snackbarId => (
	// 	<SnackActionButton id={snackbarId} buttonText="name" onClickAction={() => {}} />
	// );

	return (
		<SnackbarProvider
			Components={{
				default: CustomSnack,
				success: CustomSnack,
				error: CustomSnack,
				warning: CustomSnack,
				info: CustomSnack,
			}}
			classes={{
				containerRoot: 'snackbar-containerRoot',
			}}
			iconVariant={{
				default: <ReactSVG src={approvedIcon} className="icon-approved" />,
				error: <ReactSVG src={attention} className="icon-attention" />,
			}}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			preventDuplicate
			// action={action} todo: action buttons are passed here
			autoHideDuration={5000}
			maxSnack={3}>
			{children}
		</SnackbarProvider>
	);
};

export default Snackbar;
