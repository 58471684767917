import * as emailService from '../../../api/services/email';

export const useEmailConfirmation = () => {
	return async ({key, token}) => {
		try {
			const response = await emailService.confirm({key, token});
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};
