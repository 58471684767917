import {useDispatch} from 'react-redux';
import * as advisorClients from '../../../api/services/advisorClients';
import {setCurrentData} from '../../../store/actions';

export const useCurrentData = params => {
	const dispatch = useDispatch();

	return async (payload = params) => {
		try {
			const response = await advisorClients.getAdvisorClients(params);
			dispatch(setCurrentData(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};
