import axios from 'axios';

const apiKey = process.env.REACT_APP_FIREBASE_WEB_API_KEY;

export class DynamicLinkService {
	longLink = 'https://pocketfinancemobile.page.link/?efr=1';

	addIosBundleId() {
		this.longLink = this.longLink + '&ibi=com.pocketfinanceinc.pocketfinancemobile';
		return this;
	}

	addAndroidPackageName() {
		this.longLink = this.longLink + '&apn=com.pocketfinanceinc.pocketfinancemobile';
		return this;
	}

	addAndroidFallbackUrl(fallbackUrl) {
		this.longLink = this.longLink + `&afl=${encodeURIComponent(fallbackUrl)}`;
		return this;
	}

	addIosFallbackUrl(fallbackUrl) {
		this.longLink = this.longLink + `&ifl=${encodeURIComponent(fallbackUrl)}`;
		return this;
	}

	// TODO: move out context of links
	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	addDeepLink(action, token, specializations) {
		this.longLink =
			this.longLink +
			`&link=${encodeURIComponent(
				`${process.env.REACT_APP_PF_DEEP_LINK_BASE}/advisor?action=${action}&token=${token}&specializations=${specializations}`,
			)}`;
		return this;
	}

	addDeepLinkValue(link) {
		this.longLink = this.longLink + `&link=${encodeURIComponent(link)}`;
		return this;
	}

	addOtherFallbackUrl(fallbackUrl) {
		this.longLink = this.longLink + `&ofl=${encodeURIComponent(fallbackUrl)}`;
		return this;
	}

	getLongLink() {
		return this.longLink;
	}
}

export const createShortLink = async longDynamicLink => {
	const shortLinkResponse = await axios.post(
		`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
		{
			longDynamicLink,
			suffix: {
				option: 'SHORT',
			},
		},
	);

	return shortLinkResponse.data.shortLink;
};
