import React from 'react';
import {ReactSVG} from 'react-svg';

import {disabilityInsurance} from 'assets';

import {InsuranceEmptyInfoHeader} from './components';

type Props = {
	isHintVisible?: boolean;
	onAddClick?: () => void;
	containerClassName?: string;
};

const DisabilityInsuranceEmptyItem: React.FC<Props> = ({
	isHintVisible,
	onAddClick,
	containerClassName,
}) => {
	return (
		<div className={containerClassName}>
			<InsuranceEmptyInfoHeader
				title="Disability Illness Insurance"
				icon={<ReactSVG src={disabilityInsurance} />}
				hint={isHintVisible ? 'None' : null}
				onAddClick={onAddClick}
			/>
		</div>
	);
};

export default DisabilityInsuranceEmptyItem;
