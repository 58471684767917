import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {EditLifeInsuranceForm, ProfileFormContainer} from 'components';
import {useGetInsurances, useUpdateLifeInsurance} from 'shared';
import {setCurrentPageTitle} from 'store/actions';
import {LifeInsuranceType} from 'types';
import {useErrorHelper} from 'utils';

import './style.scss';

const EditLifeInsurance = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const tokens = window.location.hash.slice(1).split('&');
	const userToken = tokens[0];
	const insuranceToken = tokens[1];
	const fetchInsurances = useGetInsurances();
	const [insurances, setInsurance] = useState<LifeInsuranceType | null>(null);
	const editInsurance = useUpdateLifeInsurance();
	const errorHelper = useErrorHelper(navigate);

	const title = 'Edit Life Insurance Information';
	const goBack = (): void => {
		navigate(-1);
	};

	const callback = useCallback(async () => {
		try {
			const res = await fetchInsurances(userToken);
			const lifeInsuranceDataByToken = res.lifeInsuranceModel?.find(
				item => item.token === insuranceToken,
			);
			if (lifeInsuranceDataByToken) setInsurance(lifeInsuranceDataByToken);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	}, [userToken]);

	useEffect(() => {
		dispatch(setCurrentPageTitle('Current'));
		callback();
	}, []);

	return (
		<div className="addLifeInsurance">
			<ProfileFormContainer title={title} goBack={goBack}>
				{insurances && (
					<EditLifeInsuranceForm
						lifeInsurance={insurances}
						userToken={userToken}
						editInsurance={editInsurance}
						goBack={goBack}
					/>
				)}
			</ProfileFormContainer>
		</div>
	);
};

export default EditLifeInsurance;
