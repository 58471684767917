import {useDispatch, useSelector} from 'react-redux';
import {PFCopyright, PFHeader, PFLink} from '../../../shared/components';
import './style.scss';
import {useEffect} from 'react';
import {setCurrentPageTitle} from '../../../store/actions';

const Error = () => {
	const dispatch = useDispatch();
	const auth = useSelector(state => state.general?.auth?.authToken);

	useEffect(() => {
		dispatch(setCurrentPageTitle('Error'));
	}, []);

	return (
		<>
			<div className="pf-error">
				{!auth && <PFHeader />}
				<div className="pf-error__block">
					<div className="pf-error__heading">404</div>
					<div className="pf-error__description">
						The link is broken or the page has been moved.
					</div>
					<PFLink to="/" className="pf-button pf-button--primary text-white">
						Back to Home
					</PFLink>
				</div>
				<PFCopyright />
			</div>
		</>
	);
};

export default Error;
