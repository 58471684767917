import React, {useCallback, useEffect, useState} from 'react';
import PFHeader from '../../../shared/components/PF-Header';
import PFButton from '../../../shared/components/PF-Button';
import PFLink from '../../../shared/components/PF-Link';
import PUBLIC_PATHS from '../../../const/url-paths/public';
import PFCopyright from '../../../shared/components/PF-Copyright';
import {useCompany, usePopupCompaniesData} from '../../../shared/hooks/companies';
import {CompleteSignUp} from '../../../components';
import {PFInput, PFPopup} from '../../../shared/components';
import {
	generateValidator,
	hints,
	validations,
	parseUrlQuery,
	renderLocation,
	useErrorHelper,
	Amplitude,
} from '../../../utils';
import {useForm} from 'react-hook-form';
import {useSignUpAdvisor} from '../../../shared/hooks';
import {HiEye, HiEyeOff} from 'react-icons/hi';
import PFSelect from '../../../shared/components/PF-Select';
import PFCheckbox from '../../../shared/components/PF-Checkbox';
import FormInputKeys from './constants';
import {useLocations} from '../../../shared/hooks/profile';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import urlPaths from '../../../api/url-paths';
import {DEFAULT_COMPANY_AVATAR, PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK} from '../../../const';
import PFAvatar from '../../../shared/components/PF-Avatar';

import './style.scss';
import PFInputAvatarCrop from '../../../shared/components/PF-InputAvatarCrop';

const SignUp = () => {
	const fetchCompanies = usePopupCompaniesData();
	const fetchLocations = useLocations();
	const fetchCompany = useCompany();
	const [selectedCompany, setSelectedCompany] = useState({});
	const [companyImage, setCompanyImage] = useState(null);
	const [isReady, setIsReady] = useState(false);
	const urlParams = parseUrlQuery(document.location);
	const [isCompleteSignUp, setIsCompleteSignUp] = useState(false);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);

	const signUp = useSignUpAdvisor();
	const formOptions = {
		mode: 'onChange',
	};
	const formProps = useForm(formOptions);
	const {
		register,
		trigger,
		getValues,
		watch,
		formState,
		setValue,
		control,
		unregister,
		setError,
		handleSubmit,
		...props
	} = formProps;

	const [isSubmitting, setIsSubmitting] = useState(false);

	const resetPasswordField = () => {
		setValue(FormInputKeys.password, '');
		setValue(FormInputKeys.confirmPassword, '');
	};

	const [isVisiblePsw, setIsVisiblePsw] = useState(false);
	const [isVisibleConfirmPsw, setIsVisibleConfirmPsw] = useState(false);
	const [isNewCompanyChecked, setIsNewCompanyChecked] = useState(false);
	const [isTermsOfUseChecked, setIsTermsOfUseChecked] = useState(false);

	const [errorModalConfig, setErrorModalConfig] = useState({
		visible: false,
		error: '',
	});

	const showErrorModal = error => {
		setErrorModalConfig({
			visible: true,
			error,
		});
	};

	const hideErrorModal = () => {
		setErrorModalConfig({
			visible: false,
			error: '',
		});
	};

	const handleTermsOfUseCheckboxClick = () => {
		setIsTermsOfUseChecked(prev => !prev);
	};

	const handleNewCompanyCheckboxClick = e => {
		setIsNewCompanyChecked(prev => !prev);
	};

	const togglePasswordVisibility = type => {
		if (type === FormInputKeys.password) {
			setIsVisiblePsw(visible => !visible);
		} else if (type === FormInputKeys.confirmPassword) {
			setIsVisibleConfirmPsw(visible => !visible);
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === FormInputKeys.password) {
				await trigger(FormInputKeys.confirmPassword);
			}
			if (name === FormInputKeys.newCompanyCheck) {
				await trigger(FormInputKeys.newCompanyCheck);
			}
			if (name === FormInputKeys.companyLocationToken) {
				await trigger(FormInputKeys.companyLocationToken);
			}
			if (name === FormInputKeys.companyName) {
				await trigger(FormInputKeys.companyName);
			}
			if (name === FormInputKeys.companyToken) {
				await trigger(FormInputKeys.companyToken);
			}
			if (name === FormInputKeys.companyLogo) {
				await trigger(FormInputKeys.companyLogo);
			}
			if (name === 'termsOfUseCheck') {
				await trigger('termsOfUseCheck');
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	const passwordIcon = isVisiblePsw ? (
		<HiEyeOff onClick={() => togglePasswordVisibility(FormInputKeys.password)} size={15} />
	) : (
		<HiEye onClick={() => togglePasswordVisibility(FormInputKeys.password)} size={15} />
	);

	const confirmPasswordIcon = isVisibleConfirmPsw ? (
		<HiEyeOff
			onClick={() => togglePasswordVisibility(FormInputKeys.confirmPassword)}
			size={15}
		/>
	) : (
		<HiEye onClick={() => togglePasswordVisibility(FormInputKeys.confirmPassword)} size={15} />
	);

	const halfWidth = 'col-lg-6 mb-4';
	const fullWidth = 'col-12 mb-4';

	const onClickGoBack = () => {
		resetPasswordField();
		setIsCompleteSignUp(false);
	};

	const findCompany = useCallback(async selectedCompanyToken => {
		try {
			const company = await fetchCompany(selectedCompanyToken);
			setSelectedCompany(company);
			setValue(FormInputKeys.companyToken, company.token);
			setCompanyImage(company?.image);
		} catch (e) {
			setSelectedCompany({});
			throw e;
		} finally {
			setIsReady(true);
		}
	}, []);

	const onChangeCompanyLogo = async image => {
		formProps.setValue(FormInputKeys.companyLogo, image);
	};

	useEffect(() => {
		if (urlParams?.ct) {
			findCompany(urlParams.ct);
		} else setIsReady(true);
	}, []);

	const onSubmit = async valueObj => {
		const getCompanyToken = () => {
			if (!!Object.keys(selectedCompany).length) {
				return selectedCompany.token;
			} else if (!isNewCompanyChecked) {
				return valueObj[FormInputKeys.companyToken];
			} else {
				return null;
			}
		};

		const profile = {
			firstName: valueObj[FormInputKeys.firstName],
			lastName: valueObj[FormInputKeys.lastName],
			email: valueObj[FormInputKeys.email],
			password: valueObj[FormInputKeys.password],
			companyToken: getCompanyToken(),
			companyName: isNewCompanyChecked ? valueObj[FormInputKeys.companyName] : null,
			companyImage: isNewCompanyChecked ? valueObj[FormInputKeys.companyLogo] : null,
			companyLocationToken: isNewCompanyChecked
				? valueObj[FormInputKeys.companyLocationToken]?.token
				: null,
		};

		try {
			setIsSubmitting(true);
			await signUp(profile);
			Amplitude.track('Signed_Up');
			setIsCompleteSignUp(true);
		} catch (e) {
			const errorMessage = e?.response?.data?.message;

			if (errorMessage) {
				Amplitude.track('Detected_Error', {error_name: errorMessage});
				showErrorModal(errorMessage);
			} else {
				errorHelper(e, false, errorMessage);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className={'pf-signUp'}>
			<PFHeader />
			<div className={`pf-signUp__form-wrapper ${isCompleteSignUp && 'd-none'}`}>
				<h3 className={'pf-signUp__form-title'}>Sign Up</h3>
				{isReady && (
					<form onSubmit={handleSubmit(onSubmit)} className={'pf-signUp__form'}>
						<div className={'row'}>
							<div className={`${fullWidth} email`}>
								<PFInput
									{...register(FormInputKeys.email, {
										validate: generateValidator(
											hints.email.required,
											validations.email,
										),
									})}
									showAsterisk={true}
									label={'Email'}
									placeholder={'Email'}
									type={'email'}
									defaultValue={''}
									error={
										formState.errors[FormInputKeys.email]?.message &&
										formState.errors[FormInputKeys.email]?.message
									}
								/>
							</div>
							<div className={`${fullWidth} firstName`}>
								<PFInput
									{...register(FormInputKeys.firstName, {
										validate: generateValidator(
											hints.textFields.required,
											validations.textFields.firstName,
										),
									})}
									showAsterisk={true}
									label={'First Name'}
									placeholder={'First Name'}
									defaultValue={''}
									error={
										formState.errors[FormInputKeys.firstName]?.message &&
										formState.errors[FormInputKeys.firstName]?.message
									}
								/>
							</div>
							<div className={`${fullWidth} lastName`}>
								<PFInput
									{...register(FormInputKeys.lastName, {
										validate: generateValidator(
											hints.textFields.required,
											validations.textFields.lastName,
										),
									})}
									showAsterisk={true}
									label={'Last Name'}
									placeholder={'Last Name'}
									defaultValue={''}
									error={
										formState.errors[FormInputKeys.lastName]?.message &&
										formState.errors[FormInputKeys.lastName]?.message
									}
								/>
							</div>
							<div
								className={classNames('inputSelectCompany', fullWidth, {
									disabled: isNewCompanyChecked,
								})}>
								<PFSelect
									{...register(FormInputKeys.companyToken, {
										required: !isNewCompanyChecked,
									})}
									defaultValue={
										!!Object.keys(selectedCompany).length ? selectedCompany : ''
									}
									showAsterisk={true}
									label={'Company'}
									placeholder={'Select your company from the list'}
									disabled={
										!!Object.keys(selectedCompany).length || isNewCompanyChecked
									}
									isMulti={false}
									blurInputOnSelect={true}
									closeMenuOnSelect={true}
									isAsync={true}
									onChange={value => {
										setValue(FormInputKeys.companyToken, value.token);
										setCompanyImage(value?.image);
									}}
									getOptionValue={item => item.name}
									loadOptions={async (searchText, loadedOptions) => {
										//this is our custom load options, we're returning the values from the API call
										const {
											body: options,
											count,
											fullCount,
										} = await fetchCompanies({
											searchText,
											take: 10,
											skip: loadedOptions.length,
										});
										return {
											options,
											hasMore:
												count !== 0 &&
												fullCount > options.length + loadedOptions.length,
										};
									}}
									formatOptionLabel={option => option.name}
									defaultOptions={true}
									error={
										formState.errors[FormInputKeys.companyToken]?.message &&
										formState.errors[FormInputKeys.companyToken]?.message
									}
									childrenBeforeInput={
										<PFAvatar
											src={
												!!companyImage &&
												`${urlPaths.BASE_IMAGES_URL}${companyImage}`
											}
											size={45}
											defaultAvatar={DEFAULT_COMPANY_AVATAR}
											variant="rounded-square"
											className="inputSelectCompany__companyLogo"
										/>
									}
								/>
							</div>

							<div className={`${fullWidth}`}>
								<PFCheckbox
									{...register(FormInputKeys.newCompanyCheck)}
									id={FormInputKeys.newCompanyCheck}
									name={FormInputKeys.newCompanyCheck}
									registerControl
									onClick={handleNewCompanyCheckboxClick}
									label={'Add a new company'}
									disabled={!!Object.keys(selectedCompany).length}
									className={'newCompanyCheck'}
								/>
							</div>

							<div
								className={classNames('anotherCompany', {
									disabled: !isNewCompanyChecked,
								})}>
								<div className={`${fullWidth} companyName`}>
									<PFInput
										{...register(FormInputKeys.companyName, {
											validate: generateValidator(
												isNewCompanyChecked,
												validations.textFields.companyName,
											),
										})}
										showAsterisk={true}
										label={'New company name'}
										placeholder={'New company name'}
										defaultValue={''}
										error={
											formState.errors[FormInputKeys.companyName]?.message &&
											formState.errors[FormInputKeys.companyName]?.message
										}
									/>
								</div>
								<div className={`${fullWidth} companyLocationToken`}>
									<PFSelect
										{...register(FormInputKeys.companyLocationToken, {
											required: isNewCompanyChecked,
										})}
										defaultValue={
											!!Object.keys(selectedCompany).length
												? selectedCompany.location
												: ''
										}
										showAsterisk={true}
										label={'Company location'}
										placeholder={'New company location'}
										disabled={false}
										isMulti={false}
										blurInputOnSelect={true}
										closeMenuOnSelect={true}
										isAsync={true}
										onChange={value => {
											setValue(FormInputKeys.companyLocationToken, value);
										}}
										getOptionValue={item => item}
										loadOptions={async (Query, loadedOptions) => {
											//this is our custom load options, we're returning the values from the API call
											const {
												body: options,
												count,
												currentCount,
											} = await fetchLocations({
												Query,
												skip: loadedOptions.length,
											});
											return {
												options,
												hasMore:
													currentCount !== 0 &&
													count > options.length + loadedOptions.length,
											};
										}}
										formatOptionLabel={option => renderLocation(option)}
										defaultOptions={true}
										error={
											formState.errors[FormInputKeys.companyLocationToken]
												?.message &&
											formState.errors[FormInputKeys.companyLocationToken]
												?.message
										}>
										{'Company location'}
									</PFSelect>
								</div>
								<div className="companyLogo d-none">
									<PFInput
										{...formProps.register(FormInputKeys.companyLogo, {
											required: isNewCompanyChecked && 'Logo is required',
											validate: value => {
												if (value === 'error') {
													return "The file you've uploaded is larger than 5MB. Please upload an image smaller than 5MB.";
												}
												if (value === 'error-type') {
													return 'HEIC image format not supported';
												}
												return true;
											},
										})}
										// @ts-ignore
										showAsterisk
										id="companyLogo"
										defaultValue={
											formProps.getValues(FormInputKeys.companyLogo) || null
										}
										error={
											formProps.formState.errors[FormInputKeys.companyLogo]
												?.message &&
											formProps.formState.errors[FormInputKeys.companyLogo]
												?.message
										}
									/>
								</div>
								<div className="col-12 companyLogo">
									<PFInputAvatarCrop
										label="Company logo"
										showAsterisk
										id="companyLogo1"
										onChange={onChangeCompanyLogo}
										src={formProps.getValues(FormInputKeys.companyLogo) || null}
										avatarSize={45}
										variant="rounded-square"
										defaultAvatar={DEFAULT_COMPANY_AVATAR}
										hint=""
										error={
											formProps.formState.errors[FormInputKeys.companyLogo]
												?.message &&
											formProps.formState.errors[FormInputKeys.companyLogo]
												?.message
										}
									/>
								</div>
							</div>

							<div className={`${fullWidth} password`}>
								<PFInput
									{...register(FormInputKeys.password, {
										validate: generateValidator(
											hints.password.required,
											validations.password,
										),
										required: true,
									})}
									showAsterisk={true}
									label={'Password'}
									placeholder={'Password'}
									autoComplete={'new-password'}
									icon={passwordIcon}
									type={isVisiblePsw ? 'text' : 'password'}
									hint={hints.password.index}
									defaultValue={''}
									error={
										formState.errors[FormInputKeys.password]?.message &&
										formState.errors[FormInputKeys.password]?.message
									}
								/>
							</div>
							<div className={`${fullWidth} confirmPassword`}>
								<PFInput
									{...register(FormInputKeys.confirmPassword, {
										validate: value =>
											value === getValues('password') ||
											'The passwords do not match!',
									})}
									showAsterisk={true}
									label={'Confirm Password'}
									placeholder={'Confirm password'}
									autoComplete={'new-password'}
									icon={confirmPasswordIcon}
									type={isVisibleConfirmPsw ? 'text' : 'password'}
									defaultValue={''}
									error={
										formState.errors[FormInputKeys.confirmPassword]?.message &&
										formState.errors[FormInputKeys.confirmPassword]?.message
									}
								/>
							</div>
						</div>
						<div className={`${fullWidth} termsOfUseCheckContainer`}>
							<PFCheckbox
								{...register('termsOfUseCheck', {required: true})}
								id={'termsOfUseCheck'}
								name={'termsOfUseCheck'}
								registerControl
								checked={isTermsOfUseChecked}
								onClick={handleTermsOfUseCheckboxClick}
								disabled={false}
								className={'termsOfUseCheck'}
								label={
									<span>
										I have read, understand and agreed to the{' '}
										<PFLink href={TERMS_OF_USE_LINK} target="_blank">
											Terms of Use
										</PFLink>{' '}
										and{' '}
										<PFLink href={PRIVACY_POLICY_LINK} target="_blank">
											Privacy Policy
										</PFLink>
										.
									</span>
								}
							/>
						</div>
						<div className={'d-flex justify-content-end w-100'}>
							<PFButton
								disabled={!formState.isValid || isSubmitting}
								type="submit"
								className={'signUpButton'}>
								sign up
							</PFButton>
						</div>
					</form>
				)}
				<p className={'pf-signUp__form-footer'}>
					<PFLink to={PUBLIC_PATHS.SIGN_IN}>Sign in</PFLink>
				</p>
			</div>
			{isCompleteSignUp && (
				<CompleteSignUp
					goBack={onClickGoBack}
					isCompleteSignUp={isCompleteSignUp}
					resetPasswordField={resetPasswordField}
					getValues={getValues}
					resend={handleSubmit(onSubmit)}
				/>
			)}
			<div className="pf-signUp__footer">
				<div className="linkWrapper">
					<span className={'d-none d-lg-block'}>Need help?</span>
					<PFLink
						href="mailto:support@pocketfinance.com"
						onClick={() => {
							Amplitude.track('Contacted_Support', {source: 'Sign Up'});
						}}
						className="pf-signUp__footer-link">
						Contact the administrator
					</PFLink>
				</div>
				<PFCopyright />
			</div>
			<PFPopup
				isShow={errorModalConfig.visible}
				handleClose={hideErrorModal}
				submitBtnText={'Ok'}
				isShowCancelBtn={false}
				primaryVariant="primary"
				isCloseButton={false}
				title={errorModalConfig.error}
				subtitle={false}
				onSubmit={hideErrorModal}
			/>
		</div>
	);
};

export default SignUp;
