import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const getCompanies = params =>
	httpClient.get(`${URL.BASE_URL}/${URL.COMPANIES.INDEX}/search`, {params});

export const getCompany = token =>
	httpClient.get(`${URL.BASE_URL}/${URL.COMPANIES.INDEX}/${token}`);

export const setCompany = body => httpClient.post(`${URL.BASE_URL}/${URL.COMPANIES.INDEX}`, body);

export const updateCompany = body => httpClient.put(`${URL.BASE_URL}/${URL.COMPANIES.INDEX}`, body);

export const setBlockedCompany = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.COMPANIES.INDEX}/${URL.COMPANIES.BLOCKED}`, body);

export const deleteCompanies = tokens =>
	httpClient.delete(`${URL.BASE_URL}/${URL.COMPANIES.INDEX}`, {data: tokens});

export const restoreCompanies = tokens =>
	httpClient.post(`${URL.BASE_URL}/${URL.COMPANIES.RESTORE}`, tokens);

export const resetIsNewCompany = token =>
	httpClient.post(`${URL.BASE_URL}/${URL.COMPANIES.RESET_IS_NEW_COMPANY}/${token}`);
