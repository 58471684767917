import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const updateMyPassword = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.PASSWORDS.INDEX}`, body);

export const setPasswordByCode = (body: {
	code: string;
	username: string;
	password: string;
	isFromPortal: boolean;
}) => httpClient.post(`${URL.BASE_URL}/${URL.PASSWORDS.INDEX}`, body);
