import {useDispatch} from 'react-redux';
import {setCompanies, setCompanyUsers} from '../../../store/actions';
import * as companiesService from '../../../api/services/companies';
import React, {useEffect, useState} from 'react';
import PFPopup from '../../components/PF-Popup';
import PFButton from '../../components/PF-Button';
import {useForm} from 'react-hook-form';
import PFSelect from '../../components/PF-Select';
import {findByToken, ToastDispatcher} from '../../../utils';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import * as usersService from '../../../api/services/users';
import classNames from 'classnames';

export const useCompanies = params => {
	const dispatch = useDispatch();

	return async (payload = params) => {
		try {
			const response = await companiesService.getCompanies(params);
			dispatch(setCompanies(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useCompanyUsers = params => {
	const dispatch = useDispatch();

	return async (payload = params) => {
		try {
			const response = await usersService.getUsers(params);
			dispatch(setCompanyUsers(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useCompany = () => {
	return async token => {
		try {
			const response = await companiesService.getCompany(token);
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useResetIsNewCompany = token => {
	return async (payload = token) => {
		try {
			await companiesService.resetIsNewCompany(payload);
		} catch (e) {
			throw e;
		}
	};
};

export const useAddNewCompany = navigate => {
	return async body => {
		try {
			return await companiesService.setCompany(body);
		} catch (e) {
			throw e;
		}
	};
};

export const useUpdateCompany = () => {
	return async body => {
		try {
			const res = await companiesService.updateCompany(body);
			ToastDispatcher.success('Company data updated');
			return res;
		} catch (e) {
			throw e;
		}
	};
};

export const useBlockedCompany = () => {
	return async (body, companyName, callback) => {
		try {
			const res = await companiesService.setBlockedCompany(body);
			ToastDispatcher.success(
				body.isBlocked ? `${companyName} suspended` : `${companyName} reactivated`,
			);
			if (callback) callback();
			return res;
		} catch (e) {
			throw e;
		}
	};
};

//! Возможно это не надо
// export const useDeletedCompany = () => {
// 	return async (token, companyName, callback) => {
// 		try {
// 			const res = await companiesService.deleteCompany(token);
// 			ToastDispatcher.success(`${companyName} deleted`);
// 			if (callback) callback();
// 			return res;
// 		} catch (e) {
// 			throw e;
// 		}
// 	};
// };

export const useBlockedPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showConfirm = () => setShowConfirmation(true);
	const cancelConfirm = () => setShowConfirmation(false);
	return {
		ConfirmModal: ({onSubmit, checkedKeys, className}) => {
			let message =
				'Suspend the company and its advisors? They will not be able to take any action on the portal';
			if (checkedKeys && checkedKeys.length > 1) {
				message = `Suspend ${checkedKeys.length} companies and its users? They will not be able to take any action on the portal`;
			}
			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelConfirm}
					submitBtnText={'Suspend'}
					primaryVariant="primary"
					isCloseButton
					title={message}
					subtitle={false}
					onSubmit={() => {
						onSubmit?.(); //if we pass any onSubmit function, it'll call this, otherwise we'll just signout the user.
						cancelConfirm();
					}}
					className={{
						root: `${className || ''}`,
					}}
				/>
			);
		},
		showConfirmation,
		showConfirm,
		cancelConfirm,
	};
};

export const useDeletedPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showDeleteConfirm = () => setShowConfirmation(true);
	const cancelDeleteConfirm = () => setShowConfirmation(false);

	return {
		ConfirmDeleteModal: ({onSubmit, checkedKeys, className, name}) => {
			let message = name
				? `Delete ${name}?`
				: `Delete ${checkedKeys.length} compan${checkedKeys.length > 1 ? 'ies' : 'y'}?`;
			const subtitle = `All users of the compan${
				checkedKeys && checkedKeys.length > 1 ? 'ies' : 'y'
			} will be notified about the deletion and the need to identify themselves in another company.`;

			const submit = async () => {
				onSubmit?.();
				cancelDeleteConfirm();
			};

			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelDeleteConfirm}
					submitBtnText={'Delete'}
					primaryVariant="alert"
					isCloseButton
					title={message}
					subtitle={subtitle}
					onSubmit={submit}
					className={{
						root: `${className || ''}`,
					}}
				/>
			);
		},
		showConfirmation,
		showDeleteConfirm,
		cancelDeleteConfirm,
	};
};

export const usePopupCompaniesData = () => {
	const body = {
		searchText: '',
		take: 10,
		skip: 0,
	};
	return async (payload = body) => {
		try {
			const {data} = await companiesService.getCompanies(payload);
			return data;
		} catch (e) {
			return [];
		}
	};
};

export const useIsSuspendUserPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showIsSuspendUserConfirm = () => setShowConfirmation(true);
	const cancelIsSuspendUserConfirm = () => setShowConfirmation(false);
	return {
		ConfirmIsSuspendUserModal: ({onSubmit, checkedKeys, className, name}) => {
			let message = name
				? `Suspend ${name}? They will not be able to take any action on the portal`
				: 'Suspend this user? They will not be able to take any action on the portal';
			if (checkedKeys && checkedKeys.length > 0) {
				message = `Suspend ${checkedKeys.length} user${
					checkedKeys.length > 1 ? 's' : ''
				}? They will not be able to take any action on the portal`;
			}

			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelIsSuspendUserConfirm}
					submitBtnText={'Suspend'}
					primaryVariant="primary"
					isCloseButton
					title={message}
					subtitle={false}
					onSubmit={() => {
						onSubmit?.(); //if we pass any onSubmit function, it'll call this, otherwise we'll just signout the user.
						cancelIsSuspendUserConfirm();
					}}
					className={{
						root: `${className || ''}`,
					}}
				/>
			);
		},
		showConfirmation,
		showIsSuspendUserConfirm: showIsSuspendUserConfirm,
		cancelIsSuspendUserConfirm: cancelIsSuspendUserConfirm,
	};
};

export const useChangeCompanyPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showChangeCompanyConfirm = () => setShowConfirmation(true);
	const cancelChangeCompanyConfirm = () => setShowConfirmation(false);
	return {
		ConfirmChangeCompanyModal: ({onSubmit, className, name, checkedKeys}) => {
			let message = name ? `Change company for ${name}` : 'Change company';
			if (checkedKeys && checkedKeys.length > 1) {
				message = `Change company for ${checkedKeys.length} user${
					checkedKeys.length > 1 ? 's' : ''
				}`;
			}

			const {register, handleSubmit, trigger, watch, formState, setValue} = useForm({
				mode: 'onChange',
			});

			const [isSubmitting, setIsSubmitting] = useState(false);
			const fetchCompanies = usePopupCompaniesData();

			useEffect(() => {
				const subscription = watch(async (data, {name}) => {
					if (name === 'companyToken') {
						await trigger('companyToken');
					}
				});
				return () => subscription.unsubscribe();
			}, [watch, trigger]);

			const submit = async valueObj => {
				const {companyToken, companyName} = valueObj;
				setIsSubmitting(true);
				onSubmit?.(checkedKeys, companyToken, companyName);
				cancelChangeCompanyConfirm();
				setIsSubmitting(false);
			};

			const loadCompanyTokenOptions = async (searchText, loadedOptions) => {
				//this is our custom load options, we're returning the values from the API call
				const {
					body: options,
					count,
					fullCount,
				} = await fetchCompanies({
					searchText,
					take: 10,
					skip: loadedOptions.length,
				});
				return {
					options,
					hasMore: count !== 0 && fullCount > options.length + loadedOptions.length,
				};
			};

			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelChangeCompanyConfirm}
					submitBtnText={'Save'}
					primaryVariant="primary"
					isCloseButton
					title={message}
					subtitle={false}
					onSubmit={() => {
						onSubmit?.();
						cancelChangeCompanyConfirm();
					}}
					className={{
						root: `${className || ''}`,
						paper: 'pf-changeCompanyPopup__paper',
					}}
					isShowFooter={false}>
					<form onSubmit={handleSubmit(submit)} className={`pf-changeCompany__form`}>
						<div className="row">
							<div className={classNames(`col-12 mb-4 inputSelectCompany`)}>
								<PFSelect
									{...register('companyToken', {
										required: 'The option should be chosen',
									})}
									defaultValue={''}
									showAsterisk={false}
									label={'Company'}
									placeholder={'Select company from the list'}
									disabled={false}
									isMulti={false}
									closeMenuOnSelect={true}
									blurInputOnSelect={false}
									isAsync={true}
									onChange={value => {
										setValue('companyToken', value.token);
										setValue('companyName', value.name);
									}}
									getOptionValue={item => item.name}
									loadOptions={loadCompanyTokenOptions}
									formatOptionLabel={option => option.name}
									defaultOptions={true}
									error={
										formState.errors?.companyToken?.message &&
										formState.errors?.companyToken?.message
									}
								/>
							</div>
						</div>
						<div className="d-flex mt-4 justify-content-center">
							<div className="d-flex w-100 m-auto pf-modal__footer-inner">
								<PFButton
									disabled={!formState.isValid || isSubmitting}
									type="submit"
									variant="primary">
									Save
								</PFButton>
								<PFButton
									onClick={cancelChangeCompanyConfirm}
									variant="secondary"
									type={'button'}>
									Cancel
								</PFButton>
							</div>
						</div>
					</form>
				</PFPopup>
			);
		},
		showConfirmation,
		showChangeCompanyConfirm,
		cancelChangeCompanyConfirm,
	};
};
