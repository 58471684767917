import React, {FC, useEffect, useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import {Virtuoso} from 'react-virtuoso';

import {Menu} from '@mui/material';
import {GetNotificationsParamsType, notificationsService} from 'api/services';
import {bellIcon, closeIcon, iconViewAll, loaderIcon, noNotificationsIcon, refresh} from 'assets';
import {AxiosResponse} from 'axios';
import classNames from 'classnames';
import {PORTAL_PATHS} from 'const';
import {useBooleanState} from 'shared/hooks';
import {useAppSelector} from 'store';
import {tableSelectors} from 'store/selectors';
import {ResponseDataNotificationsSearchType, ResponseDataNotificationsType} from 'types';
import {ToastDispatcher, useErrorHelper} from 'utils';

import SnackActionButton from '../Snackbar/components/SnackActionButton/SnackActionButton';

import NotificationItem from './components/NotificationItem/NotificationItem';

import './style.scss';

type PropsTypes = {
	className?: string;
};

const VirtuosoFooter = () => {
	return (
		<div className="notifications__loadMore">
			<ReactSVG src={loaderIcon} className="notifications__loadMore-icon" />
		</div>
	);
};

const Notifications: FC<PropsTypes> = ({className}) => {
	const navigate: NavigateFunction = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [notificationsCount, setNotificationsCount] = useState<number>(0);
	const [notifications, setNotifications] = useState<ResponseDataNotificationsType>([]);
	const [isLoading, toggleIsLoading] = useBooleanState(false);
	const [isSubmittingDismissAll, setIsSubmittingDismissAll] = useBooleanState(false);
	const [isFull, setIsFull] = useState<boolean>(false);
	const isShowSearch = useAppSelector(tableSelectors.selectIsShowSearch);

	const handleBellClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseNotificationCenter = () => {
		setAnchorEl(null);
	};

	const onMarkIsNotNew = async (notificationsToken: string[]) => {
		await notificationsService.resetIsNewNotification(notificationsToken);
	};

	const getUnreadNotifications = async (isRefresh = false) => {
		try {
			const params: GetNotificationsParamsType = {
				skip: 0,
				take: 5,
				isActive: true,
				reverse: true,
				tokenFrom:
					notifications.length && !isRefresh
						? notifications[notifications.length - 1].token
						: null,
			};
			const response: AxiosResponse<ResponseDataNotificationsSearchType> =
				await notificationsService.getNotifications(params);
			if (response.data.body.length) {
				if (isRefresh) {
					setNotifications(response.data.body);
				} else {
					setNotifications([...notifications, ...response.data.body]);
				}
				await onMarkIsNotNew(response.data.body.map(item => item.token));
			} else {
				setIsFull(true);
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			toggleIsLoading(false);
		}
	};

	const loadMore = async () => {
		if (!isFull) {
			await getUnreadNotifications();
		}
	};

	const getNewNotificationsCount = async () => {
		try {
			const response: AxiosResponse<number> =
				await notificationsService.getCountIsNewNotifications();
			setNotificationsCount(response.data);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const getVirtuosoItem = (index, item) => {
		return (
			<NotificationItem
				key={item.token}
				data={item}
				updateNotificationsCount={getNewNotificationsCount}
				onCloseNotificationCenter={handleCloseNotificationCenter}
			/>
		);
	};

	const onRefresh = async () => {
		toggleIsLoading(true);
		setIsFull(false);
		await getNewNotificationsCount();
		await getUnreadNotifications(true);
	};

	const onDismissAll = async (notificationsToken: string[]) => {
		try {
			setIsSubmittingDismissAll(true);
			await notificationsService.markIsActionNotifications(notificationsToken);
			ToastDispatcher.success('All notifications dismissed.', snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Undo"
					onClickAction={async () => {
						try {
							await notificationsService.resetIsActionNotifications(
								notificationsToken,
							);
							await onRefresh();
						} catch (e) {
							errorHelper(e);
						}
					}}
				/>
			));
			await onRefresh();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmittingDismissAll(false);
		}
	};

	const onViewArchive = () => {
		handleCloseNotificationCenter();
		navigate(`${PORTAL_PATHS.OTHER_PATHS.VIEW_ARCHIVE_NOTIFICATIONS}`);
	};

	useEffect(() => {
		getNewNotificationsCount();
	}, []);

	useEffect(() => {
		getNewNotificationsCount();
		if (open) {
			toggleIsLoading(true);
			setIsFull(false);
			getUnreadNotifications(true);
		}
	}, [open]);

	useEffect(() => {
		const intervalId = setInterval(getNewNotificationsCount, 10000); // 10 seconds interval

		return () => clearInterval(intervalId); // Clear interval on component unmount
	}, []);

	return (
		<div className={classNames('notifications', {hidden: isShowSearch})}>
			<div className="button-container">
				<button
					className={classNames('toggle-button', {new: !!notificationsCount})}
					type="button"
					onClick={handleBellClick}
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}>
					<div className="count-text">
						{notificationsCount < 100 ? notificationsCount : '99+'}
					</div>
					<ReactSVG className="bell-icon" src={bellIcon} />
				</button>
			</div>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleCloseNotificationCenter}
				classes={{
					root: 'MuiMenu-root notifications__menu-root',
					paper: 'MuiMenu-paper notifications__menu-paper',
					list: 'MuiMenu-list notifications__menu-list',
				}}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{horizontal: 'right', vertical: 'top'}}
				anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
				<div className="notifications__menu-list-header">
					<div className="notifications__menu-list-header__title">Notifications</div>
					<div className="notifications__menu-list-header__buttonWrapper">
						<button
							type="button"
							className="notifications__menu-list-header__buttonWrapper__refresh"
							onClick={onRefresh}>
							<ReactSVG
								src={refresh}
								wrapper="div"
								className="notifications__menu-list-header__buttonWrapper__refresh-icon"
							/>
						</button>
						<button
							className="notifications__menu-list-header__buttonWrapper__closeButton"
							type="button"
							onClick={handleCloseNotificationCenter}>
							<ReactSVG
								src={closeIcon}
								wrapper="div"
								className="notifications__menu-list-header__buttonWrapper__closeButton-icon"
							/>
						</button>
					</div>
				</div>

				<div className={classNames('notifications__menu-list-body')}>
					<div className="notifications__menu-list-body__tabWrapper">
						{isLoading && (
							<div className="notifications__menu-list-body__loading__spinner">
								<ReactSVG
									src={loaderIcon}
									className="notifications__menu-list-body__loading__spinner-icon"
								/>
							</div>
						)}
						{!isLoading && notifications?.length > 0 ? (
							<Virtuoso
								style={{height: '100%'}}
								data={notifications}
								endReached={loadMore}
								itemContent={getVirtuosoItem}
								components={!isFull ? {Footer: VirtuosoFooter} : {}}
							/>
						) : (
							!isLoading &&
							isFull && (
								<div className="notifications__menu-list-body__empty-label">
									<ReactSVG
										src={noNotificationsIcon}
										className="no-notifications-icon"
									/>
									<div className="no-notifications-text">
										You have no new notifications
									</div>
								</div>
							)
						)}
					</div>
				</div>

				<div className={classNames('notifications__menu-list-footer')}>
					<div className="notifications__menu-list-footer__dismissAll">
						{notifications?.length > 0 && !isLoading && (
							<button
								className={classNames(
									'notifications__menu-list-footer__dismissAll__button',
									{
										'notifications__menu-list-footer__dismissAll__button__none':
											notifications?.length < 1 || isLoading,
									},
								)}
								disabled={isSubmittingDismissAll}
								type="button"
								onClick={() => {
									onDismissAll(notifications.map(item => item.token));
								}}>
								Dismiss All
							</button>
						)}
					</div>
					<div className="notifications__menu-list-footer__viewArchive">
						<button
							type="button"
							className="notifications__menu-list-footer__viewArchive__button"
							onClick={onViewArchive}>
							View Archive
							<ReactSVG
								src={iconViewAll}
								className="notifications__menu-list-footer__viewArchive__button-icon"
							/>
						</button>
					</div>
				</div>
			</Menu>
		</div>
	);
};

export default Notifications;
