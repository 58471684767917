enum WhoIsReferred {
	YourClients = '1',
	UnregisteredUsers = '2',
}

enum ReferringToBusinessesType {
	Mortgage = 1,
	Lending = 2,
	Insurance = 3,
	Investment = 4,
	RealEstate = 5,
	LegalServices = 6,
	HomeAuto = 7,
	RestructuringRestoration = 8,
	FinancialPlanning,
}

export {ReferringToBusinessesType, WhoIsReferred};
