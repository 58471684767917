import React from 'react';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {ReactSVG} from 'react-svg';

import {iconDots} from 'assets';
import {OpportunityItemActionType} from 'types';

import {Deadline} from './Deadline';

import './TodoItem.scss';
import styles from './TodoItem.module.scss';

type Props = {
	showDescription: boolean;
	shouldColorDeadlines: boolean;
	title: string | null;
	text: string | null;
	deadline: string | null;
	icon: React.ReactNode | string;
	date: Date | string | null;
	actions?: OpportunityItemActionType[];
};

const dateFormatter = new Intl.DateTimeFormat('en-US', {
	month: 'short',
	day: '2-digit',
	year: 'numeric',
});

const TodoItem: React.FC<Props> = ({
	showDescription,
	shouldColorDeadlines,
	title,
	text,
	deadline,
	icon,
	date,
	actions,
}) => {
	// const dateView =
	// 	// eslint-disable-next-line no-nested-ternary
	// 	date === null ? (
	// 		'n/a'
	// 	) : (
	// 		<span className={styles.dateValue}>
	// 			{typeof date === 'string' ? date : dateFormatter.format(date)}
	// 		</span>
	// 	);

	return (
		<div className={styles.container}>
			<div className={styles.icon}>
				{typeof icon === 'string' ? <ReactSVG wrapper="div" src={icon} /> : icon}
			</div>
			<div className={styles.infoContainer}>
				<div className={styles.title}>{title}</div>
				{showDescription && <div className={styles.text}>{text}</div>}
				{!!deadline && (
					<Deadline date={deadline} shouldColorDeadlines={shouldColorDeadlines} />
				)}
			</div>
			{actions !== undefined && actions?.length > 0 && (
				<div className={styles.actions}>
					<DropdownButton
						align="end"
						title={<ReactSVG wrapper="div" src={iconDots} />}
						id="bg-nested-dropdown"
						className="insurance-dropdown-button">
						{actions.map((action, index) => (
							<Dropdown.Item
								onClick={action.handler}
								eventKey={`${index + 1}`}
								key={action.name}>
								<div>{action.name}</div>
							</Dropdown.Item>
						))}
					</DropdownButton>
				</div>
			)}
		</div>
	);
};

export default TodoItem;
