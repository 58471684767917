import React, {FC} from 'react';
import {useMediaQuery} from 'react-responsive';

import {Tooltip, Whisper} from 'rsuite';

import './style.scss';

type PropsTypes = {
	customClientTags: string[];
	token?: string;
};

const CustomTagCellItem: FC<PropsTypes> = ({customClientTags, token}) => {
	const trigger = useMediaQuery({query: '(max-width: 992px)'}) ? 'click' : 'hover';
	const tagBadges =
		customClientTags.length === 0 ? (
			<div className="emptyBadge">-</div>
		) : (
			customClientTags.map(item => (
				<div className="tagBadge" key={item}>
					{item}
				</div>
			))
		);

	return (
		<div className="customTagCellContainer" id={token}>
			{customClientTags.length > 1 ? (
				<Whisper
					enterable
					preventOverflow
					trigger={trigger}
					placement="auto"
					controlId={`control-${token}-auto`}
					speaker={
						<Tooltip arrow={false} className="pf-tooltip">
							{tagBadges}
						</Tooltip>
					}>
					<div id={`control-${token}-auto`} className="customTagCellContainer__content">
						{tagBadges[0]}
						<span className="tagDots">...</span>
					</div>
				</Whisper>
			) : (
				tagBadges
			)}
		</div>
	);
};

export default CustomTagCellItem;
