import moment from 'moment';

import * as financialReportsService from '../../api/services/financialReports';

export const generateReportName = (firstName: string, lastName: string, date: string): string => {
	return `${firstName}-${lastName}-Summary-Report-${moment
		.utc(date)
		.local()
		.format('MMM-DD-YYYY')}`;
};

export const downloadFinancialReport = async (token: string, reportName: string): Promise<any> => {
	const response = await financialReportsService.getFinancialReportPdf(token);
	if (response.status === 200) {
		const blob = response.data;
		const a = document.createElement('a');
		a.download = `${reportName}.pdf`;
		a.style.display = 'none';
		a.onload = () => {
			window.URL.revokeObjectURL(a.href);
		};
		a.href = window.URL.createObjectURL(blob);
		document.body.appendChild(a);
		a.click();
		a.remove();
	}
};
