import React, {useMemo} from 'react';
import {ReactSVG} from 'react-svg';

import clockIcon from 'assets/clock-icon.svg';
import moment from 'moment';

import styles from './Deadline.module.scss';

type StylePostfix = 'Basic' | 'Warning' | 'Error';

export const Deadline: React.FC<{
	date: string;
	shouldColorDeadlines: boolean;
}> = ({date, shouldColorDeadlines}) => {
	const dateObject = useMemo(() => moment(date), [date]);
	const stylePostfix: StylePostfix = useMemo(() => {
		if (!shouldColorDeadlines) {
			return 'Basic';
		}

		const now = moment();

		const diff = dateObject.diff(now, 'days');

		if (diff < 0) {
			return 'Error';
		}

		if (diff < 5) {
			return 'Warning';
		}

		return 'Basic';
	}, [dateObject, shouldColorDeadlines]);

	return (
		<div className={`${styles.wrapper}`}>
			<div className={`${styles.container} ${styles[`container${stylePostfix}`]}`}>
				<ReactSVG
					wrapper="div"
					src={clockIcon}
					className={`${styles.icon} ${styles[`icon${stylePostfix}`]}`}
				/>
				<div className={`${styles.date} ${styles[`date${stylePostfix}`]}`}>
					{dateObject.format('MMM D, YYYY')}
				</div>
			</div>
		</div>
	);
};
