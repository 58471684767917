/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
enum FormInputKeys {
	email = 'email',
	firstName = 'firstName,',
	lastName = 'lastName',
	company = 'company',
	avatar = 'avatar',
	videoPresentationLink = 'videoPresentationLink',
	description = 'description',
	financialCertificates = 'financialCertificates',
	specializationValue = 'specializationValue',
	calendlyLink = 'calendlyLink',
	location = 'location',
	phoneNumber = 'phoneNumber',
	facebook = 'facebook',
	linkedin = 'linkedin',
	instagram = 'instagram',
	youtube = 'youtube',
	twitter = 'twitter',
	contactEmail = 'contactEmail',
	newCompanyCheck = 'newCompanyCheck',
	companyLocationToken = 'companyLocationToken',
	companyName = 'companyName',
	companyLogo = 'companyLogo',
}

export default FormInputKeys;
