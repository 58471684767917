import {PLANS} from 'const';
import {RootState} from 'store/store';
import {SubscriptionPeriods} from 'types/enums';

export const selectHasSubscription = (state: RootState) => {
	if (state.subscription.isLoading && state.subscription.isFirstLoad) {
		return null;
	}

	return (
		!!state.subscription.subscription && state.subscription.subscription.isSubscriptionActive
	);
};

export const selectCurrentCount = (state: RootState) => state.subscription.currentCount;
export const selectLeadsCount = (state: RootState) => state.subscription.leadsCount;

export const selectIsLoading = (state: RootState) => state.subscription.isLoading;
export const selectSubscription = (state: RootState) => state.subscription.subscription;

export const selectCurrentPlan = (state: RootState) => {
	const {subscription} = state.subscription;

	if (subscription && subscription.isSubscriptionActive) {
		return {
			plan: PLANS[1],
			isAnnual: subscription.type === SubscriptionPeriods.Yearly,
		};
	}

	return {
		plan: PLANS[0],
		isAnnual: false,
	};
};
