import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

import {leadsService, RejectRequestLeadsToCurrentParamsType} from 'api/services';
import classNames from 'classnames';
import PFButton from 'shared/components/PF-Button';
import PFPopup from 'shared/components/PF-Popup';
import PFRadioGroup from 'shared/components/PF-RadioGroup';
import PFTextarea from 'shared/components/PF-Textarea';
import {useBooleanState} from 'shared/hooks';
import {useAppDispatch} from 'store';
import {removeCheckedItems, removeLeadsItem, setSideModal} from 'store/actions';
import {fetchCounts} from 'store/reducers/SubscriptionReducer';
import {Amplitude, ToastDispatcher} from 'utils';
import {generateValidator, validations} from 'utils/futureTS/validations';

import './style.scss';

const RejectLeadModal: React.FC<{
	tokens: string[];
	visible: boolean;
	className?: string;
	onCancel: () => void;
}> = ({tokens, visible, className, onCancel}) => {
	const dispatch = useAppDispatch();
	const {register, trigger, watch, formState, setValue, handleSubmit, control, getValues} =
		useForm({
			mode: 'onChange',
		});

	const [isSubmitting, setIsSubmitting] = useBooleanState(false);
	const [isVisibleReasonRejectField, setIsVisibleReasonRejectField] = useBooleanState(false);

	const [hintValue, setHintValue] = useState<number>(0);
	const descriptionHint = (
		<div className={classNames('counter-hint', {error: hintValue > 90})}>{hintValue}/90</div>
	);

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === 'customReason') {
				await trigger('customReason');
			}
			if (name === 'isDefaultReason') {
				if (data.isDefaultReason === '1') {
					setIsVisibleReasonRejectField(false);
				}
				if (data.isDefaultReason === '2') {
					setIsVisibleReasonRejectField(true);
				}
				await trigger('customReason');
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	const submitForm = async valueObj => {
		const body: RejectRequestLeadsToCurrentParamsType = {
			userTokens: tokens,
			reason:
				valueObj.isDefaultReason === '1'
					? 'Not accepting any clients at this time.'
					: valueObj.customReason,
		};
		try {
			setIsSubmitting(true);
			await leadsService.rejectRequestLeadsToCurrent(body);

			Amplitude.track('Rejected_Client', {
				clients: tokens,
				reason: body.reason,
			});

			// @ts-ignore
			await dispatch(fetchCounts());

			onCancel();
			dispatch(setSideModal(false));
			dispatch(removeLeadsItem({tokens}));
			dispatch(removeCheckedItems(tokens));
			ToastDispatcher.success('User removed from Leads.');
		} catch (e) {
			ToastDispatcher.error(`Failed to remove user from Leads. Please try again`);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<PFPopup
			isShow={visible}
			handleClose={onCancel}
			isShowFooter={false}
			submitBtnText="Save"
			primaryVariant="primary"
			isCloseButton
			isShowCancelBtn={false}
			title="Specify the reason"
			onSubmit={() => false}
			className={{root: className}}>
			<form onSubmit={handleSubmit(submitForm)} className="rejectLeadModal__form">
				<div className="col-12 mb-4 isDefaultReason">
					<PFRadioGroup
						control={control}
						name="isDefaultReason"
						className="isDefaultReason__radioGroup"
						variant="vertical"
						options={[
							{
								value: '1',
								label: 'Not accepting any clients at this time',
							},
							{
								value: '2',
								label: 'Other reason (specify):',
							},
						]}
						hint={false}
						error={
							formState.errors?.isDefaultReason?.message &&
							formState.errors?.isDefaultReason?.message
						}
					/>
				</div>
				<div
					className={classNames(`col-12 mb-4 rejectLeadModal__form`, {
						disabled: !isVisibleReasonRejectField,
					})}>
					<PFTextarea
						{...register('customReason', {
							validate: generateValidator(
								isVisibleReasonRejectField,
								validations.textFields.leadsRejectReason,
							),
							onChange: evt => {
								setValue('customReason', evt.target.value);
								setHintValue(evt.target.value.length);
							},
						})}
						// @ts-ignore
						showAsterisk={false}
						label={false}
						placeholder="Enter your text"
						defaultValue={getValues('customReason') || ''}
						hint={descriptionHint}
						error={
							formState.errors?.customReason?.message &&
							formState.errors?.customReason?.message
						}
					/>
				</div>
				<div className="d-flex justify-content-center">
					<div className="d-flex w-100 m-auto pf-modal__footer-inner">
						<PFButton
							disabled={!formState.isValid || isSubmitting}
							variant="alert"
							onClick={() => false}
							className="pf-profileEdit__form-button pf-profileEdit__form-button--submit pf-edit-email-submit"
							type="submit">
							Reject
						</PFButton>
						<PFButton onClick={onCancel} variant="secondary" type="button">
							Cancel
						</PFButton>
					</div>
				</div>
			</form>
		</PFPopup>
	);
};

export default RejectLeadModal;
