import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ClientTagsPopupState {
	isOpen: boolean;
	clientToken: string;
	clientsTags: string[] | null;
}

type SetTokenActionType = string;

type SetClientsTagsActionType = string[];

const initialState: ClientTagsPopupState = {
	isOpen: false,
	clientToken: '',
	clientsTags: null,
};

export const clientTagsPopupSlice = createSlice({
	name: 'clientTagsPopup',
	initialState,
	reducers: {
		setIsOpenClientTagsPopup: (state, action: PayloadAction<SetTokenActionType>) => {
			state.clientToken = action.payload;
			state.isOpen = true;
		},
		setCloseClientTagsPopup: state => {
			state.isOpen = false;
			state.clientToken = '';
			state.clientsTags = null;
		},
		setClientTags: (state, action: PayloadAction<SetClientsTagsActionType>) => {
			state.clientsTags = action.payload;
		},
	},
});

export default clientTagsPopupSlice.reducer;
