import React, {FC} from 'react';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {APIInstancesService} from 'api/services';
import {PORTAL_PATHS} from 'const';
import {useDeletedAloneApiPopup, useHandleCopyApiKey} from 'shared/hooks';
import {RootState, useAppSelector} from 'store';
import {tableSelectors} from 'store/selectors';
import {ApiItem} from 'types';
import {useErrorHelper} from 'utils';
import {findByToken} from 'utils/table';
import {ToastDispatcher} from 'utils/ToastDispatcher';

import iconCopy from '../../assets/icon-copy.svg';
import iconDelete from '../../assets/icon-delete.svg';
import dot from '../../assets/icon-dots.svg';
import iconEdit from '../../assets/icon-edit.svg';

import styles from './styles.module.scss';

type Props = {
	updateDataForUseOnModal: () => void;
};

const ApiModal: FC<Props> = ({updateDataForUseOnModal}) => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const modalData: ApiItem = useAppSelector(tableSelectors.selectTableModalData);
	const apiState: ApiItem[] = useSelector((state: RootState) => state.APIInstances).api;
	const handleCopyApiKey = useHandleCopyApiKey();
	const {showDeletedAloneAPIConfirm, ConfirmDeleteAloneAPIModal} = useDeletedAloneApiPopup();

	const fetchDeleteAPI = async token => {
		try {
			const response = await APIInstancesService.deleteApi(token);
			ToastDispatcher.success('API instance deleted');

			const foundBlockedUser = findByToken(apiState, token);
			if (foundBlockedUser) foundBlockedUser.isDeleted = true;
			updateDataForUseOnModal();

			return response.data;
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const goToEditAPI = () => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.EDIT_API_INSTANCE}#${modalData.token}`);
	};

	return (
		<div className={`${styles.apiModal__wrapper} apiModal__wrapper`}>
			<div className={styles.apiModal__wrapper__instanceName}>{modalData.instanceName}</div>
			<div className={styles.apiModal__wrapper__copyKey}>
				<CopyToClipboard text={modalData.key} onCopy={handleCopyApiKey}>
					<ReactSVG
						wrapper="div"
						className={styles.apiModal__wrapper__copyKey__icon}
						src={iconCopy}
					/>
				</CopyToClipboard>
				<CopyToClipboard text={modalData.key} onCopy={handleCopyApiKey}>
					<div className={styles.apiModal__wrapper__copyKey__text}>{modalData.key}</div>
				</CopyToClipboard>
			</div>
			<div className={styles.apiModal__wrapper__note}>{modalData.notes}</div>
			<div className={styles.apiModal__wrapper__actionContainer}>
				<button
					type="button"
					className={styles.apiModal__wrapper__actionContainer__editBtn}
					onClick={goToEditAPI}>
					<ReactSVG wrapper="div" src={iconEdit} />
					<span>Edit API Instance</span>
				</button>
				<DropdownButton
					title={<ReactSVG wrapper="div" src={dot} />}
					className={styles.apiModal__wrapper__actionContainer__dropdown}
					id="bg-nested-dropdown">
					<Dropdown.Item onClick={showDeletedAloneAPIConfirm} eventKey="1">
						<div className={styles.apiModal__wrapper__actionContainer__deleteBtn}>
							<ReactSVG wrapper="div" src={iconDelete} />
							<span>Delete</span>
						</div>
					</Dropdown.Item>
				</DropdownButton>
			</div>
			<ConfirmDeleteAloneAPIModal
				onSubmit={() => fetchDeleteAPI(modalData.token)}
				className={styles.apiModal__deletePopup}
			/>
		</div>
	);
};

export default ApiModal;
