import {PlanInfo} from 'types/subscriptions';

export const PLANS: PlanInfo[] = [
	{
		id: 1,
		title: 'Starter Plan',
		monthly: null,
		annual: null,
		annualBeforeDiscount: null,
		annualDiscount: null,
		features: ['Full functionality', '5 current clients', '50 leads'],
	},
	{
		id: 2,
		title: 'Unlimited Access Plan',
		monthly: '$10.00',
		annual: '$100.00',
		annualBeforeDiscount: '$112.00',
		annualDiscount: '$12 OFF',
		features: [
			'Full functionality',
			'Unlimited clients and leads',
			'The price goes up every quarter*',
		],
	},
];
