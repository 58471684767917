import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import classNames from 'classnames';
import PFSelect from 'shared/components/PF-Select';
import PFTextarea from 'shared/components/PF-Textarea';
import {generateValidator, validations} from 'utils';

import FormInputKeys, {REFERRING_TO_BUSINESSES_OPTIONS_TYPES} from '../constants';

import './style.scss';
import style from './style.module.scss';

const StepDetails: React.FC<{
	formProps: UseFormReturn;
	hintValue: number;
	className?: string;
}> = ({className, hintValue, formProps}) => {
	const fullWidthClass = 'col-12 mb-4';

	const descriptionHint = (
		<div className={classNames('counter-hint', {error: hintValue > 590})}>{hintValue}/590</div>
	);

	return (
		<div className={style.referPopup_main}>
			<div className={classNames(`${fullWidthClass} referringToBusinessesType`)}>
				<PFSelect
					{...formProps.register(FormInputKeys.referringToBusinessesType, {
						required: true,
					})}
					// @ts-ignore
					defaultValue={
						(!!formProps.getValues(FormInputKeys.referringToBusinessesType) && {
							type: formProps.getValues(FormInputKeys.referringToBusinessesType),
							name: REFERRING_TO_BUSINESSES_OPTIONS_TYPES.find(
								item =>
									item.type ===
									formProps.getValues(FormInputKeys.referringToBusinessesType),
							)?.name,
						}) ||
						null
					}
					showAsterisk
					label="Type of business referred"
					placeholder="Select the type"
					disabled={false}
					isMulti={false}
					// @ts-ignore
					closeMenuOnSelect
					isAsync={false}
					onChange={value => {
						formProps.setValue(FormInputKeys.referringToBusinessesType, value.type);
					}}
					getOptionValue={item => item.type}
					options={REFERRING_TO_BUSINESSES_OPTIONS_TYPES}
					formatOptionLabel={option => option.name}
					defaultOptions
					error={
						formProps.formState.errors[FormInputKeys.referringToBusinessesType]
							?.message &&
						formProps.formState.errors[FormInputKeys.referringToBusinessesType]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} ${style.description}`}>
				<PFTextarea
					{...formProps.register(FormInputKeys.description, {
						validate: generateValidator(
							true,
							validations.textFields.referBusinessDescription,
						),
					})}
					// @ts-ignore
					showAsterisk
					label="Description"
					placeholder="Enter the business description"
					className="referPopup__textarea"
					defaultValue={formProps.getValues(FormInputKeys.description) || ''}
					hint={descriptionHint}
					error={
						formProps.formState.errors[FormInputKeys.description]?.message &&
						formProps.formState.errors[FormInputKeys.description]?.message
					}
				/>
			</div>
		</div>
	);
};

export default StepDetails;
