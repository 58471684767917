import {combineReducers} from 'redux';
import GeneralReducer from './GeneralReducer';
import myProfileReducer from './myProfileReducer';
import advisorReducer from './AdvisorReducer';
import tableReducer from './TableReducer';
import companiesReducer from './CompaniesReducer';
import consumersReducer from './ConsumersReducer';
import currentReducer from './CurrentReducer';
import leadsReducer from './LeadsReducer';
import APIInstancesReducer from './APIInstancesReducer';
import UiSettingsReducer from './UiSettingsReducer';
import InviteAdvisorModalReducer from './InviteAdvisorModalReducer';
import EcoAdvisorsReducer from './EcosystemAdvisorsReducer';
import AdvisorsForEcoReducer from './AdvisorsForEcoReducer';
import SpecializationsFilterReducer from './SpecializationsFilterReducer';
import RecommendPopupReducer from './RecommendPopupReducer';
import CopyDeepLinkPopupReducer from './CopyDeepLinkPopupReducer';
import ReferBusinessPopupReducer from './ReferBusinessPopupReducer';
import ReferredToMeReducer from './ReferredToMeReducer';
import ClientTagsPopupReducer from './ClientTagsPopupReducer';
import UserProfileReducer from './UserProfileReducer';
import SubscriptionReducer from './SubscriptionReducer';

const rootReducer = combineReducers({
	general: GeneralReducer,
	subscription: SubscriptionReducer,
	uiSettings: UiSettingsReducer,
	advisors: advisorReducer,
	table: tableReducer,
	companies: companiesReducer,
	consumers: consumersReducer,
	current: currentReducer,
	leads: leadsReducer,
	APIInstances: APIInstancesReducer,
	inviteAdvisorModal: InviteAdvisorModalReducer,
	ecoAdvisors: EcoAdvisorsReducer,
	advisorsForEco: AdvisorsForEcoReducer,
	myProfile: myProfileReducer,
	specFilter: SpecializationsFilterReducer,
	recommendPopup: RecommendPopupReducer,
	copyDeepLinkPopup: CopyDeepLinkPopupReducer,
	referBusinessPopup: ReferBusinessPopupReducer,
	referredToMe: ReferredToMeReducer,
	clientTagsPopup: ClientTagsPopupReducer,
	userProfile: UserProfileReducer,
});

export default rootReducer;
