import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {insuranceService} from 'api/services';
import {DisabilityInsuranceForm, ProfileFormContainer} from 'components';
import {useGetInsurances} from 'shared';
import {setCurrentPageTitle} from 'store/actions';
import {DisabilityInsuranceType} from 'types';
import {Amplitude, ToastDispatcher, useErrorHelper} from 'utils';

import {FORM_TITLE} from './constants';

const EditDisabilityInsurance: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const errorHelper = useErrorHelper(navigate);
	const tokens = window.location.hash.slice(1).split('&');
	const userToken = tokens[0];
	const insuranceToken = tokens[1];

	const fetchInsurances = useGetInsurances();

	const [insurance, setInsurance] = useState<DisabilityInsuranceType | null>(null);

	const goBack = (): void => {
		navigate(-1);
	};

	const handleSave = async (newInsurance: DisabilityInsuranceType) => {
		try {
			await insuranceService.updateDisabilityInsurance(newInsurance);

			Amplitude.track('Edited_Insurance', {
				type: 'Disability Insurance',
				data: newInsurance,
			});

			ToastDispatcher.success('Disability Insurance updated');
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	useEffect(() => {
		dispatch(setCurrentPageTitle('Current'));

		const fetchInsurance = async () => {
			try {
				const insurances = await fetchInsurances(userToken);
				const disabilityInsuranceDataByToken = insurances.disabilityInsuranceModel?.find(
					item => item.token === insuranceToken,
				);

				if (disabilityInsuranceDataByToken) {
					setInsurance(disabilityInsuranceDataByToken);
				}
			} catch (e: any) {
				errorHelper(e);
				throw e;
			}
		};

		fetchInsurance();
	}, []);

	return (
		<ProfileFormContainer title={FORM_TITLE} goBack={goBack}>
			{insurance !== null && (
				<DisabilityInsuranceForm
					insurance={insurance}
					onSave={handleSave}
					onCancel={goBack}
				/>
			)}
		</ProfileFormContainer>
	);
};

export default EditDisabilityInsurance;
