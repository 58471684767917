import React, {FC} from 'react';
import {UseFormReturn} from 'react-hook-form';

import PFInput from 'shared/components/PF-Input';
import {AdvisorType} from 'types';
import {generateValidator, hints, validations} from 'utils';

import FormInputKeys from '../EditProfileForm/constants';

type PropsTypes = {
	formProps: UseFormReturn;
	advisor?: AdvisorType;
};

const LoginDataFormBloc: FC<PropsTypes> = ({advisor, formProps}) => {
	const fullWidthClass = 'col-12 mb-4';

	return (
		<div className={`${fullWidthClass} email`}>
			<PFInput
				{...formProps.register(FormInputKeys.email, {
					validate: generateValidator(hints.email.required, validations.email),
				})}
				// @ts-ignore
				showAsterisk
				label="Email"
				placeholder="Email"
				type="email"
				defaultValue={
					formProps.getValues(FormInputKeys.email) || (advisor && advisor.email)
				}
				error={
					formProps.formState.errors[FormInputKeys.email]?.message &&
					formProps.formState.errors[FormInputKeys.email]?.message
				}
			/>
		</div>
	);
};

export default LoginDataFormBloc;
