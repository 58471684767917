import React, {FC, ReactNode} from 'react';

import urlPaths from '../../../api/url-paths';
import {DEFAULT_COMPANY_AVATAR} from '../../../const';
import PFAvatar from '../PF-Avatar';

// import {PFAvatar} from '../PF-Avatar';
import s from './style.module.scss';

type PropsTypes = {
	name: string;
	logo: string;
	children?: ReactNode;
};

const PFCompanyDescription: FC<PropsTypes> = ({name, logo, children}) => {
	return (
		<div className={s.pf_user_description}>
			<PFAvatar
				src={(logo && `${urlPaths.BASE_IMAGES_URL}${logo}`) || DEFAULT_COMPANY_AVATAR}
				size={60}
				defaultAvatar={DEFAULT_COMPANY_AVATAR}
				variant="rounded-square"
				className={s.companyLogo}
			/>
			<div>
				<h3 className={s.label}>{name}</h3>
				{children && <div className={s.description}>{children}</div>}
			</div>
		</div>
	);
};

export default PFCompanyDescription;
