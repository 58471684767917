import {SET_API_DATA, CLEANED_UP_API_DATA} from '../types/Types';

export const setAPIData = users => {
	return {
		type: SET_API_DATA,
		payload: users,
	};
};

export const cleanedUpAPIData = () => {
	return {
		type: CLEANED_UP_API_DATA,
	};
};
