import React, {useLayoutEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

//general UI components
import PFHeader from '../../../shared/components/PF-Header';
import PFInput from '../../../shared/components/PF-Input';
import PFButton from '../../../shared/components/PF-Button';
import PFLink from '../../../shared/components/PF-Link';

import attention from '../../../assets/icon-attention.svg';
//styles and assets
import {HiEye, HiEyeOff} from 'react-icons/hi';
import './style.scss';
import bgLogo from '../../../assets/logo-bg.svg';
import MoreIcon from '../../../assets/icon-search-arrow.svg';

//utility functions, hooks
import PUBLIC_PATHS from '../../../const/url-paths/public';
import {useSignIn} from '../../../shared/hooks/auth';
import PFCopyright from '../../../shared/components/PF-Copyright';
import {ReactSVG} from 'react-svg';
import {Amplitude, hints, validations} from '../../../utils';
import {useNavigate} from 'react-router-dom';
import PFPopup from '../../../shared/components/PF-Popup';
import {PORTAL_PATHS} from 'const';

export default function SignIn() {
	const navigate = useNavigate();
	const signIn = useSignIn();
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false); //state variable for after the sign-in API is called. When user submits form it becomes true and disables the sign in button.
	const {
		register,
		handleSubmit,
		setError,
		getValues,
		formState: {errors, isValid, touchedFields},
	} = useForm({mode: 'onChange'});
	const [errorMessage, setErrorMessage] = useState(null);

	const togglePassword = () => setIsPasswordVisible(visible => !visible);
	const [show, setShow] = useState(false);
	const hideModal = () => {
		setShow(false);
	};
	//When the form has been submitted
	const onSubmit = values => {
		setIsLoading(true);

		signIn(values, navigate)
			.catch(err => {
				//showModal() //TODO: When the API is updated to give us info that the user hasn't verified email, we will enable this
				handleSignInError(err);
			})
			.finally(() => setIsLoading(false));
	};

	const handleSignInError = error => {
		Amplitude.track('Detected_Error', {error_name: error?.message || 'Unknown error'});

		setError('password', {
			type: 'validate',
			message: '',
		});
		setError('username', {
			type: 'validate',
			message: '',
		});
		setErrorMessage(error?.message || 'Error.');
	};

	//Logic for the 'view password' icon
	const passwordIcon = isPasswordVisible ? (
		<HiEyeOff onClick={togglePassword} size={15} />
	) : (
		<HiEye onClick={togglePassword} size={15} />
	);

	//redirects the forgot? link
	const forgotLink = (
		<PFLink
			to={PORTAL_PATHS.OTHER_PATHS.RESTORE_PASSWORD}
			onClick={() => {
				Amplitude.track('Forgot_Password');
			}}>
			Forgot?
		</PFLink>
	);

	const goToSignUp = () => {
		navigate(`${PUBLIC_PATHS.SIGN_UP}`);
	};

	const [wasInitiallyAutofilled, setWasInitiallyAutofilled] = useState(false);

	useLayoutEffect(() => {
		/**
		 * The field can be prefilled on the very first page loading by the browser
		 * By the security reason browser limits access to the field value from JS level and the value becomes available
		 * only after first user interaction with the page
		 * So, even if the form thinks that the field is not touched by user and empty,
		 * it actually can have some value, so we should process this edge case in the form logic
		 */
		const checkAutofilled = id => {
			const element = document.getElementById(id);
			return !!element?.matches('*:autofill') || !!element?.matches('*:-webkit-autofill');
		};

		const checkEveryFieldAutofilled = () => {
			const wasAutofilled = checkAutofilled('username') && checkAutofilled('password');

			if (wasAutofilled) {
				setWasInitiallyAutofilled(wasAutofilled);
			}
		};
		// The time when it's ready is not very stable, so check few times
		setTimeout(checkEveryFieldAutofilled, 500);
		setTimeout(checkEveryFieldAutofilled, 1000);
		setTimeout(checkEveryFieldAutofilled, 2000);
	}, []);

	const wasFormInitiallyAutofilled =
		getValues().username === '' &&
		getValues().password === '' &&
		!touchedFields.username &&
		!touchedFields.password &&
		wasInitiallyAutofilled;

	const isSubmitEnabled = (isValid && !isLoading) || wasFormInitiallyAutofilled;

	return (
		<div className="pf-signin">
			<PFHeader />
			<div className="pf-signin__container">
				<div className="pf-signin__banner">
					<img src={bgLogo} alt="pf logo" className="pf-signin__banner-logo" />
					<p className="pf-signin__banner-heading">
						The Right Information at the Right Time
					</p>
					<a href="https://pocketfinance.com" className="pf-signin__banner-link">
						Learn More <ReactSVG wrapper="span" className="moreIcon" src={MoreIcon} />
					</a>
				</div>
				<div className="pf-signin__form-wrapper">
					<h3 className="pf-signin__form-title">Sign In</h3>

					<form onSubmit={handleSubmit(onSubmit)} className="pf-signin__form">
						<PFInput
							{...register('username', {
								required: hints.email.required,
								validate: validations.email,
								onChange: () => {
									setErrorMessage(null);
								},
							})}
							id="username"
							label="Email"
							className="mb-4"
							error={errors.username && (errors.username?.message || true)}
						/>
						{/* if the errors.password has any value and if there is a message then show message or turn the box to red   */}
						<PFInput
							{...register('password', {
								required: 'Password is required',
								// ! может  понадобится согда все изменят пароль на 8 символов
								// validate: generateValidator(
								//   'Password is required',
								//   validations.password,
								// ),
								onChange: () => {
									setErrorMessage(null);
								},
							})}
							id="password"
							label="Password"
							type={isPasswordVisible ? 'text' : 'password'}
							labelSufix={forgotLink}
							icon={passwordIcon}
							className="mb-4"
							error={errors.password && (errors.password?.message || true)}
						/>
						{!!errorMessage && (
							<div className="error-message__container">
								<ReactSVG
									src={attention}
									wrapper="div"
									className="error-message__icon"
								/>
								<div className="error-message__text">{errorMessage}</div>
							</div>
						)}
						<PFButton
							disabled={!isSubmitEnabled}
							type="submit"
							className={'signInButton'}>
							sign in
						</PFButton>
					</form>
					<div className="pf-signin__form-footer">
						<p className="pf-signin__form-footer__title">Don't Have an Account?</p>
						<PFButton
							type="button"
							onClick={goToSignUp}
							variant="yellow"
							className={'createProfileButton'}>
							Create Free Professional Profile
						</PFButton>
					</div>
				</div>
			</div>
			<PFPopup
				isShow={show}
				handleClose={hideModal}
				submitBtnText={'Ok'}
				isShowCancelBtn={false}
				primaryVariant="primary"
				isCloseButton={false}
				title={'Please verify your email before logging into your account'}
				subtitle={false}
				onSubmit={hideModal}
			/>

			<div className="pf-signin__footer">
				<div className="pf-signin__footer-links">
					<span className={'d-none d-lg-block'}>Need help?</span>
					<PFLink
						href="mailto:support@pocketfinance.com"
						onClick={() => {
							Amplitude.track('Contacted_Support', {source: 'Sign In'});
						}}
						className="pf-signin__footer-link">
						Contact the administrator
					</PFLink>
					<span className="px-4 d-lg-none">|</span>
					<PFLink href="//pocketfinance.com" className="pf-signin__footer-link d-lg-none">
						About
					</PFLink>
				</div>
				<PFCopyright />
			</div>
		</div>
	);
}
