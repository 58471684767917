import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import iconAdd from 'assets/buttons/icon-add.svg';
import iconBack from 'assets/icon-arrow-back.svg';
import hintIcon from 'assets/icon-attention.svg';
import iconRemove from 'assets/icon-close.svg';
import ProfileFormContainer from 'components/ProfileFormContainer';
import {DEFAULT_COMPANY_AVATAR, PORTAL_PATHS} from 'const';
import {isPossiblePhoneNumber} from 'libphonenumber-js';
import {PFButton, PFInput, PFPhoneInput} from 'shared/components';
import PFInputAvatarCrop from 'shared/components/PF-InputAvatarCrop';
import PFPopup from 'shared/components/PF-Popup';
import PFSelect from 'shared/components/PF-Select';
import {useCompanyAdministrators, useCompanyUsersData, useLocations} from 'shared/hooks';
import {useSignOut} from 'shared/hooks/auth';
import {useCompany, useUpdateCompany} from 'shared/hooks/companies';
import {useAppSelector} from 'store';
import {myProfileSelectors} from 'store/selectors';
import {CompanyData} from 'types';
import {AdvisorsRole} from 'types/enums';
import {
	Amplitude,
	generateValidator,
	hints,
	isPhoneEmpty,
	renderLocation,
	useErrorHelper,
	validations,
} from 'utils';

import FormInputKeys from './constants';

import './style.scss';

const EditCompanyData = () => {
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const updateCompany = useUpdateCompany();
	const getCompanyAdministrators = useCompanyAdministrators();
	const userRole = profile?.roles[0];
	const fetchLocations = useLocations();
	const navigate = useNavigate();
	const companyToken = window.location.hash.slice(1);
	const [selectedCompany, setSelectedCompany] = useState<CompanyData | null>(null);
	const fetchCompany = useCompany();
	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;
	const [admins, setAdmins] = useState<
		{token: string; role: string; firstName?: string; lastName?: string}[]
	>([]);
	const fetchCompanyUsers = useCompanyUsersData(companyToken);
	const {signOut} = useSignOut(navigate);
	const errorHelper = useErrorHelper(navigate);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showRemoveAdminModal, setShowRemoveAdminModal] = useState({
		open: false,
		index: null,
	}); // for admins modal
	const [isAssignAdminModal, setIsShowAssignAdminModal] = useState({
		open: false,
		index: null,
	});
	const [showLogOutModal, setShowLogOutModal] = useState(false);
	const halfWidthClass = 'col-lg-6 mb-4';
	const fullWidthClass = 'col-12 mb-4';

	const goBack = () => {
		if (userRole === AdvisorsRole.SuperAdmin) {
			navigate(`${PORTAL_PATHS.OTHER_PATHS.COMPANY_PROFILE}#${companyToken}`);
		} else {
			navigate(`${PORTAL_PATHS.SIDEBAR.MY_COMPANY}`);
		}
	};

	const showRemoveAdmin = index =>
		setShowRemoveAdminModal({
			open: true,
			index,
		});
	const cancelRemoveAdmin = () =>
		setShowRemoveAdminModal({
			open: false,
			index: null,
		});
	const showAssignAdminModal = index =>
		setIsShowAssignAdminModal({
			open: true,
			index,
		});
	const cancelAssignAdminModal = () =>
		setIsShowAssignAdminModal({
			open: false,
			index: null,
		});

	const logOut = () => {
		setShowLogOutModal(false);
		signOut();

		Amplitude.track('Signed_Out');
		Amplitude.reset();
	};

	const formProps = useForm({
		mode: 'onChange',
	});
	const {
		register,
		trigger,
		getValues,
		watch,
		formState,
		setValue,
		control,
		unregister,
		setError,
		handleSubmit,
		...props
	} = formProps;

	const addAdministrator = () => {
		setAdmins(ad => {
			return [
				...ad,
				{
					token: '',
					role: AdvisorsRole.Administrator,
				},
			];
		});
	};

	const removeAdministrator = id => {
		unregister(`Administrator${id}`);
		setAdmins(admin => admin.filter((ad, index) => index !== id));
		cancelRemoveAdmin();
	};

	const assignAdmin = index => {
		setAdmins(ad => {
			ad[0] = {
				role: AdvisorsRole.AccountAdministrator,
				token: ad[index].token,
				firstName: ad[index].firstName,
				lastName: ad[index].lastName,
			};

			return [...ad];
		});
		removeAdministrator(index);
		cancelAssignAdminModal();
	};

	const AccountAdministratorHint = (
		<div className="accountAdministratorHint">
			<ReactSVG wrapper="span" className="accountAdministratorHint__icon" src={hintIcon} />
			<span className="accountAdministratorHint__text">
				Account administrator is able to add more administrators for this company
			</span>
		</div>
	);
	const addIcon = (
		<ReactSVG wrapper="span" className="pf-companyProfileEdit__iconAdd" src={iconAdd} />
	);
	const removeIcon = <ReactSVG wrapper="span" className="iconRemove" src={iconRemove} />;

	const onSubmit = async valueObj => {
		const body = {
			name: valueObj[FormInputKeys.name],
			description: '',
			streetAddress: valueObj[FormInputKeys.streetAddress],
			postalCode: valueObj[FormInputKeys.postalCode],
			phoneNumber: isPhoneEmpty(valueObj[FormInputKeys.phoneNumber])
				? '' // ph# is not required, so send an empty string if it's empty.
				: valueObj[FormInputKeys.phoneNumber]?.formattedValue ||
				  valueObj[FormInputKeys.phoneNumber], // if the phoneNumber has been changd, we send a formatted ph#, otherwise the existing ph#.
			contactInfo: valueObj[FormInputKeys.contactInfo],
			administratorTokens: admins
				.filter((item, index) => !!item.token && index !== 0)
				.map(item => item.token),
			accountAdministratorToken: admins[0].token ? admins[0].token : null,
			locationToken: valueObj[FormInputKeys.locationToken],
			token: companyToken,
			image: valueObj[FormInputKeys.companyLogo] || null,
		};

		try {
			setIsSubmitting(true);
			await updateCompany(body);
			if (
				userRole === AdvisorsRole.AccountAdministrator &&
				admins[0]?.token !== profile?.token
			) {
				setShowLogOutModal(true);
			} else {
				setIsSubmitting(false);
				goBack();
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const onChangeCompanyLogo = async image => {
		formProps.setValue(FormInputKeys.companyLogo, image);
		await formProps.trigger(FormInputKeys.companyLogo);
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === FormInputKeys.locationToken) {
				await trigger(FormInputKeys.locationToken);
			}
			if (name === FormInputKeys.name) {
				await trigger(FormInputKeys.name);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	const getCompanyData = async token => {
		if (token) {
			try {
				const company = await fetchCompany(token);

				if (company) {
					setValue(FormInputKeys.name, company.name);
					setValue(FormInputKeys.locationToken, company.locationToken);
					setValue(FormInputKeys.streetAddress, company.streetAddress);
					setValue(FormInputKeys.postalCode, company.postalCode);
					setValue(FormInputKeys.contactInfo, company.contactInfo);
					setValue(FormInputKeys.phoneNumber, company.phoneNumber);
					setSelectedCompany(company);
				}

				const companyAdministrators = await getCompanyAdministrators(token);

				if (companyAdministrators.length) {
					setAdmins(companyAdministrators);
				} else {
					setAdmins([
						{
							token: '',
							role: AdvisorsRole.AccountAdministrator,
						},
					]); // ! когда Саша сделает все первых юзеров админами - убери это
				}
			} catch (e) {
				errorHelper(e);
				throw e;
			}
		}
	};

	useEffect(() => {
		getCompanyData(companyToken);
	}, [companyToken]);

	return (
		<div className="editCompany">
			<ProfileFormContainer title="Edit Company Data" goBack={goBack}>
				{selectedCompany && (
					<form onSubmit={handleSubmit(onSubmit)} className="editCompany__form">
						<div className="row">
							<div className="col-12">
								<h4>Company Data</h4>
							</div>
							<div className={`${fullWidthClass} companyName`}>
								<PFInput
									{...register(FormInputKeys.name, {
										validate: generateValidator(
											true,
											validations.textFields.companyName,
										),
									})}
									// @ts-ignore
									showAsterisk
									label="Company Name"
									placeholder="Company Name"
									// type={'email'}
									defaultValue={selectedCompany?.name && ''}
									error={
										formState.errors[FormInputKeys.name]?.message &&
										formState.errors[FormInputKeys.name]?.message
									}
								/>
							</div>
							<div className={`${halfWidthClass} locationToken`}>
								<PFSelect
									{...register(FormInputKeys.locationToken, {
										required: true,
									})}
									// @ts-ignore
									defaultValue={
										selectedCompany?.location &&
										!!Object.keys(selectedCompany.location).length
											? selectedCompany?.location
											: ''
									}
									showAsterisk
									label="Location"
									placeholder="Company location"
									disabled={false}
									isMulti={false}
									blurInputOnSelect
									closeMenuOnSelect
									isAsync
									onChange={value => {
										setValue(FormInputKeys.locationToken, value.token);
									}}
									getOptionValue={item => item.token}
									loadOptions={async (search, loadedOptions) => {
										// this is our custom load options, we're returning the values from the API call
										const {
											body: options,
											count,
											currentCount,
										} = await fetchLocations({
											Query: search,
											skip: loadedOptions.length,
										});
										return {
											options,
											hasMore:
												currentCount !== 0 &&
												count > options.length + loadedOptions.length,
										};
									}}
									formatOptionLabel={option => renderLocation(option)}
									defaultOptions
									error={
										formState.errors[FormInputKeys.locationToken]?.message &&
										formState.errors[FormInputKeys.locationToken]?.message
									}
								/>
							</div>
							<div className={`${halfWidthClass} streetAddress`}>
								<PFInput
									{...register(FormInputKeys.streetAddress, {
										validate: generateValidator(
											false,
											validations.textFields.streetAddress,
										),
									})}
									// @ts-ignore
									showAsterisk={false}
									label="Street Address"
									placeholder="Street Address"
									defaultValue={selectedCompany?.streetAddress && ''}
									error={
										formState.errors[FormInputKeys.streetAddress]?.message &&
										formState.errors[FormInputKeys.streetAddress]?.message
									}
								/>
							</div>
							<div className={`${halfWidthClass} postalCode`}>
								<PFInput
									{...register(FormInputKeys.postalCode, {
										validate: generateValidator(
											false,
											validations.textFields.postalCode,
										),
									})}
									// @ts-ignore
									showAsterisk={false}
									label="Postal Code"
									placeholder="Postal Code"
									defaultValue={selectedCompany?.postalCode && ''}
									error={
										formState.errors[FormInputKeys.postalCode]?.message &&
										formState.errors[FormInputKeys.postalCode]?.message
									}
								/>
							</div>
							<div className={`${halfWidthClass} phoneNumber`}>
								<PFPhoneInput
									name="phoneNumber"
									control={control}
									showAsterisk={false}
									label="Phone Number"
									defaultValue={selectedCompany?.phoneNumber && ''}
									error={
										formState.errors[FormInputKeys.phoneNumber]?.message &&
										formState.errors[FormInputKeys.phoneNumber]?.message
									}
									labelSufix={false}
									variant={false}
									className=""
									hint={false}
									rules={{
										required: false,
										validate: value => {
											if (value?.countryCode) {
												if (isPhoneEmpty(value)) return true; // Ph# is not required, so if it's empty, then it's valid
												const k = isPossiblePhoneNumber(
													// This uses a google library to check if the ph# the user is entering is valid or invalid
													value?.value,
													value?.countryCode?.toUpperCase(),
												);
												return k || 'Please enter a valid phone number.'; // If valid, we're good, otherwise show the error message
											}
											return true;
										},
									}}
								/>
							</div>
							<div className={`${halfWidthClass} contactInfo`}>
								<PFInput
									{...register(FormInputKeys.contactInfo, {
										validate: generateValidator(false, validations.email),
									})}
									// @ts-ignore
									showAsterisk={false}
									label="Email"
									placeholder="Email"
									type="email"
									defaultValue={selectedCompany?.contactInfo && ''}
									error={
										formState.errors[FormInputKeys.contactInfo]?.message &&
										formState.errors[FormInputKeys.contactInfo]?.message
									}
								/>
							</div>
							<div className="companyLogo d-none">
								<PFInput
									{...formProps.register(FormInputKeys.companyLogo, {
										required: 'Logo is required',
										validate: value => {
											if (value === 'error') {
												return "The file you've uploaded is larger than 5MB. Please upload an image smaller than 5MB.";
											}
											if (value === 'error-type') {
												return 'HEIC image format not supported';
											}
											return true;
										},
									})}
									// @ts-ignore
									showAsterisk
									id="companyLogo"
									defaultValue={
										formProps.getValues(FormInputKeys.companyLogo) ||
										(selectedCompany && selectedCompany?.image)
									}
									error={
										formProps.formState.errors[FormInputKeys.companyLogo]
											?.message &&
										formProps.formState.errors[FormInputKeys.companyLogo]
											?.message
									}
								/>
							</div>
							<div className="col-12 companyLogo">
								<PFInputAvatarCrop
									label="Company logo"
									showAsterisk
									id="companyLogo1"
									onChange={onChangeCompanyLogo}
									src={
										formProps.getValues(FormInputKeys.companyLogo) ||
										(selectedCompany && selectedCompany?.image)
									}
									avatarSize={45}
									variant="rounded-square"
									defaultAvatar={DEFAULT_COMPANY_AVATAR}
									hint=""
									error={
										formProps.formState.errors[FormInputKeys.companyLogo]
											?.message &&
										formProps.formState.errors[FormInputKeys.companyLogo]
											?.message
									}
								/>
							</div>
							{/* ------------------------------------------------------- */}
							{!!admins.length && userRole !== AdvisorsRole.Administrator && (
								<div className="administratorsSection">
									<div className="col-12">
										<h4>Company Administrators</h4>
									</div>

									{admins.map((item, index) => {
										return (
											<div
												className={`${halfWidthClass} Administrator pf-companyProfileEdit`}
												key={item.token}>
												<PFSelect
													{...register(
														`${FormInputKeys.Administrator}${index}`,
														{
															required: false,
														},
													)}
													// @ts-ignore
													defaultValue={
														!!item?.firstName || !!item?.lastName
															? item
															: null
													}
													disabled={
														userRole !== AdvisorsRole.SuperAdmin &&
														index === 0
													}
													showAsterisk={false}
													label={
														index === 0
															? 'Account Administrator'
															: `Administrator ${index} (select from company users)`
													}
													placeholder="Select from company users"
													isMulti={false}
													blurInputOnSelect
													closeMenuOnSelect
													isAsync
													onChange={value => {
														setValue(
															`${FormInputKeys.Administrator}${index}`,
															value.token,
														);
														setAdmins(ad => {
															ad[index].firstName = value.firstName;
															ad[index].lastName = value.lastName;
															ad[index].token = value.token;
															// eslint-disable-next-line prefer-destructuring
															ad[index].role = value.roles[0];
															return [...ad];
														});
													}}
													getOptionValue={admin => admin.token}
													loadOptions={async (...args) => {
														// this is our custom load options, we're returning the values from the API call
														const optionsLength = args[1].length;
														const {
															body: options,
															count,
															fullCount,
														} = await fetchCompanyUsers({
															companyToken,
															take: 10,
															skip: optionsLength,
														});

														return {
															options,
															hasMore:
																count !== 0 &&
																fullCount >
																	options.length + optionsLength,
														};
													}}
													formatOptionLabel={option => {
														return `${
															option.firstName ? option.firstName : ''
														} ${
															option.lastName ? option.lastName : ''
														}`;
													}}
													defaultOptions
													icon={index > 0 ? removeIcon : null}
													onIconClick={() => showRemoveAdmin(index)}
													hint={
														index === 0 &&
														userRole === AdvisorsRole.SuperAdmin
															? AccountAdministratorHint
															: false
													}
													error={
														formState.errors[
															`${FormInputKeys.Administrator}${index}`
														]?.message &&
														formState.errors[
															`${FormInputKeys.Administrator}${index}`
														]?.message
													}
												/>
												{index > 0 && (
													<div className="pf-companyProfileEdit__assignAsButton">
														<PFButton
															onClick={() =>
																showAssignAdminModal(index)
															}
															disabled={
																!item.firstName && !item.lastName
															}
															className="assignAsButton"
															variant="plain"
															type="button">
															Assign as Account Administrator
														</PFButton>
													</div>
												)}
											</div>
										);
									})}

									<div className="pf-companyProfileEdit__addAdminButton">
										<PFButton
											onClick={addAdministrator}
											className="addAdminButton"
											variant="plain"
											prefixIcon={addIcon}
											type="button">
											Add More Administrators
										</PFButton>
									</div>
								</div>
							)}

							<PFPopup
								isShow={showRemoveAdminModal.open}
								title="Remove administrator?"
								submitBtnText="Delete"
								cancelBtnText="Cancel"
								isShowCancelBtn
								isCloseButton
								isShowFooter
								handleClose={cancelRemoveAdmin}
								primaryVariant="alert"
								onSubmit={() => removeAdministrator(showRemoveAdminModal.index)}
							/>
							<PFPopup
								isShow={isAssignAdminModal.open}
								title="There can be only one Account Administrator. Do you want to transfer your role to Administrator?"
								submitBtnText="Assign"
								cancelBtnText="Cancel"
								isShowCancelBtn
								isCloseButton
								isShowFooter
								handleClose={cancelAssignAdminModal}
								primaryVariant="alert"
								onSubmit={() => assignAdmin(isAssignAdminModal.index)}
							/>

							<PFPopup
								isShow={showLogOutModal}
								title="You have assigned another user as a chief administrator. Please sign in again."
								submitBtnText="Sign In"
								isShowCancelBtn={false}
								isCloseButton={false}
								isShowFooter
								handleClose={() => false}
								primaryVariant="primary"
								onSubmit={logOut}
							/>
						</div>

						<div className="formButton-wrapper">
							<PFButton
								onClick={goBack}
								className="editCompany__form-button"
								variant="secondary"
								type="button">
								Cancel
							</PFButton>
							<PFButton
								disabled={!formState.isValid || isSubmitting}
								className="editCompany__form-button editCompany__form-button--submit"
								type="submit">
								Save
							</PFButton>
						</div>
					</form>
				)}
			</ProfileFormContainer>
		</div>
	);
};

export default EditCompanyData;
