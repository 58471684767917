import React, {useState} from 'react';
import {ReactSVG} from 'react-svg';

import {referBusinessesService} from 'api/services';
import {iconBlock} from 'assets';
import DismissReferPopup from 'pages/private/ReferredToMe/components/DismissReferPopup/DismissReferPopup';
import PFButton from 'shared/components/PF-Button';
import useBooleanState from 'shared/hooks/utility/useBooleanState';
import {dismissReferItems, removeCheckedItems, setSideModal} from 'store/actions';
import {Amplitude, mapQueryParams, parseUrlQuery, ToastDispatcher} from 'utils';

import {useReferralsData} from '../../../shared/hooks';
import {useAppDispatch} from '../../../store';
import {REFERRING_TO_BUSINESSES_OPTIONS_TYPES} from '../../ReferBusinessPopup/components/constants';
import {SnackActionButton} from '../../Snackbar';

import s from './style.module.scss';

const AdditionalReferredToMeInfo = ({userToken, referredType, description}) => {
	const dispatch = useAppDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isShowDismissPopup, setIsShowDismissPopup] = useBooleanState(false);
	const urlParams = parseUrlQuery(document.location);
	const fetchReferrals = useReferralsData({
		...mapQueryParams(urlParams),
	});

	const onDismissRefer = async (tokens: string[]) => {
		try {
			setIsSubmitting(true);
			await referBusinessesService.dismissReferral(tokens);

			Amplitude.track('Dissmissed_Referral');

			dispatch(setSideModal(false));
			dispatch(dismissReferItems(tokens));
			dispatch(removeCheckedItems(tokens));
			ToastDispatcher.success(`Referral dismissed.`, snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Undo"
					onClickAction={async () => {
						try {
							await referBusinessesService.restoreReferBusinessesItems(tokens);

							Amplitude.track('Dissmissed_Referral_Undo');

							await fetchReferrals();
						} catch (e) {
							ToastDispatcher.error(`Failed to restore.`);
						}
					}}
				/>
			));
		} catch (e) {
			ToastDispatcher.error(`Failed to dismiss. Please try again`);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className={`${s.referredToMeAdditionalInfo} referredToMeAdditionalInfo`}>
			<div className={`${s.btnContainer} btnContainer`}>
				<div className={s.btnGroup}>
					<PFButton
						type="button"
						variant="white-alert"
						buttonHeight={40}
						disabled={isSubmitting}
						prefixIcon={
							<ReactSVG src={iconBlock} className={s.dismissRefer_button_icon} />
						}
						className={`${s.button} ${s.dismissRefer_button}`}
						onClick={() => setIsShowDismissPopup(true)}>
						Dismiss
					</PFButton>
				</div>
			</div>
			<div className={s.referredType}>
				<h6 className={s.additionalInfoTitle}>Type of business referred</h6>
				<div className={s.text}>
					{
						REFERRING_TO_BUSINESSES_OPTIONS_TYPES.find(
							option => option.type === referredType,
						)?.name
					}
				</div>
			</div>
			<div className={s.description}>
				<h6 className={s.additionalInfoTitle}>Description</h6>
				<div className={s.text}>{description}</div>
			</div>
			<DismissReferPopup
				visible={isShowDismissPopup}
				onCancel={() => setIsShowDismissPopup(false)}
				title="Dismiss referral?"
				onSubmit={() => onDismissRefer([userToken])}
			/>
		</div>
	);
};

export default AdditionalReferredToMeInfo;
