import React, {useEffect, useState} from 'react';
import {ReactSVG} from 'react-svg';
import {useNavigate} from 'react-router-dom';
import {
	cleanUpUserProfileData,
	setCurrentPageTitle,
	setIsOpenReferPopup,
	setReferClientsCheckedKey,
	setUserProfileData,
} from '../../../store/actions';
import {
	renderLocation,
	ToastDispatcher,
	getFullName,
	useErrorHelper,
	Amplitude,
	checkExtendedProfileView,
} from '../../../utils';
import PFAvatar from '../../../shared/components/PF-Avatar';
import PFButton from '../../../shared/components/PF-Button';
import PFLink from '../../../shared/components/PF-Link';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import urlPaths from '../../../api/url-paths';
import iconBack from '../../../assets/icon-arrow-back.svg';
import iconLocation from '../../../assets/icon-location.svg';
import iconEmail from '../../../assets/icon-email.svg';
import {PFCopyright, PFNote} from '../../../shared/components';
import iconPhone from '../../../assets/icon-phone-without-circle.svg';
import requestReportIcon from '../../../assets/buttons/report-request.svg';
import {useUserProfile} from '../../../shared/hooks/users';
import * as financialReportsService from '../../../api/services/financialReports';
import {
	UserPersonalDataBloc,
	UserProfileReportsBloc,
	UserProfileOpportunitiesBloc,
	UserProfileTeamBloc,
	UserProfileEditableNote,
	UserProfileInsuranceBloc,
	UserProfileTodoBloc,
} from '../../../components';
import PFAccordion from '../../../shared/components/PF-Accordion';
import './style.scss';
import * as todoService from '../../../api/services/todo';
import {iconDots, referBusiness} from '../../../assets';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {useAppDispatch, useAppSelector} from '../../../store';
import ClientTagsComponent from '../../../components/ClientTagsComponent';
import {DEFAULT_AVATAR} from '../../../const';
import {myProfileSelectors, selectUserProfileData} from '../../../store/selectors';
import {useBooleanState} from 'shared';

import {scheduleMeetingIcon} from 'assets';
import {advisorClientsService} from 'api/services';

const UserProfile = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userToken = window.location.hash.slice(1);
	const selectedUser = useAppSelector(selectUserProfileData);
	const fullName = getFullName(selectedUser?.user?.firstName, selectedUser?.user?.lastName);
	const fetchUserInfo = useUserProfile();
	const errorHelper = useErrorHelper(navigate);
	const [reportsCount, setReportsCount] = useState(0);
	const [insurancesCount, setInsurancesCount] = useState(0);
	const [todoItems, setTodoItems] = useState(null);

	const isExtendedProfile = checkExtendedProfileView(
		selectedUser?.advisorClientSpecializationTypes,
	);

	const [isSubmittingScheduleMeeting, setIsSubmittingScheduleMeeting] = useBooleanState(false);

	const calendlyLink = useAppSelector(myProfileSelectors.selectMyCalendlyLink);

	const getTodosData = async token => {
		try {
			const response = await todoService.getTodoByToken(token);
			const todoItemsState = response?.data.filter(
				item => !item.isCompleted && !item.isDismissed,
			);
			setTodoItems(todoItemsState);
		} catch (e) {
			errorHelper(e);
		}
	};

	const updateTodoCallback = () => {
		getTodosData(userToken);
	};

	const fetchUser = async token => {
		try {
			const data = await fetchUserInfo(token);
			dispatch(setUserProfileData(data));
		} catch (e) {
			errorHelper(e, true, e?.response?.data?.message);
		}
	};

	const requestReport = async () => {
		try {
			const response = await financialReportsService.requestReport({
				userToken: userToken,
			});
			if (response.status === 200) {
				Amplitude.track('Requested_Report', {
					source: 'User Profile',
				});

				ToastDispatcher.success('Success');
			}
		} catch (e) {
			errorHelper(e);
		}
	};

	const sendScheduleMeeting = async userTokens => {
		try {
			setIsSubmittingScheduleMeeting(true);
			await advisorClientsService.sendScheduleMeeting(userTokens);

			Amplitude.track('Scheduled_Meeting', {
				source: 'User Profile',
				clients: userTokens,
			});

			ToastDispatcher.success(
				'The client will receive your calendar link to suggest the meeting date and time.',
			);
		} catch (e) {
			ToastDispatcher.error(`Failed to schedule a meeting. Please check your calendar link.`);
			throw e;
		} finally {
			setIsSubmittingScheduleMeeting(false);
		}
	};

	const onReferClient = () => {
		dispatch(setReferClientsCheckedKey(userToken));
		dispatch(setIsOpenReferPopup('Current'));
	};

	const updateCallback = () => {
		fetchUser(userToken);
	};

	useEffect(() => {
		fetchUser(userToken);
		getTodosData(userToken);
	}, [userToken]);

	useEffect(() => {
		dispatch(setCurrentPageTitle('Current'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const goBack = () => {
		navigate(PORTAL_PATHS.SIDEBAR.CURRENT);
	};

	//wrapped=span is used here because otherwise the default is a div that's causing
	//the arrow and the 'back' text to appear in separate lines.
	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;
	const phoneIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconPhone} />
	);
	const locationIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconLocation} />
	);
	const mailIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconEmail} />
	);

	useEffect(() => {
		return () => {
			dispatch(cleanUpUserProfileData());
		};
	}, []);

	return (
		<div className="user-profile">
			{selectedUser && (
				<div className={'grid-wrapper'}>
					<div className={'header'}>
						<PFButton
							className="goBack-button"
							prefixIcon={backIcon}
							variant="plain"
							onClick={goBack}
							type={'button'}>
							Back
						</PFButton>
					</div>

					<div className={'contact-info'}>
						<div className="contact-info__avatar-wrapper">
							<PFAvatar
								className="contact-info__avatar"
								src={
									selectedUser?.user?.profileImage
										? `${urlPaths.BASE_IMAGES_URL}${selectedUser?.user?.profileImage}`
										: DEFAULT_AVATAR
								}
								size={90}
							/>
							<div className="contact-info__name-wrapper">
								<h3 className="contact-info__name my-auto">{fullName}</h3>
							</div>
						</div>
						{selectedUser && (
							<ClientTagsComponent
								clientToken={selectedUser?.user?.token}
								clientTags={selectedUser?.customClientTags}
							/>
						)}
						<div className="contact-info__contact">
							{(selectedUser?.user?.city || selectedUser?.user?.province) && (
								<p className="contact-info__info">
									{locationIcon}
									{renderLocation({
										name: selectedUser?.user?.city,
										state: {name: selectedUser?.user?.province},
									})}
								</p>
							)}
							{selectedUser?.user?.email && (
								<p className="contact-info__info">
									{mailIcon}
									<PFLink href={`mailto:${selectedUser?.user?.email}`}>
										{selectedUser?.user?.email}
									</PFLink>
								</p>
							)}
							{selectedUser?.user?.phoneNumber && (
								<p className="contact-info__info">
									{phoneIcon}
									{selectedUser?.user?.phoneNumber}
								</p>
							)}
						</div>
						<div className="contact-info__buttons-wrapper">
							<div className="contact-info__buttons">
								<div className="btnGroup">
									<PFButton
										prefixIcon={
											<ReactSVG
												wrapper="span"
												className="pf-icon-request-report"
												src={requestReportIcon}
											/>
										}
										className={'pf-request-report-button'}
										onClick={requestReport}
										type={'button'}
										variant={'primary'}
										buttonHeight={40}>
										<span className={'button-text'}>Request Report</span>
									</PFButton>
									<PFButton
										type="button"
										variant="secondary"
										disabled={isSubmittingScheduleMeeting || !calendlyLink}
										prefixIcon={
											<ReactSVG
												wrapper="span"
												className="pf-icon-reject"
												src={scheduleMeetingIcon}
											/>
										}
										className="pf-leads-scheduleMeeting-button"
										onClick={() => sendScheduleMeeting([userToken])}>
										<span className="button-text">Schedule a Meeting</span>
									</PFButton>
								</div>
								<DropdownButton
									placement="bottom-end"
									align="end"
									title={<ReactSVG wrapper="div" src={iconDots} />}
									id="bg-nested-dropdown">
									<Dropdown.Item
										disabled={false}
										onClick={onReferClient}
										eventKey="1">
										<div className="dropdownItemText">
											<ReactSVG wrapper="div" src={referBusiness} />
											<span>Refer Business</span>
										</div>
									</Dropdown.Item>
								</DropdownButton>
							</div>
							{!calendlyLink && (
								<PFNote className="sendScheduleMeetingNote">
									<div>
										<PFLink to={PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT}>
											Add your calendar link
										</PFLink>{' '}
										to easily schedule appointments for your leads with a click
										of a button.
									</div>
								</PFNote>
							)}
						</div>
						<div className="contact-info__note">
							<UserProfileEditableNote
								userToken={userToken}
								userNote={selectedUser?.note}
							/>
						</div>
					</div>

					<div className={'general-info'}>
						<div className="general-info__wrapper">
							<div className="general-info__wrapper-left">
								{isExtendedProfile && (
									<PFAccordion
										header={`Opportunities ${
											selectedUser?.currentOpportunities.length > 0
												? '(' +
												  selectedUser?.currentOpportunities.length +
												  ')'
												: ''
										}`}>
										{selectedUser?.currentOpportunities && (
											<UserProfileOpportunitiesBloc
												userToken={userToken}
												opportunities={
													selectedUser?.currentOpportunities &&
													selectedUser?.currentOpportunities.length > 0
														? selectedUser?.currentOpportunities
														: 'New opportunities will appear here.'
												}
												maxItems={3}
												isCompact={false}
												updateCallback={updateCallback}
											/>
										)}
									</PFAccordion>
								)}
								<PFAccordion
									header={`Client Team ${
										selectedUser?.user.teamCount > 0
											? '(' + selectedUser?.user.teamCount + ')'
											: ''
									}`}>
									<UserProfileTeamBloc userToken={userToken} maxItems={3} />
								</PFAccordion>
								{isExtendedProfile && (
									<PFAccordion
										header={`Insurance ${
											insurancesCount > 0 ? '(' + insurancesCount + ')' : ''
										}`}>
										<UserProfileInsuranceBloc
											userToken={userToken}
											maxItems={3}
											isShortView={true}
											setInsurancesCount={setInsurancesCount}
										/>
									</PFAccordion>
								)}
								{!isExtendedProfile && (
									<PFAccordion
										header={`Reports ${
											reportsCount > 0 ? '(' + reportsCount + ')' : ''
										}`}>
										<UserProfileReportsBloc
											userToken={userToken}
											maxItems={5}
											setReportsCount={setReportsCount}
										/>
									</PFAccordion>
								)}
							</div>

							<div className="general-info__wrapper-right">
								<PFAccordion
									header={`To Do List ${
										todoItems && todoItems.length > 0
											? '(' + todoItems.length + ')'
											: ''
									}`}>
									<UserProfileTodoBloc
										shouldColorDeadlines
										showDescription={false}
										userToken={userToken}
										todoItems={
											(todoItems && todoItems.length) > 0
												? todoItems
												: 'You have not sent any tasks to the user. Click the button above to add one.'
										}
										updateCallback={updateTodoCallback}
										isVisibleSendTaskButton
										isCompact={false}
										maxItems={3}
									/>
								</PFAccordion>
								{isExtendedProfile && (
									<PFAccordion
										header={`Reports ${
											reportsCount > 0 ? '(' + reportsCount + ')' : ''
										}`}>
										<UserProfileReportsBloc
											userToken={userToken}
											maxItems={5}
											setReportsCount={setReportsCount}
										/>
									</PFAccordion>
								)}
								{isExtendedProfile && (
									<PFAccordion header={'Personal Data'}>
										<UserPersonalDataBloc userToken={userToken} maxItems={5} />
									</PFAccordion>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			<PFCopyright />
		</div>
	);
};

export default UserProfile;
