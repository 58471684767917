export const SET_USER = 'set_user';
export const UNSET_USER = 'unset_user';

//ui settings
export const SET_SIDEBAR = 'set_sidebar';
export const SET_SIDE_MODAL = 'set_side_modal';
export const SET_CURRENT_PAGE_TITLE = 'set_current_page_title';

// advisor
export const SET_ADVISOR_CLIENTS_DATA = 'set_advisor_clients_data';
export const CLEANED_UP_ADVISOR_CLIENTS_DATA = 'cleaned_up_advisor_clients_data';
export const SET_SELECTED_ADVISOR_CLIENT_DATA = 'set_selected_advisor_clients_data';

// users
export const SET_USERS_DATA = 'set_users_data';
export const CLEANED_UP_USERS_DATA = 'cleaned_up_users_data';
export const SET_SELECTED_USER_DATA = 'set_selected_user_data';

export const RESET_UI = 'RESET_UI';
// ! ----------------------NEW--------------------------
// table
export const CLEANED_UP_TABLE_DATA = 'cleaned_up_table_data';
export const SET_SELECTED_TABLE_ROW_DATA = 'set_selected_table_row_data';
export const SET_CHECKED_DATA = 'set_checked_data';
export const SET_PAGE = 'set_page';
export const SET_DISPLAY_LENGTH = 'set_display_length';
export const SET_SEARCH = 'set_search';
export const SET_SHOW_SEARCH = 'set_show_search';
export const SET_SORT_PARAM = 'set_sort_param';
export const SET_RESET = 'set_reset';
export const REMOVE_CHECKED_ITEMS = 'remove_checked_items';
// companies
export const SET_COMPANIES = 'set_companies';
export const SET_COMPANY_PROFILE_DATA = 'set_company_profile_data';
export const CLEANED_UP_COMPANY_PROFILE_DATA = 'cleaned_up_company_profile_data';
export const CLEANED_UP_COMPANIES_DATA = 'cleaned_up_companies_data';
export const SET_COMPANY_USERS_DATA = 'set_company_users_data';
// consumers
export const SET_CONSUMERS_DATA = 'set_consumers_data';
export const CLEANED_UP_CONSUMERS_DATA = 'cleaned_up_consumers_data';
// current
// export const SET_CURRENT_DATA = 'set_current_data';
// export const CLEANED_UP_CURRENT_DATA = 'cleaned_up_current_data';
// leads
// export const SET_LEADS_DATA = 'set_leads_data';
// export const CLEANED_UP_LEADS_DATA = 'cleaned_up_leads_data';
// APIInstances
export const SET_API_DATA = 'set_api_data';
export const CLEANED_UP_API_DATA = 'cleaned_up_api_data';
