const PORTAL_PATHS = {
	PORTAL_BASE_URL: '/',
	SIDEBAR: {
		CURRENT: '/portal/current',
		LEADS: '/portal/leads',
		MEETINGS: '/portal/meetings',
		COMPANIES: '/portal/companies',
		ADVISORS: '/portal/advisors',
		CONSUMERS: '/portal/consumers',
		API_INSTANCES: '/portal/api-instances',
		MY_COMPANY: '/company-profile',
		MY_NETWORK: '/my-network',
		REFERRED_TO_ME: '/referred-to-me',
	},
	OTHER_PATHS: {
		MY_PROFILE: '/my-profile',
		MY_PROFILE_EDIT: '/my-profile/edit',
		LOGIN_SETTINGS_EDIT: '/login-settings-edit',
		ADD_NEW_COMPANY: '/add-new-company',
		COMPANY_PROFILE: '/company-profile',
		EDIT_COMPANY_DATA: '/edit-company-data',
		ADD_NEW_ADVISOR: '/add_new_advisor',
		ADVISOR_PROFILE: '/advisor-profile',
		ADVISOR_PROFILE_EDIT: '/advisor-profile-edit',
		ADD_API_INSTANCE: '/add-api-instance',
		EDIT_API_INSTANCE: '/edit-api-instance',
		USER_PROFILE: '/user-profile',
		VIEW_ALL_TEAM: '/view-all-team',
		VIEW_ALL_REPORTS: '/view-all-reports',
		VIEW_ALL_PERSONAL_DATA: '/view-all-personal-data',
		VIEW_ALL_INSURANCES_DATA: '/view-all-insurances-data',
		ADD_NEW_LIFE_INSURANCE: '/add-new-life-insurance',
		EDIT_LIFE_INSURANCE: '/edit-life-insurance',
		ADD_CRITICAL_ILLNESS_INSURANCE: '/add-critical-illness-insurance',
		EDIT_CRITICAL_ILLNESS_INSURANCE: '/edit-critical-illness-insurance',
		ADD_DISABILITY_INSURANCE: '/add-disability-insurance',
		EDIT_DISABILITY_INSURANCE: '/edit-disability-insurance',
		ACCOUNT_SETTINGS: '/account-settings',
		VIEW_ALL_OPPORTUNITIES_DATA: '/view-all-opportunities-data',
		VIEW_ALL_TODO_DATA: '/view-all-todo-data',
		VIEW_ARCHIVE_NOTIFICATIONS: '/view-archive-notifications',
		INVITE_TO_ECOSYSTEM: '/invite-to-ecosystem',
		ECO_ADVISOR_PROFILE: '/eco-advisor-profile',
		MANAGE_SUBSCRIPTION: '/manage-subscription',
		SUBSCRIPTION_STATUS: '/subscription-status',
		RESTORE_PASSWORD: '/restore-password',
		SET_PASSWORD_BY_CODE: '/set-password-by-code',
	},
};

export default PORTAL_PATHS;
