import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {ProfileFormContainer} from 'components';
import AddNewAdvisorForm from 'components/AddNewAdvisorForm';
import {PORTAL_PATHS} from 'const';
import {useCompany} from 'shared/hooks/companies';
import {useAddNewAdvisor} from 'shared/hooks/profile';
import {setCurrentPageTitle} from 'store/actions';
import {useErrorHelper} from 'utils';

import './style.scss';

const AddNewAdvisor = () => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const fetchCompany = useCompany();
	const addNewAdvisor = useAddNewAdvisor(navigate);
	const [selectedCompany, setSelectedCompany] = useState({});
	const [isReady, setIsReady] = useState(false);
	const companyToken = window.location.hash.slice(1);
	const dispatch = useDispatch();

	const title = 'Add New User';

	const goBack = () => {
		if (companyToken) {
			navigate(`${PORTAL_PATHS.OTHER_PATHS.COMPANY_PROFILE}#${companyToken}`);
		} else navigate(-1);
	};

	const fetchCompanyData = async () => {
		try {
			const data = await fetchCompany(companyToken);
			setSelectedCompany(data);
			setIsReady(true);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	useEffect(() => {
		dispatch(setCurrentPageTitle('Advisors'));
	}, []);

	useEffect(() => {
		if (companyToken) {
			fetchCompanyData();
		} else setIsReady(true);
	}, []);

	return (
		<div className="addNevAdvisor">
			<ProfileFormContainer title={title} goBack={goBack}>
				{isReady && (
					<AddNewAdvisorForm
						selectedCompany={selectedCompany}
						addNewAdvisor={addNewAdvisor}
						goBack={goBack}
					/>
				)}
			</ProfileFormContainer>
		</div>
	);
};

export default AddNewAdvisor;
