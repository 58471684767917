import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {advisorClientsService, SubscriptionDataService} from 'api/services';
import {Subscription} from 'types';
import {Amplitude} from 'utils';

type SubscriptionState = {
	subscription: Subscription | null;
	isFirstLoad: boolean;
	isLoading: boolean;
	errorMessage: string;

	currentCount: number;
	leadsCount: number;
};

const initialState: SubscriptionState = {
	subscription: null,
	isFirstLoad: true,
	isLoading: true,
	errorMessage: '',

	currentCount: 0,
	leadsCount: 0,
};

const fetchSubscription = createAsyncThunk('subsctiption/fetchSubscription', async () => {
	const res = await SubscriptionDataService.get();

	Amplitude.setUserProperty('plan_type', (!!res.data && 'paid') || 'free');

	return res;
});

const fetchCounts = createAsyncThunk('subsctiption/fetchCounts', async () => {
	return Promise.all([
		advisorClientsService.getAdvisorClients({type: 2, take: 0, skip: 0}),
		advisorClientsService.getAdvisorClients({type: 3, take: 0, skip: 0}),
	]).then(([current, leads]) => ({
		currentCount: Number(current.data.fullCount),
		leadsCount: Number(leads.data.fullCount),
	}));
});

const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchSubscription.pending, state => {
			state.isLoading = true;
		});
		builder.addCase(fetchSubscription.rejected, state => {
			state.subscription = null;
			state.isLoading = false;
			state.errorMessage = 'Error';
			state.isFirstLoad = false;
		});
		builder.addCase(fetchSubscription.fulfilled, (state, action) => {
			state.subscription = action.payload.data;
			state.isLoading = false;
			state.errorMessage = '';
			state.isFirstLoad = false;
		});
		builder.addCase(fetchCounts.fulfilled, (state, action) => {
			state.currentCount = action.payload.currentCount;
			state.leadsCount = action.payload.leadsCount;
		});
	},
});

export default subscriptionSlice.reducer;
export {fetchCounts, fetchSubscription};
