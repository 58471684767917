import React, {useEffect, useState} from 'react';
import {ReactSVG} from 'react-svg';
import {DropdownButton, Dropdown} from 'react-bootstrap'; // TODO: понадобятся когда доработают фунционал
import moment from 'moment';
import * as financialReportsService from '../../../api/services/financialReports';
import * as advisorClientsService from '../../../api/services/advisorClients';
// import * as emailService from "../../../api/services/email"; TODO: понадобятся когда доработают фунционал
import iconView from '../../../assets/icon-view.svg'; // TODO: понадобятся когда доработают фунционал
// import iconCalendarBtn from '../../../assets/icon-calendly.svg'; // TODO: понадобятся когда доработают фунционал
import dots from '../../../assets/icon-dots.svg';
import requestReportIcon from '../../../assets/buttons/report-request.svg';
import PFButton from '../../../shared/components/PF-Button';
import PFBadge from '../../../shared/components/PF-Badge';
import {
	useErrorHelper,
	findByToken,
	ToastDispatcher,
	parseUrlQuery,
	mapQueryParams,
	Amplitude,
} from 'utils';
import {
	downloadFinancialReport,
	generateReportName,
} from '../../../utils/futureTS/financialReports';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import {useNavigate} from 'react-router-dom';
import {quitTeamIcon, referBusiness} from '../../../assets';
import {setIsOpenReferPopup, setReferClientsCheckedKey, setSideModal} from '../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../store';
import {myProfileSelectors} from '../../../store/selectors';

import {scheduleMeetingIcon} from 'assets';

import './style.scss';
import PFLink from '../../../shared/components/PF-Link';
import PFNote from '../../../shared/components/PF-Note';
import useBooleanState from '../../../shared/hooks/utility/useBooleanState';
import {useCurrentData} from 'shared/hooks';

const AdditionalCurrentInfo = ({token, connectedAccountsCount}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const urlParams = parseUrlQuery(document.location);
	const params = {
		type: 2,
		...mapQueryParams(urlParams),
	};
	const fetchCurrentData = useCurrentData(params);

	const [financialReports, setFinancialReports] = useState(null);
	const [isQuittingTeam, setIsQuittingTeam] = useState(false);

	const [isSubmittingScheduleMeeting, setIsSubmittingScheduleMeeting] = useBooleanState(false);

	const calendlyLink = useAppSelector(myProfileSelectors.selectMyCalendlyLink);

	const errorHelper = useErrorHelper(navigate);

	const goToUserProfile = () => {
		Amplitude.track('Opened_Client_Profile', {source: 'Current'});

		navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${token}`);
	};

	const getFinancialReportsItems = financialReports => {
		return financialReports.map(item => {
			const isNew = item.isNew;
			return (
				<PFBadge
					key={`report-${item.token}`}
					className="currentReport-badge"
					onClick={() => openInWindowFinancialReport(item.token, token, isNew, item)}>
					{
						<div className="report-wrapper">
							{isNew && <div className="report-dot" />}
							<span key={item.token + 'date'}>
								{moment.utc(item.createdAt).local().format('MMM DD, YY')}
							</span>
						</div>
					}
				</PFBadge>
			);
		});
	};

	const sendScheduleMeeting = async userTokens => {
		try {
			setIsSubmittingScheduleMeeting(true);
			await advisorClientsService.sendScheduleMeeting(userTokens);

			Amplitude.track('Scheduled_Meeting', {
				source: 'Current Modal',
				clients: userTokens,
			});

			ToastDispatcher.success(
				'The client will receive your calendar link to suggest the meeting date and time.',
			);
		} catch (e) {
			ToastDispatcher.error(`Failed to schedule a meeting. Please check your calendar link.`);
			throw e;
		} finally {
			setIsSubmittingScheduleMeeting(false);
		}
	};

	const openInWindowFinancialReport = async (reportToken, userToken, isNew, reportItem) => {
		const downloadName = generateReportName(
			reportItem.firstName,
			reportItem.lastName,
			reportItem.createdAt,
		);

		try {
			await downloadFinancialReport(reportToken, downloadName);

			if (isNew) {
				await advisorClientsService.resetAdvisorClientStatuses(userToken);
				await financialReportsService.resetIsNewReport(reportToken);
				if (Array.isArray(financialReports?.body)) {
					const foundFinancialReport = findByToken(financialReports?.body, reportToken);
					if (foundFinancialReport) foundFinancialReport.isNew = false;
					setFinancialReports({...financialReports});
				}
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const fetchData = async () => {
		try {
			const response = await financialReportsService.getFinancialReportsByToken(token);
			setFinancialReports(response.data);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const requestReport = async () => {
		try {
			const response = await financialReportsService.requestReport({
				userToken: token,
			});

			if (response.status === 200) {
				Amplitude.track('Requested_Report', {
					source: 'Current Modal',
				});
				ToastDispatcher.success('Success');
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const onReferClient = () => {
		dispatch(setSideModal(false));
		dispatch(setReferClientsCheckedKey(token));
		dispatch(setIsOpenReferPopup('Current'));
	};

	const handleQuitTeamPress = async () => {
		try {
			setIsQuittingTeam(true);

			await advisorClientsService.removeClientsFromTeam({
				ClientTokens: [token],
			});

			await fetchCurrentData();

			ToastDispatcher.success('You have left client’s team.');

			dispatch(setSideModal(false));
		} catch (e) {
			errorHelper(e, false, 'Unable to quit client’s team. Please try again.');
			throw e;
		} finally {
			setIsQuittingTeam(false);
		}
	};

	//TODO: calendlyInvite функция ниже относится к выподающим кнопкам (они понадобятся когда доработают фунционал)
	// const calendlyInvite = async () => {
	//   try {
	//     const response = await emailService.create({ type: 1, userToken: token });
	//     if (response.status === 200) ToastDispatcher.success('Success');
	//   } catch (err) {
	//	   ToastDispatcher.error(err.message);
	//     throw err?.response?.data;
	//   }
	// };

	useEffect(() => {
		fetchData();
	}, [token]);

	return (
		<div className="current-info">
			<div className="current-info__btn-wrapper">
				<div className="current-info__btn-container">
					<div className="current-info__btn-group-main">
						<PFButton
							prefixIcon={<ReactSVG wrapper="span" src={iconView} />}
							className={'pf-request-report-button'}
							onClick={goToUserProfile}
							type={'button'}
							variant={'primary'}
							buttonHeight={40}>
							<span className={'button-text'}>View Profile</span>
						</PFButton>
						<PFButton
							type="button"
							variant="secondary"
							disabled={isSubmittingScheduleMeeting || !calendlyLink}
							prefixIcon={
								<ReactSVG
									wrapper="span"
									className="pf-icon-reject"
									src={scheduleMeetingIcon}
								/>
							}
							className="pf-leads-scheduleMeeting-button"
							onClick={() => {
								sendScheduleMeeting([token]);
							}}>
							<span className="button-text">Schedule a Meeting</span>
						</PFButton>
					</div>
					<DropdownButton
						className="current-info__btn-group"
						title={<ReactSVG wrapper="div" src={dots} />}
						id="bg-nested-dropdown">
						<Dropdown.Item onClick={requestReport} eventKey="1">
							<div className="dropdown-item__text requestReport">
								<ReactSVG wrapper="div" src={requestReportIcon} />
								<span>Request Report</span>
							</div>
						</Dropdown.Item>
						{token && (
							<>
								<Dropdown.Item onClick={onReferClient} eventKey="2">
									<div className="dropdown-item__text referBusiness">
										<ReactSVG wrapper="div" src={referBusiness} />
										<span>Refer Client</span>
									</div>
								</Dropdown.Item>
								<hr />
								<Dropdown.Item
									disabled={isQuittingTeam}
									onClick={handleQuitTeamPress}
									eventKey="3">
									<div className="dropdown-item__text quitTeamButton">
										<ReactSVG wrapper="div" src={quitTeamIcon} />
										<span>Quit Client’s Team</span>
									</div>
								</Dropdown.Item>
							</>
						)}
					</DropdownButton>
				</div>
				{!calendlyLink && (
					<PFNote className="sendScheduleMeetingNote">
						<div>
							<PFLink to={PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT}>
								Add your calendar link
							</PFLink>{' '}
							to easily schedule appointments for your leads with a click of a button.
						</div>
					</PFNote>
				)}
			</div>
			<div>
				<h5 className="current-info__title">
					Reports{' '}
					{(financialReports?.count || financialReports?.count === 0) &&
						`(${financialReports.count})`}
				</h5>
				{financialReports && (
					<div className="current-info__reports">
						{getFinancialReportsItems(financialReports.body)}
					</div>
				)}
			</div>
		</div>
	);
};

export default AdditionalCurrentInfo;
