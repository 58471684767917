import React, {FC, MutableRefObject, ReactNode, useEffect, useRef, useState} from 'react';
import {FieldError, FieldErrorsImpl, Merge} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {ReactSVG} from 'react-svg';

import urlPaths from 'api/url-paths';
import {replaceIcon} from 'assets/buttons';
import UploadIcon from 'assets/icon-upload.svg';
import classNames from 'classnames';
import {CropImageType, CroppedAreaType} from 'types';
import {generateBlobImage, getPhotoCompressionRatio, imageCompressor} from 'utils';
import {ToastDispatcher} from 'utils/ToastDispatcher';

import {useFileUpload} from '../../hooks';
import PFAvatar from '../PF-Avatar';
import PFButton from '../PF-Button';
import PFImageCropper from '../PF-ImageCropper';
import PFPopup from '../PF-Popup';

import './style.scss';

type PropsTypes = {
	id: string;
	src: string;
	avatarSize: number;
	showAsterisk: boolean;
	label: string;
	error: boolean | string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
	hint: string | ReactNode;
	onChange: (image: string) => void;
	className?: string;
	labelSuffix?: string | ReactNode;
	variant?: 'rounded-circle' | 'rounded-square';
	defaultAvatar?: string;
};

const PFInputAvatarCrop: FC<PropsTypes> = ({
	id,
	src,
	avatarSize = 140,
	showAsterisk,
	label,
	error,
	hint,
	onChange,
	className,
	labelSuffix,
	variant = 'rounded-circle',
	defaultAvatar,
}) => {
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const upload = useFileUpload();
	// const uploadImg = (
	// 	<ReactSVG
	// 		wrapper="span"
	// 		className="pf-button__icon pf-button__icon--prefix pf-inputAvatar__button-icon"
	// 		src={UploadIcon}
	// 	/>
	// );

	const triggerFileSelectPopup = (refInput: MutableRefObject<HTMLInputElement | null>) => {
		if (refInput.current) refInput.current.click();
	};
	const [state, setState] = useState<{avatarUrl: string}>({
		avatarUrl: '',
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [image, setImage] = useState<CropImageType>(null);
	const [croppedArea, setCroppedArea] = useState<CroppedAreaType>({
		width: 0,
		height: 0,
		x: 0,
		y: 0,
	});

	const [inputValue, setInputValue] = useState<string>('');
	const [showModal, setShowModal] = useState<boolean>(false);

	const onCropComplete = (
		croppedAreaPercentage: CroppedAreaType,
		croppedAreaPixels: CroppedAreaType,
	): void => {
		setCroppedArea(croppedAreaPixels);
	};

	const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setInputValue('');
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			const fileSize = file.size / 1024;
			if (fileSize > 5000) {
				onChange('error');
				return;
			}
			if (file.name.indexOf('HEIC') !== -1) {
				onChange('error-type');
				return;
			}
			imageCompressor({
				file,
				quality: getPhotoCompressionRatio(fileSize),
			});
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.addEventListener('load', () => {
				setImage(reader.result);
			});
			if (!showModal) setShowModal(true);
		}
	};

	const onUpload = async (): Promise<any> => {
		setShowModal(false);
		const img = await generateBlobImage(image, croppedArea);
		try {
			setIsLoading(true);
			const fileName = await upload(img);
			if (fileName) {
				setState({
					avatarUrl: urlPaths.BASE_IMAGES_URL + fileName,
				});
			}
			onChange(fileName);
		} catch (e: unknown) {
			if (e instanceof Error) {
				ToastDispatcher.error(`${e.message}`);
			}
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const hideModal = (): void => {
		setShowModal(false);
	};

	useEffect((): void => {
		if (src) {
			setState({
				avatarUrl: urlPaths.BASE_IMAGES_URL + src,
			});
		}
	}, [src]);

	return (
		<div
			className={classNames(
				'pf-inputAvatar',
				{
					'pf-inputAvatar--error': error,
				},
				className,
			)}>
			<div className="pf-inputAvatar__label-wrapper">
				<div className="pf-inputAvatar__label">
					{showAsterisk && <span className="pf-inputAvatar__label-asterisk">*</span>}
					{label}
				</div>
				{labelSuffix}
			</div>
			<div className="container-buttons">
				{/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
				<label htmlFor={id}>
					<PFAvatar
						src={state.avatarUrl}
						isLoading={isLoading}
						size={avatarSize}
						variant={variant}
						defaultAvatar={defaultAvatar} //!
					/>
				</label>
				<input
					id={id}
					type="file"
					accept="image/*"
					ref={inputRef}
					onChange={onSelectFile}
					style={{display: 'none'}}
					value={inputValue}
				/>
				<div className="pf-inputAvatar__button-wrapper">
					<label htmlFor={id} className="pf-inputAvatar__button-label">
						<PFButton
							type="button"
							variant="plain"
							prefixIcon={
								<ReactSVG
									wrapper="span"
									className="pf-button__icon pf-button__icon--prefix pf-inputAvatar__button-icon"
									src={state.avatarUrl ? replaceIcon : UploadIcon}
								/>
							}
							onClick={() => triggerFileSelectPopup(inputRef)}>
							{state.avatarUrl ? 'Replace' : 'Upload'}
						</PFButton>
						<p className="uploadHint">*.jpg, *.jpeg, *.png; 5 MB max</p>
					</label>
				</div>
			</div>
			<p
				className={classNames('pf-inputAvatar__hint', {
					'pf-inputAvatar__hint--error': error,
				})}>
				{(typeof error === 'string' && error) || hint}
			</p>
			<PFPopup
				isShow={showModal}
				className={{root: 'inputAvatarModal'}}
				title="Zoom and crop your profile image"
				submitBtnText="Save"
				cancelBtnText="Cancel"
				isShowCancelBtn
				isCloseButton
				isShowFooter
				handleClose={hideModal}
				onSubmit={onUpload}>
				{typeof image === 'string' && (
					<PFImageCropper
						image={image}
						cropShape={variant === 'rounded-circle' ? 'round' : 'rect'}
						onCropComplete={onCropComplete}
						showGrid={false}
					/>
				)}
			</PFPopup>
		</div>
	);
};
export default PFInputAvatarCrop;
