import React, {FC, ReactNode} from 'react';

import AdvisorProfileDescription from 'components/AdvisorProfileDescription';
import {HASHTAG_TYPES, SPECIALIZATION_OPTIONS_TYPES} from 'const';
import PFBadge from 'shared/components/PF-Badge';
import YouTubePlayer from 'shared/components/PF-YouTubePlayer';
import {Specialization} from 'types/enums';
import {matchYouTubeLink} from 'utils';

import './style.scss';

type PropsTypes = {
	videoPresentationLink: string;
	description?: string;
	emptyFieldMessage?: ReactNode | string;
	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	advisorSpecializationTypes?: Specialization[];
	hashtags?: any;
	auth: boolean;
};

const AdvisorProfileGeneralInfoBloc: FC<PropsTypes> = ({
	videoPresentationLink,
	description,
	emptyFieldMessage,
	advisorSpecializationTypes,
	hashtags,
	auth,
}) => {
	const youTubeLinkMatchArray = matchYouTubeLink(videoPresentationLink);
	const specializationLabel = auth ? 'Roles available' : 'Profession(s)';
	const hashTagTypes = auth
		? Object.values(HASHTAG_TYPES)
		: Object.values(HASHTAG_TYPES).filter(item => item.authReq === false);

	const renderTags = (list, type, index) => {
		const filteredList = list?.filter(item => item.type === type.value);

		return (
			<AdvisorProfileDescription
				key={index}
				label={type.label}
				description={filteredList?.length < 1 && emptyFieldMessage}>
				<div className="pf-myProfile__tags">
					{filteredList?.map(item => (
						<PFBadge key={item?.token}>{item?.name}</PFBadge>
					))}
				</div>
			</AdvisorProfileDescription>
		);
	};

	return (
		<div className="general-bloc__wrapper">
			{!!videoPresentationLink && youTubeLinkMatchArray && !!youTubeLinkMatchArray.length && (
				<div className="youtube-frame">
					<YouTubePlayer id={youTubeLinkMatchArray[4]} />
				</div>
			)}
			<AdvisorProfileDescription
				labelClassName="mt-0"
				label="Description"
				description={description || emptyFieldMessage}
			/>
			{/*! ледуюшего блока "Specialization" может не быть тут - читай комент выше в функции "renderTags" */}
			{/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */}
			{advisorSpecializationTypes && advisorSpecializationTypes.length > 0 ? (
				<AdvisorProfileDescription key="Specialization" label={specializationLabel}>
					<div className="general-bloc__tags">
						{advisorSpecializationTypes?.map(item => (
							<PFBadge key={`Specialization-${item}`}>
								{
									SPECIALIZATION_OPTIONS_TYPES.find(spec => {
										return spec.type === item;
									})?.name
								}
							</PFBadge>
						))}
					</div>
				</AdvisorProfileDescription>
			) : (
				<AdvisorProfileDescription
					label={specializationLabel}
					description={emptyFieldMessage}
				/>
			)}
			{hashTagTypes.map((item, index) => renderTags(hashtags, item, index))}
		</div>
	);
};

export default AdvisorProfileGeneralInfoBloc;
