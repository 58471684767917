import React, {FC, ReactNode} from 'react';

import {DEFAULT_AVATAR} from 'const';

import urlPaths from '../../../api/url-paths';
import PFAvatar from '../PF-Avatar';

import './style.scss';

type PropsTypes = {
	name: string;
	children: ReactNode;
	avatarPhoto?: string | null;
	defaultAvatar?: string;
};

const PFUserDescription: FC<PropsTypes> = ({name, children, avatarPhoto, defaultAvatar}) => {
	const src = avatarPhoto
		? `${urlPaths.BASE_IMAGES_URL}${avatarPhoto}`
		: defaultAvatar || DEFAULT_AVATAR;
	return (
		<div className="pf-user-description">
			<PFAvatar src={src} size={90} />
			<div className="userDescription">
				<h3>{name}</h3>
				<div className="children_wrapper">{children}</div>
			</div>
		</div>
	);
};

export default PFUserDescription;
