import COMMON_PATHS from './url-paths/common';
import PORTAL_PATHS from './url-paths/portal';
import PUBLIC_PATHS from './url-paths/public';
import Statuses from './status';

const PATHS = {
	PORTAL: PORTAL_PATHS,
	COMMON: COMMON_PATHS,
	PUBLIC: PUBLIC_PATHS,
};

export * from './forms';
export * from './general';
export * from './insurance';
export * from './policy';
export * from './profile';
export * from './sidebar-menus';
export * from './subscriptions';
export {Statuses};
export {COMMON_PATHS, PATHS, PORTAL_PATHS, PUBLIC_PATHS};
