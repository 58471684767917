import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MyProfile} from 'types';

interface MyProfileState {
	data: MyProfile | null;
}
type SetMyProfileDataActionType = MyProfile;

const initialState: MyProfileState = {
	data: null,
};

export const myProfileSlice = createSlice({
	name: 'myProfile',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setMyProfile: (state, action: PayloadAction<SetMyProfileDataActionType>) => {
			state.data = action.payload;
		},
		resetMyProfile: state => {
			state.data = initialState.data;
		},
	},
});

export default myProfileSlice.reducer;
