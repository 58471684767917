import React, {FC, ReactNode} from 'react';
import {ReactSVG} from 'react-svg';

import classNames from 'classnames';

import iconClose from '../../../assets/icon-close.svg';

import './style.scss';

type PropsTypes = {
	children: ReactNode | string;
	variant?: string;
	onRemove?: () => any;
};

const PFTile: FC<PropsTypes> = ({children, variant, onRemove, ...props}) => {
	return (
		<div className={classNames('pf-tile', variant)} {...props}>
			{children}
			{onRemove && (
				<div
					className="pf-tile__remove"
					role="button"
					tabIndex={0}
					onClick={() => onRemove()}
					onKeyDown={event => {
						if (event.key === 'Enter' || event.key === ' ') {
							onRemove();
						}
					}}>
					<ReactSVG src={iconClose} className="pf-tile__remove-icon" />
				</div>
			)}
		</div>
	);
};

export default PFTile;
