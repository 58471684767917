import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type InviteAdvisorModalStateSource = 'Navigation' | 'My Network' | null;

interface InviteAdvisorModalState {
	isOpen: boolean;
	source: InviteAdvisorModalStateSource;
}

const initialState: InviteAdvisorModalState = {
	isOpen: false,
	source: null,
};

export const inviteAdvisorModalSlice = createSlice({
	name: 'inviteAdvisorModal',
	initialState,
	reducers: {
		setOpen: (state, action: PayloadAction<InviteAdvisorModalStateSource>) => {
			state.isOpen = true;
			state.source = action.payload;
		},
		setClose: state => {
			state.isOpen = false;
			state.source = null;
		},
	},
});

export default inviteAdvisorModalSlice.reducer;
