import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

//general UI components
import PFButton from '../../../shared/components/PF-Button';
import PFHeader from '../../../shared/components/PF-Header';
import PFCopyright from '../../../shared/components/PF-Copyright';

//styles, assets
import './style.scss';

import PFInput from '../../../shared/components/PF-Input';
import * as usersService from '../../../api/services/users';
import {useResetPasswordForm} from './utils';
import {parseUrlQuery, ToastDispatcher} from '../../../utils';
import PUBLIC_PATHS from '../../../const/url-paths/public';

const ResetPassword = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const {c, t} = parseUrlQuery(document.location);

	const {
		fields,
		handleSubmit,
		formState: {isValid},
	} = useResetPasswordForm();

	const successSetNewPassword = () => {
		navigate(PUBLIC_PATHS.SIGN_IN);
		ToastDispatcher.success('New password has been set. Now you can log in.');
	};

	const setNewPassword = async valueObj => {
		const body = {
			token: t,
			code: atob(c),
			password: valueObj.password,
			isInvited: false,
		};
		try {
			setIsLoading(true);
			await usersService.resetPasswordByCode(body);
			successSetNewPassword();
		} catch (e) {
			ToastDispatcher.error('Something went wrong. Please try again.');
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="pf-restore-password">
			<PFHeader />
			<div className="pf-restore-password__container">
				<div className={'pf-restore-password__main'}>
					<div className="pf-restore-password__card">
						<>
							<h2 className="pf-restore-password__title">Set New Password</h2>
							<p className="text-center pf-restore-password___text">
								Create a new password for entering the portal
							</p>
							<form onSubmit={handleSubmit(setNewPassword)}>
								{fields.map(({error, ...field}) => (
									<PFInput key={field.name} {...field} error={error?.message} />
								))}
								<PFButton
									disabled={isLoading || !isValid}
									className="pf-restore-password__button"
									type="submit">
									Set
								</PFButton>
							</form>
						</>
					</div>
				</div>
			</div>
			<PFCopyright />
		</div>
	);
};

export default ResetPassword;
