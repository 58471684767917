import React, {useEffect, useState} from 'react';
import close from '../../../assets/icon-close.svg';
import {ReactSVG} from 'react-svg';
import './style.scss';
import {setCheckedData} from '../../../store/actions';
import {Checkbox} from 'rsuite';
import {useAppDispatch} from '../../../store';

const PFTableBar = ({tableData, tableBarButtons, checkedKeys, modal, className = ''}) => {
	const [tableBarHeight, setTableBarHeight] = useState(0);
	const dispatch = useAppDispatch();

	const handleTableBar = () => {
		const screenWidth = window.innerWidth;

		if (screenWidth <= 1650 && modal === 'companyProfile') {
			if (checkedKeys.length > 0) {
				setTableBarHeight(80);
			} else {
				setTableBarHeight(0);
			}
		} else if (screenWidth <= 360) {
			if (checkedKeys.length > 0) {
				setTableBarHeight(60);
			} else {
				setTableBarHeight(0);
			}
		} else {
			if (checkedKeys.length > 0) {
				setTableBarHeight(80);
			} else {
				setTableBarHeight(0);
			}
		}
	};

	let checked = false;
	let indeterminate = false;

	if (tableData && checkedKeys.length === tableData.length && tableData.length !== 0) {
		checked = true;
	} else if (checkedKeys.length === 0) {
		checked = false;
	} else if (tableData && checkedKeys.length > 0 && checkedKeys.length < tableData.length) {
		indeterminate = true;
	}

	const handleCheckAll = (value, checked) => {
		const checkedKeys = checked ? tableData.map(item => item.token) : [];
		dispatch(setCheckedData(checkedKeys));
	};

	const onClickCloseButton = () => {
		dispatch(setCheckedData([]));
		handleTableBar([]);
	};

	useEffect(() => {
		if (checkedKeys && typeof checkedKeys.length === 'number') handleTableBar();
	}, [checkedKeys]);

	useEffect(() => {
		window.addEventListener('resize', handleTableBar(checkedKeys));

		return () => {
			window.addEventListener('resize', handleTableBar(checkedKeys));
		};
	}, []);

	return (
		<div
			className={`${
				tableBarHeight ? 'pf-tableBar pf-tableBar-selected' : 'pf-tableBar'
			} ${className}`}
			style={{height: `${tableBarHeight}px`}}>
			<div className={'pf-tableBar__checkbox'}>
				<Checkbox
					inline
					checked={checked}
					indeterminate={indeterminate}
					onChange={handleCheckAll}
				/>
			</div>

			<button className={'pf-close-button'} onClick={onClickCloseButton}>
				<ReactSVG wrapper="div" className="pf-icon-close" src={close} />
				{checkedKeys.length}{' '}
				<span className={'pf-close-button__text'}>
					item{checkedKeys.length > 1 ? 's' : null} selected
				</span>
			</button>
			{tableBarButtons &&
				modal === 'companyProfile' &&
				tableBarButtons.map((item, index) => (index < 1 ? item : null))}
			<div className={'group-buttons'}>
				{tableBarButtons && modal !== 'companyProfile'
					? tableBarButtons.map((item, index) => (index <= 1 ? item : null))
					: tableBarButtons.map((item, index) => (index >= 1 ? item : null))}
			</div>
			{tableBarButtons &&
				modal !== 'companyProfile' &&
				tableBarButtons.map((item, index) => (index > 1 ? item : null))}
		</div>
	);
};

export default PFTableBar;
