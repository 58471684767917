import React from 'react';
import {ReactSVG} from 'react-svg';

import {loaderIcon} from 'assets';

import style from './style.module.scss';

const VirtuosoFooter = () => {
	return (
		<div className={style.virtuoso__loadMore}>
			<ReactSVG src={loaderIcon} className={style.virtuoso__loadMore_icon} />
		</div>
	);
};

export default VirtuosoFooter;
