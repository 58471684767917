import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export type RejectRequestLeadsToCurrentParamsType = {
	userTokens: string[];
	reason: string;
};

export const acceptRequestLeadsToCurrent = (body: string[]) =>
	httpClient.put(`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/addToTeam`, body);

export const rejectRequestLeadsToCurrent = (body: RejectRequestLeadsToCurrentParamsType) =>
	httpClient.put(
		`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/declineRequest?reason=${body.reason}`,
		body.userTokens,
	);
