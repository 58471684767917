import React, {useState, useEffect} from 'react';

import {HiEye, HiEyeOff} from 'react-icons/hi';
import {useForm} from 'react-hook-form';
import {myProfileSelectors} from '../../../../store/selectors';
import {PFInput} from '../../../../shared/components';
import {useUpdateUserLogin} from '../../../../shared/hooks/users';
import {generateValidator, hints, validations} from '../../../../utils';
import {useAppSelector} from '../../../../store';

export const useLoginEditForm = () => {
	const updateUserLogin = useUpdateUserLogin();
	const formOptions = {
		mode: 'onChange',
	};
	const formProps = useForm(formOptions);
	const {
		register,
		trigger,
		getValues,
		watch,
		formState,
		setValue,
		control,
		unregister,
		setError,
		...props
	} = formProps;

	const myProfile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const [isSubmitting, setisSubmitting] = useState(false);

	//For password and confirmPassword hiding
	const [isVisiblePsw, setIsVisiblePsw] = useState(false);
	const [isVisibleConfirmPsw, setIsVisibleConfirmPsw] = useState(false);

	const togglePasswordVisibility = type => {
		if (type === 'password') {
			setIsVisiblePsw(visible => !visible);
		} else if (type === 'confirmPassword') {
			setIsVisibleConfirmPsw(visible => !visible);
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === 'password') {
				await trigger('confirmPassword');
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	const passwordIcon = isVisiblePsw ? (
		<HiEyeOff onClick={() => togglePasswordVisibility('password')} size={15} />
	) : (
		<HiEye onClick={() => togglePasswordVisibility('password')} size={15} />
	);

	const confirmPasswordIcon = isVisibleConfirmPsw ? (
		<HiEyeOff onClick={() => togglePasswordVisibility('confirmPassword')} size={15} />
	) : (
		<HiEye onClick={() => togglePasswordVisibility('confirmPassword')} size={15} />
	);
	//The className variable below adds a bit of margin after each field.
	const className = 'col-12 mb-4';
	const classHalf = 'col-lg-6 mb-4';
	const classFull = 'col-12 mb-4';

	const generateField = (
		Component,
		name,
		label,
		placeholder,
		validations,
		isRequired = false,
	) => {
		return {
			...register(name, validations),
			showAsterisk: isRequired,
			label: label,
			error: formState.touchedFields[name] && formState.errors[name],
			placeholder: placeholder,
			Component: Component,
		};
	};

	const fields = [
		{
			type: 'email',
			className: classFull,
			defaultValue: myProfile.email,
			...generateField(
				PFInput,
				'email',
				'Email',
				'Email',
				{
					validate: generateValidator(hints.email.required, validations.email),
				},
				true,
			),
			error: formState.errors.email,
		},
		{
			...register('password', {
				validate: generateValidator(false, validations.password),
			}),
			label: 'New Password',
			autoComplete: 'new-password',
			error: formState.errors.password,
			showAsterisk: false,
			icon: passwordIcon,
			type: isVisiblePsw ? 'text' : 'password',
			hint: hints.password.index,
			className: classHalf,
			placeholder: 'Password',
			Component: PFInput,
		},
		{
			...register('confirmPassword', {
				validate: value => value === getValues('password') || 'The passwords do not match!',
			}),
			label: 'Confirm New Password',
			autoComplete: 'new-password',
			error: formState.errors.confirmPassword,
			showAsterisk: false,
			icon: confirmPasswordIcon,
			type: isVisibleConfirmPsw ? 'text' : 'password',
			className: classHalf,
			placeholder: 'Confirm password',
			Component: PFInput,
		},
	];
	const onSubmit = async valueObj => {
		const {email, password} = valueObj;
		const body = {
			token: myProfile.token,
			email: email,
			password: password,
		};
		setisSubmitting(true);
		await updateUserLogin(body);
		setisSubmitting(false);
	};
	return {
		fields,
		formState,
		setValue,
		onSubmit,
		isSubmitting,
		...props,
	};
};
