import {Subscription} from 'types';

import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const get = () => {
	return httpClient.get<Subscription | null>(`${URL.BASE_URL}/PremiumSubscriptions`);
};

export const getUpdateLink = () => {
	return httpClient.put<{link: string}>(`${URL.BASE_URL}/PremiumSubscriptions`);
};

export const cancel = () => {
	return httpClient.delete(`${URL.BASE_URL}/PremiumSubscriptions`);
};

// Early-Adopter-CAD-Monthly
// Early-Adopter-CAD-Yearly
export const getCheckoutLink = (config: {
	isAnnual: boolean;
	email: string;
	firstName: string;
	lastName: string;
}) => {
	return `https://pocketfinance.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=${
		config.isAnnual ? 'Early-Adopter-CAD-Yearly' : 'Early-Adopter-CAD-Monthly'
	}&subscription_items[quantity][0]=1&layout=in_app&customer[email]=${
		config.email
	}&customer[first_name]=${config.firstName}&customer[last_name]=${
		config.lastName
	}&redirect_url=https://app.pocketfinance.com/subscription-status`;
};
