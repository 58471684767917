import React, {FC, ReactNode, useEffect} from 'react';

import {useTheme} from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import './style.scss';

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const {children, value, index, ...other} = props;

	return (
		<div
			hidden={value !== index}
			id={`full-width-tabPanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<div className="row tab-page" key={`${value}-block`}>
					{children}
				</div>
			)}
		</div>
	);
};

function a11yProps(index: number) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabPanel-${index}`,
	};
}

type PropsTypes = {
	labels: string[] | ReactNode[];
	children?: ReactNode[] | JSX.Element[];
	isTabBody?: boolean;
	tabIndex?: number;
	onTabIndexChage?: (index: number) => void;
	className?: string;
};

const PFTabsContainer: FC<PropsTypes> = ({
	labels,
	children,
	isTabBody = true,
	tabIndex,
	onTabIndexChage,
	className,
}) => {
	const theme = useTheme();
	const [tab, setTab] = React.useState(0);
	const handleChange = (event, newValue) => {
		setTab(newValue);
		onTabIndexChage?.(newValue);
	};

	const tabClasses = {
		root: 'tab-button',
		selected: 'tab-button__selected',
	};

	const tabsClasses = {
		root: `${labels?.length && tab !== labels.length - 1 ? 'tabs-root' : ''}`,
		indicator: 'tab-indicator',
		scroller: 'tab-scroller',
		flexContainer: 'tabs-flexContainer',
	};

	useEffect(() => {
		if (tabIndex !== undefined) {
			setTab(tabIndex);
		}
	}, [tabIndex]);

	return (
		<div className={`tabsBlock ${className || ''}`}>
			<Tabs
				variant="scrollable"
				scrollButtons={false}
				allowScrollButtonsMobile={false}
				value={tab}
				onChange={handleChange}
				aria-label="full width tabs example"
				classes={tabsClasses}>
				{labels.map((item, index) => (
					<Tab
						key={`${typeof item === 'string' ? item : item.key}-tab`}
						label={item}
						classes={tabClasses}
						style={{maxWidth: `${100 / labels.length}%`}}
						{...a11yProps(index)}
					/>
				))}
			</Tabs>
			{isTabBody && children && (
				<div className="tab-animation">
					<div
						className="tab-animation__wrap"
						style={{
							flexDirection: 'row',
							transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
							direction: 'ltr',
							display: 'flex',
							willChange: 'transform',
							transform: `translate(${tab * -100}%, 0px)`,
							zIndex: '5',
							position: 'relative',
						}}>
						{children.map((item, index) => {
							const childrenKey = index;
							return (
								<div
									key={childrenKey}
									className="animation-item"
									style={{width: '100%', flexShrink: '0'}}>
									<TabPanel value={tab} index={index} dir={theme.direction}>
										{item}
									</TabPanel>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default PFTabsContainer;
