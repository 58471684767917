import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const uploadFile = (body, config) => {
	return httpClient.post(`${URL.BASE_URL}/${URL.UPLOAD.INDEX}`, body, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		...config,
	});
};
