import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {advisorClientsService} from 'api/services';
import {PORTAL_PATHS} from 'const';
import {AdvisorType} from 'types';
import {useErrorHelper} from 'utils';

import arrow from '../../../assets/buttons/icon-view-all.svg';
import UserProfileTeamItem from '../UserProfileTeamItem';

import './style.scss';

type PropsTypes = {
	userToken: string;
	maxItems?: number | undefined;
	isCompanyNames?: boolean;
};

const UserProfileTeamBloc: FC<PropsTypes> = ({userToken, maxItems, isCompanyNames}) => {
	const navigate = useNavigate();
	const [team, setTeam] = useState<AdvisorType[] | null>(null);
	const errorHelper = useErrorHelper(navigate);

	const getTeamItems = (data: AdvisorType[]): JSX.Element[] | null => {
		if (data.length !== 0) {
			const teamItems = data.map(item => {
				return (
					<UserProfileTeamItem
						teamItem={item}
						key={`item-${item.token}`}
						isCompanyNames={isCompanyNames}
					/>
				);
			});
			if (maxItems) {
				return teamItems.slice(0, maxItems);
			}
			return teamItems;
		}
		return null;
	};

	const fetchUserTeam = async (token: string): Promise<any> => {
		try {
			const response = await advisorClientsService.getUsersTeam(token);
			setTeam(response.data.items);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const goToViewAll = (): void => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.VIEW_ALL_TEAM}?token=${userToken}`);
	};

	useEffect(() => {
		fetchUserTeam(userToken);
	}, [userToken]);

	return (
		<div className="teamData">
			{team && getTeamItems(team)}
			{maxItems && team && team?.length > maxItems && (
				<div className="teamData__viewAllButton">
					<button onClick={goToViewAll} type="button">
						View All
						<ReactSVG wrapper="span" className="pf-arrowSvg" src={arrow} />
					</button>
				</div>
			)}
		</div>
	);
};

export default UserProfileTeamBloc;
