import React, {FC} from 'react';

import {DEFAULT_AVATAR} from 'const';
import PFAvatar from 'shared/components/PF-Avatar';
import {getFullName} from 'utils';

import urlPaths from '../../../api/url-paths';
import RestoreButton from '../../RestoreButton';

import './style.scss';

type PropsTypes = {
	avatarImage?: string;
	avatarSize?: number;
	firstName: string;
	lastName: string;
	isDeleted: boolean;
	defaultAvatar?: string;
	restore?: (token: string) => void;
	restoreToken?: string;
};

const UserCellItem: FC<PropsTypes> = ({
	avatarImage,
	avatarSize,
	firstName,
	lastName,
	isDeleted,
	defaultAvatar,
	restore,
	restoreToken,
}) => {
	// ! разноцветные аватары (пока херня, потому что меняется цвет при каждом ререндере)
	// const src = useMemo(
	// 	(): ((avatar: string) => string) => avatar => {
	// 		return avatar ? `${urlPaths.BASE_IMAGES_URL}${avatar}` : getDefaultAvatar();
	// 	},
	// 	[avatarImage],
	// );
	const src = avatarImage
		? `${urlPaths.BASE_IMAGES_URL}${avatarImage}`
		: defaultAvatar || DEFAULT_AVATAR;

	return (
		<div className="pf-userCellItem">
			<PFAvatar src={src || defaultAvatar} size={avatarSize} isDeleted={isDeleted} />
			<div className="pf-userCellItem__name-group">
				<div className="pf-tableText name">{getFullName(firstName, lastName)}</div>
				{restore && restoreToken && (
					<RestoreButton isDeleted={isDeleted} onClick={restore} token={restoreToken} />
				)}
			</div>
		</div>
	);
};

export default UserCellItem;
