import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {UserPersonalDataBloc} from 'components';
import {useUserProfile} from 'shared/hooks';
import {getFullName, parseUrlQuery, useErrorHelper} from 'utils';

import ViewAll from '../ViewAll';

const ViewAllPersonalData = () => {
	const {token} = parseUrlQuery(document.location);
	const fetchUserInfo = useUserProfile();
	const [name, setName] = useState<string>('User without name');
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);

	const fetchUser = async userToken => {
		// eslint-disable-next-line no-useless-catch
		try {
			const data = await fetchUserInfo(userToken);
			const fullName = getFullName(data?.user?.firstName, data?.user?.lastName);
			setName(fullName);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	useEffect(() => {
		fetchUser(token);
	}, [token]);

	return (
		<ViewAll userToken={token} title={`${decodeURI(name)}'s Personal Data`}>
			<UserPersonalDataBloc userToken={token} />
		</ViewAll>
	);
};

export default ViewAllPersonalData;
