import React, {useCallback, useEffect, useState} from 'react';
import {ReactSVG} from 'react-svg';

//general UI components
import PFCopyright from '../../../shared/components/PF-Copyright';

//styles, assets
import {
	invitation_apple,
	invitation_bg,
	invitation_bg_mobile,
	invitation_faсebook,
	invitation_google,
	invitation_iconInf,
	invitation_instagram,
	invitation_linkedin,
	invitation_logo,
	invitation_mobileDevice,
	invitation_mobileDevice713,
	invitation_twitter,
} from 'assets';
import './style.scss';

const Invitation = () => {
	const [isMobileBg, setIsMobileBg] = useState(false);

	const resizeListener = useCallback(() => {
		const screenWidth = window.innerWidth;

		if (screenWidth <= 640) {
			setIsMobileBg(true);
		} else {
			setIsMobileBg(false);
		}
	}, [setIsMobileBg]);

	useEffect(() => {
		resizeListener();

		window.addEventListener('resize', resizeListener);

		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, [resizeListener]);

	return (
		<div className={'pf-invitation-page'}>
			<div
				className={'pf-invitation-main'}
				style={{
					backgroundImage: `url("${isMobileBg ? invitation_bg_mobile : invitation_bg}")`,
				}}>
				<img
					src={invitation_logo}
					alt={'Pocket Finance'}
					className={'pf-invitation-logo'}
				/>
				<div className={'main-mobileImg'}>
					<img
						src={isMobileBg ? invitation_mobileDevice713 : invitation_mobileDevice}
						alt={'mobile device'}
						className={'mobileImg'}
					/>
				</div>
				<div className={'main-text'}>
					<h1>
						Welcome
						<br /> to Pocket Finance
					</h1>
					<h2>The Right Information at the Right Time</h2>
					<p>
						Install the <span className={'yellow-text'}>Pocket Finance</span> app using
						one of the links below and enjoy the benefits of financial advisory as never
						before.
					</p>
					<div className={'main-appLinks'}>
						<a
							href={
								'https://play.google.com/store/apps/details?id=com.pocketfinanceinc.pocketfinancemobile'
							}
							target={'_blank'}>
							<img
								src={invitation_google}
								alt={'Google Play'}
								className={'googleImg'}
							/>
						</a>
						<a
							href={
								'https://apps.apple.com/by/app/pocket-finance-mobile/id1539785018'
							}
							target={'_blank'}>
							<img src={invitation_apple} alt={'App Store'} className={'appleImg'} />
						</a>
					</div>
					<div className={'main-info'}>
						<div className={'main-info__img'}>
							<img src={invitation_iconInf} alt={'Info'} className={'infoImg'} />
						</div>
						<div className={'main-info__message'}>
							If you received a link from your advisor, please open it on your mobile
							device instead.
						</div>
					</div>
				</div>
			</div>
			<div className={'pf-invitation-footer'}>
				<h2 className={'footer-learn-more'}>
					<a href={'http://pocketfinance.com/'} target={'_blank'} className={'blue-text'}>
						Learn More about <span>Pocket Finance</span>
					</a>
				</h2>
				<div className={'footer-socialLinks'}>
					<a
						href={'https://www.facebook.com/Pocket.finance.mobile?_rdc=1&_rdr'}
						title={'Faсebook'}
						target={'_blank'}>
						<ReactSVG src={invitation_faсebook} className={'faсebookImg socialImg'} />
					</a>
					<a
						href={'https://www.linkedin.com/company/pocket-finance/'}
						title={'Linkedin'}
						target={'_blank'}>
						<ReactSVG src={invitation_linkedin} className={'linkedinImg socialImg'} />
					</a>
					<a
						href={'https://www.instagram.com/pocket.finance/'}
						title={'Instagram'}
						target={'_blank'}>
						<ReactSVG src={invitation_instagram} className={'instagramImg socialImg'} />
					</a>
					<a
						href={'https://twitter.com/pocketfinance'}
						title={'Twitter'}
						target={'_blank'}>
						<ReactSVG src={invitation_twitter} className={'twitterImg socialImg'} />
					</a>
				</div>
				<PFCopyright className="copyright" />
			</div>
		</div>
	);
};

export default Invitation;
