import {ecosystemService, SearchEcoAdvisorsType} from 'api/services';
import {actions, useAppDispatch} from 'store';

export const useEcoAdvisorsData = (params: SearchEcoAdvisorsType) => {
	const dispatch = useAppDispatch();

	return async (payload = params) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await ecosystemService.searchEcoAdvisors(params);
			dispatch(actions.setEcoAdvisorsData(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useEcoInviteAdvisorsData = params => {
	const dispatch = useAppDispatch();

	return async (payload = params) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await ecosystemService.searchAdvisors(params);
			dispatch(actions.setAdvisorsData(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};
