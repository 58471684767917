import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import {loaderIcon} from 'assets';

import './style.scss';

type PropsTypes = {
	className?: string;
};

const PFLoader: FC<PropsTypes> = ({className}) => {
	return (
		<div className={`pf-loader ${className || ''}`}>
			<ReactSVG src={loaderIcon} className="pf-loader icon" />
		</div>
	);
};

export default PFLoader;
