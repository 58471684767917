import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const getAdvisor = () => httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR.INDEX}`);

export const signUpAdvisor = body =>
	httpClient.post(`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}/sign-up`, body);

export const deleteAdvisor = token =>
	httpClient.delete(`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}/${token}`);

// export const restoreAdvisor = token =>
// 	httpClient.post(`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}/${token}/restore`); // ! new may 1

export const getCompanyAdvisors = params =>
	httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR.SEARCH}`, {params});

export const updateAdvisor = body => httpClient.put(`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}`, body);

export const updateAdvisorCompany = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}/setCompany`, body);

export const addNewAdvisor = body => httpClient.post(`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}`, body);

export const searchAdvisor = token =>
	httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}/${token}`);

export const getNoAuthorizedAdvisor = token =>
	httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}/noAuthorized?advisorToken=${token}`);

// TODO: sent to the server and received from the server as "Certificates". On the front side, the field is called "Credentials".
export const getCertificates = params =>
	httpClient.get(`${URL.BASE_URL}/${URL.CERTIFICATES.INDEX}`, {params});

export const getLocations = params =>
	httpClient.get(`${URL.BASE_URL}/${URL.LOCATIONS.INDEX}`, {params});

export const getHashtags = params =>
	httpClient.get(`${URL.BASE_URL}/${URL.HASHTAGS.INDEX}`, {params});

export const getAdvisorAnswers = token =>
	httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR.INDEX}/${token}/answers`);

export const getCompanyAdministrators = token =>
	httpClient.get(
		`${URL.BASE_URL}/${URL.ADVISOR.UPDATE}/${URL.ADVISOR.ADMINISTRATORS}?companyToken=${token}`,
	);
