import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {advisorClientsService, financialReportsService} from 'api/services';
import classNames from 'classnames';
import {PORTAL_PATHS} from 'const';
import moment from 'moment';
import {FinancialReportsType} from 'types/userProfile';
import {findByToken, useErrorHelper} from 'utils';
import {downloadFinancialReport, generateReportName} from 'utils/futureTS/financialReports';

import arrow from '../../../assets/buttons/icon-view-all.svg';
import reportIcon from '../../../assets/buttons/report-user-profile.svg';

import './style.scss';

type PropsTypes = {
	userToken: string;
	setReportsCount?: (number) => void;
	maxItems?: number | undefined;
};

const UserProfileReportsBloc: FC<PropsTypes> = ({userToken, maxItems, setReportsCount}) => {
	const navigate = useNavigate();
	const [financialReports, setFinancialReports] = useState<FinancialReportsType[] | null>(null);
	const errorHelper = useErrorHelper(navigate);

	const openInWindowFinancialReport = async (reportToken, token, isNew, reportItem) => {
		const downloadName = generateReportName(
			reportItem.firstName,
			reportItem.lastName,
			reportItem.createdAt,
		);

		try {
			await downloadFinancialReport(reportToken, downloadName);

			if (isNew) {
				await advisorClientsService.resetAdvisorClientStatuses(token);
				await financialReportsService.resetIsNewReport(reportToken);
				if (Array.isArray(financialReports)) {
					const foundFinancialReport = findByToken(financialReports, reportToken);
					if (foundFinancialReport) foundFinancialReport.isNew = false;
					setFinancialReports([...financialReports]);
				}
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const getFinancialReportsItems = (
		data: FinancialReportsType[],
		token: string,
	): JSX.Element[] | string => {
		if (data?.length !== 0) {
			const financialReportsItems = data.map(item => {
				const {isNew} = item;
				return (
					<button
						className="reportsBloc__report-wrapper"
						key={`report-${item.token}`}
						type="button"
						onClick={() => openInWindowFinancialReport(item.token, token, isNew, item)}>
						<ReactSVG
							wrapper="div"
							className={classNames('report-icon', {'new-report': isNew})}
							src={reportIcon}
						/>
						<div
							key={`${item.token}-date`}
							className={classNames('report-date', {'new-report': isNew})}>
							{moment.utc(item.createdAt).local().format('MMM DD, YY')}
						</div>
						<div key={`${item.token}-time`} className="report-time">
							{`${moment.utc(item.createdAt).local().format('LTS')} UTS`}
						</div>
					</button>
				);
			});
			if (maxItems) {
				return financialReportsItems.slice(0, maxItems);
			}
			return financialReportsItems;
		}
		return 'No reports available.';
	};

	const fetchReportsData = async (token: string): Promise<any> => {
		try {
			const response = await financialReportsService.getFinancialReportsByToken(token);
			setFinancialReports(response?.data?.body);
			setReportsCount?.(response?.data.count);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const goToViewAll = (): void => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.VIEW_ALL_REPORTS}?token=${userToken}`);
	};

	useEffect(() => {
		fetchReportsData(userToken);
	}, [userToken]);

	return (
		<div className="reportsBloc">
			{financialReports && getFinancialReportsItems(financialReports, userToken)}
			{maxItems && financialReports && financialReports?.length > maxItems && (
				<div className="reportsBloc__viewAllButton">
					<button onClick={goToViewAll} type="button">
						View All
						<ReactSVG wrapper="span" className="pf-arrowSvg" src={arrow} />
					</button>
				</div>
			)}
		</div>
	);
};

export default UserProfileReportsBloc;
