import React, {useState} from 'react';

import {advisorClientsService} from 'api/services';
import {PORTAL_PATHS} from 'const';
import {useAppSelector} from 'store';
import {myProfileSelectors} from 'store/selectors';
import {NotificationType} from 'types';
import {Amplitude, ToastDispatcher} from 'utils';

import PFLink from '../../../../../shared/components/PF-Link';
import PFNote from '../../../../../shared/components/PF-Note';

import styles from './ScheduleMeetingAction.module.scss';

const ScheduleMeetingAction: React.FC<{
	notification: NotificationType;
	closeNotificationCenter?: () => void;
}> = ({notification, closeNotificationCenter}) => {
	const calendlyLink = useAppSelector(myProfileSelectors.selectMyCalendlyLink);
	const [isSubmittingScheduleMeeting, setIsSubmittingScheduleMeeting] = useState<boolean>(false);

	const disabled = isSubmittingScheduleMeeting || !calendlyLink;

	const handleScheduleClick = async () => {
		if (notification.userTokenFrom) {
			try {
				setIsSubmittingScheduleMeeting(true);
				await advisorClientsService.sendScheduleMeeting([notification.userTokenFrom]);

				Amplitude.track('Scheduled_Meeting', {
					source: 'Notifications',
					clients: [notification.userTokenFrom],
				});

				ToastDispatcher.success(
					'The client will receive your calendar link to suggest the meeting date and time.',
				);
			} catch (e) {
				ToastDispatcher.error(
					`Failed to schedule a meeting. Please check your calendar link.`,
				);
			} finally {
				setIsSubmittingScheduleMeeting(false);
			}
		}
	};

	return (
		<>
			<button
				type="button"
				disabled={disabled}
				className={`${styles.button} ${notification.isNew ? styles.buttonNew : ''} ${
					disabled ? styles.buttonDisabled : ''
				}`}
				onClick={handleScheduleClick}>
				Send Calendar Link
			</button>
			{!calendlyLink && (
				<PFNote className={styles.note}>
					<div>
						<PFLink
							to={PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT}
							onClick={closeNotificationCenter}>
							Add your calendar link
						</PFLink>{' '}
						to easily schedule appointments for your clients by creating calendar
						events.
					</div>
				</PFNote>
			)}
		</>
	);
};

export default ScheduleMeetingAction;
