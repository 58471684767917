import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AdvisorType} from 'types';

interface EcoAdvisorsState {
	ecoAdvisors: AdvisorType[];
	fullCount: number;
}
type SetEcoAdvisorsDataActionType = {
	body: any[];
	fullCount: number;
};

type RemoveEcoAdvisorsItemActionType = {
	tokens: string[];
};

const initialState: EcoAdvisorsState = {
	ecoAdvisors: [],
	fullCount: 0,
};

export const ecosystemAdvisorsSlice = createSlice({
	name: 'ecosystemAdvisors',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setEcoAdvisorsData: (state, action: PayloadAction<SetEcoAdvisorsDataActionType>) => {
			state.ecoAdvisors = action.payload.body;
			state.fullCount = action.payload.fullCount;
		},
		cleanUpEcoAdvisorsData: state => {
			state.ecoAdvisors = initialState.ecoAdvisors;
			state.fullCount = initialState.fullCount;
		},
		removeEcoAdvisorsItem: (state, action: PayloadAction<RemoveEcoAdvisorsItemActionType>) => {
			state.ecoAdvisors = state.ecoAdvisors.filter(
				advisor => !action.payload.tokens.includes(advisor.token),
			);
		},
	},
});

export default ecosystemAdvisorsSlice.reducer;
