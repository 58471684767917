import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

type SetCustomTagsBodyType = {
	userToken: string;
	tags: string[];
};

type SearchAdvisorClientTagsParamsType = {
	searchText: string;
	reverse?: boolean;
	predicate?: string;
	skip?: number;
	take?: number;
};

export const getAdvisorClients = params => {
	return httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/search`, {
		params,
	});
};

// export const getConsumerTeam = token => {
// 	return httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/${token}`);
// };

export const getUserProfile = params => {
	return httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/Profile`, {
		params,
	});
};

export const resetAdvisorClientStatuses = token => {
	return httpClient.post(`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.STATUSES}/reset/${token}`);
};

export const getUsersTeam = userToken => {
	return httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/userTeam/${userToken}`);
};

export const setNote = body =>
	httpClient.put(`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/setNote`, body);

export const resetLastViewedCurrentItem = (token: string) =>
	httpClient.put(
		`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/resetLastViewed?userToken=${token}`,
	);

export const setCustomClientsTags = (body: SetCustomTagsBodyType) =>
	httpClient.put(`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/setCustomTags`, body);

export const searchAdvisorClientTags = (params: SearchAdvisorClientTagsParamsType) => {
	return httpClient.get(`${URL.BASE_URL}/${URL.CLIENT_TAGS.SEARCH}`, {
		params,
	});
};

export const sendScheduleMeeting = (body: string[]) => {
	return httpClient.post(`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/scheduleMeeting`, body);
};

export const removeClientsFromTeam = (body: {ClientTokens: string[]}) => {
	return httpClient.delete(
		`${URL.BASE_URL}/${URL.ADVISOR_CLIENTS.INDEX}/${URL.ADVISOR_CLIENTS.REMOVE}`,
		{data: body},
	);
};
