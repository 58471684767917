import React, {useEffect} from 'react';

import {advisorClientsService} from 'api/services';
import ClientTagsComponent from 'components/ClientTagsComponent';
import moment from 'moment';
import PFContactsBlock from 'shared/components/PF-ContactsBlock';
import PFUserDescription from 'shared/components/PF-UserDescription';
import {useAppSelector} from 'store';
import {tableSelectors, uiSettingsSelector} from 'store/selectors';
import {CurrentUser} from 'types';
import {getFullName, ToastDispatcher} from 'utils';

import AdditionalCurrentInfo from './AdditionalCurrentInfo';

import './style.scss';

const CurrentModal = () => {
	const rowData: CurrentUser = useAppSelector(tableSelectors.selectTableModalData);
	const isModalOpen = useAppSelector(uiSettingsSelector.GetIsSideModalOpen);

	const setLastViewed = async token => {
		try {
			await advisorClientsService.resetLastViewedCurrentItem(token);
		} catch (e) {
			ToastDispatcher.error('Reset "last viewed" error');
		}
	};

	useEffect(() => {
		if (isModalOpen && rowData?.token) {
			setLastViewed(rowData.token);
		}
	}, [isModalOpen]);

	return (
		<div className="current-modal">
			<PFUserDescription
				name={getFullName(rowData?.firstName, rowData?.lastName)}
				avatarPhoto={rowData?.profileImage}>
				Last viewed:{' '}
				{rowData?.lastViewed ? moment.utc(rowData?.lastViewed).local().fromNow() : 'Never'}
			</PFUserDescription>
			{rowData && (
				<ClientTagsComponent
					clientToken={rowData?.token}
					clientTags={rowData?.customClientTags}
				/>
			)}
			<PFContactsBlock
				phone={rowData?.phoneNumber}
				email={rowData?.email}
				location={{name: rowData?.city, state: {name: rowData?.province}}}
			/>
			<AdditionalCurrentInfo
				token={rowData.token}
				connectedAccountsCount={rowData.connectedAccountsCount}
			/>
		</div>
	);
};

export default CurrentModal;
