import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {lifeInsurance} from 'assets';
import {PORTAL_PATHS} from 'const';
import {useDeleteInsurance, useDeleteInsurancePopup} from 'shared/hooks';
import {LifeInsuranceType} from 'types';
import {
	InsuranceDurationEnum,
	InsuranceFrequencyOfPaymentEnum,
	InsurancePayerEnum,
	InsurancePolicyPayerEnum,
	InsuranceTypeEnum,
} from 'types/enums';
import {
	Amplitude,
	formatNumberThousandsWithDelimiter,
	getAmountCommasValue,
	useErrorHelper,
} from 'utils';

import {
	PAYMENT_FREQUENCY_OPTIONS,
	POLICY_PAYER_OPTIONS,
	RIDER_OPTIONS,
	TYPE_BY_DURATION_OPTIONS,
} from '../Forms/constants';

import {BeneficiariesInfoItem, InsuranceInfoHeader, InsurancePropertyItem} from './components';
import {getCompanyName} from './utils';

import './styles.scss';

type Props = {
	insurance: LifeInsuranceType;
	isCompact?: boolean;
	containerClassName?: string;
	onRefreshInsurances?: () => Promise<void>;
};

const name = 'Life Insurance';

const LifeInsuranceItem: React.FC<Props> = ({
	insurance,
	isCompact,
	containerClassName,
	onRefreshInsurances,
}) => {
	const navigate = useNavigate();
	const deleteInsurance = useDeleteInsurance();
	const {showConfirm, ConfirmModal} = useDeleteInsurancePopup();
	const errorHelper = useErrorHelper(navigate);

	const onDeleteLifeInsurance = async () => {
		// eslint-disable-next-line no-useless-catch
		try {
			if (insurance.token) {
				await deleteInsurance(
					{token: insurance.token, type: InsuranceTypeEnum.LifeInsurance},
					name,
				);

				Amplitude.track('Deleted_Insurance', {
					type: 'Life Insurance',
				});

				if (onRefreshInsurances) {
					onRefreshInsurances();
				}
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const actions = isCompact
		? undefined
		: [
				{
					name: 'Edit',
					handler: () => {
						navigate(
							`${PORTAL_PATHS.OTHER_PATHS.EDIT_LIFE_INSURANCE}#${insurance.userToken}&${insurance.token}`,
						);
					},
				},
				{
					name: 'Delete',
					handler: showConfirm,
				},
		  ];

	const company = getCompanyName(
		insurance.insuranceCompany,
		insurance.customInsuranceCompanyName,
	);

	const date = insurance.policyRenewalDate ? new Date(insurance.policyRenewalDate) : null;

	return (
		<>
			<div className={containerClassName}>
				<InsuranceInfoHeader
					title={name}
					icon={<ReactSVG src={lifeInsurance} />}
					company={company}
					amount={insurance.amountOfCoverage}
					date={
						insurance.typeByDuration === InsuranceDurationEnum.Permanent
							? 'Permanent'
							: date
					}
					actions={actions}
				/>
				{!isCompact && (
					<div className="insurance-properties--container">
						<div className="insurance-properties--group">
							{!!insurance.insurancePayer && (
								<InsurancePropertyItem
									label="Purchased"
									value={
										insurance.insurancePayer
											? (insurance.insurancePayer ===
													InsurancePayerEnum.Employer &&
													'Through employer') ||
											  (insurance.insurancePayer ===
													InsurancePayerEnum.Independent &&
													'Independently')
											: 'n/a'
									}
								/>
							)}
							{insurance.typeByDuration && (
								<InsurancePropertyItem
									label="Type by duration"
									value={
										TYPE_BY_DURATION_OPTIONS.find(
											item =>
												Number(item.value) === insurance?.typeByDuration,
										)?.label
									}
								/>
							)}
							{insurance.typeByDuration &&
								insurance.typeByDuration === InsuranceDurationEnum.Permanent && (
									<>
										{insurance.isCashInThePolicy !== null && (
											<InsurancePropertyItem
												label="Cash"
												value={
													insurance?.isCashInThePolicy
														? (insurance?.amountCashInThePolicy &&
																getAmountCommasValue(
																	insurance?.amountCashInThePolicy,
																)) ||
														  'n/a'
														: 'No'
												}
											/>
										)}
										{insurance.isLoanAgainstPolicy !== null && (
											<InsurancePropertyItem
												label="Loan against the policy"
												value={
													insurance.isLoanAgainstPolicy
														? (insurance.amountLoanAgainstPolicy &&
																getAmountCommasValue(
																	insurance.amountLoanAgainstPolicy,
																)) ||
														  'n/a'
														: 'No'
												}
											/>
										)}
									</>
								)}
							<InsurancePropertyItem
								label="Policy number"
								value={insurance.policyNumber || 'n/a'}
							/>
							<InsurancePropertyItem
								label="Who is this policy for?"
								value={insurance.insured || 'n/a'}
							/>
							<InsurancePropertyItem
								label="What is the purpose of this plan?"
								value={insurance.purposeOfPlan || 'n/a'}
							/>
						</div>
						<div className="insurance-properties--group">
							<BeneficiariesInfoItem
								beneficiaries={
									insurance.isExistsBeneficiary ? insurance.beneficiaries : null
								}
							/>
							<InsurancePropertyItem
								label="Trustee"
								value={insurance.trusteeName || 'n/a'}
							/>
						</div>
						<div className="insurance-properties--group">
							<InsurancePropertyItem
								label="Owner"
								value={
									insurance.policyPayer === InsurancePolicyPayerEnum.SomeoneElse
										? insurance.customPolicyPayer || 'n/a'
										: POLICY_PAYER_OPTIONS.find(
												item =>
													Number(item.value) === insurance.policyPayer,
										  )?.label
								}
							/>
							<InsurancePropertyItem
								label="Payment frequency"
								value={
									PAYMENT_FREQUENCY_OPTIONS.find(
										item => Number(item.value) === insurance.paymentFrequency,
									)?.label || 'n/a'
								}
							/>
						</div>
						<div className="insurance-properties--group">
							{!(
								insurance.paymentFrequency ===
									InsuranceFrequencyOfPaymentEnum.NoLongerPayingIntoThisPolicy ||
								insurance.paymentFrequency ===
									InsuranceFrequencyOfPaymentEnum.ThisIsGroupCoverage
							) && (
								<InsurancePropertyItem
									label="How much do you pay?"
									value={
										(insurance.amountOfPayment &&
											getAmountCommasValue(insurance.amountOfPayment)) ||
										'n/a'
									}
								/>
							)}
							<InsurancePropertyItem
								label="Riders"
								value={
									Array.isArray(insurance.riders) && !!insurance.riders.length
										? ''
										: 'None'
								}
							/>
							{Array.isArray(insurance.riders) &&
								!!insurance.riders.length &&
								insurance.riders.map(rider => {
									return (
										<InsurancePropertyItem
											key={rider.rider}
											label={
												RIDER_OPTIONS.find(
													o => o.value === String(rider.rider),
												)?.label || ''
											}
											value={
												rider.coverageAmount === null
													? ''
													: formatNumberThousandsWithDelimiter(
															rider.coverageAmount,
															0,
													  )
											}
											compact
										/>
									);
								})}
						</div>
					</div>
				)}
			</div>
			<ConfirmModal name={name} onSubmit={onDeleteLifeInsurance} className="" />
		</>
	);
};

export default LifeInsuranceItem;
