import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PFContactsBlock from '../../shared/components/PF-ContactsBlock';
import PFUserDescription from '../../shared/components/PF-UserDescription';
import AdditionalCompanyProfileInfo from './AdditionalCompanyProfileInfo';
import {useAdvisorInfo} from '../../shared/hooks/users';
import {getFullName} from '../../utils';
import PFisActiveButton from '../../shared/components/PF-ActiveInactiveButton';

import './style.scss';

const CompanyProfileModal = ({updateDataForUseOnModal, tableData}) => {
	// TODO: sent to the server and received from the server as "Certificates". On the front side, the field is called "Credentials".
	const [certificates, setCertificates] = useState([]);
	const rowData = useSelector(state => state?.table?.modalData);
	const advisorToken = rowData?.advisor?.token;
	const userName = getFullName(rowData?.firstName, rowData?.lastName);
	const fetchAdvisorInfo = useAdvisorInfo();

	const fetchData = async () => {
		try {
			const response = await fetchAdvisorInfo(advisorToken);
			setCertificates(response?.financialCertificates);
		} catch (e) {
			throw e;
		}
	};

	useEffect(() => {
		if (advisorToken) fetchData();
	}, [rowData]);

	return (
		<div className={'pf-companyProfileModal'}>
			<PFUserDescription name={userName} avatarPhoto={rowData?.advisor?.profileImage}>
				<PFisActiveButton
					isActive={rowData?.advisor?.isActive}
					userToken={rowData?.token}
					advisorToken={rowData?.advisor?.token}
					name={userName}
					reasonInactive={rowData?.advisor?.reasonInactive}
					tableData={tableData}
					onUpdateData={updateDataForUseOnModal}
				/>
				<div className={'descriptionInfo'}>
					{/* TODO: sent to the server and received from the server as "Certificates". On the front side, the field is called "Credentials". */}
					{certificates?.map(item => item.name).join(', ')}
				</div>
			</PFUserDescription>
			<PFContactsBlock
				companyName={rowData?.advisor?.companyName}
				companyImage={rowData?.advisor?.companyImage}
				phone={rowData?.advisor?.phoneNumber}
				email={rowData?.advisor?.contactEmail || rowData?.advisor?.email}
				location={rowData?.advisor?.location}
				isOnMobile={true}
				isEmailConfirmed={rowData?.isEmailConfirmed}
			/>
			<AdditionalCompanyProfileInfo
				token={rowData?.token}
				email={rowData?.email}
				advisorToken={advisorToken}
				isBlocked={rowData?.isBlocked}
				name={userName}
				role={rowData?.roles}
				/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
				specializations={rowData?.advisor?.advisorSpecializationTypes}
				updateDataForUseOnModal={updateDataForUseOnModal}
				tableData={tableData}
			/>
		</div>
	);
};

export default CompanyProfileModal;
