import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface RecommendPopupState {
	checkedKeys: string[];
}
type SetCheckedKeyActionType = string | 0;

type RemoveCheckedKeyActionType = string;

const initialState: RecommendPopupState = {
	checkedKeys: [],
};

export const recommendPopupSlice = createSlice({
	name: 'recommendPopup',
	initialState,
	reducers: {
		setRecommendCheckedKey: (state, action: PayloadAction<SetCheckedKeyActionType>) => {
			if (action.payload === 0) {
				state.checkedKeys = [];
			} else {
				state.checkedKeys = [...state.checkedKeys, action.payload];
			}
		},
		removeRecommendCheckedKey: (state, action: PayloadAction<RemoveCheckedKeyActionType>) => {
			state.checkedKeys = state.checkedKeys.filter(type => type !== action.payload);
		},
	},
});

export default recommendPopupSlice.reducer;
