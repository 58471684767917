import React, {FC} from 'react';

import classNames from 'classnames';

import './style.scss';

type PropsTypes = {
	className?: string;
};

const PFCopyright: FC<PropsTypes> = ({className}) => {
	const date = new Date();
	const year = date.getFullYear();
	return <p className={classNames('pf-copyright', className)}>Pocket Finance &copy; {year}</p>;
};

export default PFCopyright;
