import React, {useEffect, useState} from 'react';
import {ReactSVG} from 'react-svg';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {setCurrentPageTitle} from '../../../../store/actions';
import {joinName, renderLocation, ToastDispatcher, useErrorHelper} from '../../../../utils';
import PORTAL_PATHS from '../../../../const/url-paths/portal';
import urlPaths from '../../../../api/url-paths';
import iconBack from '../../../../assets/icon-arrow-back.svg';
import iconEdit from '../../../../assets/buttons/icon-pencil.svg';
import iconLocation from '../../../../assets/icon-location.svg';
import iconEmail from '../../../../assets/icon-email.svg';
import iconPhone from '../../../../assets/icon-phone-without-circle.svg';
import iconCompany from '../../../../assets/icon-company.svg';
import dot from '../../../../assets/icon-dots.svg';
import iconChangeCompany from '../../../../assets/icon-changeCompany.svg';
import iconDelete from '../../../../assets/icon-delete.svg';
import iconUnblock from '../../../../assets/icon-unblock.svg';
import iconBlock from '../../../../assets/icon-block.svg';
import resendEmailIcon from '../../../../assets/resend-email-icon.svg';
import {deletedIconForRestorePage} from '../../../../assets';
import {
	PFCopyright,
	PFNote,
	PFAvatar,
	PFButton,
	PFLink,
	PFisActiveButton,
} from '../../../../shared/components';
import {
	useAdvisorInfo,
	useBlockedUser,
	useDeletedUser,
	useDeletedUserPopup,
	useRestoreUser,
} from '../../../../shared/hooks/users';
import {useChangeCompanyPopup, useIsSuspendUserPopup} from '../../../../shared/hooks/companies';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import * as advisorService from '../../../../api/services/advisor';
import {DEFAULT_COMPANY_AVATAR, PATHS} from '../../../../const';
import {
	DeletedPage,
	AdvisorProfileGeneralInfoBloc,
	AdvisorProfileSocialInfoBloc,
	AdvisorProfileDescription,
} from '../../../../components';
import {getCamelCaseToText, getFullName, isCompletedProfile} from '../../../../utils';
import {myProfileSelectors, selectIsAuth} from '../../../../store/selectors';
import {useAppSelector} from 'store';
import './style.scss';
import {AdvisorsRole} from '../../../../types/enums';
import {usersService} from 'api/services';

const AdvisorProfile = () => {
	const accountRole = useSelector(myProfileSelectors.selectMyRole);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tokens = window.location.hash.slice(1).split('&');
	const advisorToken = tokens[0];
	const userToken = tokens[1];
	const [selectedAdvisor, setSelectedAdvisor] = useState(null);
	const deleteUser = useDeletedUser();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const fetchAdvisorInfo = useAdvisorInfo();
	const {showChangeCompanyConfirm, ConfirmChangeCompanyModal} = useChangeCompanyPopup();
	const {showDeletedUserConfirm, ConfirmDeleteUserModal} = useDeletedUserPopup();
	const fullName = getFullName(selectedAdvisor?.firstName, selectedAdvisor?.lastName);
	const [isDeletedAdvisor, setIsDeletedAdvisor] = useState(false);
	const restoreUser = useRestoreUser();
	const {showIsSuspendUserConfirm, ConfirmIsSuspendUserModal} = useIsSuspendUserPopup();
	const suspendUser = useBlockedUser();
	const [isShowCompleteProfileNote, setIsShowCompleteProfileNote] = useState(false);
	const errorHelper = useErrorHelper(navigate);
	const auth = useAppSelector(selectIsAuth);

	const goBack = () => {
		if (accountRole === AdvisorsRole.SuperAdmin) {
			navigate(PORTAL_PATHS.SIDEBAR.ADVISORS);
		} else {
			navigate(PORTAL_PATHS.SIDEBAR.MY_COMPANY);
		}
	};

	const fetchRestoreUser = async token => {
		try {
			await restoreUser(token);
			setIsDeletedAdvisor(false);
		} catch (e) {
			throw e;
		}
	};

	const onUpdateSelectedAdvisorData = (active, reason) => {
		setSelectedAdvisor({...selectedAdvisor, isActive: active, reasonInactive: reason});
	};

	const onIsSuspend = async (isBlocked, token) => {
		const update = () => {
			setSelectedAdvisor({...selectedAdvisor, isBlocked});
		};

		const body = {
			token,
			isBlocked,
		};

		try {
			setIsSubmitting(true);
			await suspendUser(body, update, fullName);
		} catch (e) {
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const showIsSuspendUserConfirmModal = () => {
		if (selectedAdvisor?.isBlocked) {
			onIsSuspend(false, userToken);
		} else {
			showIsSuspendUserConfirm();
		}
	};

	//! Если эту страницу открывает из тоста, то нет userToken, надо что-то придумать
	const onDeleteUser = async () => {
		const update = () => {
			setSelectedAdvisor({
				...selectedAdvisor,
				isBlocked: !selectedAdvisor?.isBlocked,
			});
		};
		try {
			setIsSubmitting(true);
			await deleteUser(userToken, update, fullName);
			goBack();
			//! setIsDeletedAdvisor(true); показывает экран с рестором, но у нас пока нет рестора
		} catch (e) {
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const getCompleteProfileNote = accountRole => {
		if (accountRole === AdvisorsRole.SuperAdmin) {
			return (
				<PFNote className={'mb-4'}>
					<div>
						Mobile app users can’t see this user in the marketplace until they{' '}
						<PFLink to={PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT}>
							complete their profile.
						</PFLink>
					</div>
				</PFNote>
			);
		}
		if (
			accountRole === AdvisorsRole.AccountAdministrator ||
			accountRole === AdvisorsRole.Administrator
		) {
			return (
				<PFNote className={'mb-4'}>
					<div>
						Mobile app users can’t see this advisor in the marketplace until they
						complete their profile.
					</div>
				</PFNote>
			);
		}
	};

	const emptyFieldMessage =
		accountRole === AdvisorsRole.SuperAdmin ? (
			<div className="pf-advisorProfile__emptyMessage">
				This section is empty.{' '}
				<PFLink to={`${PORTAL_PATHS.OTHER_PATHS.ADVISOR_PROFILE_EDIT}#${advisorToken}`}>
					Edit profile
				</PFLink>{' '}
				to complete it.
			</div>
		) : (
			<div className="pf-advisorProfile__emptyMessage">This section is empty.</div>
		);

	const goToProfileEdit = () => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.ADVISOR_PROFILE_EDIT}#${advisorToken}`);
	};

	//wrapped=span is used here because otherwise the default is a div that's causing
	//the arrow and the 'back' text to appear in separate lines.
	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;
	const editIcon = <ReactSVG wrapper="span" className="me-2" src={iconEdit} />;
	const companyIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconCompany} />
	);
	const phoneIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconPhone} />
	);
	const locationIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconLocation} />
	);
	const mailIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconEmail} />
	);

	const fetchChangeUsersCompany = async (advisorUserTokens, companyToken, companyName) => {
		const toastMessage = fullName ? `Change company for ${fullName}` : 'Change company';
		try {
			await advisorService
				.updateAdvisorCompany({userTokens: advisorUserTokens, companyToken})
				.then(response => {
					ToastDispatcher.success(toastMessage);
					setSelectedAdvisor({...selectedAdvisor, companyName: companyName});
				})
				.catch(err => {
					ToastDispatcher.error(`${err.message}`);
				});
		} catch (err) {
			throw err?.response?.data;
		}
	};

	const deletePageProps = {
		className: 'delete-advisor',
		srcImage: deletedIconForRestorePage,
		title: 'User Deleted',
		subtitle: `You have just deleted ${fullName} profile. If you leave this page, it will be impossible to restore this profile.`,
		buttonVariant: 'primary',
		goBackCallback: goBack,
		restoreCallback: () => fetchRestoreUser(userToken),
	};

	const getAdvisorInfo = async () => {
		try {
			const data = await fetchAdvisorInfo(advisorToken);
			setSelectedAdvisor(data);
		} catch (e) {
			navigate(PATHS.COMMON.ERROR);
			throw e;
		}
	};

	const sendEmailCode = async () => {
		const email = selectedAdvisor?.email;

		if (email) {
			try {
				setIsSubmitting(true);

				const params = {
					username: email,
					isFromPortal: true,
				};

				await usersService.sendEmailCode(params);

				ToastDispatcher.success('Email sent');
			} catch (err) {
				const msg = err?.response?.data?.message;
				errorHelper(err, false, msg);
				throw err;
			} finally {
				setIsSubmitting(false);
			}
		}
	};

	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	useEffect(() => {
		if (!isCompletedProfile(selectedAdvisor)) {
			setIsShowCompleteProfileNote(false);
		} else {
			setIsShowCompleteProfileNote(true);
		}
	}, [selectedAdvisor]);

	useEffect(() => {
		getAdvisorInfo();
	}, []);

	useEffect(() => {
		if (accountRole === AdvisorsRole.SuperAdmin) {
			dispatch(setCurrentPageTitle('Advisors'));
		} else {
			dispatch(setCurrentPageTitle('My Company'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!isDeletedAdvisor && selectedAdvisor && selectedAdvisor?.length !== 0 && (
				<div className="pf-advisorProfile">
					<div className={'grid-wrapper'}>
						<div className="header">
							<PFButton
								className="goBack-button"
								prefixIcon={backIcon}
								variant="plain"
								onClick={goBack}
								type={'button'}>
								Back
							</PFButton>
						</div>
						<div className={'contact-info'}>
							<div className="contact-info__avatar-wrapper">
								<PFAvatar
									className="contact-info__avatar"
									src={
										selectedAdvisor?.profileImage &&
										`${urlPaths.BASE_IMAGES_URL}${selectedAdvisor?.profileImage}`
									}
									size={90}
								/>
								<div className="contact-info__name-wrapper">
									<h3 className="contact-info__name">
										{joinName(
											selectedAdvisor?.firstName,
											selectedAdvisor?.lastName,
										)}
									</h3>
									<PFisActiveButton
										isActive={selectedAdvisor.isActive}
										advisorToken={selectedAdvisor.token}
										name={fullName}
										reasonInactive={selectedAdvisor.reasonInactive}
										onUpdateSelectedAdvisorData={onUpdateSelectedAdvisorData}
									/>
									<span className="pl-3 contact-info__certificate">
										{selectedAdvisor?.financialCertificates
											?.map(item => item.name)
											.join(', ')}
									</span>
								</div>
							</div>
							{!isShowCompleteProfileNote && getCompleteProfileNote(accountRole)}
							{accountRole === AdvisorsRole.SuperAdmin && (
								<PFButton
									className="w-auto h-auto contact-info__edit-button"
									prefixIcon={editIcon}
									variant="plain"
									onClick={goToProfileEdit}
									type={'button'}>
									Edit User Profile
								</PFButton>
							)}

							<div className="contact-info__contact">
								{selectedAdvisor?.companyName && (
									<div className="contact-info__info companyName">
										{selectedAdvisor?.companyImage ? (
											<PFAvatar
												src={`${urlPaths.BASE_IMAGES_URL}${selectedAdvisor?.companyImage}`}
												size={20}
												defaultAvatar={DEFAULT_COMPANY_AVATAR}
												variant="rounded-square"
												className="companyLogo"
											/>
										) : (
											companyIcon
										)}
										{selectedAdvisor?.companyName}
									</div>
								)}
								{selectedAdvisor?.location && (
									<p className="contact-info__info">
										{locationIcon}
										{renderLocation(selectedAdvisor?.location)}
									</p>
								)}
								{(selectedAdvisor?.contactEmail || selectedAdvisor?.email) && (
									<div className="contact-info__info email">
										<div className="emailWrapper">
											{mailIcon}
											<PFLink
												href={`mailto:${
													selectedAdvisor?.contactEmail ||
													selectedAdvisor?.email
												}`}>
												{selectedAdvisor?.contactEmail ||
													selectedAdvisor?.email}
											</PFLink>
										</div>
										{accountRole === AdvisorsRole.SuperAdmin &&
											typeof selectedAdvisor?.isEmailConfirmed ===
												'boolean' &&
											!selectedAdvisor?.isEmailConfirmed && (
												<p className="unconfirmedEmail">Unconfirmed</p>
											)}
									</div>
								)}
								{selectedAdvisor?.phoneNumber && (
									<p className="contact-info__info">
										{phoneIcon}
										{selectedAdvisor?.phoneNumber}
									</p>
								)}
							</div>
							<div className="pf-myProfile__buttons">
								<DropdownButton
									className={'dropdownButton'}
									title={<ReactSVG wrapper="div" src={dot} />}
									id="bg-nested-dropdown">
									{accountRole === AdvisorsRole.SuperAdmin && (
										<Dropdown.Item
											disabled={isSubmitting}
											onClick={sendEmailCode}
											eventKey="1">
											<div className={'dropdownSendItemTextChange'}>
												<ReactSVG wrapper="div" src={resendEmailIcon} />
												<span>Send Password Reset Email</span>
											</div>
										</Dropdown.Item>
									)}
									{accountRole === AdvisorsRole.SuperAdmin && (
										<Dropdown.Item
											disabled={isSubmitting}
											onClick={showChangeCompanyConfirm}
											eventKey="1">
											<div className={'dropdownItemTextChange'}>
												<ReactSVG wrapper="div" src={iconChangeCompany} />
												<span>Change Company</span>
											</div>
										</Dropdown.Item>
									)}
									<Dropdown.Item
										disabled={isSubmitting}
										onClick={showIsSuspendUserConfirmModal}
										eventKey="1">
										<div className={'dropdownItemText__block'}>
											<ReactSVG
												wrapper="div"
												src={
													selectedAdvisor?.isBlocked
														? iconUnblock
														: iconBlock
												}
											/>
											<span>
												{selectedAdvisor?.isBlocked
													? 'Reactivate'
													: 'Suspend'}
											</span>
										</div>
									</Dropdown.Item>
									{accountRole === AdvisorsRole.SuperAdmin && <hr />}
									{accountRole === AdvisorsRole.SuperAdmin && (
										<Dropdown.Item
											disabled={isSubmitting}
											onClick={showDeletedUserConfirm}
											eventKey="1">
											<div className={'dropdownItemText'}>
												<ReactSVG wrapper="div" src={iconDelete} />
												<span>Delete</span>
											</div>
										</Dropdown.Item>
									)}
								</DropdownButton>
							</div>
							{selectedAdvisor?.role && (
								<AdvisorProfileDescription
									label={'Role'}
									description={
										<p className="contact-info__role">
											{getCamelCaseToText(selectedAdvisor?.role)}
										</p>
									}
								/>
							)}
						</div>

						<div className={'general-info'}>
							<AdvisorProfileGeneralInfoBloc
								videoPresentationLink={selectedAdvisor?.videoPresentationLink}
								description={selectedAdvisor?.description}
								emptyFieldMessage={emptyFieldMessage}
								advisorSpecializationTypes={
									selectedAdvisor?.advisorSpecializationTypes
								}
								hashtags={selectedAdvisor?.hashtags}
								auth={auth}
							/>
						</div>

						<div className={'social-info'}>
							<AdvisorProfileSocialInfoBloc
								emptyFieldMessage={emptyFieldMessage}
								linkedin={selectedAdvisor?.linkedin}
								facebook={selectedAdvisor?.facebook}
								instagram={selectedAdvisor?.instagram}
								youtube={selectedAdvisor?.youtube}
								twitter={selectedAdvisor?.twitter}
							/>
						</div>
					</div>
					<PFCopyright />
				</div>
			)}
			{isDeletedAdvisor && <DeletedPage {...deletePageProps} />}
			<ConfirmChangeCompanyModal
				className={'pf-confirmChangeCompany'}
				name={fullName}
				onSubmit={fetchChangeUsersCompany}
				checkedKeys={[userToken]}
			/>
			<ConfirmDeleteUserModal
				className={'pf-confirmDeleteModal'}
				onSubmit={onDeleteUser}
				name={fullName}
			/>
			<ConfirmIsSuspendUserModal
				onSubmit={() => onIsSuspend(!selectedAdvisor?.isBlocked, userToken)}
				className={'pf-confirmIsActiveUserModal'}
				name={fullName}
			/>
		</>
	);
};

export default AdvisorProfile;
