import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import logo from 'assets/icon-logo.svg';
import classnames from 'classnames';
import PORTAL_PATHS from 'const/url-paths/portal';

import './style.scss';

type PropsTypes = {
	to: string;
	settingClasses?: any;
};

const PFLogoLink: FC<PropsTypes> = ({to, settingClasses}) => {
	const logoIcon = <ReactSVG src={logo} className="logo__img" />;

	return (
		<Link to={PORTAL_PATHS.PORTAL_BASE_URL} className={classnames('logo', settingClasses)}>
			<span>{logoIcon}</span>
		</Link>
	);
};

export default PFLogoLink;
