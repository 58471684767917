import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ReactSVG} from 'react-svg';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import iconBlock from '../../../assets/icon-block.svg';
import iconUnblock from '../../../assets/icon-unblock.svg';
import dot from '../../../assets/icon-dots.svg';
import iconDelete from '../../../assets/icon-delete.svg';
import resendEmailIcon from '../../../assets/resend-email-icon.svg';
import {
	useBlockedUser,
	useBlockedUserPopup,
	useDeletedUser,
	useDeletedUserPopup,
} from '../../../shared/hooks/users';
import {ToastDispatcher, findByToken, useErrorHelper} from '../../../utils';
import s from './style.module.scss';
import './style.scss';
import {setSideModal} from '../../../store/actions';
import UserProfileTeamBloc from '../../UserProfile/UserProfileTeamBloc';
import {useNavigate} from 'react-router-dom';
import {usersService} from 'api/services';
import {DynamicLinkService, createShortLink} from 'api/dynamicLinkService';

const AdditionalConsumersInfo = ({token, email, isBlocked, name, updateDataForUseOnModal}) => {
	const users = useSelector(state => state.consumers.consumers);
	const blockUser = useBlockedUser();
	const deleteUser = useDeletedUser();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const {showBlockedUserConfirm, ConfirmBlockedUserModal} = useBlockedUserPopup();
	const {showDeletedUserConfirm, ConfirmDeleteUserModal} = useDeletedUserPopup();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);

	const updateData = () => {
		const foundBlockedUser = findByToken(users, token);
		if (foundBlockedUser) foundBlockedUser.isBlocked = !isBlocked;
		updateDataForUseOnModal();
	};

	const handleBlocked = async () => {
		try {
			const body = {
				token,
				isBlocked: !isBlocked,
			};
			setIsSubmitting(true);
			await blockUser(body, updateData, name);
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const deleteUpdateCallback = () => {
		const foundDeletedUser = findByToken(users, token);
		if (foundDeletedUser) foundDeletedUser.isDeleted = true;
		dispatch(setSideModal(false));
		updateDataForUseOnModal();
	};

	const onDeleteUser = async () => {
		try {
			setIsSubmitting(true);
			await deleteUser(token, deleteUpdateCallback, name);
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const showConfirmModal = () => {
		if (isBlocked) {
			handleBlocked();
		} else {
			showBlockedUserConfirm();
		}
	};

	const sendEmailCode = async () => {
		if (email) {
			try {
				setIsSubmitting(true);

				const deepLink = `${process.env.REACT_APP_PF_DEEP_LINK_BASE}/set-password-by-code?e=${email}`;

				const longLink = new DynamicLinkService()
					.addIosBundleId()
					.addAndroidPackageName()
					.addIosFallbackUrl('https://app.pocketfinance.com/invitation')
					.addAndroidFallbackUrl('https://app.pocketfinance.com/invitation')
					.addOtherFallbackUrl('https://app.pocketfinance.com/invitation')
					.addDeepLinkValue(deepLink)
					.getLongLink();

				const shortDeepLink = await createShortLink(longLink);

				await usersService.sendEmailCode({
					username: email,
					isFromPortal: false,
					deepLink: shortDeepLink,
				});

				ToastDispatcher.success('Email sent');
			} catch (err) {
				const msg = err?.response?.data?.message;
				errorHelper(err, false, msg);
				throw err;
			} finally {
				setIsSubmitting(false);
			}
		}
	};

	return (
		<div className={`${s.pfAdditionalInfo} pfAdditionalInfo`}>
			<div className={`${s.btnContainer} btnContainer`}>
				<button
					disabled={isSubmitting}
					className={`${s.consumerInfoBtn}`}
					onClick={() => showConfirmModal()}>
					<ReactSVG wrapper="div" src={isBlocked ? iconUnblock : iconBlock} />
					<span>{isBlocked ? 'Reactivate User' : 'Suspend User'}</span>
				</button>
				<DropdownButton
					title={<ReactSVG wrapper="div" src={dot} />}
					id="bg-nested-dropdown">
					<Dropdown.Item onClick={sendEmailCode} eventKey="1">
						<div className={`${s.dropdownBasicItemText}`}>
							<ReactSVG wrapper="div" src={resendEmailIcon} />
							<span>Send Password Reset Email</span>
						</div>
					</Dropdown.Item>
					<hr />
					<Dropdown.Item onClick={showDeletedUserConfirm} eventKey="1">
						<div className={`${s.dropdownItemText}`}>
							<ReactSVG wrapper="div" src={iconDelete} />
							<span>Delete</span>
						</div>
					</Dropdown.Item>
				</DropdownButton>
			</div>
			<h5 className={s.additionalInfoTitle}>Client Team</h5>
			<UserProfileTeamBloc userToken={token} isCompanyNames />
			<ConfirmBlockedUserModal onSubmit={handleBlocked} name={name} />
			<ConfirmDeleteUserModal onSubmit={() => onDeleteUser(token)} name={name} />
		</div>
	);
};

export default AdditionalConsumersInfo;
