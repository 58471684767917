import {SET_CONSUMERS_DATA, CLEANED_UP_CONSUMERS_DATA} from '../types/Types';

const INITIAL_STATE = {
	consumers: [],
	fullCount: 0,
};

const consumersReducer = (state = INITIAL_STATE, {payload, type}) => {
	switch (type) {
		case SET_CONSUMERS_DATA:
			return {
				...state,
				consumers: payload.body,
				fullCount: payload.fullCount,
			};
		case CLEANED_UP_CONSUMERS_DATA:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default consumersReducer;
