import React from 'react';
import {ReactSVG} from 'react-svg';

import {linkArrowIcon} from 'assets';
import upgradePlanBadgeIcon from 'assets/header/upgrade-plan-badge-icon.svg';

import styles from './UpgradePlanBar.style.module.scss';

export const UpgradePlanBar: React.FC<{
	numberOfLeadsMore: number;
	onClick: () => void;
}> = ({numberOfLeadsMore, onClick}) => {
	return (
		<div className={styles.wrapper} onClick={onClick} aria-hidden tabIndex={0} role="button">
			<div className={styles.container}>
				<ReactSVG wrapper="span" className={styles.icon} src={upgradePlanBadgeIcon} />
				<div className={styles.textContainer}>
					<span className={styles.text}>
						You Have {numberOfLeadsMore} More Leads — Upgrade Now to See Them
					</span>
					<ReactSVG wrapper="span" className={styles.arrow} src={linkArrowIcon} />
				</div>
			</div>
		</div>
	);
};
