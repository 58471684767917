import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {insuranceService} from 'api/services';
import {CriticalIllnessInsuranceForm, ProfileFormContainer} from 'components';
import {useGetInsurances} from 'shared';
import {setCurrentPageTitle} from 'store/actions';
import {CriticalIllnessInsuranceType} from 'types';
import {Amplitude, ToastDispatcher, useErrorHelper} from 'utils';

import {FORM_TITLE} from './constants';

const EditCriticalIllnessInsurance: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const errorHelper = useErrorHelper(navigate);
	const tokens = window.location.hash.slice(1).split('&');
	const userToken = tokens[0];
	const insuranceToken = tokens[1];

	const fetchInsurances = useGetInsurances();

	const [insurance, setInsurance] = useState<CriticalIllnessInsuranceType | null>(null);

	const goBack = (): void => {
		navigate(-1);
	};

	const handleSave = async (newInsurance: CriticalIllnessInsuranceType) => {
		try {
			await insuranceService.updateCriticalIllnessInsurance(newInsurance);

			Amplitude.track('Edited_Insurance', {
				type: 'Critical Illness Insurance',
				data: newInsurance,
			});

			ToastDispatcher.success('Critical Illness Insurance updated');
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	useEffect(() => {
		dispatch(setCurrentPageTitle('Current'));

		const fetchInsurance = async () => {
			try {
				const insurances = await fetchInsurances(userToken);
				const criticalIllnessInsuranceDataByToken =
					insurances.criticalIllnessInsuranceModel?.find(
						item => item.token === insuranceToken,
					);

				if (criticalIllnessInsuranceDataByToken) {
					setInsurance(criticalIllnessInsuranceDataByToken);
				}
			} catch (e: any) {
				errorHelper(e);
				throw e;
			}
		};

		fetchInsurance();
	}, []);

	return (
		<ProfileFormContainer title={FORM_TITLE} goBack={goBack}>
			{insurance !== null && (
				<CriticalIllnessInsuranceForm
					insurance={insurance}
					onSave={handleSave}
					onCancel={goBack}
				/>
			)}
		</ProfileFormContainer>
	);
};

export default EditCriticalIllnessInsurance;
