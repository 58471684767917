import React, {FC, ReactNode} from 'react';
import {ReactSVG} from 'react-svg';

import {Step, StepLabel} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import {iconComplete} from 'assets';
import classNames from 'classnames';

import './style.scss';

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const {children, value, index, ...other} = props;

	return (
		<div
			hidden={value !== index}
			id={`full-width-tabPanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<div className="row step-page" key={`${value}-block`}>
					{children}
				</div>
			)}
		</div>
	);
};

type PropsTypes = {
	labels?: string[];
	children: ReactNode[];
	step: number;
	isTabHeader?: boolean;
};

const PFStepsContainer: FC<PropsTypes> = ({labels, children, step, isTabHeader = true}) => {
	const theme = useTheme();

	const stepLabelClasses = {
		root: 'label-wrapper',
		label: 'label',
		active: 'label-active',
		completed: 'label-completed',
		disabled: 'label-disabled',
		iconContainer: 'label-iconContainer',
		labelContainer: 'label-labelContainer',
	};

	const tabsClasses = {
		indicator: 'tab-indicator',
		scroller: 'tab-scroller',
		flexContainer: 'tabs-flexContainer',
	};

	return (
		<div className="stepsBlock">
			{isTabHeader && (
				<Tabs
					variant="scrollable"
					scrollButtons={false}
					allowScrollButtonsMobile={false}
					value={step}
					aria-label="full width tabs example"
					classes={tabsClasses}>
					{labels &&
						labels.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							return (
								<Step key={label} {...stepProps}>
									<StepLabel
										classes={stepLabelClasses}
										{...labelProps}
										className={classNames({
											'label-completed': step > index,
											'label-disabled': step < index,
											'label-active': step === index,
										})}
										icon={
											<div className="label-icon">
												{step > index ? (
													<ReactSVG
														src={iconComplete}
														className="iconComplete"
													/>
												) : (
													index + 1
												)}
											</div>
										}>
										{label}
									</StepLabel>
								</Step>
							);
						})}
				</Tabs>
			)}
			<div className="tab-animation">
				<div
					className="tab-animation__wrap"
					style={{
						flexDirection: 'row',
						transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
						direction: 'ltr',
						display: 'flex',
						willChange: 'transform',
						transform: `translate(${step * -100}%, 0px)`,
						zIndex: '5',
						position: 'relative',
					}}>
					{children.map((item, index) => {
						const childrenKey = index;
						return (
							<div
								key={childrenKey}
								className="animation-item"
								style={{width: '100%', flexShrink: '0'}}>
								<TabPanel value={step} index={index} dir={theme.direction}>
									{item}
								</TabPanel>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default PFStepsContainer;
