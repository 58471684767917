import React, {FC} from 'react';

import {SPECIALIZATION_OPTIONS_TYPES} from 'const';
import PFAvatar from 'shared/components/PF-Avatar';
import {AdvisorType} from 'types';
import {getFullName, getSpecializationName} from 'utils';

import urlPaths from '../../../api/url-paths';

import './style.scss';

type PropsTypes = {
	teamItem: AdvisorType;
	isCompanyNames?: boolean;
};

const UserProfileTeamItem: FC<PropsTypes> = ({teamItem, isCompanyNames}) => {
	return (
		<div key={`item-${teamItem.token}`} className="teamData__item">
			<PFAvatar
				className="teamData__item-avatar"
				src={`${urlPaths.BASE_IMAGES_URL}${teamItem?.profileImage}`}
				size={40}
			/>
			<div className="teamData__item-name-wrapper">
				<div className="teamData__item-name">
					{getFullName(teamItem?.firstName, teamItem?.lastName)}
				</div>
				{isCompanyNames && (
					<div className="teamData__item-companyName">{teamItem?.companyName}</div>
				)}
				{/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */}
				<div className="teamData__item-specializations">
					{teamItem?.clientAdvisorSpecializationTypes &&
						teamItem?.clientAdvisorSpecializationTypes.map(specializationType => (
							<div
								key={`specialization-${specializationType}`}
								className="teamData__item-specializations-badge">
								{getSpecializationName(
									SPECIALIZATION_OPTIONS_TYPES,
									specializationType,
								)}
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default UserProfileTeamItem;
