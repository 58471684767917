import {
	CLEANED_UP_TABLE_DATA,
	SET_PAGE,
	SET_DISPLAY_LENGTH,
	SET_SEARCH,
	SET_SORT_PARAM,
	SET_RESET,
	REMOVE_CHECKED_ITEMS,
	SET_SHOW_SEARCH,
} from '../types/Types';

export const cleanedUpTableData = () => {
	return {
		type: CLEANED_UP_TABLE_DATA,
	};
};

export const setTablePage = page => {
	return {
		type: SET_PAGE,
		payload: page,
	};
};

export const setTableDisplayLength = number => {
	return {
		type: SET_DISPLAY_LENGTH,
		payload: number,
	};
};

export const setTableSearch = text => {
	return {
		type: SET_SEARCH,
		payload: text,
	};
};

export const setShowSearch = isShow => {
	return {
		type: SET_SHOW_SEARCH,
		payload: isShow,
	};
};

export const setTableSort = param => {
	return {
		type: SET_SORT_PARAM,
		payload: param,
	};
};

export const setTableReset = () => {
	return {
		type: SET_RESET,
	};
};

export const removeCheckedItems = tokens => {
	return {
		type: REMOVE_CHECKED_ITEMS,
		payload: tokens,
	};
};
