import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {iconViewAll} from 'assets';
import {PORTAL_PATHS} from 'const';
import {NULL_TOKEN} from 'const/general';
import {useSetOpportunities} from 'shared/hooks';
import {OpportunityItemActionType, OpportunityType} from 'types';
import {Amplitude, getOpportunityIcon, useErrorHelper} from 'utils';

import OpportunityItem from './components/OpportunityItem';
import SetOpportunityNoteModal from './components/SetOpportunityNoteModal/SetOpportunityNoteModal';

import './style.scss';

type PropsTypes = {
	userToken: string;
	opportunities: OpportunityType[] | string;
	updateCallback?: () => void;
	isCompact: boolean;
	maxItems?: number | undefined;
};

type NoteModalType = {
	item: {token: string; note: string | null} | null;
	isVisibleModal: boolean;
};

const initialNoteModalState = {item: null, isVisibleModal: false};

const UserProfileOpportunitiesBloc: FC<PropsTypes> = ({
	userToken,
	opportunities,
	maxItems,
	isCompact,
	updateCallback,
}) => {
	const navigate = useNavigate();
	const fetchSetOpportunities = useSetOpportunities();
	const [noteModalInfo, setNoteModalInfo] = useState<NoteModalType>(initialNoteModalState);
	const errorHelper = useErrorHelper(navigate);

	const getActions = (
		action: 'current' | 'restore',
		token: string,
		note: string | null,
	): OpportunityItemActionType[] => {
		const actions: any[] = [];
		if (action === 'current') {
			actions.push(
				{
					name: 'Mark Actioned',
					handler: async () => {
						try {
							const toastsMessage = 'Opportunity marked actioned';
							const body = {
								token,
								isActioned: true,
								isDismissed: false,
								isRestored: false,
							};
							await fetchSetOpportunities(body, toastsMessage);

							Amplitude.track('Opportunities_Mark_Actioned', {
								client: userToken,
							});

							updateCallback?.();
						} catch (e) {
							errorHelper(e);
							throw e;
						}
					},
				},
				{
					name: 'Dismiss',
					handler: async () => {
						try {
							const toastsMessage = 'Opportunity dismissed';
							const body = {
								token,
								isActioned: false,
								isDismissed: true,
								isRestored: false,
							};
							await fetchSetOpportunities(body, toastsMessage);

							Amplitude.track('Dismissed_Opportunities', {
								client: userToken,
							});

							updateCallback?.();
						} catch (e) {
							errorHelper(e);
							throw e;
						}
					},
				},
			);
		} else {
			actions.push({
				name: 'Restore',
				handler: async () => {
					try {
						const toastsMessage = 'Opportunity restored';
						const body = {
							token,
							isActioned: false,
							isDismissed: false,
							isRestored: true,
						};
						await fetchSetOpportunities(body, toastsMessage);

						Amplitude.track('Opportunities_Restored', {
							client: userToken,
						});

						updateCallback?.();
					} catch (e) {
						errorHelper(e);
						throw e;
					}
				},
			});
		}

		actions.push({
			name: `${note ? 'Edit' : 'Add'} Note`,
			handler: () => {
				setNoteModalInfo({item: {token, note}, isVisibleModal: true});
			},
		});

		return actions;
	};

	const onCancelNoteModal = () => {
		setNoteModalInfo(initialNoteModalState);
	};

	const getOpportunitiesItems = (data: OpportunityType[]): JSX.Element[] | null => {
		if (data.length !== 0) {
			const opportunitiesItems = data.map(item => {
				const date = item?.createdAt && new Date(item?.createdAt);
				const whoDidTheAction =
					(item.isActionedByFio && `Actioned by ${item?.isActionedByFio}`) ||
					(item.isDismissedByFio && `Dismissed by ${item?.isDismissedByFio}`) ||
					(item.isRestoredByFio && `Restored by ${item?.isRestoredByFio}`);

				let actions: OpportunityItemActionType[] | undefined;

				if (
					item.token &&
					item.token !== NULL_TOKEN &&
					(item.isActioned || item.isDismissed)
				) {
					actions = getActions('restore', item.token, item.note);
				} else if (
					item.token &&
					item.token !== NULL_TOKEN &&
					(!item.isActioned || !item.isDismissed)
				) {
					actions = getActions('current', item.token, item.note);
				}

				return (
					<OpportunityItem
						title={item?.text}
						date={date}
						icon={getOpportunityIcon(item?.type)}
						whoDidTheAction={whoDidTheAction}
						note={item.note}
						actions={isCompact ? undefined : actions}
						key={item.token}
					/>
				);
			});
			if (maxItems) {
				return opportunitiesItems.slice(0, maxItems);
			}
			return opportunitiesItems;
		}
		return null;
	};

	const goToViewAll = (): void => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.VIEW_ALL_OPPORTUNITIES_DATA}?token=${userToken}`);
	};

	return (
		<div className="opportunitiesData">
			{opportunities && Array.isArray(opportunities) && getOpportunitiesItems(opportunities)}

			{opportunities && typeof opportunities === 'string' && (
				<div className="empty">{opportunities}</div>
			)}
			{!isCompact && !!maxItems && (
				<div className="opportunitiesData__viewAllButton">
					<button onClick={goToViewAll} type="button">
						View All
						<ReactSVG wrapper="span" className="pf-arrowSvg" src={iconViewAll} />
					</button>
				</div>
			)}
			{!isCompact && noteModalInfo.item && (
				<SetOpportunityNoteModal
					userToken={userToken}
					token={noteModalInfo.item.token}
					note={noteModalInfo.item.note}
					visible={noteModalInfo.isVisibleModal}
					className="opportunityItemModal"
					onCancel={onCancelNoteModal}
					updateCallback={updateCallback}
				/>
			)}
		</div>
	);
};

export default UserProfileOpportunitiesBloc;
