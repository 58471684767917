import React, {FC, ReactNode} from 'react';
import {ReactSVG} from 'react-svg';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import classNames from 'classnames';

import iconClose from '../../../assets/icon-close.svg';
import PFButton from '../PF-Button';

import './style.scss';

type PropsTypes = {
	isShow: boolean;
	isCloseButton: boolean;
	handleClose: () => any;
	title: ReactNode | string;
	onSubmit: () => any;
	onCancel?: () => any;
	subtitle?: ReactNode | string;
	onBackdropClick?: (event: object, reason: string) => void;
	className?: {
		root?: string;
		container?: string;
		paper?: string;
		contentBody?: string;
	};
	headerClassName?: string;
	children?: ReactNode | string;
	primaryVariant?: 'primary' | 'alert';
	submitBtnText?: string;
	submitBtnClassName?: string;
	isShowCancelBtn?: boolean;
	cancelBtnText?: string;
	isShowFooter?: boolean;
	customFooter?: ReactNode;
	disabledSubmitBtn?: boolean | null | undefined;
};

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const PFPopup: FC<PropsTypes> = ({
	isShow,
	title = true,
	subtitle,
	isCloseButton = true,
	handleClose,
	onSubmit,
	onCancel,
	onBackdropClick,
	className,
	headerClassName,
	children,
	primaryVariant = 'primary',
	submitBtnText = 'Save',
	submitBtnClassName,
	cancelBtnText = 'Cancel',
	isShowCancelBtn = true,
	isShowFooter = true,
	customFooter,
	disabledSubmitBtn = false,
}) => {
	return (
		<div>
			<Dialog
				onClose={onBackdropClick}
				aria-labelledby="customized-dialog-title"
				open={isShow}
				TransitionComponent={Transition}
				classes={{
					root: `pf-modal ${className?.root || ''}`,
					container: `pf-modal__container ${className?.container || ''}`,
					paper: `pf-modal__paper ${className?.paper || ''}`,
				}}>
				<DialogTitle className={`pf-modal__close-area ${headerClassName || ''}`}>
					{isCloseButton ? (
						<button
							className="pf-modal__close-icon"
							onClick={handleClose}
							type="button">
							<ReactSVG src={iconClose} />
						</button>
					) : null}
				</DialogTitle>
				{title && (
					<div className={`pf-modal__title ${subtitle ? 'withSubtitle' : ''}`}>
						{title}
					</div>
				)}
				{subtitle && <div className="pf-modal__subtitle">{subtitle}</div>}
				{children && (
					<DialogContent
						className={classNames(`pf-modal__content ${className?.contentBody || ''}`, {
							'mb-0': !isShowFooter,
						})}
						dividers>
						{children}
					</DialogContent>
				)}
				{isShowFooter &&
					(customFooter || (
						<DialogActions>
							<div className="d-flex w-100 m-auto pf-modal__footer-inner">
								<PFButton
									type="button"
									onClick={onSubmit}
									disabled={
										typeof disabledSubmitBtn === 'boolean'
											? disabledSubmitBtn
											: false
									}
									className={submitBtnClassName}
									variant={primaryVariant}>
									{submitBtnText}
								</PFButton>
								{isShowCancelBtn && (
									<PFButton
										type="button"
										onClick={onCancel || handleClose}
										variant="secondary">
										{cancelBtnText}
									</PFButton>
								)}
							</div>
						</DialogActions>
					))}
			</Dialog>
		</div>
	);
};

export default PFPopup;
