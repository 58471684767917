import {Specialization} from 'types/enums';

export const checkExtendedProfileView = (specializations?: Specialization[]) => {
	if (!specializations) {
		return false;
	}

	if (
		specializations.includes(Specialization['Investment Advisor']) ||
		specializations.includes(Specialization['Life and Health Insurance Advisor']) ||
		specializations.includes(Specialization['Financial Advisor']) ||
		specializations.includes(Specialization['Financial Planner'])
	) {
		return true;
	}

	return false;
};
