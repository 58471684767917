import {BrowserRouter} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Portal from '../containers/Portal';
import Public from '../containers/Public';

import momentUpdate from '../utils/moment';
import 'rsuite/dist/rsuite.min.css';
import './style.scss';
import '../styles/style.scss';
import Snackbar from '../components/Snackbar/Snackbar';
import {useAppDispatch, useAppSelector} from '../store';
import {myProfileSelectors} from '../store/selectors';
import {useSignOut} from '../shared/hooks/auth';
import Storage from '../utils/futureTS/localStorage';
import {useEffect, useState} from 'react';
import {fetchSubscription} from 'store/reducers/SubscriptionReducer';
import {useProfile} from 'shared/hooks';

function App() {
	const dispatch = useAppDispatch();

	const [shouldInit, setShouldInit] = useState(null);

	const auth = useSelector(state => state.general?.auth?.authToken);
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);

	const fetchProfile = useProfile();

	const {signOut} = useSignOut();
	momentUpdate();

	const storageData = Storage.getData('persist:root');
	if (!profile || !storageData || !storageData?.myProfile) {
		signOut();
	}

	useEffect(() => {
		if (shouldInit) {
			fetchProfile();
			setShouldInit(false);
		}
	}, [shouldInit, fetchProfile]);

	useEffect(() => {
		if (auth && profile) {
			setShouldInit(prevShouldInit => {
				if (prevShouldInit === null) {
					return true;
				}

				return prevShouldInit;
			});
		}
	}, [auth, profile]);

	return (
		<Snackbar>
			<div className="pf-app">
				<BrowserRouter>
					{auth && profile && <Portal />}
					{!auth && <Public />}
				</BrowserRouter>
			</div>
		</Snackbar>
	);
}

export default App;
