import React, {forwardRef} from 'react';
import {ReactSVG} from 'react-svg';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import {closeSnackbar, CustomContentProps, SnackbarContent} from 'notistack';

import closeIcon from '../../../../assets/icon-close.svg';

import styles from './styles.module.scss';

interface DefaultSnackProps extends CustomContentProps {}

const CustomSnack = forwardRef<HTMLDivElement, DefaultSnackProps>(({id, ...props}, ref) => {
	return (
		<SnackbarContent ref={ref} className={styles.root}>
			<Card className={`${styles.card}`}>
				<CardActions classes={{root: `${styles.actionRoot} ${styles[props.variant]}`}}>
					<div className={styles.typography}>
						{!props.hideIconVariant ? props.iconVariant[props.variant] : null}
						{props.message}
					</div>
					<div className={styles.actions}>
						{typeof props.action === 'function' && props.action(id)}
						<button
							className={styles.closeActionButton}
							type="button"
							onClick={() => closeSnackbar(id)}>
							<ReactSVG src={closeIcon} className={styles.closeActionButton__icon} />
						</button>
					</div>
				</CardActions>
			</Card>
		</SnackbarContent>
	);
});

CustomSnack.displayName = 'CustomSnack';

export default CustomSnack;
