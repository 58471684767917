enum InsuranceTypeEnum {
	LifeInsurance = 1,
	CriticalIllnessInsurance = 2,
	DisabilityInsurance = 3,
}

enum InsuranceDurationEnum {
	Term = 1,
	Permanent = 2,
}

enum InsuranceFrequencyOfPaymentEnum {
	Monthly = 1,
	Quarterly = 2,
	Yearly = 3,
	NoLongerPayingIntoThisPolicy = 4,
	ThisIsGroupCoverage = 5,
	PaidInFull = 6,
	Lapsed = 7,
	Other = 8,
}

enum InsuranceCompanyEnum {
	AssumptionLife = 1,
	Beneva = 2,
	BlueCross = 3,
	BMO = 4,
	CanadaProtectionPlan = 5,
	CanadaLife = 6,
	Combined = 7,
	Cooperators = 8,
	Desjardins = 9,
	EmpireLife = 10,
	EquitableLife = 11,
	ForestersFinancial = 12,
	GreenShield = 13,
	IndustrialAlliance = 14,
	Ivari = 15,
	LaCapitale = 16,
	Manulife = 17,
	Primerica = 18,
	RBC = 19,
	SereniaLife = 20,
	SpecialtyLife = 21,
	SSQ = 22,
	SunLife = 23,
	TeacherLife = 24,
	UVInsurance = 25,
	WawanesaInsurance = 26,
}

enum InsurancePolicyPayerEnum {
	TheInsured = 1,
	SomeoneElse = 2,
}

enum DisabilityTermEnum {
	ShortTerm = 1,
	LongTerm = 2,
}

enum InsuranceRiderEnum {
	ChildRider = 1,
	DisabilityWaiverRider = 2,
	ReturnOfPremium = 3,
	ReturnOfPremiumOnDeath = 4,
	AccidentalDeathAndDismemberment = 5,
	CriticalIllness = 6,
}

enum InsurancePayerEnum {
	Employer = 1,
	Independent = 2,
}

export {
	DisabilityTermEnum,
	InsuranceCompanyEnum,
	InsuranceDurationEnum,
	InsuranceFrequencyOfPaymentEnum,
	InsurancePayerEnum,
	InsurancePolicyPayerEnum,
	InsuranceRiderEnum,
	InsuranceTypeEnum,
};
