import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {PUBLIC_PATHS} from 'const';
import PFButton from 'shared/components/PF-Button';
import PFLink from 'shared/components/PF-Link';

import completeEmail from '../../assets/complete-email.svg';
import iconBack from '../../assets/icon-arrow-back.svg';

import ResendButton from './ResendButton';

import './style.scss';

const CompleteSignUp = ({getValues, goBack, isCompleteSignUp}) => {
	const navigate = useNavigate();
	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;
	const onClickGoBack = () => goBack();
	const onClickGoToSignIn = () => navigate(PUBLIC_PATHS.SIGN_IN);

	return (
		<div className={`completeSignUp ${!isCompleteSignUp && 'd-none'}`}>
			<div className="completeSignUp__container">
				<PFButton
					className="w-auto completeSignUp__backButton"
					prefixIcon={backIcon}
					variant="plain"
					type="button"
					onClick={onClickGoBack}>
					Back to Sign Up
				</PFButton>
				<div className="completeSignUp__card">
					<div className="completeSignUp__header">
						<ReactSVG src={completeEmail} className="completeSignUp__icon" />
					</div>
					<div className="completeSignUp__body">
						<h4 className="completeSignUp__title">Sign Up Almost Complete!</h4>
						<p className="completeSignUp___text">
							Please follow the link we sent
							<br /> to <span>{getValues('email')}</span>
							<br /> to confirm your email.
						</p>
						<PFButton
							className="completeSignUp__button"
							variant="primary"
							type="button"
							onClick={onClickGoToSignIn}>
							Go to Sign In
						</PFButton>
					</div>
					<div className="completeSignUp__footer">
						<div className="completeSignUp__footer-text completeSignUp__footer-resend-bloc">
							<div>Haven&#39;t received an email?</div>
							<ResendButton email={getValues('email')} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompleteSignUp;
