import {referBusinessesService} from 'api/services';
import {actions, useAppDispatch} from 'store';

export const useReferralsData = params => {
	const dispatch = useAppDispatch();

	return async (payload = params) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await referBusinessesService.searchReferringToBusinesses(params);
			dispatch(actions.setReferralsData(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};
