import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import {Virtuoso} from 'react-virtuoso';

import {
	deleteNotificationByToken,
	getNotifications,
	GetNotificationsParamsType,
	restoreIsDeletedNotification,
} from 'api/services/notifications';
import {loaderIcon, noNotificationsIcon} from 'assets';
import {AxiosResponse} from 'axios';
import classNames from 'classnames';
import {ClearAllNotificationsModal, NotificationItem} from 'components';
import {SnackActionButton} from 'components/Snackbar/components';
import {PFButton} from 'shared/components';
import {useBooleanState} from 'shared/hooks';
import {ResponseDataNotificationsSearchType, ResponseDataNotificationsType} from 'types';
import {ToastDispatcher, useErrorHelper} from 'utils';

import iconBack from '../../../../assets/icon-arrow-back.svg';

import './style.scss';

const VirtuosoFooter = () => {
	return (
		<div className="notifications__loadMore">
			<ReactSVG src={loaderIcon} className="notifications__loadMore-icon" />
		</div>
	);
};

const ViewArchiveNotifications = () => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const [notifications, setNotifications] = useState<ResponseDataNotificationsType>([]);
	const [isLoading, toggleIsLoading] = useBooleanState(false);
	const [isSubmittingReadAll, setIsSubmittingReadAll] = useBooleanState(false);
	const [isFull, setIsFull] = useState<boolean>(false);
	const [isDeleteReadNotificationsModalVisible, toggleDeleteReadNotificationsModal] =
		useBooleanState(false);

	const getArchiveNotifications = async (isRefresh = false) => {
		try {
			const params: GetNotificationsParamsType = {
				skip: 0,
				take: 5,
				isNew: false,
				isActive: false,
				reverse: true,
				tokenFrom:
					notifications.length && !isRefresh
						? notifications[notifications.length - 1].token
						: null,
			};
			const response: AxiosResponse<ResponseDataNotificationsSearchType> =
				await getNotifications(params);
			if (response.data.body.length) {
				if (isRefresh) {
					setNotifications(response.data.body);
				} else {
					setNotifications([...notifications, ...response.data.body]);
				}
			} else {
				setIsFull(true);
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			toggleIsLoading(false);
		}
	};

	const loadMore = async () => {
		if (!isFull) {
			await getArchiveNotifications();
		}
	};

	const getVirtuosoItem = (index, item) => {
		return <NotificationItem key={item.token} data={item} />;
	};

	const onRefresh = async () => {
		toggleIsLoading(true);
		await getArchiveNotifications(true);
	};

	const onRemoveAll = async (notificationsToken: string[]) => {
		try {
			setIsSubmittingReadAll(true);
			await deleteNotificationByToken(notificationsToken);
			ToastDispatcher.success('All notifications removed.', snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Undo"
					onClickAction={async () => {
						try {
							await restoreIsDeletedNotification(notificationsToken);
							await onRefresh();
						} catch (e) {
							errorHelper(e);
						}
					}}
				/>
			));
			setNotifications([]);
			await onRefresh();
		} catch (e) {
			ToastDispatcher.error('Failed to remove notifications. Try again.');
		} finally {
			setIsSubmittingReadAll(false);
		}
	};

	const goBack = () => {
		navigate(-1);
	};

	useEffect(() => {
		toggleIsLoading(true);
		getArchiveNotifications();
	}, []);

	return (
		<div className="view-all-notification">
			<div className="view-all-notification__container">
				<PFButton
					className="px-0 view-all-notification__container-backButton"
					prefixIcon={<ReactSVG wrapper="span" className="me-2" src={iconBack} />}
					variant="plain"
					onClick={goBack}
					type="button">
					Back
				</PFButton>
				<div className="view-all-notification__container-titleWrapper">
					<div className="view-all-notification__container-titleWrapper-title">
						Archived Notifications
					</div>
					{!!notifications.length && !isLoading && (
						<button
							className="view-all-notification__container-titleWrapper-removeAllButton"
							onClick={() => toggleDeleteReadNotificationsModal(true)}
							disabled={isSubmittingReadAll}
							type="button">
							Remove All
						</button>
					)}
				</div>
				<div
					className={classNames('view-all-notification__container-body', {
						'view-all-notification__container-body__empty':
							!notifications || notifications?.length < 1,
						'view-all-notification__container-body__loading': isLoading,
					})}>
					{isLoading && (
						<div className="view-all-notification__container-body__loading__spinner">
							<ReactSVG
								src={loaderIcon}
								className="view-all-notification__container-body__loading__spinner-icon"
							/>
						</div>
					)}
					{!isLoading && notifications?.length > 0 ? (
						<Virtuoso
							style={{height: '100%'}}
							data={notifications}
							endReached={loadMore}
							itemContent={getVirtuosoItem}
							components={!isFull ? {Footer: VirtuosoFooter} : {}}
						/>
					) : (
						!isLoading &&
						isFull && (
							<div className="view-all-notification__container-body__empty-label">
								<ReactSVG
									src={noNotificationsIcon}
									className="no-notifications-icon"
								/>
								<div className="no-notifications-text">
									You have no archived notifications
								</div>
							</div>
						)
					)}
				</div>
			</div>
			<ClearAllNotificationsModal
				onSubmit={() => onRemoveAll(notifications.map(item => item.token))}
				visible={isDeleteReadNotificationsModalVisible}
				onCancel={() => toggleDeleteReadNotificationsModal(false)}
			/>
		</div>
	);
};

export default ViewArchiveNotifications;
