import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ReactSVG} from 'react-svg';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import PFAvatar from '../../../shared/components/PF-Avatar';
import iconBlock from '../../../assets/icon-block.svg';
import dot from '../../../assets/icon-dots.svg';
import iconDelete from '../../../assets/icon-delete.svg';
import iconUnblock from '../../../assets/icon-unblock.svg';
import iconView from '../../../assets/icon-view.svg';
import {useBlockedCompany, useBlockedPopup, useDeletedPopup} from '../../../shared/hooks/companies';
import {findByToken, ToastDispatcher, useErrorHelper} from '../../../utils';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import {useNavigate} from 'react-router-dom';
import {useCompanyAdvisors} from '../../../shared/hooks/advisor';
import urlPaths from '../../../api/url-paths';
import s from './style.module.scss';
import './style.scss';
import {setSideModal} from '../../../store/actions';
import * as companiesService from '../../../api/services/companies';
import {deleteCompanies} from '../../../api/services/companies';
import {SnackActionButton} from '../../Snackbar';

const AdditionalCompaniesInfo = ({
	token,
	isBlocked,
	administratorName,
	companyName,
	updateDataForUseOnModal,
}) => {
	const companies = useSelector(state => state.companies.companies);
	const blockCompany = useBlockedCompany();
	const getCompanyAdvisors = useCompanyAdvisors();
	const [advisors, setAdvisors] = useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const errorHelper = useErrorHelper(navigate);

	const goToCompanyProfile = () => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.COMPANY_PROFILE}#${token}`);
	};

	const updateData = () => {
		const foundBlockedCompany = findByToken(companies, token);
		if (foundBlockedCompany) foundBlockedCompany.isBlocked = !isBlocked;
		updateDataForUseOnModal();
	};

	const handleBlocked = async () => {
		const body = {
			token,
			isBlocked: !isBlocked,
		};
		try {
			await blockCompany(body, companyName, updateData);
		} catch (e) {
			errorHelper(e);
		}
	};

	const deleteUpdateCallback = isDeleted => {
		const foundDeletedCompany = findByToken(companies, token);
		if (foundDeletedCompany) foundDeletedCompany.isDeleted = isDeleted;
		dispatch(setSideModal(false));
		updateDataForUseOnModal();
	};

	const onDeleteCompany = async () => {
		try {
			await companiesService.deleteCompanies([token]);
			ToastDispatcher.success(`Company deleted`);
			deleteUpdateCallback(true);
		} catch (e) {
			errorHelper(e);
		}
	};

	const {showConfirm, ConfirmModal} = useBlockedPopup();
	const {showDeleteConfirm, ConfirmDeleteModal} = useDeletedPopup();

	const showConfirmModal = () => {
		if (isBlocked) {
			handleBlocked();
		} else {
			showConfirm();
		}
	};

	const fetchCompanyAdvisors = async token => {
		const body = {
			companyToken: token,
		};
		try {
			const advisors = await getCompanyAdvisors(body);
			setAdvisors(advisors);
		} catch (e) {
			errorHelper(e);
		}
	};

	useEffect(() => {
		fetchCompanyAdvisors(token);
	}, [token]);

	return (
		<div className={`${s.pfAdditionalInfo} pfAdditionalCompaniesInfo`}>
			<div className={`${s.btnContainer} btnContainer`}>
				<button className="current-info__btn" onClick={goToCompanyProfile}>
					<ReactSVG wrapper="div" src={iconView} />
					<span>
						View <span className={s.mobile_hidden}>Company</span> Profile
					</span>
				</button>
				<DropdownButton
					title={<ReactSVG wrapper="div" src={dot} />}
					id="bg-nested-dropdown">
					<Dropdown.Item onClick={showConfirmModal} eventKey="1">
						<div className={`${s.dropdownItemText__block}`}>
							<ReactSVG wrapper="div" src={isBlocked ? iconUnblock : iconBlock} />
							<span>{isBlocked ? 'Reactivate Company' : 'Suspend Company'}</span>
						</div>
					</Dropdown.Item>
					<hr />
					<Dropdown.Item onClick={showDeleteConfirm} eventKey="2">
						<div className={`${s.dropdownItemText__delete}`}>
							<ReactSVG wrapper="div" src={iconDelete} />
							<span>Delete</span>
						</div>
					</Dropdown.Item>
				</DropdownButton>
			</div>
			{/* То что ниже, пока скрыл, но надо показать когда в базе будут админы*/}
			{/*<div className={s.additionalInfoWrapper}>
        <h5 className={s.additionalInfoTitle}>Primary Administrator</h5>
        {administratorName && (
          <p>
            <span>{administratorName}</span>
            <span>Level 1 Admin</span>
          </p>
        )}
        <p><a href={`mailto:simonlebon@gmail.com`}>simonlebon@gmail.com</a></p>
        <p>(999) 999-9999</p>
      </div>*/}
			{advisors && <h5 className={s.additionalInfoTitle}>Advisors: {advisors.fullCount}</h5>}
			{advisors && (
				<ul className={`${s.pfLeadsInfoTeam}`}>
					{advisors.body.map((item, index) => {
						if (index < 10) {
							return (
								<li key={`key-${item?.token}`}>
									<PFAvatar
										src={
											item?.advisor?.profileImage &&
											`${urlPaths.BASE_IMAGES_URL}${item?.advisor?.profileImage}`
										}
										size={20}
									/>
									<span>{`${item?.firstName} ${item?.lastName}`}</span>
								</li>
							);
						}
					})}
				</ul>
			)}
			<ConfirmModal onSubmit={handleBlocked} className={'pf-confirmBlockedModal'} />
			<ConfirmDeleteModal
				className={'pf-confirmDeleteModal'}
				onSubmit={onDeleteCompany}
				name={companyName}
			/>
		</div>
	);
};

export default AdditionalCompaniesInfo;
