import {InsuranceCompanyEnum, InsurancePayerEnum} from 'types';

export const FORM_TITLE = 'Add Critical Illness Insurance Information';

export class DefaultCriticalIllnessInsurance {
	userToken: string;

	policyRenewalDate: string | null = null;

	amountOfCoverage: number | null = null;

	insuranceCompany: InsuranceCompanyEnum | null = null;

	insurancePayer: InsurancePayerEnum | null = null;

	customInsuranceCompanyName: string | null = null;

	insured: string | null = null;

	purposeOfPlan: string | null = null;

	token: string | null = null;

	constructor(userToken: string) {
		this.userToken = userToken;
	}
}
