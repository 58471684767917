import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {AddNewLifeInsuranceForm, ProfileFormContainer} from 'components';
import {useSetLifeInsurance} from 'shared';
import {setCurrentPageTitle} from 'store/actions';

import './style.scss';

const AddNewLifeInsurance = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userToken = window.location.hash.slice(1);
	const addInsurance = useSetLifeInsurance();

	const title = 'Add Life Insurance Information';
	const goBack = (): void => {
		navigate(-1);
	};

	useEffect(() => {
		dispatch(setCurrentPageTitle('Current'));
	}, []);

	return (
		<div className="addLifeInsurance">
			<ProfileFormContainer title={title} goBack={goBack}>
				{!!userToken && (
					<AddNewLifeInsuranceForm
						userToken={userToken}
						addInsurance={addInsurance}
						goBack={goBack}
					/>
				)}
			</ProfileFormContainer>
		</div>
	);
};

export default AddNewLifeInsurance;
