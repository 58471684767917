import React from 'react';
import {useFieldArray, UseFormReturn} from 'react-hook-form';
import {ReactSVG} from 'react-svg';

import PFButton from 'shared/components/PF-Button';
import PFInput from 'shared/components/PF-Input';

import iconAdd from '../../../../../assets/buttons/icon-add.svg';
import iconRemove from '../../../../../assets/icon-close.svg';
import FormInputKeys from '../../constants';

import './beneficiaries-form.styles.scss';

type Props = {
	formProps: UseFormReturn;
};

type BeneficiaryFormType = {
	[FormInputKeys.beneficiaryFullName]: string;
	[FormInputKeys.beneficiaryProportion]: string;
};

const BeneficiariesForm: React.FC<Props> = ({formProps}) => {
	const {fields, append, remove} = useFieldArray({
		control: formProps.control,
		name: FormInputKeys.beneficiaries,
		rules: {
			// @ts-ignore
			validate: (items: BeneficiaryFormType[]) => {
				const sum = items.reduce((acc, item) => {
					const fieldProportionString = item[FormInputKeys.beneficiaryProportion];
					const fieldProportion = Number(fieldProportionString);

					return Number.isNaN(fieldProportion) ? acc : acc + fieldProportion;
				}, 0);

				if (sum > 100) {
					return 'The total percentage for your beneficiaries is over 100% — re-enter the correct amounts';
				}

				return true;
			},
		},
	});

	const proportionErrorMessage =
		formProps.formState.errors[FormInputKeys.beneficiaries]?.root?.message;

	return (
		<div className="col-12 mb-4 beneficiaries">
			{fields.map((item, index, items) => (
				<div key={item.id}>
					<div className="beneficiaries-item">
						<PFInput
							{...formProps.register(
								`${FormInputKeys.beneficiaries}.${index}.${FormInputKeys.beneficiaryFullName}`,
								{required: false},
							)}
							// @ts-ignore
							showAsterisk={false}
							label="Beneficiary full name"
							placeholder="Enter full name"
						/>
						<PFInput
							{...formProps.register(
								`${FormInputKeys.beneficiaries}.${index}.${FormInputKeys.beneficiaryProportion}`,
								{
									required: false,
									onChange: e => {
										const newValue = e.target.value.replace(/[^0-9.]/g, '');
										e.target.value = newValue;
										formProps.setValue(
											`${FormInputKeys.beneficiaries}.${index}.${FormInputKeys.beneficiaryProportion}`,
											newValue,
										);
									},
								},
							)}
							// @ts-ignore
							label="Proportion, %"
							placeholder="Percentage"
							className="beneficiaries-item--proportion"
							error={Boolean(proportionErrorMessage)}
						/>
						<div
							onClick={() => {
								remove(index);
							}}
							aria-hidden="true"
							className="beneficiaries-item--remove-button">
							<ReactSVG wrapper="span" className="icon" src={iconRemove} />
						</div>
					</div>
				</div>
			))}
			{Boolean(proportionErrorMessage) && (
				// @ts-ignore
				<div className="beneficiaries--error-hint">{proportionErrorMessage}</div>
			)}
			{fields.length < 10 && (
				<PFButton
					onClick={() => {
						append({
							[FormInputKeys.beneficiaryFullName]: '',
							[FormInputKeys.beneficiaryProportion]: '',
						});
					}}
					className="beneficiaries--add-button"
					variant="plain"
					prefixIcon={
						<ReactSVG
							wrapper="span"
							className="pf-companyProfileEdit__iconAdd"
							src={iconAdd}
						/>
					}
					type="button">
					Add Beneficiary
				</PFButton>
			)}
		</div>
	);
};

export default BeneficiariesForm;
