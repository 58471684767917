export default function convertToNumberOrNull(value: string | null): number | null {
	if (value === '' || value === null) {
		return null;
	}

	const converted = Number(value);

	if (Number.isNaN(converted)) {
		return null;
	}

	return converted;
}
