import React from 'react';

import {DEFAULT_AVATAR} from 'const';
import moment from 'moment';
import PFContactsBlock from 'shared/components/PF-ContactsBlock';
import PFUserDescription from 'shared/components/PF-UserDescription';
import {getFullName} from 'utils';

import {useAppSelector} from '../../store';
import {tableSelectors} from '../../store/selectors';

import AdditionalConsumersInfo from './AdditionalConsumersInfo';

const ConsumersModal = ({updateDataForUseOnModal}) => {
	const rowData = useAppSelector(tableSelectors.selectTableModalData);

	return (
		<>
			<PFUserDescription
				name={getFullName(rowData.firstName, rowData.lastName)}
				avatarPhoto={rowData?.profileImage}>
				Last contacted:{' '}
				{rowData.lastUpdatedAt
					? moment.utc(rowData.lastUpdatedAt).local().fromNow()
					: 'Never'}
			</PFUserDescription>
			<PFContactsBlock
				phone={rowData?.phoneNumber}
				email={rowData?.email}
				location={{name: rowData?.city, state: {name: rowData?.province}}}
				updateDataForUseOnModal={updateDataForUseOnModal}
				token={rowData.token}
				isEmailConfirmed={rowData?.isEmailConfirmed}
			/>
			<AdditionalConsumersInfo
				token={rowData.token}
				email={rowData?.email}
				isBlocked={rowData.isBlocked}
				name={getFullName(rowData.firstName, rowData.lastName)}
				updateDataForUseOnModal={updateDataForUseOnModal}
			/>
		</>
	);
};

export default ConsumersModal;
