import type {ReactElement} from 'react';
import React, {ReactNode} from 'react';
import {FieldError, FieldErrorsImpl, Merge} from 'react-hook-form';
import {components, GroupBase, OptionProps} from 'react-select';
import type {CreatableProps} from 'react-select/creatable';
import Creatable from 'react-select/creatable';
import type {ComponentProps, UseAsyncPaginateParams} from 'react-select-async-paginate';
import {withAsyncPaginate} from 'react-select-async-paginate';

import classNames from 'classnames';

import style from './style';

import './style.scss';

type AsyncPaginateCreatableProps<
	OptionType,
	Group extends GroupBase<OptionType>,
	Additional,
	IsMulti extends boolean,
> = CreatableProps<OptionType, IsMulti, Group> &
	UseAsyncPaginateParams<OptionType, Group, Additional> &
	ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <
	OptionType,
	Group extends GroupBase<OptionType>,
	Additional,
	IsMulti extends boolean = false,
>(
	props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>,
) => ReactElement;

type LoadOptionsType = (
	search: string,
	prevOptions: any,
) => Promise<{options: any; hasMore: boolean}>;

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType;

type PropsTypes = {
	id: string;
	isMulti: boolean;
	loadOptions: LoadOptionsType;
	closeMenuOnSelect: boolean;
	formatOptionLabel: (any) => any;
	getOptionValue: (any) => string;
	hideSelectedOptions: boolean;
	defaultOptions: any;
	onChange: (any) => void;
	isValidNewOption: (value: string) => boolean;

	options?: any;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	classNamePrefix?: string;
	defaultValue?: any;
	value?: string | number | readonly string[] | undefined;
	onSelect?: any;
	menuPlacement?: 'bottom' | 'auto' | 'top';
	menuPosition?: 'absolute' | 'fixed';
	noOptionsMessage?: string;

	className?: string;
	label?: ReactNode | string;
	labelSuffix?: ReactNode | string;
	showAsterisk?: boolean;
	icon?: ReactNode | string;
	onIconClick?: () => void;
	error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
	hint?: ReactNode | string;
};

const Option = (props: OptionProps) => {
	let isCreateNew = false;
	const optionsLength = props?.options?.length;

	if (props && typeof props?.children === 'string') {
		isCreateNew = props?.children?.includes('new'); // TODO: See comment below!
	}

	return (
		<div className="customTagsLabel">
			<components.Option {...props} />
			{isCreateNew && typeof optionsLength === 'number' && optionsLength > 1 && (
				<span className="newLabel__hr" />
			)}
		</div>
	);
};

const PFAsyncCreatableSelect = React.forwardRef<HTMLSelectElement, PropsTypes>(
	(
		{
			id,
			options,
			isMulti = false,
			closeMenuOnSelect = false,
			loadOptions,
			formatOptionLabel,
			getOptionValue,
			hideSelectedOptions = false,
			placeholder,
			required = false,
			classNamePrefix,
			defaultOptions,
			value,
			onChange,
			onSelect,
			defaultValue,
			disabled = false,
			isValidNewOption,
			menuPlacement = 'auto',
			menuPosition = 'absolute',
			noOptionsMessage = 'No options',

			className,
			label,
			labelSuffix,
			showAsterisk,
			icon,
			onIconClick,
			error,
			hint,
			...props
		},
		ref,
	) => {
		return (
			<div className="pf-asyncCreatableSelect">
				<div className="pf-asyncCreatableSelect__label-wrapper">
					{label && (
						<label htmlFor={id} className="pf-asyncCreatableSelect__label">
							{showAsterisk && (
								<span className="pf-asyncCreatableSelect__label-asterisk">*</span>
							)}
							{label}
						</label>
					)}
					{labelSuffix}
				</div>
				<div className="pf-asyncCreatableSelect__component-wrapper">
					<CreatableAsyncPaginate
						id={id}
						value={value}
						options={options}
						defaultValue={defaultValue}
						isMulti={isMulti}
						loadOptions={loadOptions}
						onChange={onChange}
						debounceTimeout={500}
						styles={style}
						placeholder={placeholder}
						required={required}
						closeMenuOnSelect={closeMenuOnSelect}
						formatOptionLabel={formatOptionLabel}
						getOptionValue={getOptionValue}
						hideSelectedOptions={hideSelectedOptions}
						defaultOptions={defaultOptions}
						isDisabled={disabled}
						classNamePrefix={classNamePrefix}
						createOptionPosition="first"
						isValidNewOption={isValidNewOption}
						menuPlacement={menuPlacement}
						menuPosition={menuPosition}
						noOptionsMessage={() => noOptionsMessage}
						formatCreateLabel={(inputValue: string) => `Add new tag: ${inputValue}`} // TODO: Attention! Above in the code, in the "Option" component, "new label" is searched for the word "new"
						components={{Option}}
						maxMenuHeight={150} //!
						menuShouldBlockScroll //!
						menuShouldScrollIntoView={false} //!
						captureMenuScroll //!
						// @ts-ignore
						ref={ref}
						{...props}
					/>
					{icon && (
						<button
							onClick={onIconClick}
							className="pf-input__field-icon"
							type="button"
							aria-hidden="true">
							{icon}
						</button>
					)}
				</div>
				{(error || hint) && (
					<p
						className={classNames('pf-select__hint', {
							'pf-select__hint--error': error,
						})}>
						{(typeof error === 'string' && error) || hint}
					</p>
				)}
			</div>
		);
	},
);

export default PFAsyncCreatableSelect;
