import React, {ReactNode} from 'react';

import classNames from 'classnames';

import styles from './InsurancePropertyItem.module.scss';

type Props = {
	label: string;
	value: ReactNode | string | number | boolean | null | undefined;
	compact?: boolean;
};

const InsurancePropertyItem: React.FC<Props> = ({label, value, compact}) => {
	return (
		<div
			className={classNames(styles.container, {
				[styles.containerCompact]: compact,
			})}>
			<div className={styles.label}>{label}</div>
			<div className={styles.value}>{value}</div>
		</div>
	);
};

export default InsurancePropertyItem;
