import React, {FC, ReactNode} from 'react';
import {ReactSVG} from 'react-svg';

import {linkArrowIcon} from 'assets';
import classNames from 'classnames';
import CSS from 'csstype';

import loadingIcon from '../../../assets/icon-spinner.svg';
import {getVariant} from '../../../utils';

import './style.scss';

type PropsTypes = {
	className?: string;
	variant?:
		| 'primary'
		| 'secondary'
		| 'plain'
		| 'alert'
		| 'plain-alert'
		| 'white-alert'
		| 'green'
		| 'yellow';
	suffixIcon?: ReactNode;
	prefixIcon?: ReactNode;
	isLoading?: boolean;
	hasArrow?: boolean;
	buttonHeight?: number;
	type: 'submit' | 'button';
	onClick?: () => any;
	disabled?: boolean;
	children?: React.ReactNode;
};

const PFButton: FC<PropsTypes> = ({
	variant = 'primary',
	children,
	suffixIcon,
	prefixIcon,
	className,
	isLoading,
	hasArrow,
	buttonHeight,
	type = 'submit',
	onClick,
	disabled = false,
	...props
}) => {
	const buttonHeightStyle: CSS.Properties = buttonHeight
		? {
				height: `${buttonHeight}px`,
		  }
		: {};
	return (
		<button
			type={type === 'submit' ? 'submit' : 'button'}
			className={classNames(
				'pf-button',
				getVariant('pf-button', isLoading ? 'secondary' : variant),
				className,
			)}
			style={buttonHeightStyle}
			onClick={() => onClick?.()}
			disabled={disabled}
			{...props}>
			{isLoading && (
				<ReactSVG src={loadingIcon} className="pf-button__icon pf-button__icon--loading" />
			)}
			<span
				className={classNames('w-100 pf-button__content', {
					'pf-button__content--hidden': isLoading,
				})}>
				{prefixIcon && (
					<span className="pf-button__icon pf-button__icon--prefix">{prefixIcon}</span>
				)}
				{children}
				{suffixIcon && (
					<span className="pf-button__icon pf-button__icon--suffix">{suffixIcon}</span>
				)}
			</span>

			{hasArrow && (
				<ReactSVG src={linkArrowIcon} className="pf-button__icon pf-button__icon--arrow" />
			)}
		</button>
	);
};

export default PFButton;
