import {SET_SIDEBAR, SET_CURRENT_PAGE_TITLE, SET_SIDE_MODAL} from '../types/Types';

const INITIAL_STATE = {
	sidebarOpen: true,
	sideModalOpen: false,
	currentPageTitle: 'Current',
};

const UiSettingsReducer = (state = INITIAL_STATE, {payload, type}) => {
	switch (type) {
		case SET_SIDEBAR:
			return {
				...state,
				sidebarOpen: payload,
			};
		case SET_SIDE_MODAL:
			return {
				...state,
				sideModalOpen: payload,
			};
		case SET_CURRENT_PAGE_TITLE:
			return {
				...state,
				currentPageTitle: payload,
			};
		default:
			return state;
	}
};

export default UiSettingsReducer;
