import {CLEANED_UP_CONSUMERS_DATA, SET_CONSUMERS_DATA} from '../types/Types';

export const setConsumersData = users => {
	return {
		type: SET_CONSUMERS_DATA,
		payload: users,
	};
};

export const cleanedUpConsumersData = () => {
	return {
		type: CLEANED_UP_CONSUMERS_DATA,
	};
};
