import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {ecosystemService} from 'api/services';
import {
	emptyDescStep2,
	emptyDescStep3,
	iconPlus,
	iconPlusPrimary,
	recommend,
	removeFromEcosystem,
} from 'assets';
import {EmptyLayout, SnackActionButton, SpecializationsCellItem, UserCellItem} from 'components';
import {PORTAL_PATHS} from 'const';
import {ColumnProps, Table} from 'rsuite';
import {PFButton, PFSortArrow, PFTable, PFTableBar} from 'shared/components';
import {useBooleanState, useEcoAdvisorsData} from 'shared/hooks';
import {useAppDispatch, useAppSelector} from 'store';
import {
	cleanedUpTableData,
	cleanUpEcoAdvisorsData,
	InviteAdvisorModalActions,
	removeCheckedItems,
	removeEcoAdvisorsItem,
	setCurrentPageTitle,
} from 'store/actions';
import {ecoAdvisorsSelectors, myProfileSelectors, tableSelectors} from 'store/selectors';
import {
	Amplitude,
	isCompletedProfile,
	mapQueryParams,
	parseUrlQuery,
	ToastDispatcher,
	useErrorHelper,
} from 'utils';

import RemoveFromEcoPopup from './components/RemoveFromEcoPopup/RemoveFromEcoPopup';
import {RecommendPopup} from './components';

import './style.scss';

type BarButtonPropsTypes = {
	action: () => void;
};

export const RecommendButton: FC<BarButtonPropsTypes> = ({action}) => {
	return (
		<PFButton
			type="button"
			prefixIcon={<ReactSVG wrapper="span" className="pf-icon-accept" src={recommend} />}
			className="ecosystemAdvisors recommend-button"
			onClick={action}>
			<span className="button-text">Recommend</span>
		</PFButton>
	);
};

export const RemoveButton: FC<BarButtonPropsTypes> = ({action}) => {
	return (
		<PFButton
			type="button"
			variant="white-alert"
			prefixIcon={
				<ReactSVG wrapper="span" className="pf-icon-reject" src={removeFromEcosystem} />
			}
			className="ecosystemAdvisors remove-button"
			onClick={action}>
			<span className="button-text">Remove</span>
		</PFButton>
	);
};

type ColumnsProps = ColumnProps & {name: string; title: string};

const MyEcosystem = () => {
	const [isLoading, setIsLoading] = useState(true);
	const {Column, HeaderCell, Cell} = Table;
	const [update, setUpdate] = useState(true);
	const tableData = useAppSelector(ecoAdvisorsSelectors.selectEcoAdvisorsData);
	const fullTableDataLength = useAppSelector(ecoAdvisorsSelectors.selectEcoAdvisorsFullCount);
	const checkedKeys = useAppSelector(tableSelectors.selectTableCheckedData);
	const searchProp = useAppSelector(tableSelectors.selectTableSearchData);
	const resetProp = useAppSelector(tableSelectors.selectTableResetData);
	const reverseProp = useAppSelector(tableSelectors.selectTableReverseData);
	const urlParams = parseUrlQuery(document.location);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const [isShowRemovePopup, setIsShowRemovePopup] = useBooleanState(false);
	const [isShowRecommendPopup, setIsShowRecommendPopup] = useBooleanState(false);

	const params = {
		...mapQueryParams(urlParams),
	};
	const fetchEcoAdvisorsData = useEcoAdvisorsData(params);
	const dispatch = useAppDispatch();
	const columns: ColumnsProps[] = [
		{
			name: 'user',
			title: 'User',
			flexGrow: 2,
			minWidth: 216,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'email',
			title: 'Email',
			flexGrow: 1,
			minWidth: 190,
			align: 'left',
			sortable: false,
			verticalAlign: 'middle',
		},
		{
			name: 'company',
			title: 'Company',
			flexGrow: 2,
			minWidth: 160,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
		{
			name: 'specializations',
			title: 'Roles available',
			flexGrow: 2,
			minWidth: 270,
			align: 'left',
			sortable: false,
			verticalAlign: 'middle',
		},
	];
	const tableColumns = columns.map(item => {
		const switchParam = param => {
			switch (param) {
				case 'user':
					return (
						<Cell dataKey="user">
							{rowData => (
								<UserCellItem
									lastName={rowData?.lastName}
									firstName={rowData?.firstName}
									avatarImage={rowData?.profileImage}
									avatarSize={40}
									isDeleted={false}
									restoreToken={rowData?.token}
								/>
							)}
						</Cell>
					);
				case 'email':
					return (
						<Cell dataKey="email">
							{rowData => (
								<a
									className="pf-tableText"
									href={`mailto:${rowData?.contactEmail || rowData?.email}`}>
									{rowData?.contactEmail || rowData?.email}
								</a>
							)}
						</Cell>
					);
				case 'company':
					return (
						<Cell dataKey="company">
							{rowData => <div className="pf-tableText">{rowData?.companyName}</div>}
						</Cell>
					);
				case 'specializations':
					return (
						<Cell dataKey="specializations">
							{rowData => (
								<SpecializationsCellItem
									advisorSpecializationTypes={rowData?.advisorSpecializationTypes}
									token={rowData?.token}
								/>
							)}
						</Cell>
					);
				default:
					return null;
			}
		};

		return (
			<Column
				key={item.name}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						{item.sortable ? <PFSortArrow /> : null}
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;
		try {
			setIsLoading(true);
			await fetchEcoAdvisorsData();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const firstRender = async () => {
		dispatch(setCurrentPageTitle('My Network'));
		try {
			setIsLoading(true);
			if (isCompletedProfile(profile?.advisor)) {
				await fetchEcoAdvisorsData();
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const emptyPageParam = {
		title: 'Don’t See Your Colleagues Here?',
		subtitle:
			'Follow the steps below to invite the advisors you work with to your Network to be able to recommend them any time to any of your clients.',
		steps: [
			{
				description: (
					<div>
						<b>Click the “Add New Advisor to Network” button:</b>
					</div>
				),
				content: (
					<>
						<PFButton
							type="button"
							prefixIcon={<ReactSVG src={iconPlus} />}
							className="inviteNewAdvisorsEmptyPageButton"
							onClick={() => navigate(PORTAL_PATHS.OTHER_PATHS.INVITE_TO_ECOSYSTEM)}>
							Add New Advisor to Network
						</PFButton>
						<div className="inviteNewAdvisorsEmptyPageDescription">
							<code className="code">&#8212;</code> or, if the person you want to add
							is not in the list of registered advisors,
							<b>
								{' '}
								invite them to <br />
								Pocket Finance
							</b>
						</div>
						<button
							type="button"
							className="inviteNewAdvisorsEmptyPageModalButton"
							onClick={() =>
								dispatch(InviteAdvisorModalActions.setOpen('My Network'))
							}>
							<ReactSVG wrapper="span" className="icon_plus" src={iconPlusPrimary} />
							<span className="newAdvisor_button_text">Invite New Advisors</span>
						</button>
					</>
				),
			},
			{
				description: (
					<div>
						<b>Select any advisors</b> at Pocket Finance you would like to join your
						Network:
					</div>
				),
				content: (
					<picture>
						<source media="(max-width: 992px)" srcSet={emptyDescStep2} />
						<img src={emptyDescStep2} alt="Portal table" />
					</picture>
				),
			},
			{
				description: (
					<div>
						<b>That’s it!</b> Now click any advisor and select “Recommend” option to
						suggest them to your
						<br /> clients’ teams!
					</div>
				),
				content: (
					<picture>
						<source media="(max-width: 992px)" srcSet={emptyDescStep3} />
						<img src={emptyDescStep3} alt="Portal table" />
					</picture>
				),
			},
		],
	};
	const searchEmptyParam = {
		className: '',
		description: `No results were found for your search.\n Refine your request`,
		isButton: false,
	};

	const onRemoveFromEco = async (tokens: string[]) => {
		try {
			await ecosystemService.removeAdvisorsFromEco({advisorTokens: tokens});

			Amplitude.track('Removed_From_Ecosystem', {
				advisors: tokens,
			});

			dispatch(removeEcoAdvisorsItem({tokens}));
			dispatch(removeCheckedItems(tokens));
			ToastDispatcher.success(
				`${checkedKeys.length} advisor${checkedKeys.length > 1 ? 's' : ''} removed`,
				snackbarId => (
					<SnackActionButton
						id={snackbarId}
						buttonText="Undo"
						onClickAction={async () => {
							await ecosystemService.addAdvisorsToEco({advisorPartTokens: tokens});
							firstRender();
						}}
					/>
				),
			);
		} catch (e) {
			ToastDispatcher.error(`Failed to remove. Please try again`);
		}
	};

	const tableBarButtons = [
		<RecommendButton
			key="RecommendButton"
			action={() => setIsShowRecommendPopup(true)} //! <-- если множественные ключи JSON.parse(checkedKeys[0])
		/>,
		<RemoveButton key="RemoveButton" action={() => setIsShowRemovePopup(true)} />,
	];

	useEffect(() => {
		firstRender();
	}, [searchProp, resetProp, reverseProp, profile]);

	useEffect(() => {
		Amplitude.track('Opened_My_Ecosystem');
	}, []);

	useEffect(() => {
		return () => {
			dispatch(cleanUpEcoAdvisorsData());
			dispatch(cleanedUpTableData());
		};
	}, []);

	return (
		<div className="myEcosystem">
			<PFTableBar
				tableData={tableData}
				checkedKeys={checkedKeys}
				tableBarButtons={tableBarButtons}
				modal="ecosystemAdvisors"
			/>
			<PFTable
				tableColumns={tableColumns}
				tableData={tableData}
				isLoading={isLoading}
				modal="ecosystemAdvisors"
				fetchMoreData={fetchMoreData}
				fullTableDataLength={fullTableDataLength}
				checkedKeys={checkedKeys}
				update={update}
				updateCallback={() => {}}
				parseUrlQuery={parseUrlQuery}
				updateDataForUseOnModal={updateData}
				emptyComponent={
					<EmptyLayout
						emptyPageParam={emptyPageParam}
						searchEmptyParam={searchEmptyParam}
					/>
				}
			/>
			<RemoveFromEcoPopup
				visible={isShowRemovePopup}
				onCancel={() => setIsShowRemovePopup(false)}
				onSubmit={() => onRemoveFromEco(checkedKeys)}
				title={`Remove ${checkedKeys.length} advisor${
					checkedKeys.length > 1 ? 's' : ''
				} from your Network?`}
			/>
			<RecommendPopup
				visible={isShowRecommendPopup}
				onCancel={() => setIsShowRecommendPopup(false)}
				title={`Recommend ${checkedKeys.length} advisor${
					checkedKeys.length > 1 ? 's' : ''
				}`}
				advisorTokens={checkedKeys}
			/>
			<button
				type="button"
				className={`newAdvisor_button add-new_button ${!tableData.length ? 'd-none' : ''}`}
				onClick={() => navigate(PORTAL_PATHS.OTHER_PATHS.INVITE_TO_ECOSYSTEM)}>
				<ReactSVG wrapper="span" className="icon_plus" src={iconPlus} />
				<span className="newAdvisor_button_text">Add Advisors to Network</span>
			</button>
		</div>
	);
};

export default MyEcosystem;
