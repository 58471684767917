import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

import {HiEye, HiEyeOff} from 'react-icons/hi';
import {generateValidator, hints, validations} from '../../../utils/futureTS/validations';

export const useResetPasswordForm = () => {
	const formOptions = {
		mode: 'onChange',
	};
	const formProps = useForm(formOptions);
	const {
		register,
		formState: {errors, touchedFields},
		watch,
		trigger,
		getValues,
	} = formProps;
	// useEffect(() => {}, [formProps.formState]);

	const [isVisiblePsw, setIsVisiblePsw] = useState(false);
	const [isVisibleConfirmPsw, setIsVisibleConfirmPsw] = useState(false);
	const togglePasswordVisibility = type => {
		if (type === 'password') {
			setIsVisiblePsw(visible => !visible);
		} else if (type === 'confirmPassword') {
			setIsVisibleConfirmPsw(visible => !visible);
		}
	};

	React.useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === 'password' && touchedFields.confirmPassword) {
				await trigger('confirmPassword');
			}
		});
		return () => subscription.unsubscribe();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch]);

	const passwordIcon = isVisiblePsw ? (
		<HiEyeOff onClick={() => togglePasswordVisibility('password')} size={15} />
	) : (
		<HiEye onClick={() => togglePasswordVisibility('password')} size={15} />
	);

	const confirmPasswordIcon = isVisibleConfirmPsw ? (
		<HiEyeOff onClick={() => togglePasswordVisibility('confirmPassword')} size={15} />
	) : (
		<HiEye onClick={() => togglePasswordVisibility('confirmPassword')} size={15} />
	);
	//The className variable below adds a bit of margin after each field.
	const className = 'mb-4';
	const fields = [
		{
			...register('password', {
				validate: generateValidator(hints.password.required, validations.password),
			}),
			label: 'Password',
			error: touchedFields.password && errors.password,
			showAsterisk: true,
			icon: passwordIcon,
			type: isVisiblePsw ? 'text' : 'password',
			hint: hints.password.index,
			className,
		},
		{
			...register('confirmPassword', {
				validate: value => value === getValues('password') || 'The passwords do not match!',
			}),
			label: 'Confirm Password',
			error: touchedFields.confirmPassword && errors.confirmPassword,
			showAsterisk: true,
			icon: confirmPasswordIcon,
			type: isVisibleConfirmPsw ? 'text' : 'password',
			className,
		},
	];
	return {fields, ...formProps};
};
