import * as amplitude from '@amplitude/analytics-browser';
import {MyProfile} from 'types';

export class Amplitude {
	public static async init() {
		return amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY as string).promise;
	}

	public static async track(eventInput: string, eventProperties?: Record<string, any>) {
		return amplitude.track(eventInput, eventProperties).promise;
	}

	public static async identifyUser(data: MyProfile) {
		const identifyEvent = new amplitude.Identify();
		identifyEvent.set('company', data.advisor.companyName);
		identifyEvent.set('email', data.advisor.email);
		identifyEvent.set('first_name', data.firstName);
		identifyEvent.set('last_name', data.lastName);
		identifyEvent.set('advisor_roles', data.advisor.advisorSpecializationTypes);
		identifyEvent.set('activity_status', data.advisor.isActive);
		identifyEvent.set('signup_date', data.createdAt);

		amplitude.setUserId(data.token);
		amplitude.identify(identifyEvent);
	}

	public static async setUserProperty(name: string, value: any) {
		const identifyEvent = new amplitude.Identify();
		identifyEvent.set(name, value);

		amplitude.identify(identifyEvent);
	}

	public static async incrementUserProperty(name: string, value: number = 1) {
		const identifyEvent = new amplitude.Identify();
		identifyEvent.add(name, value);

		amplitude.identify(identifyEvent);
	}

	public static async reset() {
		amplitude.flush();
		amplitude.reset();
	}
}
