import React, {useEffect, useRef, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useMediaQuery} from 'react-responsive';
import {ReactSVG} from 'react-svg';
import {Virtuoso} from 'react-virtuoso';

import {advisorClientsService} from 'api/services';
import {closeIcon, loaderIcon} from 'assets';
import {PORTAL_PATHS} from 'const';
import {isPossiblePhoneNumber} from 'libphonenumber-js';
import {Input, InputGroup} from 'rsuite';
import PFInput from 'shared/components/PF-Input';
import PFLink from 'shared/components/PF-Link';
import PFPhoneInput from 'shared/components/PF-PhoneInput';
import PFRadioGroup from 'shared/components/PF-RadioGroup';
import {useAppDispatch, useAppSelector} from 'store';
import {removeReferClientsCheckedKey, setReferClientsCheckedKey} from 'store/actions';
import {selectReferPopupClientsCheckedKeys} from 'store/selectors';
import {CurrentUser} from 'types';
import {WhoIsReferred} from 'types/enums';
import {generateValidator, hints, validations} from 'utils';

import FormInputKeys from '../constants';
import EmptyScreen from '../EmptyScreen';
import VirtuosoFooter from '../VirtuosoFooter';
import VirtuosoItem from '../VirtuosoItem';

import './style.scss';
import style from './style.module.scss';

type Props = {
	isOpen: boolean;
	formProps: UseFormReturn;
	className?: string;
};

const StepClients: React.FC<Props> = ({isOpen, formProps, className}) => {
	const dispatch = useAppDispatch();
	const [searchText, setSearchText] = useState('');
	const [debouncedValue, setDebouncedValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [currentUsers, setCurrentUsers] = useState<CurrentUser[]>([]);
	const [isFull, setIsFull] = useState<boolean>(false);
	const checkedKeys = useAppSelector(selectReferPopupClientsCheckedKeys);
	const inputEl = useRef(null);
	const isMobileScreen = useMediaQuery({query: '(max-width: 768px)'});

	const halfWidthClass = `col-lg-6 ${style.pf_mb_4}`;

	const onChangeSearchText = text => {
		const searchValue = text.replace(/^\s*/g, '');
		setSearchText(searchValue);
	};

	const onRemoveSearchText = () => {
		setSearchText('');
	};

	const searchCurrentUsers = async () => {
		const params = {
			type: 2,
			take: 10,
			skip: currentUsers.length,
			predicate: '',
			reverse: false,
			searchText,
		};
		try {
			const response = await advisorClientsService.getAdvisorClients(params);
			if (
				currentUsers.length + response.data.body.length >= response.data.fullCount ||
				!response.data.body.length
			) {
				setIsFull(true);
			}
			if (response.data.body.length) {
				setCurrentUsers([...currentUsers, ...response.data.body]);
			}
			// eslint-disable-next-line no-useless-catch
		} catch (e) {
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const handleCheckboxClick = (e, token) => {
		if (e.target.checked) {
			dispatch(setReferClientsCheckedKey(token));
		} else {
			dispatch(removeReferClientsCheckedKey(token));
		}
	};

	const getVirtuosoItem = (index, item) => {
		return (
			<VirtuosoItem
				key={item.token}
				avatarUrl={item?.profileImage}
				token={item?.token}
				firstName={item?.firstName}
				lastName={item?.lastName}
				checkedKeys={checkedKeys}
				handleCheckboxClick={handleCheckboxClick}
			/>
		);
	};

	const loadMore = async () => {
		if (!isFull) {
			await searchCurrentUsers();
		}
	};

	const WHO_IS_REFERRED_OPTIONS = [
		{
			value: String(WhoIsReferred.YourClients),
			label: isMobileScreen ? 'Clients' : 'Refer your clients',
		},
		{
			value: String(WhoIsReferred.UnregisteredUsers),
			label: isMobileScreen ? 'Unregistered user' : 'Refer unregistered user',
		},
	];

	useEffect(() => {
		setCurrentUsers([]);
		setIsFull(false);
		setIsLoading(true);
		if (isOpen) searchCurrentUsers();
	}, [debouncedValue, isOpen]);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(searchText);
		}, 500);
		return () => {
			clearTimeout(handler);
		};
	}, [searchText]);

	return (
		<div className={`${style.referPopup_main} ${className || ''}`}>
			<div className={`${style.whoIsReferred}`}>
				<PFRadioGroup
					control={formProps.control}
					name={FormInputKeys.whoIsReferred}
					// @ts-ignore
					disabled={false}
					defaultValue={
						formProps.getValues(FormInputKeys.whoIsReferred) ||
						String(WhoIsReferred.YourClients)
					}
					options={WHO_IS_REFERRED_OPTIONS}
					error={
						formProps.formState.errors[FormInputKeys.whoIsReferred]?.message &&
						formProps.formState.errors[FormInputKeys.whoIsReferred]?.message
					}
				/>
			</div>
			{formProps.getValues(FormInputKeys.whoIsReferred) ===
				String(WhoIsReferred.YourClients) && (
				<div className={style.referYourClientsSection}>
					<div className={style.searchSection}>
						<InputGroup
							inside
							className="referPopup-inputGroup"
							id="example-collapse-text">
							<Input
								className="referPopup-inputGroup__input"
								placeholder="Search..."
								onChange={onChangeSearchText}
								value={searchText}
								inputRef={inputEl}
								maxLength={85}
								autoFocus={false}
							/>
							<InputGroup.Addon
								className="referPopup-inputGroup__addon"
								onClick={onRemoveSearchText}>
								<div>
									<ReactSVG wrapper="span" src={closeIcon} />
								</div>
							</InputGroup.Addon>
						</InputGroup>
					</div>
					<div className={style.searchResultSection}>
						{!isLoading && (
							<Virtuoso
								style={{height: '100%', width: '100%'}}
								data={currentUsers}
								endReached={loadMore}
								itemContent={getVirtuosoItem}
								components={!isFull ? {Footer: VirtuosoFooter} : {}}
							/>
						)}
						{!isLoading && !currentUsers.length && (
							<EmptyScreen
								description={
									debouncedValue !== '' ? (
										`No matching results found`
									) : (
										<div>
											You have no clients in your Current list.
											<br />{' '}
											<PFLink to={PORTAL_PATHS.SIDEBAR.LEADS}>
												Visit Leads
											</PFLink>{' '}
											to get new clients.
										</div>
									)
								}
							/>
						)}
						{isLoading && (
							<div className={style.loadingWrapper}>
								<ReactSVG src={loaderIcon} className={style.spinnerIcon} />
							</div>
						)}
					</div>
					<div className={style.searchActionsSection}>
						<div className={style.selectedCount}>{checkedKeys.length} selected</div>
						<button
							type="button"
							className={style.clearAllButton}
							onClick={() => dispatch(setReferClientsCheckedKey(0))}>
							<ReactSVG
								wrapper="span"
								src={closeIcon}
								className={style.clearAllButton__icon}
							/>
							<span className={style.clearAllButton__text}>Clear All</span>
						</button>
					</div>
				</div>
			)}
			{formProps.getValues(FormInputKeys.whoIsReferred) ===
				String(WhoIsReferred.UnregisteredUsers) && (
				<div
					className={`${style.referUnregisteredUserSection} referUnregisteredUserSection row`}>
					<div className={`${halfWidthClass} firstName`}>
						<PFInput
							{...formProps.register(FormInputKeys.firstName, {
								validate: generateValidator(
									hints.textFields.required,
									validations.textFields.firstName,
								),
							})}
							// @ts-ignore
							showAsterisk
							label="First Name"
							placeholder="User first name"
							defaultValue={formProps.getValues(FormInputKeys.firstName) || ''}
							error={
								formProps.formState.errors[FormInputKeys.firstName]?.message &&
								formProps.formState.errors[FormInputKeys.firstName]?.message
							}
						/>
					</div>
					<div className={`${halfWidthClass} lastName`}>
						<PFInput
							{...formProps.register(FormInputKeys.lastName, {
								validate: generateValidator(
									hints.textFields.required,
									validations.textFields.lastName,
								),
							})}
							// @ts-ignore
							showAsterisk
							label="Last Name"
							placeholder="User last name"
							defaultValue={formProps.getValues(FormInputKeys.lastName) || ''}
							error={
								formProps.formState.errors[FormInputKeys.lastName]?.message &&
								formProps.formState.errors[FormInputKeys.lastName]?.message
							}
						/>
					</div>
					<div className={`${halfWidthClass} email`}>
						<PFInput
							{...formProps.register(FormInputKeys.email, {
								validate: generateValidator(
									hints.email.required,
									validations.email,
								),
							})}
							// @ts-ignore
							showAsterisk
							label="Email"
							placeholder="example@mail.com"
							type="email"
							defaultValue={formProps.getValues(FormInputKeys.email) || ''}
							error={
								formProps.formState.errors[FormInputKeys.email]?.message &&
								formProps.formState.errors[FormInputKeys.email]?.message
							}
						/>
					</div>
					<div className={`${halfWidthClass} phoneNumber`}>
						<PFPhoneInput
							name={FormInputKeys.phoneNumber}
							control={formProps.control}
							labelSufix={false}
							variant=""
							className=""
							hint={false}
							// @ts-ignore
							showAsterisk
							label="Phone Number"
							defaultValue={formProps.getValues(FormInputKeys.phoneNumber) || null}
							error={
								formProps.formState.errors[FormInputKeys.phoneNumber]?.message &&
								formProps.formState.errors[FormInputKeys.phoneNumber]?.message
							}
							rules={{
								required: true,
								validate: value => {
									if (value?.countryCode) {
										const k = isPossiblePhoneNumber(
											// This uses a google library to check if the ph# the user is entering is valid or invalid
											value?.value,
											value?.countryCode?.toUpperCase(),
										);
										return k || 'Please enter a valid phone number.'; // If valid, we're good, otherwise show the error message
									}
									return true;
								},
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default StepClients;
