import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import PFButton from 'shared/components/PF-Button';
import PFCopyright from 'shared/components/PF-Copyright';

import iconBack from '../../assets/icon-arrow-back.svg';

import './style.scss';

type PropsTypes = {
	className?: string;
	srcImage: string;
	title: string;
	subtitle: string;
	buttonVariant: 'secondary' | 'primary';
	goBackCallback: () => void;
	restoreCallback: () => (token: string) => void;
};

const DeletedPage: FC<PropsTypes> = ({
	className,
	srcImage,
	title,
	subtitle,
	buttonVariant,
	goBackCallback,
	restoreCallback,
}) => {
	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;

	return (
		<div className={`deleted-page__container ${className && className}`}>
			<div className="deleted-page__header">
				<PFButton
					type="button"
					className="w-auto px-0 mb-2"
					prefixIcon={backIcon}
					variant="plain"
					onClick={goBackCallback}>
					Back
				</PFButton>
			</div>
			<div className="deleted-page__main">
				<img src={srcImage} alt="deleted" />
				<h1>{title}</h1>
				<h2>{subtitle}</h2>
				<PFButton
					type="button"
					className={`restoreButton ${buttonVariant || ''}`}
					onClick={restoreCallback}>
					Restore
				</PFButton>
			</div>
			<div className="deleted-page__main">
				<PFCopyright />
			</div>
		</div>
	);
};

export default DeletedPage;
