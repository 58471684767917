import React, {FC} from 'react';

type PropsType = {
	id: string;
};

const YouTubePlayer: FC<PropsType> = ({id}) => {
	return (
		<iframe
			width="100%"
			height="100%"
			src={`https://www.youtube-nocookie.com/embed/${id}`}
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>
	);
};

export default YouTubePlayer;
