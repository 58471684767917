import {InsuranceCompanyEnum} from 'types/enums/insurance';

export const COMPANY_OPTIONS: {
	value: InsuranceCompanyEnum | string;
	label: string;
	isNew?: boolean;
}[] = [
	{
		value: InsuranceCompanyEnum.AssumptionLife,
		label: 'Assumption Life',
	},
	{
		value: InsuranceCompanyEnum.Beneva,
		label: 'Beneva',
	},
	{
		value: InsuranceCompanyEnum.BlueCross,
		label: 'Blue Cross',
	},
	{
		value: InsuranceCompanyEnum.BMO,
		label: 'BMO',
	},
	{
		value: InsuranceCompanyEnum.CanadaProtectionPlan,
		label: 'Canada Protection Plan',
	},
	{
		value: InsuranceCompanyEnum.CanadaLife,
		label: 'Canada Life',
	},
	{
		value: InsuranceCompanyEnum.Combined,
		label: 'Combined',
	},
	{
		value: InsuranceCompanyEnum.Cooperators,
		label: 'Cooperators',
	},
	{
		value: InsuranceCompanyEnum.Desjardins,
		label: 'Desjardins',
	},
	{
		value: InsuranceCompanyEnum.EmpireLife,
		label: 'Empire Life',
	},
	{
		value: InsuranceCompanyEnum.EquitableLife,
		label: 'Equitable Life',
	},
	{
		value: InsuranceCompanyEnum.ForestersFinancial,
		label: 'Foresters Financial',
	},
	{
		value: InsuranceCompanyEnum.GreenShield,
		label: 'Green Shield',
	},
	{
		value: InsuranceCompanyEnum.IndustrialAlliance,
		label: 'Industrial Alliance',
	},
	{
		value: InsuranceCompanyEnum.Ivari,
		label: 'Ivari',
	},
	{
		value: InsuranceCompanyEnum.LaCapitale,
		label: 'La Capitale',
	},
	{
		value: InsuranceCompanyEnum.Manulife,
		label: 'Manulife',
	},
	{
		value: InsuranceCompanyEnum.Primerica,
		label: 'Primerica',
	},
	{
		value: InsuranceCompanyEnum.RBC,
		label: 'RBC',
	},
	{
		value: InsuranceCompanyEnum.SereniaLife,
		label: 'Serenia Life',
	},
	{
		value: InsuranceCompanyEnum.SpecialtyLife,
		label: 'Specialty Life',
	},
	{
		value: InsuranceCompanyEnum.SSQ,
		label: 'SSQ',
	},
	{
		value: InsuranceCompanyEnum.SunLife,
		label: 'Sun Life',
	},
	{
		value: InsuranceCompanyEnum.TeacherLife,
		label: 'Teacher Life',
	},
	{
		value: InsuranceCompanyEnum.UVInsurance,
		label: 'UV insurance',
	},
	{
		value: InsuranceCompanyEnum.WawanesaInsurance,
		label: 'Wawanesa Insurance',
	},
];
