import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {insuranceService} from 'api/services';
import {CriticalIllnessInsuranceForm, ProfileFormContainer} from 'components';
import {setCurrentPageTitle} from 'store/actions';
import {CriticalIllnessInsuranceType} from 'types';
import {Amplitude, ToastDispatcher, useErrorHelper} from 'utils';

import {DefaultCriticalIllnessInsurance, FORM_TITLE} from './constants';

const AddCriticalIllnessInsurance: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const errorHelper = useErrorHelper(navigate);

	const userToken = window.location.hash.slice(1);

	const goBack = (): void => {
		navigate(-1);
	};

	const handleSave = async (insurance: CriticalIllnessInsuranceType) => {
		try {
			await insuranceService.setCriticalIllnessInsurance({...insurance, userToken});

			Amplitude.track('Advisor_Added_Insurance', {
				type: 'Critical Illness Insurance',
				data: insurance,
			});

			ToastDispatcher.success('Critical Illness Insurance added');
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	useEffect(() => {
		dispatch(setCurrentPageTitle('Current'));
	}, []);

	return (
		<ProfileFormContainer title={FORM_TITLE} goBack={goBack}>
			{Boolean(userToken) && (
				<CriticalIllnessInsuranceForm
					insurance={new DefaultCriticalIllnessInsurance(userToken)}
					onSave={handleSave}
					onCancel={goBack}
				/>
			)}
		</ProfileFormContainer>
	);
};

export default AddCriticalIllnessInsurance;
