import moment from 'moment';

const momentUpdate = () => {
	moment.updateLocale('en', {
		relativeTime: {
			future: 'in %s',
			past: '%s ago',
			s: '%d sec',
			ss: '%d sec',
			m: '%d min',
			mm: '%d min',
			h: '%d h',
			hh: '%d h',
			d: '%d d',
			dd: '%d d',
			w: '%d w',
			ww: '%d w',
			M: '%d m',
			MM: '%d m',
			y: '%d y',
			yy: '%d y',
		},
	});
};

export default momentUpdate;
