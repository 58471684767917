export enum FormInputKeys {
	policyRenewalDate = 'policyRenewalDate',
	month = 'month',
	day = 'day',
	year = 'year',
	amountOfCoverage = 'amountOfCoverage',
	insuranceCompany = 'insuranceCompany',
	insured = 'insured', // * Who is the policy for?
	insurancePayer = 'insurancePayer',
	purposeOfPlan = 'purposeOfPlan',
}

export const fullWidthInputClass = 'col-12 mb-4';
export const halfWidthInputClass = 'col-6 mb-4';
