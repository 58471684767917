import React, {useEffect, useRef} from 'react';
import {notFoundSprite} from '../../../assets';
import './style.scss';

const NothingFoundAnimation = ({width = 420, height = 420}) => {
	const canvasRef = useRef(null);
	const imageRef = useRef(null);
	const size = {width, height};

	useEffect(() => {
		let requestIdRef;

		const animationImgRef = {
			sx: 0,
			sy: 0,
			sWidth: 120,
			sHeight: 120,
			dx: 0,
			dy: 0,
			dWidth: width,
			dHeight: height,
			charFrameX: 0,
			charFrameY: 0,
			frameCount: 78,
		};

		const updateAnimation = () => {
			const animation = animationImgRef;
			animation.sx = animation.sWidth * animation.charFrameX;
			animation.charFrameX += 1;
		};

		function frameRenderer(size) {
			const ctx = canvasRef.current.getContext('2d');
			const animation = animationImgRef;
			ctx.clearRect(0, 0, size.width, size.height);
			ctx.drawImage(
				imageRef.current,
				animation.sx,
				animation.sy,
				animation.sWidth,
				animation.sHeight,
				animation.dx,
				animation.dy,
				animation.dWidth,
				animation.dHeight,
			);
		}

		const renderFrame = () => {
			updateAnimation();
			frameRenderer(size);
		};

		const tick = () => {
			if (!canvasRef.current) return;

			if (animationImgRef.charFrameX < animationImgRef.frameCount) {
				renderFrame();
				requestIdRef = requestAnimationFrame(tick);
			} else {
				cancelAnimationFrame(requestIdRef);
				const animation = animationImgRef;
				animation.sx = 0;
				animation.charFrameX = 0;
			}
		};

		requestIdRef = requestAnimationFrame(tick);

		return () => {
			cancelAnimationFrame(requestIdRef);
		};
	}, []);

	return (
		<canvas {...size} ref={canvasRef} style={size}>
			<img ref={imageRef} src={notFoundSprite} />
		</canvas>
	);
};

export default NothingFoundAnimation;
