import React, {useState} from 'react';

import PFButton from 'shared/components/PF-Button';
import PFPopup from 'shared/components/PF-Popup';

import style from './style.module.scss';

const ClearAllNotificationsModal: React.FC<{
	visible: boolean;
	onSubmit: () => void;
	onCancel: () => void;
	className?: string;
}> = ({visible, onSubmit, onCancel, className}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onClear = async () => {
		try {
			setIsSubmitting(true);
			await onSubmit();
			onCancel();
			// eslint-disable-next-line no-useless-catch
		} catch (e: any) {
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<PFPopup
			isShow={visible}
			handleClose={onCancel}
			isShowFooter={false}
			submitBtnText="Remove"
			primaryVariant="alert"
			isCloseButton
			isShowCancelBtn={false}
			title="Remove all notifications?"
			onSubmit={() => false}
			className={{root: className}}>
			<div className={style.modal_footer}>
				<div className={style.modal_footer__buttonsWrapper}>
					<PFButton
						disabled={isSubmitting}
						variant="alert"
						onClick={onClear}
						className={style.modal_footer__buttonsWrapper__clearButton}
						type="button">
						Remove
					</PFButton>
					<PFButton onClick={onCancel} variant="secondary" type="button">
						Cancel
					</PFButton>
				</div>
			</div>
		</PFPopup>
	);
};

export default ClearAllNotificationsModal;
