import {PORTAL_PATHS} from 'const';
import React from 'react';
import {PFCopyright, PFLogoLink} from 'shared/components';

import styles from './styles.module.scss';
import {Content} from './Content';

const RestorePasswordByEmail: React.FC = () => {
	return (
		<div className={styles.container}>
			<header className={styles.header}>
				<PFLogoLink to={PORTAL_PATHS.PORTAL_BASE_URL} />
			</header>

			<Content />
			<PFCopyright />
		</div>
	);
};

export {RestorePasswordByEmail};
