import React from 'react';
import {ReactSVG} from 'react-svg';

import upgradePlanBadgeIcon from 'assets/header/upgrade-plan-badge-icon.svg';

import styles from './badge.styles.module.scss';

export const UpgradePlanBadge: React.FC<{
	onClick: () => void;
}> = ({onClick}) => {
	return (
		<div className={styles.container} onClick={onClick} aria-hidden role="button" tabIndex={0}>
			<ReactSVG wrapper="div" src={upgradePlanBadgeIcon} />
			<div className={styles.label}>Upgrade</div>
		</div>
	);
};
