import {RootState} from '../store';

export const selectIsOpenCopyDeepLinkPopup = (state: RootState) => state.copyDeepLinkPopup.isOpen;
export const selectIsMyLinkCopyDeepLinkPopup = (state: RootState) =>
	state.copyDeepLinkPopup.isMyLink;
export const selectCopyDeepLinkPopupToken = (state: RootState) =>
	state.copyDeepLinkPopup.advisorToken;
export const selectDeepLinkSpecCheckedKeys = (state: RootState) =>
	state.copyDeepLinkPopup.checkedTypes;
export const selectDeepShortLink = (state: RootState) => state.copyDeepLinkPopup.shortLink;
