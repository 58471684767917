import React, {useEffect, useRef, useState} from 'react';
import {ReactSVG} from 'react-svg';
import {Virtuoso} from 'react-virtuoso';

import {advisorClientsService, advisorService, ecosystemService, usersService} from 'api/services';
import {closeIcon, loaderIcon} from 'assets';
import {PORTAL_PATHS} from 'const';
import {Input, InputGroup} from 'rsuite';
import PFLink from 'shared/components/PF-Link';
import {useAppDispatch, useAppSelector} from 'store';
import {removeReferAdvisorCheckedKey, setReferAdvisorCheckedKey} from 'store/actions';
import {selectReferPopupAdvisorCheckedKeys} from 'store/selectors';
import {AdvisorType, FullAdvisorType} from 'types';

import {getCompanyAdvisors} from '../../../../api/services/advisor';
import EmptyScreen from '../EmptyScreen';
import VirtuosoFooter from '../VirtuosoFooter';
import VirtuosoItem from '../VirtuosoItem';

// import './style.scss';
import style from './style.module.scss';

const StepRecipients: React.FC<{
	isOpen: boolean;
	className?: string;
}> = ({isOpen, className}) => {
	const dispatch = useAppDispatch();
	const [searchText, setSearchText] = useState('');
	const [debouncedValue, setDebouncedValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [referAdvisors, setReferAdvisors] = useState<AdvisorType[]>([]);
	const [isFull, setIsFull] = useState<boolean>(false);
	const checkedKeys = useAppSelector(selectReferPopupAdvisorCheckedKeys);
	const inputEl = useRef(null);

	const onChangeSearchText = text => {
		const searchValue = text.replace(/^\s*/g, '');
		setSearchText(searchValue);
	};

	const onRemoveSearchText = () => {
		setSearchText('');
	};

	const searchCurrentUsers = async () => {
		const params = {
			take: 10,
			skip: referAdvisors.length,
			predicate: '',
			reverse: false,
			searchText,
		};
		try {
			const response = await ecosystemService.searchEcoAdvisors(params);
			if (
				referAdvisors.length + response.data.body.length >= response.data.fullCount ||
				!response.data.body.length
			) {
				setIsFull(true);
			}
			if (response.data.body.length) {
				setReferAdvisors([...referAdvisors, ...response.data.body]);
			}
			// eslint-disable-next-line no-useless-catch
		} catch (e) {
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const handleCheckboxClick = (e, token) => {
		if (e.target.checked) {
			dispatch(setReferAdvisorCheckedKey(token));
		} else {
			dispatch(removeReferAdvisorCheckedKey(token));
		}
	};

	const getVirtuosoItem = (index, item) => {
		return (
			<VirtuosoItem
				key={item.token}
				avatarUrl={item.profileImage}
				token={item.token}
				firstName={item.firstName}
				lastName={item.lastName}
				advisorSpecializationTypes={item.advisorSpecializationTypes}
				checkedKeys={checkedKeys}
				handleCheckboxClick={handleCheckboxClick}
			/>
		);
	};

	const loadMore = async () => {
		if (!isFull) {
			await searchCurrentUsers();
		}
	};

	useEffect(() => {
		setReferAdvisors([]);
		setIsFull(false);
		setIsLoading(true);
		if (isOpen) searchCurrentUsers();
	}, [debouncedValue, isOpen]);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(searchText);
		}, 500);
		return () => {
			clearTimeout(handler);
		};
	}, [searchText]);

	return (
		<div className={`${style.referPopup_main} ${className || ''}`}>
			<div className={style.referAdvisorsSection}>
				<div className={style.searchSection}>
					<InputGroup inside className="referPopup-inputGroup" id="example-collapse-text">
						<Input
							className="referPopup-inputGroup__input"
							placeholder="Search..."
							onChange={onChangeSearchText}
							value={searchText}
							inputRef={inputEl}
							maxLength={85}
							autoFocus={false}
						/>
						<InputGroup.Addon
							className="referPopup-inputGroup__addon"
							onClick={onRemoveSearchText}>
							<div>
								<ReactSVG wrapper="span" src={closeIcon} />
							</div>
						</InputGroup.Addon>
					</InputGroup>
				</div>
				<div className={style.searchResultSection}>
					{!isLoading && (
						<Virtuoso
							style={{height: '100%', width: '100%'}}
							data={referAdvisors}
							endReached={loadMore}
							itemContent={getVirtuosoItem}
							components={!isFull ? {Footer: VirtuosoFooter} : {}}
						/>
					)}
					{!isLoading && !referAdvisors.length && (
						<EmptyScreen
							description={
								debouncedValue !== '' ? (
									`No results were found for your search.\n Refine your request`
								) : (
									<div>
										You have no other professionals in your Network to refer
										business to.
										<br />{' '}
										<PFLink to={PORTAL_PATHS.SIDEBAR.MY_NETWORK}>
											Visit My Network
										</PFLink>{' '}
										to add other professionals.
									</div>
								)
							}
						/>
					)}
					{isLoading && (
						<div className={style.loadingWrapper}>
							<ReactSVG src={loaderIcon} className={style.spinnerIcon} />
						</div>
					)}
				</div>
				<div className={style.searchActionsSection}>
					<div className={style.selectedCount}>{checkedKeys.length} selected</div>
					<button
						type="button"
						className={style.clearAllButton}
						onClick={() => dispatch(setReferAdvisorCheckedKey(0))}>
						<ReactSVG
							wrapper="span"
							src={closeIcon}
							className={style.clearAllButton__icon}
						/>
						<span className={style.clearAllButton__text}>Clear All</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default StepRecipients;
