import React, {FC, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

import {emailService} from 'api/services';
import PFButton from 'shared/components/PF-Button';
import PFInput from 'shared/components/PF-Input';
import PFPopup from 'shared/components/PF-Popup';
import PFTile from 'shared/components/PF-Tile';
import {useAppDispatch, useAppSelector} from 'store';
import {InviteAdvisorModalActions} from 'store/actions';
import {selectIsOpenInviteAdvisorModal, selectSourceInviteAdvisorModal} from 'store/selectors';
import {Amplitude, generateValidator, hints, ToastDispatcher, validations} from 'utils';

import './style.scss';

type PropsTypes = {
	className?: string;
};

const InviteNewAdviserModal: FC<PropsTypes> = ({className}) => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(selectIsOpenInviteAdvisorModal);
	const source = useAppSelector(selectSourceInviteAdvisorModal);
	const {formState, handleSubmit, watch, trigger, register} = useForm({
		mode: 'onChange',
	});

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [emails, setEmails] = useState<string[]>([]);

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === 'email') {
				await trigger('email');
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	const submitForm = async () => {
		try {
			setIsSubmitting(true);
			const promises = emails.map(async email => {
				const body = {email};
				await emailService.inviteAdvisor(body);
			});
			await Promise.all(promises);

			Amplitude.track('Invited_New_Advisor', {
				source,
			});
			Amplitude.incrementUserProperty('Total_Sent_Invitation');

			ToastDispatcher.success('Invitation sent');
			dispatch(InviteAdvisorModalActions.setClose());
		} catch (e) {
			ToastDispatcher.error('Failed to sent invitation. Please try again');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<PFPopup
			isShow={isOpen}
			handleClose={() => dispatch(InviteAdvisorModalActions.setClose())}
			isShowFooter={false}
			submitBtnText="Send"
			primaryVariant="primary"
			isCloseButton
			isShowCancelBtn={false}
			title="Invite a new advisor"
			subtitle="Invite key professionals to join your client’s Pocket Finance network for seamless collaboration and holistic financial planning. Add these experts to enhance your client's financial strategy:"
			onSubmit={() => false}
			className={{root: `inviteAdvisorModal ${className || ''}`}}>
			<div className="col-12 advisorCategories">
				<div className="row">
					<ul className="col-12 col-md-6 col-lg-3">
						<li className="bullet">Investment Advisor</li>
						<li className="bullet">Financial Advisor</li>
						<li className="bullet">Accountant</li>
						<li className="bullet">Residential Mortgage Advisor</li>
					</ul>
					<ul className="col-12 col-md-6 col-lg-3">
						<li className="bullet">Realtor</li>
						<li className="bullet">Financial Planner</li>
						<li className="bullet">Restructuring Specialist</li>
						<li className="bullet">Home and Auto Insurance Advisor</li>
					</ul>
					<ul className="col-12 col-md-6 col-lg-3">
						<li className="bullet">Money Coach</li>
						<li className="bullet">Group Benefits Advisor</li>
						<li className="bullet">Commercial Mortgage Advisor</li>
					</ul>
					<ul className="col-12 col-md-6 col-lg-3">
						<li className="bullet">Bookkeeper</li>
						<li className="bullet">Lawyer</li>
						<li className="bullet">Life and Health Insurance Advisor</li>
					</ul>
				</div>
			</div>
			<form onSubmit={handleSubmit(submitForm)} className="inviteAdvisorModal__form">
				<div className="col-12 email">
					<div className="emailWithTile">
						<PFInput
							{...register('email', {
								validate: (value: string) => {
									if (
										!generateValidator(
											hints.email.required,
											validations.email,
										)(value)
									) {
										return 'Invalid email';
									}
									if (emails.includes(value)) {
										return 'Email already exist';
									}
									return true;
								},
							})}
							// @ts-ignore
							showAsterisk={false}
							label="Email"
							placeholder="Email"
							type="email"
							defaultValue=""
							hint="Press enter after each email address"
							error={
								formState.errors?.email?.message && formState.errors?.email?.message
							}
							onKeyPress={e => {
								if (e.key === 'Enter') {
									e.preventDefault();
									const email = e.currentTarget.value;
									setEmails([...emails, email]);
									e.currentTarget.value = '';
								}
							}}
						/>
						<div className="emailTileWrapper">
							{emails.map(email => (
								<PFTile
									key={email}
									variant="primary"
									onRemove={() => setEmails(emails.filter(e => e !== email))}>
									{email}
								</PFTile>
							))}
						</div>
					</div>
				</div>
				<div className="footer">
					<PFButton disabled={emails.length === 0 || isSubmitting} type="submit">
						Send
					</PFButton>
				</div>
			</form>
		</PFPopup>
	);
};

export default InviteNewAdviserModal;
