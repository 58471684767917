import React from 'react';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {ReactSVG} from 'react-svg';

import {iconDots} from 'assets';
import {OpportunityItemActionType} from 'types';

import './OpportunityItem.scss';
import styles from './OpportunityItem.module.scss';

type Props = {
	title: string | null;
	icon: React.ReactNode | string;
	date: Date | string | null;
	note?: string | null;
	whoDidTheAction?: string | null;
	actions?: OpportunityItemActionType[];
};

const dateFormatter = new Intl.DateTimeFormat('en-US', {
	month: 'short',
	day: '2-digit',
	year: 'numeric',
});

const OpportunityItem: React.FC<Props> = ({title, icon, date, actions, whoDidTheAction, note}) => {
	const dateView =
		// eslint-disable-next-line no-nested-ternary
		date === null ? (
			'n/a'
		) : (
			<span className={styles.dateValue}>
				{typeof date === 'string' ? date : dateFormatter.format(date)}
			</span>
		);

	return (
		<div className={styles.container}>
			<div className={styles.icon}>
				{typeof icon === 'string' ? <ReactSVG wrapper="div" src={icon} /> : icon}
			</div>
			<div className={styles.infoContainer}>
				<div className={styles.title}>{title}</div>
				<div className={styles.details}>
					<div className={styles.date}>{dateView}</div>
					{whoDidTheAction && (
						<div className={styles.whoDidTheAction}>{whoDidTheAction}</div>
					)}
				</div>
				{note && <div className={styles.note}>{note}</div>}
			</div>
			{actions !== undefined && actions?.length > 0 && (
				<div className={styles.actions}>
					<DropdownButton
						align="end"
						title={<ReactSVG wrapper="div" src={iconDots} />}
						id="bg-nested-dropdown"
						className="insurance-dropdown-button">
						{actions.map((action, index) => (
							<Dropdown.Item
								onClick={action.handler}
								eventKey={`${index + 1}`}
								key={action.name}>
								<div>{action.name}</div>
							</Dropdown.Item>
						))}
					</DropdownButton>
				</div>
			)}
		</div>
	);
};

export default OpportunityItem;
