import React from 'react';
import {useLocation} from 'react-router-dom';
import View from '../View';
import {InvitationHeader} from '../../components';
import PORTAL_PATHS from '../../const/url-paths/portal';
import './style.scss';

export default function Public() {
	const location = useLocation();
	const isInvitation = location.pathname === PORTAL_PATHS.OTHER_PATHS.ECO_ADVISOR_PROFILE;

	return (
		<div className={'pf-public'}>
			{isInvitation && <InvitationHeader />}
			<View isAuth={false} />
		</div>
	);
}
