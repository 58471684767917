import React from 'react';
import {HiEye, HiEyeOff} from 'react-icons/hi';

export const EyeIcon: React.FC<{
	closed: boolean;
	onClick: () => void;
}> = ({closed, onClick}) => {
	const handleClick = () => {
		onClick();
	};

	if (closed) {
		return <HiEyeOff onClick={handleClick} size={15} />;
	}

	return <HiEye onClick={handleClick} size={15} />;
};
