import React, {useState} from 'react';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {ecosystemService} from 'api/services';
import {
	addToEcosystem,
	iconDots,
	iconView,
	profileLink,
	recommend,
	referBusiness,
	removeFromEcosystem,
} from 'assets';
import {PORTAL_PATHS} from 'const';
import {
	removeAdvisorsItem,
	removeCheckedItems,
	removeEcoAdvisorsItem,
	setIsOpenReferPopup,
	setOpenDeepLinkPopup,
	setReferAdvisorCheckedKey,
	setSideModal,
} from 'store/actions';
import {Specialization} from 'types/enums';
import {Amplitude, mapQueryParams, parseUrlQuery, ToastDispatcher} from 'utils';

import {RecommendPopup} from '../../../pages/private/MyEcosystem/components';
import RemoveFromEcoPopup from '../../../pages/private/MyEcosystem/components/RemoveFromEcoPopup/RemoveFromEcoPopup';
import PFBadge from '../../../shared/components/PF-Badge';
import PFButton from '../../../shared/components/PF-Button';
import PFTooltip from '../../../shared/components/PF-Tooltip';
import {useEcoAdvisorsData} from '../../../shared/hooks';
import useBooleanState from '../../../shared/hooks/utility/useBooleanState';
import {SnackActionButton} from '../../Snackbar';

import s from './style.module.scss';

const AdditionalEcosystemInfo = ({advisorToken, advisorName, specializations, isInEcosystem}) => {
	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	const urlParams = parseUrlQuery(document.location);
	const fetchEcoAdvisorsData = useEcoAdvisorsData({
		...mapQueryParams(urlParams),
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isShowRemovePopup, setIsShowRemovePopup] = useBooleanState(false);
	const [isShowRecommendPopup, setIsShowRecommendPopup] = useBooleanState(false);

	const addToMyEcoAction = async (tokens: string[]) => {
		try {
			setIsSubmitting(true);
			await ecosystemService.addAdvisorsToEco({advisorPartTokens: tokens});

			Amplitude.track('Added_Advisor_To_Ecosystem', {
				advisors: tokens,
			});

			dispatch(setSideModal(false));
			dispatch(removeAdvisorsItem({tokens}));
			dispatch(removeCheckedItems(tokens));
			ToastDispatcher.success(`${tokens.length} advisors added to Your Network`);
		} catch (e) {
			ToastDispatcher.error(
				`An advisor has not been added to Your Network. Please try again.`,
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onRemoveFromEco = async (tokens: string[]) => {
		try {
			setIsSubmitting(true);
			await ecosystemService.removeAdvisorsFromEco({advisorTokens: tokens});

			Amplitude.track('Removed_From_Ecosystem', {
				advisors: tokens,
			});

			dispatch(setSideModal(false));
			dispatch(removeEcoAdvisorsItem({tokens}));
			dispatch(removeCheckedItems(tokens));
			ToastDispatcher.success(`User removed`, snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Undo"
					onClickAction={async () => {
						await ecosystemService.addAdvisorsToEco({advisorPartTokens: tokens});
						await fetchEcoAdvisorsData();
					}}
				/>
			));
		} catch (e) {
			ToastDispatcher.error(`Failed to remove. Please try again`);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onRefer = () => {
		dispatch(setReferAdvisorCheckedKey(advisorToken));
		dispatch(setIsOpenReferPopup('Network'));
	};

	return (
		<div className={`${s.ecosystemAdditionalInfo} pfAdditionalCompaniesInfo`}>
			<div className={`${s.btnContainer} btnContainer`}>
				<div className={s.btnGroup}>
					<PFButton
						type="button"
						variant="primary"
						buttonHeight={40}
						disabled={isSubmitting}
						prefixIcon={<ReactSVG src={isInEcosystem ? recommend : addToEcosystem} />}
						className="button recommendOrAddButton"
						onClick={
							isInEcosystem
								? () => setIsShowRecommendPopup(true)
								: () => addToMyEcoAction([advisorToken])
						}>
						{isInEcosystem ? 'Recommend' : 'Add to My Network'}
					</PFButton>
					{isInEcosystem && (
						<div className={s.shareProfileButtonWrapper}>
							<PFButton
								type="button"
								variant="secondary"
								buttonHeight={40}
								prefixIcon={<ReactSVG wrapper="div" src={profileLink} />}
								className={`${s.button} ${s.shareProfileButton}`}
								onClick={() =>
									dispatch(
										setOpenDeepLinkPopup({
											advisorToken,
										}),
									)
								}>
								Share Profile Link
							</PFButton>
							<PFTooltip
								tooltipBody="Click this button to share this advisor profile link with your clients and colleagues."
								arrow
								className={{
									clickAria: s.shareProfileTooltip,
									tooltipBody: s.ecosystemModal_tooltipBody,
								}}
							/>
						</div>
					)}
					<PFButton
						type="button"
						variant="secondary"
						buttonHeight={40}
						prefixIcon={<ReactSVG src={iconView} />}
						className={`${s.button} ${s.viewProfileButton}`}
						onClick={() => {
							navigate(
								`${PORTAL_PATHS.OTHER_PATHS.ECO_ADVISOR_PROFILE}#${advisorToken}`,
							);
						}}>
						View Profile
					</PFButton>
				</div>

				{isInEcosystem && (
					<DropdownButton
						title={<ReactSVG wrapper="div" src={iconDots} />}
						id="bg-nested-dropdown">
						<Dropdown.Item disabled={false} onClick={onRefer} eventKey="2">
							<div className={`${s.dropdownItemText}`}>
								<ReactSVG wrapper="div" src={referBusiness} />
								<span>Refer Business</span>
							</div>
						</Dropdown.Item>

						<hr />
						<Dropdown.Item
							disabled={isSubmitting}
							onClick={() => setIsShowRemovePopup(true)}
							eventKey="3">
							<div className={`${s.dropdownItemText} ${s.removeText}`}>
								<ReactSVG wrapper="div" src={removeFromEcosystem} />
								<span>Remove From Network</span>
							</div>
						</Dropdown.Item>
					</DropdownButton>
				)}
			</div>
			{/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */}
			{specializations && specializations?.length > 0 && (
				<h5 className={s.additionalInfoTitle}>Roles available</h5>
			)}
			<div className={s.specializations}>
				{specializations &&
					specializations?.map(type => (
						<PFBadge key={`spec-${type}`}>{Specialization[type]}</PFBadge>
					))}
			</div>
			<RemoveFromEcoPopup
				visible={isShowRemovePopup}
				onCancel={() => setIsShowRemovePopup(false)}
				onSubmit={() => onRemoveFromEco([advisorToken])}
				title={`Remove ${advisorName} from your Network?`}
			/>
			<RecommendPopup
				visible={isShowRecommendPopup}
				onCancel={() => setIsShowRecommendPopup(false)}
				title={`Recommend ${advisorName}`}
				advisorTokens={[advisorToken]}
			/>
		</div>
	);
};

export default AdditionalEcosystemInfo;
