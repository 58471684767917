import React from 'react';
import {ReactSVG} from 'react-svg';

import {addInsurance} from 'assets';

import './InsuranceEmptyInfoHeader.scss';
import styles from './InsuranceEmptyInfoHeader.module.scss';

type Props = {
	title: string;
	icon: React.ReactNode;
	hint: string | null;
	onAddClick?: () => void;
};

const InsuranceEmptyInfoHeader: React.FC<Props> = ({title, icon, hint, onAddClick}) => {
	return (
		<div className={styles.container}>
			<div className={styles.icon}>{icon}</div>
			<div className={styles.infoContainer}>
				<div>
					<div className={styles.title}>{title}</div>
					{Boolean(hint) && <div className={styles.hint}>{hint}</div>}
				</div>
			</div>
			{Boolean(onAddClick) && (
				<div className={styles.actions}>
					<button type="button" className="add-button" onClick={onAddClick}>
						<ReactSVG src={addInsurance} className="add-button__icon" />
					</button>
				</div>
			)}
		</div>
	);
};

export default InsuranceEmptyInfoHeader;
