import * as advisorClients from '../../../api/services/advisorClients';
import {setLeadsData} from '../../../store/actions/';
import {useAppDispatch} from '../../../store';

export const useLeadsData = params => {
	const dispatch = useAppDispatch();

	return async (payload = params) => {
		try {
			const response = await advisorClients.getAdvisorClients(params);
			dispatch(setLeadsData(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};
