import React, {FC, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useMediaQuery} from 'react-responsive';

import classNames from 'classnames';
import {DEFAULT_COMPANY_AVATAR, HASHTAG_TYPES, SPECIALIZATION_OPTIONS_TYPES} from 'const';
import PFInput from 'shared/components/PF-Input';
import PFInputAvatarCrop from 'shared/components/PF-InputAvatarCrop';
import PFSelect from 'shared/components/PF-Select';
import PFTextarea from 'shared/components/PF-Textarea';
import {usePopupCompaniesData} from 'shared/hooks/companies';
import {useCertificates, useHashtags, useLocations} from 'shared/hooks/profile';
import {AdvisorType} from 'types';
import {isBoolean} from 'util';
import {generateValidator, hints, renderLocation, validations} from 'utils';

import urlPaths from '../../api/url-paths';
import PFAvatar from '../../shared/components/PF-Avatar';
import PFCheckbox from '../../shared/components/PF-Checkbox';
import FormInputKeys from '../EditProfileForm/constants';

import s from './styles.module.scss';

type PropsTypes = {
	formProps: UseFormReturn;
	hintValue: number;
	advisor?: AdvisorType;
	companyImage?: string | null | undefined | boolean;
	selectedCompany?: any;
};

const UserDataFormBloc: FC<PropsTypes> = ({
	advisor,
	formProps,
	hintValue,
	selectedCompany,
	companyImage,
}) => {
	const fetchCompanies = usePopupCompaniesData();
	const fetchCertificates = useCertificates();
	const fetchHashtags = useHashtags();
	const fetchLocations = useLocations();
	const isMobileScreen = useMediaQuery({query: '(max-width: 992px)'});

	const halfWidthClass = 'col-lg-6 mb-4';
	const fullWidthClass = 'col-12 mb-4';

	const videoPresentationLinkHint = (
		<div className="inputHint">Link to your YouTube video here</div>
	);

	const descriptionHint = (
		<div className={classNames('counter-hint', {error: hintValue > 490}, s.descriptionHint)}>
			<span>{hintValue}/490</span>
			<span>Avoid using @, #, $, %, &, *, _, -, or ^</span>
		</div>
	);

	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	const specializationDefaultValue = advisor?.advisorSpecializationTypes?.map(type =>
		SPECIALIZATION_OPTIONS_TYPES.find(spec => {
			return spec.type === type;
		}),
	);

	const onChangeAvatar = async image => {
		formProps.setValue(FormInputKeys.avatar, image);
		await formProps.trigger(FormInputKeys.avatar);
	};

	const loadCompanyTokenOptions = async (searchText, loadedOptions) => {
		// this is our custom load options, we're returning the values from the API call
		const {
			body: options,
			count,
			fullCount,
		} = await fetchCompanies({
			searchText,
			take: 10,
			skip: loadedOptions.length,
		});
		return {
			options,
			hasMore: count !== 0 && fullCount > options.length + loadedOptions.length,
		};
	};

	const loadFinancialCertificatesOptions = async (SearchText, loadedOptions) => {
		// this is our custom load options, we're returning the values from the API call
		const {
			body: options,
			count,
			currentCount,
		} = await fetchCertificates({
			SearchText,
			skip: loadedOptions.length,
		});
		return {
			options,
			hasMore: currentCount !== 0 && count > options.length + loadedOptions.length,
		};
	};

	const loadLocationOptions = async (Query, loadedOptions) => {
		// this is our custom load options, we're returning the values from the API call
		const {
			body: options,
			count,
			currentCount,
		} = await fetchLocations({
			Query,
			skip: loadedOptions.length,
		});
		return {
			options,
			hasMore: currentCount !== 0 && count > options.length + loadedOptions.length,
		};
	};

	const getCompanyDefaultValue = () => {
		const formPropsValue = formProps.getValues(FormInputKeys.company);
		if (formPropsValue) {
			return formPropsValue;
		}
		if (advisor) {
			return {
				token: advisor?.companyToken,
				name: advisor?.companyName,
				image: advisor?.companyImage,
			};
		}
		if (selectedCompany && !!Object.keys(selectedCompany).length) {
			return {
				token: selectedCompany?.token,
				name: selectedCompany?.name,
				image: selectedCompany?.image,
			};
		}
		return null;
	};

	const onChangeCompanyLogo = async image => {
		formProps.setValue(FormInputKeys.companyLogo, image);
	};

	return (
		<>
			<div className={`${halfWidthClass} firstName`}>
				<PFInput
					{...formProps.register(FormInputKeys.firstName, {
						validate: generateValidator(
							hints.textFields.required,
							validations.textFields.firstName,
						),
					})}
					// @ts-ignore
					showAsterisk
					label="First Name"
					placeholder="First Name"
					defaultValue={advisor ? advisor?.firstName : ''}
					error={
						formProps.formState.errors[FormInputKeys.firstName]?.message &&
						formProps.formState.errors[FormInputKeys.firstName]?.message
					}
				/>
			</div>
			<div className={`${halfWidthClass} lastName`}>
				<PFInput
					{...formProps.register(FormInputKeys.lastName, {
						validate: generateValidator(
							hints.textFields.required,
							validations.textFields.lastName,
						),
					})}
					// @ts-ignore
					showAsterisk
					label="Last Name"
					placeholder="Last Name"
					defaultValue={advisor ? advisor?.lastName : ''}
					error={
						formProps.formState.errors[FormInputKeys.lastName]?.message &&
						formProps.formState.errors[FormInputKeys.lastName]?.message
					}
				/>
			</div>
			<div
				className={classNames('inputSelectCompany', fullWidthClass, {
					disabled: formProps.getValues(FormInputKeys.newCompanyCheck), //!
				})}>
				<PFSelect
					{...formProps.register(FormInputKeys.company, {
						required: !formProps.getValues(FormInputKeys.newCompanyCheck),
					})}
					// @ts-ignore
					defaultValue={getCompanyDefaultValue()}
					showAsterisk
					label="Company"
					placeholder="Select your company from the list"
					disabled={
						(selectedCompany && !!Object.keys(selectedCompany).length) ||
						formProps.getValues(FormInputKeys.newCompanyCheck) //!
					}
					isMulti={false}
					blurInputOnSelect
					closeMenuOnSelect
					isAsync
					onChange={value => {
						formProps.setValue(FormInputKeys.company, value);
					}}
					getOptionValue={item => item.name}
					loadOptions={loadCompanyTokenOptions}
					formatOptionLabel={option => option.name}
					defaultOptions
					error={
						formProps.formState.errors[FormInputKeys.company]?.message &&
						formProps.formState.errors[FormInputKeys.company]?.message
					}
					childrenBeforeInput={
						<PFAvatar
							src={!!companyImage && `${urlPaths.BASE_IMAGES_URL}${companyImage}`}
							size={45}
							defaultAvatar={DEFAULT_COMPANY_AVATAR}
							variant="rounded-square"
							className="inputSelectCompany__companyLogo"
						/>
					}
				/>
			</div>
			<div className={`${fullWidthClass}`}>
				<PFCheckbox
					{...formProps.register(FormInputKeys.newCompanyCheck)}
					name={FormInputKeys.newCompanyCheck}
					registerControl
					onClick={() => false}
					label="Add a new company"
					disabled={selectedCompany && !!Object.keys(selectedCompany).length}
					className="newCompanyCheck"
				/>
			</div>
			<div
				className={classNames('anotherCompany', {
					disabled: !formProps.getValues(FormInputKeys.newCompanyCheck), //!
				})}>
				<div className={`${fullWidthClass} companyName`}>
					<PFInput
						{...formProps.register(FormInputKeys.companyName, {
							validate: generateValidator(
								formProps.getValues(FormInputKeys.newCompanyCheck), //!
								validations.textFields.companyName,
							),
						})}
						// @ts-ignore
						showAsterisk
						label="New company name"
						placeholder="New company name"
						defaultValue=""
						error={
							formProps.formState.errors[FormInputKeys.companyName]?.message &&
							formProps.formState.errors[FormInputKeys.companyName]?.message
						}
					/>
				</div>
				<div className={`${fullWidthClass} companyLocationToken`}>
					<PFSelect
						{...formProps.register(FormInputKeys.companyLocationToken, {
							required: formProps.getValues(FormInputKeys.newCompanyCheck), //!
						})}
						// @ts-ignore
						defaultValue={formProps.getValues(FormInputKeys.companyLocationToken)}
						showAsterisk
						label="Company location"
						placeholder="New company location"
						disabled={false}
						isMulti={false}
						blurInputOnSelect
						closeMenuOnSelect
						isAsync
						onChange={value => {
							formProps.setValue(FormInputKeys.companyLocationToken, value);
						}}
						getOptionValue={item => item}
						loadOptions={loadLocationOptions}
						formatOptionLabel={option => renderLocation(option)}
						defaultOptions
						error={
							formProps.formState.errors[FormInputKeys.companyLocationToken]
								?.message &&
							formProps.formState.errors[FormInputKeys.companyLocationToken]?.message
						}
					/>
				</div>
				<div className="companyLogo d-none">
					<PFInput
						{...formProps.register(FormInputKeys.companyLogo, {
							required:
								formProps.getValues(FormInputKeys.newCompanyCheck) &&
								'Logo is required',
							validate: value => {
								if (value === 'error') {
									return "The file you've uploaded is larger than 5MB. Please upload an image smaller than 5MB.";
								}
								if (value === 'error-type') {
									return 'HEIC image format not supported';
								}
								return true;
							},
						})}
						// @ts-ignore
						showAsterisk
						id="companyLogo"
						defaultValue={formProps.getValues(FormInputKeys.companyLogo) || null}
						error={
							formProps.formState.errors[FormInputKeys.companyLogo]?.message &&
							formProps.formState.errors[FormInputKeys.companyLogo]?.message
						}
					/>
				</div>
				<div className="col-12 companyLogo">
					<PFInputAvatarCrop
						label="Company logo"
						showAsterisk
						id="companyLogo1"
						onChange={onChangeCompanyLogo}
						src={formProps.getValues(FormInputKeys.companyLogo) || null}
						avatarSize={45}
						variant="rounded-square"
						defaultAvatar={DEFAULT_COMPANY_AVATAR}
						hint=""
						error={
							formProps.formState.errors[FormInputKeys.companyLogo]?.message &&
							formProps.formState.errors[FormInputKeys.companyLogo]?.message
						}
					/>
				</div>
			</div>

			<div className="avatar d-none">
				<PFInput
					{...formProps.register(FormInputKeys.avatar, {
						required: advisor ? 'Avatar is required' : false,
						validate: value => {
							if (value === 'error') {
								return "The file you've uploaded is larger than 5MB. Please upload an image smaller than 5MB.";
							}
							if (value === 'error-type') {
								return 'HEIC image format not supported';
							}
							return true;
						},
					})}
					// @ts-ignore
					showAsterisk={!!advisor}
					id="avatar"
					defaultValue={
						formProps.getValues(FormInputKeys.avatar) ||
						(advisor && advisor?.profileImage)
					}
					error={
						formProps.formState.errors[FormInputKeys.avatar]?.message &&
						formProps.formState.errors[FormInputKeys.avatar]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} avatar`}>
				<PFInputAvatarCrop
					label="Profile Image"
					showAsterisk={!!advisor}
					id="avatar1"
					onChange={onChangeAvatar}
					src={
						formProps.getValues(FormInputKeys.avatar) ||
						(advisor && advisor?.profileImage)
					}
					avatarSize={isMobileScreen ? 100 : 140}
					hint=""
					error={
						formProps.formState.errors[FormInputKeys.avatar]?.message &&
						formProps.formState.errors[FormInputKeys.avatar]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} videoPresentationLink`}>
				<PFInput
					{...formProps.register(FormInputKeys.videoPresentationLink, {
						validate: generateValidator(
							false,
							validations.textFields.videoPresentationLink,
						),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Video Link"
					placeholder="Video Link"
					defaultValue={advisor ? advisor?.videoPresentationLink : ''}
					hint={videoPresentationLinkHint}
					error={
						formProps.formState.errors[FormInputKeys.videoPresentationLink]?.message &&
						formProps.formState.errors[FormInputKeys.videoPresentationLink]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} description`}>
				<PFTextarea
					{...formProps.register(FormInputKeys.description, {
						validate: generateValidator(false, validations.textFields.description),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Description"
					placeholder="Description"
					defaultValue={
						formProps.getValues(FormInputKeys.description) ||
						(advisor && advisor?.description)
					}
					hint={descriptionHint}
					error={
						formProps.formState.errors[FormInputKeys.description]?.message &&
						formProps.formState.errors[FormInputKeys.description]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} financialCertificates`}>
				<PFSelect
					{...formProps.register(FormInputKeys.financialCertificates)}
					// @ts-ignore
					defaultValue={
						formProps.getValues(FormInputKeys.financialCertificates) ||
						(advisor && advisor?.financialCertificates)
					}
					showAsterisk={false}
					/* TODO: sent to the server and received from the server as "Certificates". On the front side, the field is called "Credentials". */
					label="Credentials"
					placeholder="List your credentials"
					disabled={false}
					isMulti
					closeMenuOnSelect={false}
					isAsync
					onChange={value => {
						formProps.setValue(FormInputKeys.financialCertificates, value);
					}}
					getOptionValue={item => item.token}
					loadOptions={loadFinancialCertificatesOptions}
					formatOptionLabel={(option, {context}) => {
						return context === 'value'
							? option.name
							: `${option.name} ${option.description}`;
					}}
					defaultOptions
					error={
						formProps.formState.errors[FormInputKeys.financialCertificates]?.message &&
						formProps.formState.errors[FormInputKeys.financialCertificates]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} specialization`}>
				{/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */}
				<PFSelect
					{...formProps.register(FormInputKeys.specializationValue, {
						required: 'The option should be chosen',
						// что бы валидация работала, надо ставить тригер в юзЭф на вотч
					})}
					// @ts-ignore
					defaultValue={
						formProps.getValues(FormInputKeys.specializationValue) ||
						(advisor && specializationDefaultValue)
					}
					showAsterisk
					label="Roles available"
					placeholder="Enter your roles"
					disabled={false}
					isMulti
					closeMenuOnSelect={false}
					isAsync={false}
					onChange={value => {
						formProps.setValue(FormInputKeys.specializationValue, value);
					}}
					getOptionValue={item => item.name}
					options={SPECIALIZATION_OPTIONS_TYPES}
					formatOptionLabel={option => option.name}
					defaultOptions
					error={
						formProps.formState.errors[FormInputKeys.specializationValue]?.message &&
						formProps.formState.errors[FormInputKeys.specializationValue]?.message
					}
				/>
			</div>
			{Object.values(HASHTAG_TYPES).map(tag => {
				return (
					<div className={`${fullWidthClass} hashtagTokens`} key={tag.key}>
						<PFSelect
							{...formProps.register(tag.key)}
							// @ts-ignore
							defaultValue={
								formProps.getValues(tag.key) ||
								(advisor &&
									advisor?.hashtags?.filter(({type}) => type === tag.value))
							}
							showAsterisk={false}
							label={tag.label}
							placeholder={`Enter ${tag.label}`}
							disabled={false}
							isMulti
							closeMenuOnSelect={false}
							isAsync
							onChange={value => {
								formProps.setValue(tag.key, value);
							}}
							getOptionValue={item => item.token}
							loadOptions={async (SearchText, loadedOptions) => {
								const {
									body: options,
									count,
									currentCount,
								} = await fetchHashtags({
									SearchText,
									type: tag.value,
									skip: loadedOptions.length,
								});
								return {
									options,
									hasMore:
										currentCount !== 0 &&
										count > options.length + loadedOptions.length,
								};
							}}
							formatOptionLabel={option => option.name}
							defaultOptions
							error={
								formProps.formState.errors[tag.key]?.message &&
								formProps.formState.errors[tag.key]?.message
							}
						/>
					</div>
				);
			})}
		</>
	);
};

export default UserDataFormBloc;
