import React, {FC, ReactNode, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {emailService, profileService, usersService} from 'api/services';
import {iconBack} from 'assets';
import {PORTAL_PATHS} from 'const';
import {useBooleanState} from 'shared';
import {PFButton} from 'shared/components';
import {
	changeRoleWarningPopup,
	useChangePasswordPopup,
	useEditEmailPopup,
	useIsActiveUserPopup,
	useProfile,
} from 'shared/hooks';
import {useAppSelector} from 'store';
import {myProfileSelectors} from 'store/selectors';
import {AdvisorsRole} from 'types/enums';
import {Amplitude, ToastDispatcher, useErrorHelper} from 'utils';

import {DeleteAccountModal} from './components';

import './style.scss';

type PropsTypesItem = {
	title: string;
	description: string;
	onClick: () => any;
	variant: 'primary' | 'warning';
};

const AccountSettingsItem: FC<PropsTypesItem> = ({title, description, onClick, variant}) => {
	return (
		<button type="button" className={`accountSettings__item ${variant}`} onClick={onClick}>
			<div className="accountSettings__item__text">
				<div className="accountSettings__item__text-title">{title}</div>
				<div className="accountSettings__item__text-description">{description}</div>
			</div>
		</button>
	);
};

const AccountSettings = () => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);

	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;
	const myProfile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const role = myProfile?.roles[0];
	const myAdvisorToken = myProfile?.advisor.token;
	const myUserToken = myProfile?.token;

	const {showIsActiveUserConfirm, ConfirmIsActiveUserModal} = useIsActiveUserPopup();
	const {showEditEmailConfirm, ConfirmEditEmailModal} = useEditEmailPopup();
	const {showWarningConfirm, ConfirmWarningModal} = changeRoleWarningPopup();
	const {showChangePasswordConfirm, ConfirmChangePasswordModal} = useChangePasswordPopup();

	const [isDeleteAccountModalVisible, toggleDeleteAccountModal] = useBooleanState(false);

	const message = useRef<{text: ReactNode | null}>({
		text: null,
	});

	const fetchProfile = useProfile();

	const goBack = (): void => {
		navigate(-1);
	};

	const onClickChangePassword = () => {
		showChangePasswordConfirm();
	};

	const onClickChangeActivity = (): void => {
		if (role === AdvisorsRole.AccountAdministrator) {
			message.current.text = (
				<div className="warningMessage">
					You are the company’s current account administrator. Open “Edit Company Data” to
					assign another administrator to be able to set your account inactive.
				</div>
			);
			showWarningConfirm();
		} else {
			showIsActiveUserConfirm();
		}
	};

	const onClickChangeEmail = () => {
		showEditEmailConfirm();
	};

	const onClickPauseAccount = () => {
		if (role === AdvisorsRole.AccountAdministrator) {
			message.current.text = (
				<div className="warningMessage">
					You are the company’s current account administrator. Open “Edit Company Data” to
					assign another administrator to be able to pause your account.
				</div>
			);
			showWarningConfirm();
		} else {
			// showIsActiveUserConfirm();
			// TODO: add amplitude event after feature unlock
		}
	};

	const onClickDeleteAccount = () => {
		if (role === AdvisorsRole.AccountAdministrator) {
			message.current.text = (
				<div className="warningMessage">
					You are the company’s current account administrator. Open “Edit Company Data” to
					assign another administrator to be able to delete your account.
				</div>
			);
			showWarningConfirm();
		} else {
			toggleDeleteAccountModal();
		}
	};

	const onIsActive = async (isActive, token, name, reasonInactive = null) => {
		const body = {
			token,
			isActive,
			reasonInactive,
		};
		try {
			await usersService.setIsActive(body);

			Amplitude.track('Changed_Activity_Status', {
				status: isActive,
				reason: reasonInactive,
			});

			ToastDispatcher.success(
				`${body.isActive ? 'Your profile is active' : 'Your profile is inactive'}`,
			);
			await fetchProfile();
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const onChangeEmail = async email => {
		const body = {
			email,
			token: myUserToken,
		};
		try {
			await emailService.updateMyEmail(body);

			Amplitude.track('Changed_Email');
			// update user props?

			ToastDispatcher.success('Email updated');
			await fetchProfile();
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const onChangePassword = async body => {
		try {
			await profileService.updateMyPassword(body);

			Amplitude.track('Changed_Password');

			ToastDispatcher.success('Password updated');
			await fetchProfile();
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	return (
		<div className="accountSettings">
			<div className="accountSettings__container">
				<PFButton
					className="w-auto px-0 accountSettings__container-backButton"
					prefixIcon={backIcon}
					variant="plain"
					onClick={goBack}
					type="button">
					Back
				</PFButton>
				<div className="accountSettings__container-title">Account Settings</div>
				<div className="accountSettings__container-main">
					<AccountSettingsItem
						title="Change Your Activity Status"
						description="Going on vacation? Pausing work for another reason? Set your profile inactive"
						onClick={onClickChangeActivity}
						variant="primary"
					/>
					<AccountSettingsItem
						title="Change Email"
						description="Change the email you use to log in"
						onClick={onClickChangeEmail}
						variant="primary"
					/>
					<AccountSettingsItem
						title="Change Password"
						description="Change your password at least every three months"
						onClick={onClickChangePassword}
						variant="primary"
					/>
					<AccountSettingsItem
						title="Pause account"
						description="You will be able to activate your account again anytime"
						onClick={onClickPauseAccount}
						variant="primary"
					/>
					<AccountSettingsItem
						title="Delete account"
						description="You won’t be able to restore your account"
						onClick={onClickDeleteAccount}
						variant="primary"
					/>
				</div>
			</div>
			{myProfile && (
				<ConfirmWarningModal
					onSubmit={() =>
						navigate(
							`${PORTAL_PATHS.OTHER_PATHS.EDIT_COMPANY_DATA}#${myProfile.advisor.companyToken}`,
						)
					}
					message={message.current.text}
				/>
			)}
			{myProfile && (
				<ConfirmIsActiveUserModal
					isActive={myProfile.advisor.isActive}
					onSubmit={onIsActive}
					className="pf-confirmIsActiveUserModal"
					token={myAdvisorToken}
					name={null}
					title="Is Your Profile Active Now?"
					reason={myProfile.advisor.reasonInactive}
				/>
			)}
			{myProfile && (
				<ConfirmEditEmailModal
					className="accountSettings__changeEmail"
					onSubmit={onChangeEmail}
					email={myProfile.email}
					modalTitle="Your Login Email"
				/>
			)}
			{myProfile && (
				<ConfirmChangePasswordModal
					onSubmit={onChangePassword}
					className="accountSettings__changePassword"
				/>
			)}
			{myProfile && (
				<DeleteAccountModal
					visible={isDeleteAccountModalVisible}
					userToken={myProfile.token}
					fullName={`${myProfile?.firstName} ${myProfile?.lastName}`}
					onCancel={toggleDeleteAccountModal}
				/>
			)}
		</div>
	);
};

export default AccountSettings;
