import React, {FC} from 'react';

import classNames from 'classnames';
import {useAppDispatch} from 'store';
import {setTableReset, SpecFilterActions} from 'store/actions';

import './style.scss';

type SlideItemPropsTypes = {
	type: number;
	name: string;
	checked: boolean;
};

const FilterItem: FC<SlideItemPropsTypes> = ({type, name, checked}) => {
	const dispatch = useAppDispatch();

	const handleClick = itemType => {
		dispatch(setTableReset());
		// updateURL(1, 25, '', false, ''); // ! понадобится когда перепишется и сломается соответственно tableReducer
		if (itemType === 0 && !checked) {
			dispatch(SpecFilterActions.setSpecialization(itemType));
			return;
		}
		if (checked) {
			dispatch(SpecFilterActions.removeSpecialization(itemType));
		} else {
			dispatch(SpecFilterActions.setSpecialization(itemType));
		}
	};

	return (
		<button
			type="button"
			className={classNames('filter-item', {checked})}
			onClick={() => handleClick(type)}>
			{name}
		</button>
	);
};

export default FilterItem;
