import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import {configureStore} from '@reduxjs/toolkit';
import {Amplitude} from 'utils';

import rootReducer from './reducers';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['general', 'myProfile'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk],
});

export const persistor = persistStore(store, {}, () => {
	const state = store.getState();
	const myProfile = state?.myProfile?.data;

	if (myProfile) {
		Amplitude.identifyUser(myProfile);
	}
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
