import React, {FC} from 'react';

import './style.scss';

type PropsTypes = {
	teamCount: number | null;
};

const TeamCellItem: FC<PropsTypes> = ({teamCount}) => {
	return teamCount && teamCount > 0 ? (
		<div className="pf-teamContainer">
			<span className="pf-tableText pf-tableTeam">{teamCount}</span>
		</div>
	) : (
		<span className="pf-tableText pf-tableTeam">-</span>
	);
};

export default TeamCellItem;
