import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {Specialization} from '../../types/enums';

/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
interface CopyDeepLinkPopupState {
	isOpen: boolean;
	isMyLink: boolean;
	advisorToken: string | null;
	checkedTypes: Specialization[];
	shortLink: string;
}

type SetOpenActionType = {advisorToken: string; isMyLink?: boolean};

type SetSpecializationsActionType = Specialization[] | 0;

type RemoveSpecializationsActionType = Specialization;

type SetShortLinkActionType = string;

const initialState: CopyDeepLinkPopupState = {
	isOpen: false,
	isMyLink: false,
	advisorToken: null,
	checkedTypes: [],
	shortLink: '',
};

export const copyDeepLinkPopupSlice = createSlice({
	name: 'copyDeepLinkPopup',
	initialState,
	reducers: {
		setOpenDeepLinkPopup: (state, action: PayloadAction<SetOpenActionType>) => {
			state.isOpen = true;
			state.isMyLink = !!action.payload.isMyLink;
			state.advisorToken = action.payload.advisorToken;
		},
		setCloseDeepLinkPopup: state => {
			state.isOpen = initialState.isOpen;
			state.isMyLink = false;
			state.advisorToken = initialState.advisorToken;
			state.checkedTypes = initialState.checkedTypes;
			state.shortLink = initialState.shortLink;
		},
		setDeepShortLink: (state, action: PayloadAction<SetShortLinkActionType>) => {
			state.shortLink = action.payload;
		},
		setDeepLinkSpecialization: (state, action: PayloadAction<SetSpecializationsActionType>) => {
			if (action.payload === 0) {
				state.checkedTypes = [];
			} else {
				state.checkedTypes = [...state.checkedTypes, ...action.payload];
			}
		},
		removeDeepLinkSpecialization: (
			state,
			action: PayloadAction<RemoveSpecializationsActionType>,
		) => {
			state.checkedTypes = state.checkedTypes.filter(type => type !== action.payload);
		},
	},
});

export default copyDeepLinkPopupSlice.reducer;
