import React, {FC, ReactNode} from 'react';
import {Link} from 'react-router-dom';

import classNames from 'classnames';

import './style.scss';

interface BaseLinkProps {
	children: ReactNode;
	onClick?: () => void;
	className?: string;
}

interface RouterLinkProps extends BaseLinkProps {
	to: string;
	href?: never;
	target?: never;
}

interface SimpleLinkProps extends BaseLinkProps {
	href?: string;
	target?: '_blank' | '_self';
	to?: never;
}

type PropsTypes = RouterLinkProps | SimpleLinkProps;

const PFLink: FC<PropsTypes> = ({children, className, to, href, onClick, target = '_self'}) => {
	if (to) {
		return (
			<Link to={to} className={classNames('pf-link', className)} onClick={onClick}>
				{children}
			</Link>
		);
	}
	return (
		<a
			href={href}
			className={classNames('pf-link', className)}
			target={target}
			onClick={onClick}>
			{children}
		</a>
	);
};

export default PFLink;
