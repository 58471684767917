const colors = {
	whiteColor: '#ffffff',
	color2: '#3349b5',
	color4: '#59657a',
	color8: '#eeeff1',
	color15: '#f2f2f2',
};

const fonts = {
	baseFont: `"Inter", sans-serif`,
	secondaryFont: `"made_tommyregular", sans-serif`,
};
const controlBorder = state => {
	if (state.isFocused) {
		return colors.color2;
	}
	return colors.color15;
};
const style = {
	control: (provided, state) => ({
		// none of react-select's styles are passed to <Control />
		...provided,
		minHeight: 45,
		backgroundColor: colors.color15,
		borderColor: controlBorder(state),
		outline: 'none',
		boxShadow: 'none',
		'&:hover': {
			borderColor: controlBorder(state),
		},
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	indicatorsContainer: () => ({
		display: 'none',
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return {...provided, opacity, transition};
	},
	multiValue: provided => ({
		...provided,
		borderRadius: '10em',
		height: 31,
		alignItems: 'center',
		paddingLeft: 20,
		paddingRight: 12,
	}),
	multiValueLabel: provided => ({
		...provided,
		color: colors.color4,
		fontSize: 13,
		fontFamily: fonts.baseFont,
	}),
	multiValueRemove: provided => ({
		...provided,
		color: colors.color4,
		paddingLeft: '2px !important',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	}),
	menu: provided => ({
		...provided,
		boxShadow: '0px 1px 12px rgba(63, 72, 87, 0.1)',
	}),
	menuList: provided => ({
		...provided,
		padding: 0,
	}),
	option: (provided, state) => ({
		...provided,
		borderRadius: 3,
		background: state.isSelected ? colors.color2 : colors.whiteColor,
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 13,
		lineHeight: '18px',
		color: state.isSelected ? colors.whiteColor : colors.color4,
		'&:hover': {
			background: state.isSelected ? colors.color2 : colors.color8,
			color: state.isSelected ? colors.whiteColor : colors.color4,
		},
		'&:active': {
			background: colors.color2,
			color: colors.whiteColor,
		},
	}),
	valueContainer: provided => ({
		...provided,
		padding: '0 18px',
		maxHeight: '90px',
		overflow: 'auto',
	}),
};

export default style;
