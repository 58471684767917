import {uploadFile} from '../../../api/services/file';

export const useFileUpload = () => {
	const upload = async (file, config) => {
		try {
			const body = new FormData();
			body.append('file', file);
			const res = await uploadFile(body, config);
			return res?.data?.fileName;
		} catch (e) {
			throw e;
		}
	};
	return upload;
};
