import React, {useState} from 'react';

import {PFInput} from 'shared/components';

const FullNameCheckInput: React.FC<{fullName: string; onMatch: (match: boolean) => void}> = ({
	fullName,
	onMatch,
}) => {
	const [fullNameInput, setFullNameInput] = useState('');

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
		const {value} = event.target;
		setFullNameInput(value);
		onMatch(value === fullName);
	};

	return (
		<div className="col-12 mb-4 mt-4">
			<PFInput
				// @ts-ignore
				value={fullNameInput}
				onChange={handleChange}
				showAsterisk={false}
				label="Full Name"
				placeholder={fullName}
			/>
		</div>
	);
};

export default FullNameCheckInput;
