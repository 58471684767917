import React, {useEffect, useRef, useState} from 'react';
import {BIG_MOBILE_SCREEN_SIZE} from '../../../const/general';
import {SwipeableDrawer} from '@mui/material';
import './style.scss';
import {GetIsSideModalOpen} from '../../../store/selectors/UISetings';
import {ReactSVG} from 'react-svg';
import closeIcon from '../../../assets/icon-close.svg';
import classNames from 'classnames';

const PFSideModal = ({id, children, modalTogler, isLoading}) => {
	const isOpen = GetIsSideModalOpen();
	const getModalType = () => (window.innerWidth < BIG_MOBILE_SCREEN_SIZE ? 'bottom' : 'right');
	const [anchor, setAnchor] = useState(getModalType());
	const touchRef = useRef({
		touchstartX: 0,
		touchstartY: 0,
		touchendX: 0,
		touchendY: 0,
	});

	const listener = () => {
		setAnchor(getModalType());
	};

	useEffect(() => {
		window.addEventListener(`resize`, listener);
		return () => {
			window.removeEventListener(`resize`, listener);
		};
	}, []);

	const onHide = () => {
		modalTogler(id);
	};

	function handleGesture() {
		const touch = touchRef.current;
		if (
			(anchor === 'right' && touch.touchstartX - touch.touchendX > 50) ||
			(anchor === 'bottom' && touch.touchendY - touch.touchstartY > 300)
		) {
			onHide();
		}
	}

	const touchstartCallback = event => {
		const touch = touchRef.current;
		touch.touchstartX = event.changedTouches[0].screenX;
		touch.touchstartY = event.changedTouches[0].screenY;
	};

	const touchendCallback = event => {
		const touch = touchRef.current;
		touch.touchendX = event.changedTouches[0].screenX;
		touch.touchendY = event.changedTouches[0].screenY;
		handleGesture();
	};

	useEffect(() => {
		const gestureZone = document.querySelector('.pf-driver-root__paper');
		if (gestureZone) {
			gestureZone.addEventListener('touchstart', touchstartCallback);
			gestureZone.addEventListener('touchend', touchendCallback);
		}
		return () => {
			const gestureZone = document.querySelector('.pf-driver-root__paper');
			if (gestureZone) {
				gestureZone.removeEventListener('touchstart', touchstartCallback);
				gestureZone.removeEventListener('touchend', touchendCallback);
			}
		};
	}, [isOpen]);

	return (
		<SwipeableDrawer
			classes={{root: 'pf-driver-root', paper: 'pf-driver-root__paper'}}
			anchor={anchor}
			open={isLoading && isOpen ? !isOpen : isOpen}
			onClose={onHide}
			onOpen={() => false}
			disableBackdropTransition={true}
			swipeAreaWidth={20}
			disableSwipeToOpen={true}
			hysteresis={0.5}
			hideBackdrop={false}
			ModalProps={{
				keepMounted: true,
			}}>
			<button
				className={classNames('pf-driver-root__closeButton', {
					bottom: anchor === 'bottom',
				})}
				onClick={onHide}>
				{anchor === 'right' && <ReactSVG wrapper={'div'} src={closeIcon} />}
			</button>

			{isOpen && children}
		</SwipeableDrawer>
	);
};

export default PFSideModal;
