import {SET_ADVISOR_CLIENTS_DATA, CLEANED_UP_ADVISOR_CLIENTS_DATA} from '../types/Types';

const INITIAL_STATE = {
	advisors: [],
	fullCount: 0,
};

const advisorReducer = (state = INITIAL_STATE, {payload, type}) => {
	switch (type) {
		case SET_ADVISOR_CLIENTS_DATA:
			return {
				...state,
				advisors: payload.body,
				fullCount: payload.fullCount,
			};
		case CLEANED_UP_ADVISOR_CLIENTS_DATA:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default advisorReducer;
