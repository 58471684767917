import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {ReactSVG} from 'react-svg';

import {SubscriptionDataService} from 'api/services';
import iconBack from 'assets/icon-arrow-back.svg';
import {PLANS} from 'const';
import {PFButton, PFLink, PFLoader} from 'shared/components';
import {useAppDispatch, useAppSelector} from 'store';
import {fetchSubscription} from 'store/reducers/SubscriptionReducer';
import {myProfileSelectors, SubscriptionSelectors} from 'store/selectors';
import {PlanInfo} from 'types/subscriptions';
import {ToastDispatcher} from 'utils';

import {Plan, PriceActions, PriceTable} from './components';

import styles from './styles.module.scss';

const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;

export const ManageSubscription: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const isLoading = useAppSelector(SubscriptionSelectors.selectIsLoading);
	const currentPlan = useAppSelector(SubscriptionSelectors.selectCurrentPlan);

	const [selectedPlan, setSelectedPlan] = useState(PLANS[0]);
	const [isAnnual, setIsAnnual] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		setSelectedPlan(currentPlan.plan);
		setIsAnnual(currentPlan.plan.id === 2 ? true : currentPlan.isAnnual);
	}, [currentPlan]);

	const handleSwitchPlanPeriod = () => {
		setIsAnnual(prevIsAnnual => {
			if (selectedPlan === PLANS[0]) {
				setSelectedPlan(PLANS[1]);
			}

			return !prevIsAnnual;
		});
	};

	const goBack = () => {
		navigate(-1);
	};

	const handlePlanClick = (plan: PlanInfo) => {
		setSelectedPlan(plan);

		if (plan.id === 2) {
			setIsAnnual(true);
		}
		if (plan.id === 1) {
			setIsAnnual(false);
		}
	};

	const handleUpdateSubscription = async () => {
		try {
			if (profile) {
				if (selectedPlan === PLANS[0]) {
					setSubmitting(true);
					await SubscriptionDataService.cancel();

					// @ts-ignore
					dispatch(fetchSubscription());
					ToastDispatcher.success('Subscription updated.');
				} else if (currentPlan.plan === PLANS[1]) {
					setSubmitting(true);
					const link = await SubscriptionDataService.getUpdateLink();

					window.open(link.data.link, '_blank');
				} else {
					const link = SubscriptionDataService.getCheckoutLink({
						isAnnual,
						email: profile.email,
						firstName: profile.firstName,
						lastName: profile.lastName,
					});

					window.open(link, '_blank');
				}
			}
		} catch (err) {
			ToastDispatcher.error('Somethig went wrong.');
		} finally {
			setSubmitting(false);
		}
	};

	const canUpdateSubscription =
		currentPlan.plan === PLANS[0]
			? currentPlan.plan !== selectedPlan
			: currentPlan.plan !== selectedPlan || currentPlan.isAnnual !== isAnnual;

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<PFButton
					className={`w-auto px-0 ${styles.back}`}
					prefixIcon={backIcon}
					variant="plain"
					onClick={goBack}
					type="button">
					Back
				</PFButton>
				<div className={styles.title}>Manage My Subscription</div>

				{isLoading ? (
					<PFLoader />
				) : (
					<div className={styles.content}>
						<div className={styles.info}>
							<div className={styles.plans}>
								{PLANS.map(plan => (
									<Plan
										key={plan.id}
										plan={plan}
										isAnnual={isAnnual}
										selected={plan === selectedPlan}
										onClick={handlePlanClick}
									/>
								))}
							</div>

							<div className={styles.hint}>
								* Please note that for each individual user, the subscription price
								will not increase after purchase and will remain grandfathered. If
								the subscription is canceled and later resumed, the user will be
								subject to the updated pricing. The table below shows the quarterly
								increases in price.
							</div>

							<PriceTable />
						</div>

						<div className={styles.actions}>
							<PriceActions
								isLoading={submitting}
								canUpdateSubscription={canUpdateSubscription}
								plan={selectedPlan}
								isAnnual={isAnnual}
								onSwitchPlanPeriod={handleSwitchPlanPeriod}
								onUpdateSubscription={handleUpdateSubscription}
							/>

							<div className={styles.links}>
								<PFLink
									href="https://pocketfinance-test.chargebee.com/users/edit_account"
									target="_blank">
									Manage Payment Info
								</PFLink>
								<PFLink
									href="https://pocketfinance-test.chargebee.com/transactions"
									target="_blank">
									Payment History
								</PFLink>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
