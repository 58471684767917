import React, {useEffect, useState} from 'react';
import {ReactSVG} from 'react-svg';

import radioIcon from 'assets/radio/radio-btn-active.svg';
import radioSelectedIcon from 'assets/radio/radio-button-selected.svg';
import cn from 'classnames';
import {PlanInfo} from 'types/subscriptions';

import {getCurrentPricing} from './PriceActions/utils';
import {PricingData} from './PriceTable';

import styles from './Plan.styles.module.scss';

export const Plan: React.FC<{
	plan: PlanInfo;
	isAnnual: boolean;
	selected?: boolean;
	onClick: (data: PlanInfo) => void;
}> = ({plan, isAnnual, selected, onClick}) => {
	const handleClick = () => {
		onClick(plan);
	};

	const [currentPricing, setCurrentPricing] = useState<PricingData | null>(null);

	useEffect(() => {
		const fetchPricing = async () => {
			const pricing = await getCurrentPricing();
			setCurrentPricing(pricing || null);
		};

		fetchPricing();
	}, [isAnnual]);

	let pricingLabel;

	if (!currentPricing) {
		return <div>Loading...</div>;
	}

	if (plan.id === 1) {
		pricingLabel = 'Free';
	} else {
		pricingLabel = isAnnual ? currentPricing.annual : currentPricing.monthly;
	}

	return (
		<div
			className={cn(styles.container, {
				[styles.containerSelected]: selected,
			})}
			onClick={handleClick}
			aria-hidden
			tabIndex={0}
			role="button">
			<div
				className={cn(styles.header, {
					[styles.headerSelected]: selected,
				})}>
				<ReactSVG className={styles.icon} src={selected ? radioSelectedIcon : radioIcon} />

				<div>
					<div className={styles.title}>{plan.title}</div>
					<div
						className={cn(styles.price, {
							[styles.priceSelected]: selected,
						})}>
						<span>
							{plan.id === 1
								? ' '
								: isAnnual &&
								  !!currentPricing.annualBeforeDiscount &&
								  currentPricing.annualBeforeDiscount}
						</span>
						{pricingLabel}
					</div>
				</div>
			</div>
			<div className={styles.content}>
				<div className={styles.features}>
					{plan.features.map(feature => (
						<div key={feature} className={styles.feature}>
							&mdash; {feature}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
