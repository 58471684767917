import React from 'react';
import './style.scss';
import PFNote from '../../../shared/components/PF-Note';

const AdvisorEmptyPage = ({title, subtitle, steps, note, className}) => {
	const main = steps?.map((item, index) => {
		return (
			<div key={index} className={'main-item'}>
				<span className={'dot'}>{index + 1}</span>
				<div className={'description'}>{item.description}</div>
				<div className={'content'}>{item.content}</div>
			</div>
		);
	});
	return (
		<div className={`emptyPage__container ${className ? className : ''}`}>
			<div className={`emptyPage__container__wrapper`}>
				<div className={'emptyPage__header'}>
					<h1>{title}</h1>
					<h2>{subtitle}</h2>
				</div>
				<div className={'emptyPage__main'}>{main}</div>
				{note && (
					<div className={'emptyPage__footer'}>
						<PFNote>{note}</PFNote>
					</div>
				)}
			</div>
		</div>
	);
};

export default AdvisorEmptyPage;
