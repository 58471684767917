import {useCallback, useState} from 'react';

export default function useBooleanState(
	initialValue: boolean,
): [boolean, (value?: boolean) => void] {
	const [value, setValue] = useState(initialValue);

	const toggleValue = useCallback((newValue?: boolean) => {
		if (newValue === undefined) {
			setValue(prevValue => !prevValue);
		} else {
			setValue(newValue);
		}
	}, []);

	return [value, toggleValue];
}
