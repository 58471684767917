import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {EditProfileForm, ProfileFormContainer} from 'components';
import {PORTAL_PATHS} from 'const';
import {useUpdateProfile} from 'shared/hooks';
import {useAppSelector} from 'store';
import {setCurrentPageTitle} from 'store/actions';
import {myProfileSelectors} from 'store/selectors';

import './style.scss';

const MyProfileEdit = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const advisor = useAppSelector(myProfileSelectors.selectMyProfileData)?.advisor;
	const updateProfile = useUpdateProfile();

	const title = 'Edit My Profile';
	const goBack = (): void => {
		navigate(PORTAL_PATHS.OTHER_PATHS.MY_PROFILE);
	};

	useEffect(() => {
		dispatch(setCurrentPageTitle('Edit My Data'));
	}, []);

	return (
		<div className="myProfileEdit">
			<ProfileFormContainer title={title} goBack={goBack}>
				{advisor && (
					<EditProfileForm
						advisor={advisor}
						updateProfile={updateProfile}
						goBack={goBack}
					/>
				)}
			</ProfileFormContainer>
		</div>
	);
};

export default MyProfileEdit;
