import React, {forwardRef} from 'react';
import Select from 'react-select';
import {AsyncPaginate} from 'react-select-async-paginate';
import classNames from 'classnames';
import style from './style';
import './style.scss';
import CreatableSelect from 'react-select';

const PFSelect = forwardRef(
	(
		{
			label,
			labelSufix,
			showAsterisk,
			error,
			options,
			hint,
			onSelect,
			onChange,
			isAsync,
			icon,
			onIconClick,
			disabled,
			isClearable,
			childrenBeforeInput,
			childrenAfterInput,
			menuPortalTarget = undefined,
			menuPlacement = 'bottom',
			...props
		},
		ref,
	) => {
		const Component = isAsync ? AsyncPaginate : Select;

		const handleChange = selectedOption => {
			// onSelect({ selectedOption });
			onChange(selectedOption);
		};
		const componentProps = {
			classNamePrefix: 'pf-select',
			styles: style,
			isClearable: false,
			hideSelectedOptions: false,
			onChange: handleChange,
			options: options,
			isDisabled: disabled,
		};
		if (isAsync) {
			componentProps.selectRef = ref;
		} else {
			componentProps.ref = ref;
		}

		const clearableHandleChange = selectedOption => {
			onChange(selectedOption);
		};
		const clearableHandleInputChange = inputValue => {
			if (inputValue) {
				const isNew = options.find(item => item.isNew === true);
				if (!isNew) {
					options.push({value: inputValue, label: inputValue, isNew: true});
				} else {
					isNew.value = inputValue;
					isNew.label = inputValue;
				}
			}
		};

		return (
			<div className="pf-select">
				<div className="pf-select__label-wrapper">
					<label className="pf-select__label">
						{showAsterisk && <span className="pf-select__label-asterisk">*</span>}
						{label}
					</label>
					{labelSufix}
				</div>
				<div className="pf-select__component-wrapper">
					{childrenBeforeInput && childrenBeforeInput}
					{isClearable ? (
						<CreatableSelect
							isClearable
							styles={style}
							onChange={clearableHandleChange}
							onInputChange={clearableHandleInputChange}
							options={options}
							ref={ref}
							menuPortalTarget={menuPortalTarget}
							menuPlacement={menuPlacement}
							{...props}
						/>
					) : (
						<Component
							// className="pf-select"
							menuPortalTarget={menuPortalTarget}
							menuPlacement={menuPlacement}
							{...componentProps}
							{...props}
						/>
					)}
					{childrenAfterInput && childrenAfterInput}
					{icon && (
						<span onClick={onIconClick} className="pf-input__field-icon">
							{icon}
						</span>
					)}
				</div>
				<p
					className={classNames('pf-select__hint', {
						'pf-select__hint--error': error,
					})}>
					{error || hint}
				</p>
			</div>
		);
	},
);

PFSelect.defaultProps = {
	options: [],
	closeMenuOnSelect: false,
	blurInputOnSelect: false,
	onSelect: () => {},
};

export default PFSelect;
