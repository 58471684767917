import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';

import * as todoClients from 'api/services/todo';
import classNames from 'classnames';
import {DAYS, MONTHS} from 'components/UserProfile/Forms/constants';
import PFButton from 'shared/components/PF-Button';
import PFInput from 'shared/components/PF-Input';
import PFPopup from 'shared/components/PF-Popup';
import PFSelect from 'shared/components/PF-Select';
import PFTextarea from 'shared/components/PF-Textarea';
import {TodoModalActionType} from 'types';
import {
	Amplitude,
	generateValidator,
	getUTSDate,
	getYears,
	parseDate,
	parseDate1,
	ToastDispatcher,
	validations,
} from 'utils';

import styles from './styles.module.scss';

export const fullWidthInputClass = 'col-12 mb-4';

export const YEARS = getYears(2, false);

const SetTodoModal: React.FC<{
	token: string;
	title: string | null;
	text: string | null;
	deadline: string | null;
	action: TodoModalActionType;
	visible: boolean;
	className?: string;
	onCancel: () => void;
	updateCallback?: () => void;
}> = ({token, title, text, deadline, action, visible, className, onCancel, updateCallback}) => {
	const {register, formState, handleSubmit, setValue} = useForm({
		mode: 'onChange',
	});

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [titleHintValue, setTitleHintValue] = useState(title?.length || 0);

	const [descriptionHintValue, setDescriptionHintValue] = useState(text?.length || 0);

	const parsedDeadlineDate = useMemo(() => parseDate1(deadline), [deadline]);

	const titleHint = (
		<div className={classNames('counter-hint', {error: titleHintValue > 100})}>
			{titleHintValue}/100
		</div>
	);

	const descriptionHint = (
		<div className={classNames('counter-hint', {error: descriptionHintValue > 500})}>
			{descriptionHintValue}/500
		</div>
	);

	const submitForm = async valueObj => {
		const toastsMessage =
			action === 'update' ? 'Task edited. The client will be notified' : 'Task sent';

		const date =
			(valueObj.year !== undefined &&
				valueObj.month !== undefined &&
				valueObj.day !== undefined &&
				getUTSDate({
					year: valueObj.year,
					month: valueObj.month,
					date: valueObj.day,
				})) ||
			null;

		try {
			setIsSubmitting(true);
			if (action === 'create') {
				const setActionBody = {
					userToken: token,
					title: valueObj.title ? valueObj.title : '',
					text: valueObj.text ? valueObj.text : '',
					deadline: date,
					notificationType: null,
				};
				await todoClients.setTodo(setActionBody);

				Amplitude.track('Sent_Task', {
					client: token,
				});
			}
			if (action === 'update') {
				const updateActionBody = {
					token,
					title: valueObj.title ? valueObj.title : '',
					text: valueObj.text ? valueObj.text : '',
					deadline: date,
					notificationType: null,
				};
				await todoClients.updateTodo(updateActionBody);

				Amplitude.track('Advisor_Edit_Task', {
					client: token,
				});
			}
			ToastDispatcher.success(toastsMessage);
			onCancel();
			updateCallback?.();
			// eslint-disable-next-line no-useless-catch
		} catch (e: any) {
			ToastDispatcher.error(
				`Failed to ${action === 'update' ? 'edit' : 'send'} task. Please try again`,
			);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		setValue('month', parsedDeadlineDate?.month);
		setValue('day', parsedDeadlineDate?.day);
		setValue('year', parsedDeadlineDate?.year || YEARS[0]);
	}, [parsedDeadlineDate]);

	return (
		<PFPopup
			isShow={visible}
			handleClose={onCancel}
			isShowFooter={false}
			submitBtnText="Save"
			primaryVariant="primary"
			isCloseButton
			isShowCancelBtn={false}
			title={`${action === 'update' ? 'Edit' : 'Send'} Task`}
			onSubmit={() => false}
			className={{root: className, paper: styles.content}}>
			<form onSubmit={handleSubmit(submitForm)} className="todoSentPopup__form">
				<div className="col-12 mb-4 note">
					<PFInput
						{...register('title', {
							required: true,
							validate: generateValidator(true, validations.textFields.todoTitle),
							onChange: evt => {
								setTitleHintValue(evt.target.value.trim().length);
							},
						})}
						// @ts-ignore
						showAsterisk={false}
						label={false}
						placeholder="Title"
						defaultValue={title}
						hint={titleHint}
						error={formState.errors?.title?.message && formState.errors?.title?.message}
						className="mb-3"
					/>
					<PFTextarea
						{...register('text', {
							required: true,
							validate: generateValidator(true, validations.textFields.todoText),
							onChange: evt => {
								setDescriptionHintValue(evt.target.value.trim().length);
							},
						})}
						// @ts-ignore
						showAsterisk={false}
						label={false}
						placeholder="Task details"
						defaultValue={text}
						hint={descriptionHint}
						error={formState.errors?.text?.message && formState.errors?.text?.message}
						className="mb-3"
					/>

					<div className={`${fullWidthInputClass} ${styles.date}`}>
						<div className={styles.month}>
							<PFSelect
								{...register('month')}
								// @ts-ignore
								showAsterisk={false}
								defaultValue={
									MONTHS.find(item => item.value === parsedDeadlineDate?.month) ||
									''
								}
								label="Deadline (optional)"
								placeholder="Month"
								disabled={false}
								isMulti={false}
								closeMenuOnSelect
								isAsync={false}
								onChange={value => {
									setValue('month', value.value);
								}}
								getOptionValue={option => option.value}
								options={MONTHS}
								formatOptionLabel={option => option.label}
								defaultOptions
								menuPortalTarget={document.body}
								menuPlacement="top"
							/>
						</div>
						<div className={styles.day}>
							<PFSelect
								{...register('day')}
								// @ts-ignore
								showAsterisk={false}
								defaultValue={
									DAYS.find(item => item.value === parsedDeadlineDate?.day) || ''
								}
								label={false}
								placeholder="Day"
								disabled={false}
								isMulti={false}
								closeMenuOnSelect
								isAsync={false}
								onChange={value => {
									setValue('day', value.value);
								}}
								getOptionValue={option => option.value}
								options={DAYS}
								formatOptionLabel={option => option.label}
								defaultOptions
								menuPortalTarget={document.body}
								menuPlacement="top"
							/>
						</div>
						<div className={styles.year}>
							<PFSelect
								{...register('year')}
								// @ts-ignore
								showAsterisk={false}
								defaultValue={
									YEARS.find(item => item.value === parsedDeadlineDate?.year) ||
									''
								}
								label={false}
								placeholder="Year"
								disabled={false}
								isMulti={false}
								closeMenuOnSelect
								isAsync={false}
								onChange={value => {
									setValue('year', value.value);
								}}
								getOptionValue={option => option.value}
								options={YEARS}
								formatOptionLabel={option => option.label}
								defaultOptions
								menuPortalTarget={document.body}
								menuPlacement="top"
							/>
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-center">
					<div className="d-flex w-100 m-auto pf-modal__footer-inner">
						<PFButton
							disabled={!formState.isValid || isSubmitting}
							onClick={() => false}
							className="pf-profileEdit__form-button pf-profileEdit__form-button--submit pf-edit-email-submit"
							type="submit">
							Send
						</PFButton>
						<PFButton onClick={onCancel} variant="secondary" type="button">
							Cancel
						</PFButton>
					</div>
				</div>
			</form>
		</PFPopup>
	);
};

export default SetTodoModal;
