import {RootState} from '../store';

export const selectTableCheckedData = (state: RootState) => state.table.checkedData;
export const selectTableSearchData = (state: RootState) => state.table.search;
export const selectTableResetData = (state: RootState) => state.table.reset;
export const selectTableReverseData = (state: RootState) => state.table.reverse;
export const selectTableModalData = (state: RootState) => state.table.modalData;
export const selectSelectedTableRow = (state: RootState) => state.table.selectedTableRow;
export const selectTablePage = (state: RootState) => state.table.page;
export const selectTableDisplayLength = (state: RootState) => state.table.displayLength;
export const selectIsShowSearch = (state: RootState) => state.table.showSearch;
