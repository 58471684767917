import React, {FC, ReactNode} from 'react';
import {Controller, FieldError, FieldErrorsImpl, Merge} from 'react-hook-form';
import {ReactSVG} from 'react-svg';

import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import classNames from 'classnames';
import {getClassNamesWithVariant} from 'utils';

import radioIcon from '../../../assets/radio/radio-btn-active.svg';
import radioSelectedIcon from '../../../assets/radio/radio-button-selected.svg';

import './style.scss';

type PropsTypes = {
	control: any;
	name: string;
	options: {value: string; label: string; disabled?: boolean}[];
	variant: 'vertical' | 'horizontal';
	className?: string;
	error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
	hint?: ReactNode | string;
	defaultValue?: string;
};

const PFRadioGroup: FC<PropsTypes> = ({
	control,
	name,
	options,
	variant,
	className,
	error,
	hint,
	defaultValue,
}) => {
	return (
		<div className="radioGroup">
			<Controller
				defaultValue={defaultValue || options[0].value}
				render={({field}) => (
					<div className={getClassNamesWithVariant('pf-radio-group', variant, className)}>
						<RadioGroup
							row={variant !== 'vertical'}
							defaultValue={defaultValue || options[0].value}
							{...field}
							name="gender1">
							{options.map(item => (
								<FormControlLabel
									key={item.value}
									value={item.value}
									control={
										<Radio
											checkedIcon={
												<ReactSVG
													className="radioIcon"
													src={radioSelectedIcon}
												/>
											}
											icon={
												<ReactSVG className="radioIcon" src={radioIcon} />
											}
											disabled={
												typeof item.disabled === 'boolean'
													? item.disabled
													: false
											}
										/>
									}
									label={item.label}
								/>
							))}
						</RadioGroup>
						{(error || hint) && (
							<div
								className={classNames('pf-radio-group__hint', {
									'pf-radio-group__hint--error': error,
								})}>
								{(typeof error === 'string' && error) || hint}
							</div>
						)}
					</div>
				)}
				rules={{required: true}}
				name={name}
				control={control}
			/>
		</div>
	);
};

export default PFRadioGroup;
