import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {criticalIllnessInsurance} from 'assets';
import {PORTAL_PATHS} from 'const';
import {useDeleteInsurance, useDeleteInsurancePopup} from 'shared';
import {CriticalIllnessInsuranceType} from 'types';
import {InsurancePayerEnum, InsuranceTypeEnum} from 'types/enums';
import {Amplitude, useErrorHelper} from 'utils';

import {InsuranceInfoHeader, InsurancePropertyItem} from './components';
import {getCompanyName} from './utils';

import './styles.scss';

type Props = {
	insurance: CriticalIllnessInsuranceType;
	isCompact?: boolean;
	containerClassName?: string;
	onRefreshInsurances?: () => Promise<void>;
};

const name = 'Critical Illness Insurance';

const CriticalIllnessInsuranceItem: React.FC<Props> = ({
	insurance,
	isCompact,
	containerClassName,
	onRefreshInsurances,
}) => {
	const navigate = useNavigate();
	const deleteInsurance = useDeleteInsurance();
	const {showConfirm, ConfirmModal} = useDeleteInsurancePopup();
	const errorHelper = useErrorHelper(navigate);

	const onDeleteCriticalIllnessInsurance = async () => {
		// eslint-disable-next-line no-useless-catch
		try {
			if (insurance.token) {
				await deleteInsurance(
					{token: insurance.token, type: InsuranceTypeEnum.CriticalIllnessInsurance},
					name,
				);

				Amplitude.track('Deleted_Insurance', {
					type: 'Critical Illness Insurance',
				});

				if (onRefreshInsurances) {
					onRefreshInsurances();
				}
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const actions = isCompact
		? undefined
		: [
				{
					name: 'Edit',
					handler: () => {
						navigate(
							`${PORTAL_PATHS.OTHER_PATHS.EDIT_CRITICAL_ILLNESS_INSURANCE}#${insurance.userToken}&${insurance.token}`,
						);
					},
				},
				{
					name: 'Delete',
					handler: showConfirm,
				},
		  ];

	const company = getCompanyName(
		insurance.insuranceCompany,
		insurance.customInsuranceCompanyName,
	);

	const date = insurance.policyRenewalDate ? new Date(insurance.policyRenewalDate) : null;

	return (
		<>
			<div className={containerClassName}>
				<InsuranceInfoHeader
					title={name}
					icon={<ReactSVG src={criticalIllnessInsurance} />}
					company={company}
					amount={insurance.amountOfCoverage}
					date={date}
					actions={actions}
				/>
				{!isCompact && (
					<div className="insurance-properties--container">
						<div className="insurance-properties--group">
							{!!insurance.insurancePayer && (
								<InsurancePropertyItem
									label="Purchased"
									value={
										insurance.insurancePayer
											? (insurance.insurancePayer ===
													InsurancePayerEnum.Employer &&
													'Through employer') ||
											  (insurance.insurancePayer ===
													InsurancePayerEnum.Independent &&
													'Independently')
											: 'n/a'
									}
								/>
							)}
							<InsurancePropertyItem
								label="Who is this policy for?"
								value={insurance?.insured || 'n/a'}
							/>
							<InsurancePropertyItem
								label="What is the purpose of this plan?"
								value={insurance?.purposeOfPlan || 'n/a'}
							/>
						</div>
					</div>
				)}
			</div>
			<ConfirmModal name={name} onSubmit={onDeleteCriticalIllnessInsurance} className="" />
		</>
	);
};

export default CriticalIllnessInsuranceItem;
