import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {PFTabsContainer} from 'shared/components';
import {useOpportunitiesByToken, useUserProfile} from 'shared/hooks';
import {OpportunityType} from 'types';
import {getFullName, parseUrlQuery, useErrorHelper} from 'utils';

import {UserProfileOpportunitiesBloc} from '../../../../components';
import ViewAll from '../ViewAll';

type StateOpportunitiesType = {
	current: OpportunityType[] | null;
	actioned: OpportunityType[] | null;
	dismissed: OpportunityType[] | null;
};

const initialNoteModalState = {
	current: null,
	actioned: null,
	dismissed: null,
};

const ViewAllOpportunities = () => {
	const {token} = parseUrlQuery(document.location);
	const fetchOpportunitiesInfo = useOpportunitiesByToken();
	const fetchUserInfo = useUserProfile();
	const [opportunities, setOpportunities] =
		useState<StateOpportunitiesType>(initialNoteModalState);
	const [name, setName] = useState<string>('User without name');
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);

	const fetchUser = async userToken => {
		// eslint-disable-next-line no-useless-catch
		try {
			const data = await fetchUserInfo(userToken);
			const fullName = getFullName(data?.user?.firstName, data?.user?.lastName);
			setName(fullName);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const fetchOpportunities = async userToken => {
		// eslint-disable-next-line no-useless-catch
		try {
			const data = await fetchOpportunitiesInfo(userToken);
			const newOpportunitiesState = {
				current: data.filter(item => !item.isActioned && !item.isDismissed),
				actioned: data.filter(item => item.isActioned),
				dismissed: data.filter(item => item.isDismissed),
			};
			setOpportunities(newOpportunitiesState);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const updateCallback = () => {
		fetchOpportunities(token);
	};

	useEffect(() => {
		fetchOpportunities(token);
		fetchUser(token);
	}, [token]);

	return (
		<ViewAll userToken={token} title={`${decodeURI(name)}'s Opportunities`}>
			<PFTabsContainer labels={['Current', 'Actioned', 'Dismissed']}>
				<UserProfileOpportunitiesBloc
					userToken={token}
					opportunities={
						opportunities.current && opportunities.current.length > 0
							? opportunities.current
							: 'New opportunities will appear here.'
					}
					isCompact={false}
					updateCallback={updateCallback}
				/>
				<UserProfileOpportunitiesBloc
					userToken={token}
					opportunities={
						opportunities.actioned && opportunities.actioned.length > 0
							? opportunities.actioned
							: 'Opportunities marked as actioned will appear here.'
					}
					isCompact={false}
					updateCallback={updateCallback}
				/>
				<UserProfileOpportunitiesBloc
					userToken={token}
					opportunities={
						opportunities.dismissed && opportunities.dismissed.length > 0
							? opportunities.dismissed
							: 'Dismissed opportunities will appear here.'
					}
					isCompact={false}
					updateCallback={updateCallback}
				/>
			</PFTabsContainer>
		</ViewAll>
	);
};

export default ViewAllOpportunities;
