import React, {useState} from 'react';

import styles from './Content.module.scss';
import {ReactSVG} from 'react-svg';
import {PFButton, PFInput, PFLink, PFPopup} from 'shared/components';
import {iconBack} from 'assets';
import {useNavigate} from 'react-router';
import {useForm} from 'react-hook-form';
import {Amplitude, hints, validations} from 'utils';
import {usersService} from 'api/services';
import {AxiosError} from 'axios';
import {PATHS} from 'const';

type FormFields = {
	email: string;
};

export const Content: React.FC<{}> = ({}) => {
	const navigate = useNavigate();

	const {
		register,
		formState: {errors, isValid},
		handleSubmit,
		getValues,
	} = useForm<FormFields>({mode: 'onChange'});

	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	const [modalMessage, setModalMessage] = useState<string>('');

	const hideModal = () => {
		setModalMessage('');
	};

	const handleSubmitModal = () => {
		hideModal();

		if (!isError) {
			navigate(
				PATHS.PORTAL.OTHER_PATHS.SET_PASSWORD_BY_CODE +
					'?e=' +
					encodeURIComponent(getValues('email')),
			);
		}
	};

	const onSubmit = async (fields: FormFields) => {
		try {
			setIsProcessing(true);

			const response = await usersService.sendEmailCode({
				username: fields.email,
				isFromPortal: true,
			});

			const message: string = response.data?.message || 'Success.';

			setIsError(false);
			setModalMessage(message);
		} catch (err) {
			const message =
				(err as AxiosError<{message?: string}>)?.response?.data?.message ||
				'Something went wrong. Please try again later.';

			setIsError(true);
			setModalMessage(message);
		} finally {
			setIsProcessing(false);
		}
	};

	return (
		<>
			<section className={styles.container}>
				<div className={styles.contentContainer}>
					<PFButton
						type="button"
						variant="plain"
						prefixIcon={<ReactSVG wrapper="span" src={iconBack} />}
						onClick={() => {
							navigate(-1);
						}}
						className={styles.back}>
						Back
					</PFButton>

					<article className={styles.content}>
						<div className={styles.title}>Restore Your Password</div>
						<div className={styles.subtitle}>
							We will send a link to restore your password to your email
						</div>

						<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
							<PFInput
								{...register('email', {
									required: hints.email.required,
									validate: validations.email,
								})}
								//@ts-ignore
								error={errors.email?.message}
								label="Email"
							/>
							<PFButton
								type="submit"
								disabled={isProcessing || !isValid}
								className={styles.button}>
								Send
							</PFButton>
						</form>

						<div className={styles.contacts}>
							<div className={styles.hint}>Don’t remember the email?</div>

							<PFLink
								href="mailto:support@pocketfinance.com"
								onClick={() => {
									Amplitude.track('Contacted_Support', {
										source: 'Restore Password',
									});
								}}
								className={styles.link}>
								Contact the Administrator
							</PFLink>
						</div>
					</article>
				</div>
			</section>

			<PFPopup
				isShow={!!modalMessage}
				handleClose={hideModal}
				submitBtnText={'Ok'}
				isShowCancelBtn={false}
				primaryVariant="primary"
				isCloseButton={false}
				title={modalMessage}
				subtitle={false}
				onSubmit={handleSubmitModal}
			/>
		</>
	);
};
