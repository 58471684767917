import {resetMyProfile, SignIn, SignOut, resetUi, setMyProfile} from '../../../store/actions';
import * as authService from '../../../api/services/auth';
import {useState} from 'react';
import PFPopup from '../../components/PF-Popup';
import Storage from '../../../utils/futureTS/localStorage';
import {useAppDispatch} from '../../../store';
import * as advisorService from '../../../api/services/advisor';
import {Amplitude, ToastDispatcher} from '../../../utils';
import {AUTH_TOKEN, REFRESH_TOKEN} from '../../../api/httpClient/apiConst';
import {AdvisorsRole} from '../../../types/enums';
import {fetchSubscription} from 'store/reducers/SubscriptionReducer';

//We're implementing all of the LogOut confirmation/pop-up modal logic within this hook.
export const useSignOut = navigate => {
	const dispatch = useAppDispatch();
	const [showConfirmation, setShowConfirmation] = useState(false);

	const signOut = () => {
		dispatch(SignOut());
		dispatch(resetMyProfile());

		dispatch(resetUi()); //! возможно ничего не делает, проверь и удали
		Storage.removeItem(AUTH_TOKEN);
		Storage.cleanStorage();
		if (navigate) navigate('/');
		if (window.fcWidget) window.fcWidget.destroy();
	};

	const showConfirm = () => setShowConfirmation(true);
	const cancelConfirm = () => setShowConfirmation(false);
	return {
		ConfirmModal: ({onSubmit}) => {
			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelConfirm}
					submitBtnText={'Log Out'}
					primaryVariant="alert"
					isCloseButton
					title={'Are you sure you want to log out?'}
					subtitle={false}
					onSubmit={() => {
						Amplitude.track('Signed_Out');
						Amplitude.reset();
						onSubmit?.(); //if we pass any onSubmit function, it'll call this, otherwise we'll just signout the user.
						signOut();
					}}
				/>
			);
		},
		showConfirmation,
		signOut,
		showConfirm,
		cancelConfirm,
	};
};

export const useSignIn = () => {
	const dispatch = useAppDispatch();

	const signIn = async ({username, password}, navigate) => {
		try {
			const res = await authService.signIn({username, password});
			const userRole = res.data.roles;
			//If the user isn't an Advisor, we throw a custom error as shown below.
			if (
				!(
					userRole.includes(AdvisorsRole.Advisor) ||
					userRole.includes(AdvisorsRole.SuperAdmin) ||
					userRole.includes(AdvisorsRole.AccountAdministrator) ||
					userRole.includes(AdvisorsRole.Administrator)
				)
			) {
				const error = {
					response: {
						data: {
							message:
								'Error. Please make sure you have entered the correct email and password and the email has been verified. If you cannot log in, contact support.',
						},
					},
				};
				throw error;
			} else if (!!res?.data?.authToken && !!res?.data?.refreshToken) {
				Storage.saveData(AUTH_TOKEN, res.data.authToken);
				Storage.saveData(REFRESH_TOKEN, res.data.refreshToken);
				try {
					const res = await advisorService.getAdvisor();
					await dispatch(setMyProfile(res.data));

					Amplitude.identifyUser(res.data);

					await dispatch(fetchSubscription());
				} catch (e) {
					ToastDispatcher.error('Error getting profile');
				}
				await dispatch(SignIn(res.data));
				//below updates the URL in the address bar
				if (navigate) navigate('/');
			}

			Amplitude.track('Signed_In');
		} catch (e) {
			throw e?.response?.data;
		}
	};

	return signIn;
};
