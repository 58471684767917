import React, {useState} from 'react';

import {SPECIALIZATION_OPTIONS_TYPES} from 'const';
import {specFilterSelectors} from 'store/selectors';
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import {useAppSelector} from '../../store';

import FilterItem from './FilterItem';

import './style.scss'; //! из файлов ниже перенес стили сюда, проверь что бы ничего не сломалось
// import 'swiper/swiper.scss';
// import 'swiper/modules/navigation/navigation.scss';

const EcosystemFilter = () => {
	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	const checkedKeys = useAppSelector(specFilterSelectors.selectSpecFilterCheckedKeys);
	return (
		<div className="specializationsFilter">
			<Swiper
				slidesPerView="auto"
				spaceBetween={5}
				navigation
				modules={[Navigation]}
				className="mySwiper">
				<SwiperSlide className="slide-item all-item">
					<FilterItem type={0} name="All" checked={!checkedKeys.length} />
				</SwiperSlide>
				{SPECIALIZATION_OPTIONS_TYPES.map(item => (
					<SwiperSlide key={`FilterItem-${item.type}`} className="slide-item">
						<FilterItem
							type={item.type}
							name={item.name}
							checked={checkedKeys.includes(item.type)}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default EcosystemFilter;
