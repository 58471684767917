import {useSelector} from 'react-redux';
import * as usersService from '../../../api/services/users';
import * as advisorService from '../../../api/services/advisor';
import React, {useEffect, useState} from 'react';
import PFButton from '../../components/PF-Button';
import PFInput from '../../components/PF-Input';
import {useForm} from 'react-hook-form';
import {findByToken} from '../../../utils';
import {generateValidator, hints, validations} from '../../../utils';
import PFSelect from '../../components/PF-Select';
import {INACTIVE_REASON_OPTIONS_TYPES} from '../../../const';
import PFRadioGroup from '../../components/PF-RadioGroup';
import classNames from 'classnames';
import * as advisorClientsService from '../../../api/services/advisorClients';
import PFPopup from '../../components/PF-Popup';
import {ToastDispatcher} from '../../../utils';

export const useUserProfile = () => {
	return async token => {
		try {
			const params = {userToken: token};
			const response = await advisorClientsService.getUserProfile(params);
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useRestoreUser = token => {
	return async (payload = token) => {
		try {
			const response = await usersService.restoreUser(payload);
			ToastDispatcher.success('User restored');
			return response.data;
		} catch (e) {
			ToastDispatcher.error(`${e?.response?.data?.message}`);
			throw e;
		}
	};
};

export const useUpdateUserLogin = () => {
	return async body => {
		try {
			const res = await usersService.updateUser(body);
			ToastDispatcher.success('Your login settings updated');
			return res;
		} catch (e) {
			ToastDispatcher.error(`${e?.message}`);
			throw e;
		}
	};
};

export const useUpdateUserEmail = () => {
	const consumers = useSelector(state => state.consumers.consumers);
	const updateData = (token, email, updateCallback) => {
		const foundBlockedUser = findByToken(consumers, token);
		if (foundBlockedUser) foundBlockedUser.email = email;
		updateCallback();
	};
	return async (body, callback) => {
		try {
			const res = await usersService.updateUserEmail(body);
			ToastDispatcher.success('User email edited');
			updateData(body.token, body.email, callback);
			return res;
		} catch (e) {
			ToastDispatcher.error(`${e?.message}`);
			throw e;
		}
	};
};

export const useBlockedUser = () => {
	return async (body, callback, name) => {
		try {
			const res = await usersService.setBlockedUser(body);
			ToastDispatcher.success(body.isBlocked ? `${name} suspended` : `${name} reactivated`);
			callback();
			return res;
		} catch (e) {
			ToastDispatcher.error(`${e?.message}`);
			throw e;
		}
	};
};

export const useDeletedUser = () => {
	return async (token, callback, name) => {
		try {
			const res = await usersService.deleteAccount({userToken: token});
			ToastDispatcher.success(`${name} deleted`);
			callback();
			return res;
		} catch (e) {
			ToastDispatcher.error(`${e?.message}`);
			throw e;
		}
	};
};

export const useIsActiveUser = () => {
	return async (body, callback, name) => {
		try {
			const res = await usersService.setIsActive(body);
			ToastDispatcher.success(
				body.isActive ? `${name} set as active` : `${name} set as inactive`,
			);
			if (callback) callback();
			return res;
		} catch (e) {
			ToastDispatcher.error(`${e?.message}`);
			throw e;
		}
	};
};

export const changeRoleWarningPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showWarningConfirm = () => setShowConfirmation(true);
	const cancelWarningConfirm = () => setShowConfirmation(false);
	return {
		ConfirmWarningModal: ({onSubmit, message}) => {
			return (
				<PFPopup
					isShow={showConfirmation}
					title="Assign New Account Administrator"
					submitBtnText="Edit Company Data"
					isShowCancelBtn={false}
					isCloseButton={true}
					isShowFooter
					handleClose={cancelWarningConfirm}
					primaryVariant="primary"
					onSubmit={onSubmit}
					children={message}
				/>
			);
		},
		showConfirmation,
		showWarningConfirm,
		cancelWarningConfirm,
	};
};

export const useIsActiveUserPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showIsActiveUserConfirm = () => setShowConfirmation(true);
	const cancelIsActiveUserConfirm = () => setShowConfirmation(false);
	return {
		ConfirmIsActiveUserModal: ({isActive, onSubmit, className, token, name, reason, title}) => {
			let message = title || 'Is the advisor active?';

			const formOptions = {
				mode: 'onChange',
			};
			const formProps = useForm(formOptions);
			const {register, trigger, watch, formState, setValue, handleSubmit, control} =
				formProps;

			const [isSubmitting, setIsSubmitting] = useState(false);
			const [isVisibleReasonInactiveField, setIsVisibleReasonInactiveField] = useState(false);

			useEffect(() => {
				const subscription = watch(async (data, {name}) => {
					if (name === 'reasonInactive') {
						await trigger('reasonInactive');
					}
					if (name === 'isActive') {
						if (data.isActive === 'true') {
							setIsVisibleReasonInactiveField(false);
						}
						if (data.isActive === 'false') {
							setIsVisibleReasonInactiveField(true);
						}
						await trigger('reasonInactive');
					}
				});
				return () => subscription.unsubscribe();
			}, [watch, trigger]);

			useEffect(() => {
				setValue('isActive', `${isActive}`);
				setValue('reasonInactive', reason);
			}, []);

			//The className variable below adds a bit of margin after each field.
			const fullWidth = 'col-12 mb-4';

			const submit = async valueObj => {
				try {
					setIsSubmitting(true);
					onSubmit?.(
						valueObj.isActive === 'true',
						token,
						name,
						valueObj.isActive === 'true' ? null : valueObj.reasonInactive,
					); //if we pass any onSubmit function, it'll call this, otherwise we'll just signout the user.
					cancelIsActiveUserConfirm();
				} catch (e) {
					throw e;
				} finally {
					setIsSubmitting(false);
				}
			};

			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelIsActiveUserConfirm}
					submitBtnText={'Save'}
					primaryVariant="primary"
					isCloseButton
					title={message}
					subtitle={false}
					onSubmit={cancelIsActiveUserConfirm}
					className={{
						root: `${className || ''}`,
						paper: 'pf-isActiveUserPopupPopup__paper',
					}}
					isShowFooter={false}>
					<form onSubmit={handleSubmit(submit)} className={`pf-IsActive__form`}>
						<div className={`${fullWidth} isActive`}>
							<PFRadioGroup
								className={'isActive__radioGroup'}
								control={control}
								name={'isActive'}
								disabled={false}
								variant="horizontal"
								defaultValue={`${isActive}`}
								options={[
									{
										value: 'true',
										label: 'Yes',
										disabled: false,
									},
									{
										value: 'false',
										label: 'No',
										disabled: false,
									},
								]}
								error={
									formState.errors?.isActive?.message &&
									formState.errors?.isActive?.message
								}
							/>
						</div>
						<div
							className={classNames(`${fullWidth} reasonInactive`, {
								disabled: !isVisibleReasonInactiveField,
							})}>
							<PFSelect
								{...register('reasonInactive', {
									required: isVisibleReasonInactiveField,
								})}
								defaultValue={
									(!!formProps.getValues('reasonInactive') && {
										name: formProps.getValues('reasonInactive'),
									}) ||
									(!!reason && {
										name: reason,
									}) ||
									null
								}
								showAsterisk={false}
								label={false}
								placeholder={'State the reason'}
								disabled={false}
								isMulti={false}
								closeMenuOnSelect={true}
								isAsync={false}
								onChange={value => {
									setValue('reasonInactive', value.name);
								}}
								getOptionValue={item => item.name}
								options={INACTIVE_REASON_OPTIONS_TYPES}
								formatOptionLabel={option => option.name}
								defaultOptions={true}
								error={
									formState.errors?.reasonInactive?.message &&
									formState.errors?.reasonInactive?.message
								}
							/>
						</div>
						<div className="d-flex justify-content-center">
							<div className="d-flex w-100 m-auto pf-modal__footer-inner">
								<PFButton
									disabled={!formState.isValid || isSubmitting}
									type="submit"
									variant="primary"
									onClick={() => false}>
									Confirm
								</PFButton>
								<PFButton
									onClick={cancelIsActiveUserConfirm}
									variant="secondary"
									type={'button'}>
									Cancel
								</PFButton>
							</div>
						</div>
					</form>
				</PFPopup>
			);
		},
		showConfirmation,
		showIsActiveUserConfirm,
		cancelIsActiveUserConfirm,
	};
};

export const useAdvisorInfo = () => {
	return async token => {
		try {
			const {data} = await advisorService.searchAdvisor(token);
			return data;
		} catch (e) {
			throw e;
		}
	};
};
export const useNoAuthorizedAdvisorInfo = () => {
	return async token => {
		try {
			const {data} = await advisorService.getNoAuthorizedAdvisor(token);
			return data;
		} catch (e) {
			throw e;
		}
	};
};

export const useCompanyUsersData = (token = '') => {
	const params = {
		companyToken: token,
		// searchText: '',
		// predicate: '',
		// reverse: false,
		skip: 0,
		take: 10,
	};

	return async (payload = params) => {
		try {
			const {data} = await usersService.getUsers(payload);
			return data;
		} catch (e) {
			throw e;
		}
	};
};

export const useBlockedUserPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showBlockedUserConfirm = () => setShowConfirmation(true);
	const cancelBlockedUserConfirm = () => setShowConfirmation(false);
	return {
		ConfirmBlockedUserModal: ({onSubmit, checkedKeys, className, name}) => {
			let message = `Suspend ${name ? name : ''}?`;
			if (checkedKeys && checkedKeys.length >= 1) {
				message = `Suspend ${checkedKeys.length} user${checkedKeys.length > 1 ? 's' : ''}?`;
			}
			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelBlockedUserConfirm}
					submitBtnText={'Suspend'}
					primaryVariant="primary"
					isCloseButton
					title={message}
					subtitle={false}
					onSubmit={() => {
						onSubmit?.(); //if we pass any onSubmit function, it'll call this, otherwise we'll just signout the user.
						cancelBlockedUserConfirm();
					}}
					className={{
						root: `${className || ''}`,
					}}
				/>
			);
		},
		showConfirmation,
		showBlockedUserConfirm,
		cancelBlockedUserConfirm,
	};
};

export const useDeletedUserPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showDeletedUserConfirm = () => setShowConfirmation(true);
	const cancelDeletedUserConfirm = () => setShowConfirmation(false);
	return {
		ConfirmDeleteUserModal: ({onSubmit, checkedKeys, className, name}) => {
			let message = `Delete ${name ? name : ''}?`;
			if (checkedKeys && checkedKeys.length >= 1) {
				message = `Delete ${checkedKeys.length} user${checkedKeys.length > 1 ? 's' : ''}?`;
			}
			return (
				<PFPopup
					isShow={showConfirmation}
					handleClose={cancelDeletedUserConfirm}
					submitBtnText={'Delete'}
					primaryVariant="alert"
					isCloseButton
					title={message}
					subtitle={false}
					onSubmit={() => {
						onSubmit?.(); //if we pass any onSubmit function, it'll call this, otherwise we'll just signout the user.
						cancelDeletedUserConfirm();
					}}
					className={{
						root: `${className || ''}`,
					}}
				/>
			);
		},
		showConfirmation,
		showDeletedUserConfirm,
		cancelDeletedUserConfirm,
	};
};

export const useEditEmailPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showEditEmailConfirm = () => setShowConfirmation(true);
	const cancelEditEmailConfirm = () => setShowConfirmation(false);

	return {
		ConfirmEditEmailModal: ({onSubmit, className, email, modalTitle}) => {
			const formOptions = {
				mode: 'onChange',
			};
			const formProps = useForm(formOptions);
			const {formState, handleSubmit, watch, trigger, setValue} = formProps;

			const [isSubmitting, setIsSubmitting] = useState(false);
			const fullWidth = 'col-12 mb-4';

			useEffect(() => {
				const subscription = watch(async (data, {name}) => {
					if (name === 'email') {
						await trigger('email');
					}
				});
				return () => subscription.unsubscribe();
			}, [watch, trigger]);

			useEffect(() => {
				if (email) {
					setValue('email', email);
				}
			}, [email]);

			const submitForm = async valueObj => {
				const email = valueObj?.email;

				try {
					setIsSubmitting(true);
					await onSubmit?.(email);
					cancelEditEmailConfirm();
				} catch (e) {
					throw e;
				} finally {
					setIsSubmitting(false);
				}
			};

			return (
				<>
					<PFPopup
						isShow={showConfirmation}
						handleClose={cancelEditEmailConfirm}
						isShowFooter={false}
						submitBtnText="Save"
						primaryVariant="primary"
						isCloseButton={true}
						isShowCancelBtn={false}
						title={modalTitle}
						onSubmit={() => false}
						className={{root: className}}>
						<form onSubmit={handleSubmit(submitForm)} className={`pf-IsActive__form`}>
							<div className={`${fullWidth} email`}>
								<PFInput
									{...formProps.register('email', {
										validate: generateValidator(
											hints.email.required,
											validations.email,
										),
									})}
									showAsterisk={false}
									label={'Email'}
									placeholder={'Email'}
									type={'email'}
									defaultValue={email}
									error={
										formState.errors?.email?.message &&
										formState.errors?.email?.message
									}
								/>
							</div>
							<div className="d-flex justify-content-center">
								<div className="d-flex w-100 m-auto pf-modal__footer-inner">
									<PFButton
										disabled={!formState.isValid || isSubmitting}
										onClick={() => false}
										className="pf-profileEdit__form-button pf-profileEdit__form-button--submit pf-edit-email-submit"
										type="submit">
										Save
									</PFButton>
									<PFButton
										onClick={cancelEditEmailConfirm}
										variant="secondary"
										type={'button'}>
										Cancel
									</PFButton>
								</div>
							</div>
						</form>
					</PFPopup>
				</>
			);
		},
		showConfirmation,
		showEditEmailConfirm,
		cancelEditEmailConfirm,
	};
};

export const useSetFreshdeskRestoreId = () => {
	return async body => {
		try {
			return await usersService.setFreshdeskRestoreId(body);
		} catch (e) {
			ToastDispatcher.error(`${e?.message}`);
			throw e;
		}
	};
};
