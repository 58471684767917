import React, {useState} from 'react';

import {AxiosResponse} from 'axios';
import PFPopup from 'shared/components/PF-Popup';
import {InsuranceType, LifeInsuranceType} from 'types';
import {InsuranceTypeEnum} from 'types/enums';
import {Amplitude, ToastDispatcher} from 'utils';

import * as insuranceService from '../../../api/services/insurance';

export const useGetInsurances = () => {
	return async (token: string): Promise<InsuranceType> => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response: AxiosResponse<InsuranceType> =
				await insuranceService.getInsurancesByToken(token);
			return response.data;
		} catch (e: any) {
			throw e;
		}
	};
};

export const useSetLifeInsurance = () => {
	return async (body: LifeInsuranceType) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const res = await insuranceService.setLifeInsurance(body);
			ToastDispatcher.success('Life Insurance added');
			return res;
		} catch (e) {
			throw e;
		}
	};
};

export const useUpdateLifeInsurance = () => {
	return async (body: LifeInsuranceType) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const res = await insuranceService.updateLifeInsurance(body);
			ToastDispatcher.success('Life Insurance information updated');
			return res;
		} catch (e) {
			throw e;
		}
	};
};

export const useSetCriticalIllnessInsurance = () => {
	return async (body: {userToken: string}) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const res = await insuranceService.setCriticalIllnessInsurance(body);
			ToastDispatcher.success('Critical Illness Insurance added');
			return res;
		} catch (e) {
			throw e;
		}
	};
};

export const useSetDisabilityInsurance = () => {
	return async (body: {userToken: string}) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const res = await insuranceService.setDisabilityInsurance(body);
			ToastDispatcher.success('Disability Insurance added');
			return res;
		} catch (e) {
			throw e;
		}
	};
};

export const useDeleteInsurance = () => {
	return async (body: {token: string; type: InsuranceTypeEnum}, name: string) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const res = await insuranceService.deleteInsurance(body);
			ToastDispatcher.success(`${name} deleted`);
			return res;
		} catch (e) {
			throw e;
		}
	};
};

export const useDeleteInsurancePopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showConfirm = () => setShowConfirmation(true);
	const cancelConfirm = () => setShowConfirmation(false);
	return {
		ConfirmModal: ({name, onSubmit, className}) => {
			const message = `Delete ${name}?`;
			return (
				<PFPopup
					isShow={showConfirmation}
					title={message}
					submitBtnText="Delete"
					cancelBtnText="Cancel"
					isShowCancelBtn
					isCloseButton
					isShowFooter
					handleClose={cancelConfirm}
					primaryVariant="alert"
					onSubmit={() => {
						onSubmit?.();
						cancelConfirm();
					}}
					className={{root: className}}
				/>
			);
		},
		showConfirmation,
		showConfirm,
		cancelConfirm,
	};
};
