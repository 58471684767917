import React, {useEffect} from 'react';

import {referBusinessesService} from 'api/services';
import classNames from 'classnames';
import {DEFAULT_AVATAR, DEFAULT_AVATAR_BLUE} from 'const';
import moment from 'moment';
import PFAvatar from 'shared/components/PF-Avatar';
import PFContactsBlock from 'shared/components/PF-ContactsBlock';
import PFUserDescription from 'shared/components/PF-UserDescription';
import {useAppSelector} from 'store';
import {tableSelectors, uiSettingsSelector} from 'store/selectors';
import {ReferralsType} from 'types';
import {getFullName, ToastDispatcher} from 'utils';

import urlPaths from '../../api/url-paths';

import AdditionalEcosystemInfo from './AdditionalReferredToMeInfo';

import './style.scss';

const ReferredToMeModal = () => {
	const rowData: ReferralsType = useAppSelector(tableSelectors.selectTableModalData);
	const isModalOpen = useAppSelector(uiSettingsSelector.GetIsSideModalOpen);
	const userName = getFullName(rowData?.userFirstName, rowData?.userLastName);

	const setLastViewed = async (tokens: string[]) => {
		try {
			await referBusinessesService.resetLastViewedReferItems(tokens);
		} catch (e) {
			ToastDispatcher.error('Reset "last viewed" error');
		}
	};

	useEffect(() => {
		if (isModalOpen && rowData?.token) {
			setLastViewed([rowData.token]);
		}
	}, [isModalOpen]);

	return (
		<div className={classNames('referredToMeModal')}>
			<PFUserDescription
				name={userName}
				avatarPhoto={rowData?.userProfileImage}
				defaultAvatar={DEFAULT_AVATAR_BLUE}>
				<div className="descriptionInfo">
					<div className="lastViewed">{`Last viewed: ${
						rowData?.lastViewed
							? moment.utc(rowData?.lastViewed).local().fromNow(true)
							: 'Never'
					}`}</div>
					<div className="referredBy">
						<div className="text">Referred by</div>
						<PFAvatar
							src={
								rowData?.recommendedByProfileImage
									? `${urlPaths.BASE_IMAGES_URL}${rowData.recommendedByProfileImage}`
									: DEFAULT_AVATAR
							}
							size={20}
							className="referredBy_avatar"
						/>
						<div className="name">
							{getFullName(
								rowData?.recommendedByFirstName,
								rowData?.recommendedByLastName,
							)}
						</div>
						<div />
					</div>
				</div>
			</PFUserDescription>
			<PFContactsBlock
				phone={rowData?.phone}
				email={rowData?.email}
				// location={rowData?.location} //! появится потом
			/>
			<AdditionalEcosystemInfo
				userToken={rowData?.token}
				referredType={rowData?.type}
				description={rowData?.description}
			/>
		</div>
	);
};

export default ReferredToMeModal;
