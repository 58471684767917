import React, {FC, ReactNode} from 'react';

import classNames from 'classnames';

import './style.scss';

type PropsTypes = {
	children?: string | ReactNode;
	label?: string | ReactNode;
	heading?: string | ReactNode;
	description?: string | ReactNode;
	labelClassName?: string | ReactNode;
};

const AdvisorProfileDescription: FC<PropsTypes> = ({
	children,
	label,
	heading,
	description,
	labelClassName,
}) => {
	return (
		<>
			{label && (
				// @ts-ignore
				<div className={classNames('description-label', labelClassName)}>{label}</div>
			)}
			{heading && <div className="description-heading">{heading}</div>}
			{description && <div className="description">{description}</div>}
			{children}
		</>
	);
};

export default AdvisorProfileDescription;
