import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {ReactSVG} from 'react-svg';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import dot from '../../../assets/icon-dots.svg';
import iconDelete from '../../../assets/icon-delete.svg';
import resendEmailIcon from '../../../assets/resend-email-icon.svg';
import {useBlockedUser, useDeletedUser, useDeletedUserPopup} from '../../../shared/hooks/users';
import {findByToken} from '../../../utils';
import iconView from '../../../assets/icon-view.svg';
import iconChangeCompany from '../../../assets/icon-changeCompany.svg';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import s from './style.module.scss';
import {useChangeCompanyPopup, useIsSuspendUserPopup} from '../../../shared/hooks/companies';
import * as advisorService from '../../../api/services/advisor';
import {setSideModal} from '../../../store/actions';
import PFBadge from '../../../shared/components/PF-Badge';
import {SPECIALIZATION_OPTIONS_TYPES} from '../../../const';
import {useErrorHelper, getCamelCaseToText} from '../../../utils';
import iconUnblock from '../../../assets/icon-unblock.svg';
import iconBlock from '../../../assets/icon-block.svg';
import {useNavigate} from 'react-router-dom';
import {ToastDispatcher} from '../../../utils';
import {myProfileSelectors} from '../../../store/selectors';
import {useAppSelector} from '../../../store';
import {AdvisorsRole} from '../../../types/enums';
import {usersService} from 'api/services';

const AdditionalCompanyProfileInfo = ({
	token,
	email,
	advisorToken,
	isBlocked,
	name,
	role,
	specializations,
	updateDataForUseOnModal,
	tableData,
}) => {
	const suspendUser = useBlockedUser();
	const accountRole = useAppSelector(myProfileSelectors.selectMyProfileData).roles[0];
	const urlHashUserToken = window.location.hash.slice(1);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const companyUsers = useAppSelector(state => state?.companies?.companyUsers);
	const deleteUser = useDeletedUser();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const {showDeletedUserConfirm, ConfirmDeleteUserModal} = useDeletedUserPopup();
	const {showChangeCompanyConfirm, ConfirmChangeCompanyModal} = useChangeCompanyPopup();
	const {showIsSuspendUserConfirm, ConfirmIsSuspendUserModal} = useIsSuspendUserPopup();
	const errorHelper = useErrorHelper(navigate);

	const updateData = () => {
		const foundBlockedUser = urlHashUserToken
			? findByToken(companyUsers, token)
			: findByToken(tableData, token);
		if (foundBlockedUser) foundBlockedUser.isDeleted = true;
		dispatch(setSideModal(false));
		updateDataForUseOnModal();
	};

	const onIsSuspend = async (isBlocked, token) => {
		const update = () => {
			const foundActiveUser = findByToken(tableData, token);
			if (foundActiveUser) foundActiveUser.isBlocked = isBlocked;
			if (foundActiveUser) foundActiveUser.isActive = false;
			updateDataForUseOnModal();
			dispatch(setSideModal(false));
		};

		const body = {
			token,
			isBlocked,
		};

		try {
			setIsSubmitting(true);
			await suspendUser(body, update, name);
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const showIsSuspendUserConfirmModal = () => {
		if (isBlocked) {
			onIsSuspend(false, token);
		} else {
			showIsSuspendUserConfirm();
		}
	};

	const onDeleteUser = async () => {
		try {
			setIsSubmitting(true);
			await deleteUser(token, updateData, name);
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const sendEmailCode = async () => {
		if (email) {
			try {
				setIsSubmitting(true);

				const params = {
					username: email,
					isFromPortal: true,
				};

				await usersService.sendEmailCode(params);

				ToastDispatcher.success('Email sent');
			} catch (err) {
				const msg = err?.response?.data?.message;
				errorHelper(err, false, msg);
				throw err;
			} finally {
				setIsSubmitting(false);
			}
		}
	};

	const fetchChangeUsersCompany = async (advisorUserTokens, companyToken, companyName) => {
		const toastMessage = name ? `Changed company for ${name}` : 'Changed company';
		try {
			await advisorService.updateAdvisorCompany({
				userTokens: advisorUserTokens,
				companyToken,
			});
			ToastDispatcher.success(toastMessage);
			const foundUser = findByToken(tableData, token);
			if (foundUser) foundUser.advisor.companyName = companyName;
			updateDataForUseOnModal();
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	return (
		<div className={`${s.pfAdditionalInfo} pfAdditionalCompaniesInfo`}>
			<div className={`${s.btnContainer} btnContainer`}>
				<button
					className="current-info__btn"
					onClick={() =>
						navigate(
							`${PORTAL_PATHS.OTHER_PATHS.ADVISOR_PROFILE}#${advisorToken}&${token}`,
						)
					}>
					<ReactSVG wrapper="div" src={iconView} />
					<span>
						View <span className={s.mobile_hidden}>Advisor</span> Profile
					</span>
				</button>
				<DropdownButton
					title={<ReactSVG wrapper="div" src={dot} />}
					id="bg-nested-dropdown">
					{accountRole === AdvisorsRole.SuperAdmin && (
						<Dropdown.Item disabled={isSubmitting} onClick={sendEmailCode} eventKey="1">
							<div className={`${s.dropdownSendItemTextChange}`}>
								<ReactSVG wrapper="div" src={resendEmailIcon} />
								<span>Send Password Reset Email</span>
							</div>
						</Dropdown.Item>
					)}
					{accountRole === AdvisorsRole.SuperAdmin && (
						<Dropdown.Item
							disabled={isSubmitting}
							onClick={showChangeCompanyConfirm}
							eventKey="1">
							<div className={`${s.dropdownItemTextChange}`}>
								<ReactSVG wrapper="div" src={iconChangeCompany} />
								<span>Change Company</span>
							</div>
						</Dropdown.Item>
					)}
					<Dropdown.Item
						disabled={isSubmitting}
						onClick={showIsSuspendUserConfirmModal}
						eventKey="1">
						<div className={`${s.dropdownItemText__block}`}>
							<ReactSVG wrapper="div" src={isBlocked ? iconUnblock : iconBlock} />
							<span>{isBlocked ? 'Reactivate' : 'Suspend'}</span>
						</div>
					</Dropdown.Item>
					{accountRole === AdvisorsRole.SuperAdmin && (
						<div>
							<hr />
							<Dropdown.Item
								disabled={isSubmitting}
								onClick={showDeletedUserConfirm}
								eventKey="1">
								<div className={`${s.dropdownItemText}`}>
									<ReactSVG wrapper="div" src={iconDelete} />
									<span>Delete</span>
								</div>
							</Dropdown.Item>
						</div>
					)}
				</DropdownButton>
			</div>
			<div className={s.additionalInfoWrapper}>
				<h5 className={`${s.additionalInfoTitle} ${s.roleTitle}`}>Company Role</h5>
				<p className={s.role}>{role && getCamelCaseToText(role[0])}</p>
			</div>
			{specializations && specializations?.length > 0 && (
				<div className={s.additionalInfoWrapper}>
					{/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */}
					<h5 className={s.additionalInfoTitle}>Roles available</h5>
					<div className={s.specializations}>
						{specializations &&
							specializations?.map(item => (
								<PFBadge key={`Specialization-${item}`}>
									{
										SPECIALIZATION_OPTIONS_TYPES.find(spec => {
											return spec.type === item;
										}).name
									}
								</PFBadge>
							))}
					</div>
				</div>
			)}
			<ConfirmDeleteUserModal
				className={'pf-confirmDeleteModal'}
				onSubmit={onDeleteUser}
				name={name}
			/>
			<ConfirmChangeCompanyModal
				className={'pf-confirmChangeCompany'}
				name={name}
				onSubmit={fetchChangeUsersCompany}
				checkedKeys={[token]}
			/>
			<ConfirmIsSuspendUserModal
				onSubmit={() => onIsSuspend(true, token)}
				className={'pf-confirmIsActiveUserModal'}
				name={name}
			/>
		</div>
	);
};

export default AdditionalCompanyProfileInfo;
