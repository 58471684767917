import {SetOpportunitiesFetchType, SetOpportunitiesNoteFetchType} from 'types';

import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const getOpportunitiesByToken = (token: string) =>
	httpClient.get(`${URL.BASE_URL}/${URL.OPPORTUNITIES.INDEX}?userToken=${token}`);

export const searchOpportunities = params =>
	httpClient.get(`${URL.BASE_URL}/${URL.OPPORTUNITIES.SEARCH}`, {params});

export const setOpportunities = (body: SetOpportunitiesFetchType) =>
	httpClient.put(`${URL.BASE_URL}/${URL.OPPORTUNITIES.INDEX}`, body);

export const setOpportunitiesNote = (body: SetOpportunitiesNoteFetchType) =>
	httpClient.post(
		`${URL.BASE_URL}/${URL.OPPORTUNITIES.SET_NOTE}?token=${body.token}&note=${body.note}`,
	);
