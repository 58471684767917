import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import {Amplitude} from 'utils';

import {persistor, store} from './store/store';
import App from './app';

import './index.css';

const root = createRoot(document.getElementById('root')!);

Amplitude.init();

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</React.StrictMode>,
);
