import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {
	cleanedUpAPIData,
	setCurrentPageTitle,
	setCheckedData,
	cleanedUpTableData,
} from '../../../../store/actions';
import {Table} from 'rsuite';
import {mapQueryParams, parseUrlQuery, ToastDispatcher} from '../../../../utils';
import {SuperAdminEmptyPage} from '../../../../components';
import {PFSortArrow, PFTable} from '../../../../shared/components';
import * as APIService from '../../../../api/services/apiInstances';
import {findByToken} from '../../../../utils/table';
import {useDeletedApiPopup, useApiData} from '../../../../shared';
import PFButton from '../../../../shared/components/PF-Button';
import {ReactSVG} from 'react-svg';
import iconDelete from '../../../../assets/icon-delete.svg';
import iconCopy from '../../../../assets/icon-copy.svg';
import SearchEmptyPage from '../../../../components/EmptyPages/EmptySearch';
import plus from '../../../../assets/icon-plus.svg';
import PORTAL_PATHS from '../../../../const/url-paths/portal';
import {useNavigate} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useHandleCopyApiKey} from '../../../../shared/hooks/clipboard';
import PFTableBar from '../../../../shared/components/PF-TableBar';
import './style.scss';
import RestoreButton from '../../../../components/RestoreButton';
import {useRestoreApi} from '../../../../shared/hooks/api';
import {useErrorHelper} from '../../../../utils/futureTS/generalUtils';

const APIInstances = props => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const [isLoading, setIsLoading] = useState(true);
	const [update, setUpdate] = useState(true);
	const [updateParam, setUpdateParam] = useState('');
	const {Column, HeaderCell, Cell} = Table;
	const tableData = props.api.api;
	const fullTableDataLength = props.api.fullCount;
	const checkedKeys = props.table.checkedData;
	const urlParams = parseUrlQuery(document.location);
	const [isEmptyPage, setIsEmptyPage] = useState(false);
	const handleCopyApiKey = useHandleCopyApiKey();
	const restoreApi = useRestoreApi();
	const {showDeletedAPIConfirm, ConfirmDeleteAPIModal} = useDeletedApiPopup();
	const params = {
		...mapQueryParams(urlParams),
	};
	const fetchAPIData = useApiData(params);
	const dispatch = useDispatch();

	const goToAddNewAPI = () => {
		navigate(PORTAL_PATHS.OTHER_PATHS.ADD_API_INSTANCE);
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const restore = async token => {
		try {
			await restoreApi(token);
			const foundDeletedCompany = findByToken(tableData, token);
			if (foundDeletedCompany) foundDeletedCompany.isDeleted = false;
			updateData();
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const [columns] = useState([
		{
			name: 'Name',
			title: 'API Instance Name',
			flexGrow: 1,
			minWidth: 230,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'Key',
			title: 'Key',
			flexGrow: 1,
			minWidth: 170,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'Note',
			title: 'Note',
			flexGrow: 3,
			minWidth: 320,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
	]);
	const tableColumns = columns.map((item, index) => {
		const switchParam = param => {
			switch (param) {
				case 'Name':
					return (
						<Cell dataKey="email">
							{rowData => (
								<div className={'name-group'}>
									<div className={`pf-tableText instance-name name`}>
										{rowData.instanceName}
									</div>
									<RestoreButton
										isDeleted={rowData.isDeleted}
										onClick={restore}
										token={rowData?.token}
									/>
								</div>
							)}
						</Cell>
					);
				case 'Key':
					return (
						<Cell dataKey="key">
							{rowData => {
								return (
									<div className={'instance-key pf-tableText'}>
										<CopyToClipboard
											text={rowData.key}
											onCopy={handleCopyApiKey}>
											<ReactSVG
												wrapper="div"
												className="icon-copy"
												src={iconCopy}
											/>
										</CopyToClipboard>
										<CopyToClipboard
											text={rowData.key}
											onCopy={handleCopyApiKey}>
											<div className={`pf-tableText instance-key__text`}>
												{rowData.key}
											</div>
										</CopyToClipboard>
									</div>
								);
							}}
						</Cell>
					);
				case 'Note':
					return (
						<Cell dataKey="note">
							{rowData => (
								<div className={`pf-tableText instance-note`}>{rowData.notes}</div>
							)}
						</Cell>
					);
				default:
					break;
			}
		};

		return (
			<Column
				key={index}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						{item.sortable ? <PFSortArrow /> : null}
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;
		try {
			setIsLoading(true);
			await fetchAPIData();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const fetchDeletedAPI = checkedKeys => {
		const count = checkedKeys.length;
		const toastMessage = `${count > 1 ? count : ''} API instance${
			count > 1 ? 's' : ''
		} deleted`;
		try {
			Promise.all(checkedKeys.map(item => APIService.deleteApi(item)))
				.then(response => {
					ToastDispatcher.success(toastMessage);
					setUpdateParam('deleted');
					updateData();
				})
				.catch(e => {
					errorHelper(e);
				});
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const updateCallback = () => {
		if (updateParam === 'blocked') {
			checkedKeys.forEach(key => {
				const foundBlockedUser = findByToken(tableData, key);
				if (foundBlockedUser) foundBlockedUser.isBlocked = true;
			});
			setUpdateParam('');
		} else if (updateParam === 'unblocked') {
			checkedKeys.forEach(key => {
				const foundBlockedUser = findByToken(tableData, key);
				if (foundBlockedUser) foundBlockedUser.isBlocked = false;
			});
			setUpdateParam('');
		} else if (updateParam === 'deleted') {
			checkedKeys.forEach(key => {
				const foundDeletedApi = findByToken(tableData, key);
				if (foundDeletedApi) foundDeletedApi.isDeleted = true;
			});
			setUpdateParam('');
			dispatch(setCheckedData([]));
		}
	};

	const DeleteButton = () => {
		return (
			<PFButton
				type={'button'}
				prefixIcon={
					<ReactSVG
						wrapper="span"
						className="pf-icon-request-report pf-icon-delete"
						src={iconDelete}
					/>
				}
				className={'pf-consumer-delete-button'}
				onClick={showDeletedAPIConfirm}>
				<span className={'button-text'}>Delete</span>
			</PFButton>
		);
	};

	const firstRender = async () => {
		dispatch(setCurrentPageTitle('API Instances'));

		try {
			setIsLoading(true);
			const APIData = await fetchAPIData();
			if (Boolean(APIData.fullCount)) {
				setIsEmptyPage(false);
			} else {
				setIsEmptyPage(true);
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const emptyPageParam = {
		title: 'The List is Empty',
		subtitle: 'Click the button to add new API instances:',
		button: {
			variant: 'primary', // "secondary" or "primary"
			onClick: goToAddNewAPI,
			icon: false, // false or component
			isShowIcon: true, // true or false
			text: 'Add New API Instance',
		},
	};

	const searchValue = useSelector(state => state.table.search);
	const searchEmptyParam = {
		variant: 'primary',
		className: '',
		pageName: 'API Instances',
		buttonText: 'Back to API List',
	};
	const tableBarButtons = [<DeleteButton key={1} />];

	useEffect(() => {
		firstRender();
	}, [props.table.search, props.table.reset, props.table.reverse]);

	useEffect(() => {
		return () => {
			dispatch(cleanedUpAPIData());
			dispatch(cleanedUpTableData());
		};
	}, []);

	return (
		<div className={'api-instances'}>
			<PFTableBar
				tableData={tableData}
				checkedKeys={checkedKeys}
				tableBarButtons={tableBarButtons}
			/>
			<PFTable
				tableColumns={tableColumns}
				tableData={tableData}
				modal={'api'}
				isLoading={isLoading}
				fetchMoreData={fetchMoreData}
				fullTableDataLength={fullTableDataLength}
				checkedKeys={checkedKeys}
				update={update}
				updateCallback={updateCallback}
				updateDataForUseOnModal={updateData}
				urlParams={urlParams}
				parseUrlQuery={parseUrlQuery}
				emptyComponent={
					searchValue ? (
						<SearchEmptyPage {...searchEmptyParam} />
					) : (
						<SuperAdminEmptyPage {...emptyPageParam} />
					)
				}
			/>
			<button
				className={`newAPI-button add-new_button ${isEmptyPage ? 'd-none' : ''}`}
				onClick={goToAddNewAPI}>
				<ReactSVG wrapper="span" className="pf-icon-plus" src={plus} />
				<span className={'newAPI-button__text'}>Add New API Instance</span>
			</button>
			<ConfirmDeleteAPIModal
				onSubmit={() => fetchDeletedAPI(checkedKeys)}
				checkedKeys={checkedKeys}
			/>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		api: state.APIInstances,
		table: state.table,
	};
};

export default connect(mapStateToProps)(APIInstances);
