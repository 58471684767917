import {SET_USER, UNSET_USER} from '../types/Types';

export const SignIn = user => {
	return {
		type: SET_USER,
		payload: user,
	};
};

export const SignOut = () => {
	return {
		type: UNSET_USER,
	};
};
