import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {Specialization} from '../../types/enums';

/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */

interface SpecializationsFilterState {
	checkedTypes: Specialization[];
}
type SetSpecializationsActionType = Specialization | 0;

type RemoveSpecializationsActionType = Specialization;

const initialState: SpecializationsFilterState = {
	checkedTypes: [],
};

export const specializationsFilterSlice = createSlice({
	name: 'specializationsFilter',
	initialState,
	reducers: {
		setSpecialization: (state, action: PayloadAction<SetSpecializationsActionType>) => {
			if (action.payload === 0) {
				state.checkedTypes = [];
			} else {
				state.checkedTypes = [...state.checkedTypes, action.payload];
			}
		},
		removeSpecialization: (state, action: PayloadAction<RemoveSpecializationsActionType>) => {
			state.checkedTypes = state.checkedTypes.filter(type => type !== action.payload);
		},
	},
});

export default specializationsFilterSlice.reducer;
