import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ReferralsType} from 'types';

interface ReferredToMeState {
	referralsData: ReferralsType[];
	fullCount: number;
}
type SetReferralsDataActionType = {
	body: ReferralsType[];
	fullCount: number;
};

type DismissReferItemActionType = string[];

const initialState: ReferredToMeState = {
	referralsData: [],
	fullCount: 0,
};
// ReferredToMeReducer
export const referredToMeSlice = createSlice({
	name: 'referredToMe',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setReferralsData: (state, action: PayloadAction<SetReferralsDataActionType>) => {
			state.referralsData = action.payload.body;
			state.fullCount = action.payload.fullCount;
		},
		cleanUpReferralsData: state => {
			state.referralsData = initialState.referralsData;
			state.fullCount = initialState.fullCount;
		},
		dismissReferItems: (state, action: PayloadAction<DismissReferItemActionType>) => {
			state.referralsData = state.referralsData.filter(
				refer => !action.payload.includes(refer.token),
			);
		},
	},
});

export default referredToMeSlice.reducer;
