const URL = {
	SERVER_URL: 'https://app.pocketfinance.com',
	BASE_URL: `${process.env.REACT_APP_API_END_POINT}/api`,
	BASE_IMAGES_URL: 'https://webjobstoragepocket.blob.core.windows.net/media/',
	FINANCIAL_REPORTS_SERVER_URL: 'https://webjobstoragepocket.blob.core.windows.net',

	AUTH: {
		INDEX: 'auth',
	},
	EMAIL: {
		INDEX: 'email',
	},
	PASSWORDS: {
		INDEX: 'passwords',
	},
	ADVISOR: {
		INDEX: 'users',
		UPDATE: 'advisors',
		SEARCH: 'advisors/search',
		ADMINISTRATORS: 'companyAdministrators',
	},
	UPLOAD: {
		INDEX: 'media',
	},
	// TODO: sent to the server and received from the server as "Certificates". On the front side, the field is called "Credentials".
	CERTIFICATES: {
		INDEX: 'FinancialCertificates',
	},
	ADVISOR_CLIENTS: {
		INDEX: 'v2/advisorClients',
		STATUSES: 'advisorClientStatuses',
		REMOVE: 'removeClients',
	},
	FINANCIAL_REPORTS: {
		INDEX: 'FinancialReports',
	},
	LOCATIONS: {
		INDEX: 'locations',
	},
	HASHTAGS: {
		INDEX: 'Hashtags',
	},
	USERS: {
		INDEX: 'users',
		BLOCKED: 'setBlocked',
		ACTIVATED: 'setIsActive',
		SET_FRESHDESK: 'setFreshdesk',
		RESEND_EMAIL_CONFIRMATION: 'resendEmailConfirmation',
	},
	COMPANIES: {
		INDEX: 'companies',
		BLOCKED: 'setBlocked',
		RESET_IS_NEW_COMPANY: 'companies/resetIsNewCompany',
		RESTORE: 'companies/restore',
	},
	API: {
		INDEX: 'OpenApiInstance',
	},
	INSURANCE: {
		INDEX: 'insurances',
	},
	OPPORTUNITIES: {
		INDEX: 'risksAndOpportunities',
		SEARCH: 'risksAndOpportunities/search',
		SET_NOTE: 'risksAndOpportunities/setNote',
	},
	TODO: {
		INDEX: 'ToDos',
		COMPLETE: 'ToDos/complete',
		DISMISS: 'ToDos/dismiss',
		RESET_TO_CURRENT: 'ToDos/resetToCurrent',
	},
	NOTIFICATIONS: {
		INDEX: 'advisorNotifications',
	},
	ADVISOR_PARTNERS: {
		INDEX: 'advisorPartners',
	},
	ADVISOR_RECOMMENDATIONS: {
		INDEX: 'advisorRecommendations',
	},
	REFERRING_TO_BUSINESSES: {
		INDEX: 'referringToBusinesses',
	},
	CLIENT_TAGS: {
		INDEX: 'clientTags',
		SEARCH: 'clientTags/search',
	},
};

export default URL;
