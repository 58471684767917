import {Specialization} from 'types/enums';

import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export type SearchEcoAdvisorsType = {
	skip?: number;
	take?: number;
	userId?: number;
	searchText?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	isDeleted?: boolean;
	companyName?: string;
	companyToken?: string;
	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	clientSpecializations?: Specialization;
	reverse?: boolean;
	predicate?: string; //!
	isInEcosystem?: boolean;
};

export type SearchAdvisorsType = SearchEcoAdvisorsType;

export type SearchEcoAdvisorsResponseType = {
	fullCount: number;
	count: number;
	body: any[];
	haveTeam: boolean;
};

export const searchEcoAdvisors = (params: SearchEcoAdvisorsType) =>
	httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR_PARTNERS.INDEX}/search`, {params});

export const searchAdvisors = (params: SearchAdvisorsType) =>
	httpClient.get(`${URL.BASE_URL}/${URL.ADVISOR.SEARCH}`, {params});

export const addAdvisorsToEco = (body: {advisorPartTokens: string[]}) =>
	httpClient.post(`${URL.BASE_URL}/${URL.ADVISOR_PARTNERS.INDEX}`, body);

export const removeAdvisorsFromEco = (body: {advisorTokens: string[]}) =>
	httpClient.delete(`${URL.BASE_URL}/${URL.ADVISOR_PARTNERS.INDEX}`, {data: body});

export const recommendAdvisorToUsers = (body: {
	advisorRecommendedTokens: string[];
	userTokens: string[];
}) => httpClient.post(`${URL.BASE_URL}/${URL.ADVISOR_RECOMMENDATIONS.INDEX}`, body);
