/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
enum Specialization {
	'Investment Advisor' = 1,
	'Life and Health Insurance Advisor' = 2,
	'Residential Mortgage Advisor' = 3,
	'Accountant' = 4,
	'Bookkeeper' = 5,
	'Lawyer' = 6,
	'Realtor' = 7,
	'Financial Planner' = 9,
	'Financial Advisor' = 10,
	'Restructuring Specialist' = 11,
	'Money Coach' = 12,
	'Group Benefits Advisor' = 13,
	'Commercial Mortgage Advisor' = 14,
	'Home and Auto Insurance Advisor' = 15,
}

export {Specialization};
