import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {ecosystemService} from 'api/services';
import {addToEcosystem, iconBack, iconPlus} from 'assets';
import {EcosystemFilter, EmptyLayout, SpecializationsCellItem, UserCellItem} from 'components';
import {PORTAL_PATHS} from 'const';
import {ColumnProps, Table} from 'rsuite';
import {PFButton, PFSortArrow, PFTable, PFTableBar} from 'shared/components';
import {useBooleanState, useEcoInviteAdvisorsData} from 'shared/hooks';
import {useAppDispatch, useAppSelector} from 'store';
import {
	cleanedUpTableData,
	cleanUpEcoAdvisorsData,
	InviteAdvisorModalActions,
	removeAdvisorsItem,
	removeCheckedItems,
	setCurrentPageTitle,
	SpecFilterActions,
} from 'store/actions';
import {
	advisorsForEcoSelectors,
	myProfileSelectors,
	specFilterSelectors,
	tableSelectors,
} from 'store/selectors';
import {
	Amplitude,
	isCompletedProfile,
	mapQueryParams,
	parseUrlQuery,
	ToastDispatcher,
	useErrorHelper,
} from 'utils';

import './style.scss';

type AddToMyEcoButtonPropsTypes = {
	action: () => void;
	disabled: boolean;
};

export const AddToMyEcoButton: FC<AddToMyEcoButtonPropsTypes> = ({action, disabled}) => {
	return (
		<PFButton
			type="button"
			prefixIcon={<ReactSVG wrapper="span" className="pf-icon-accept" src={addToEcosystem} />}
			className="pf-inviteToEcosystem addToMyEcosystemButton"
			disabled={disabled}
			onClick={action}>
			<span className="button-text">Add to My Network</span>
		</PFButton>
	);
};

type ColumnsProps = ColumnProps & {name: string; title: string};

const InviteToEcosystem = () => {
	const [isLoading, setIsLoading] = useState(true);
	const {Column, HeaderCell, Cell} = Table;
	const [update, setUpdate] = useState(true);
	const tableData = useAppSelector(advisorsForEcoSelectors.selectAdvisorsForEcoData);
	const fullTableDataLength = useAppSelector(
		advisorsForEcoSelectors.selectAdvisorsForEcoFullCount,
	);
	const checkedKeys = useAppSelector(tableSelectors.selectTableCheckedData);
	const searchProp = useAppSelector(tableSelectors.selectTableSearchData);
	const resetProp = useAppSelector(tableSelectors.selectTableResetData);
	const reverseProp = useAppSelector(tableSelectors.selectTableReverseData);
	const urlParams = parseUrlQuery(document.location);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const [isSubmittingAddToMyEco, setIsSubmittingAddToMyEco] = useBooleanState(false);
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);
	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	const checkedSpecializationsKeys = useAppSelector(
		specFilterSelectors.selectSpecFilterCheckedKeys,
	);

	const params = {
		RoleIds: [3, 4, 5],
		ClientSpecializations: checkedSpecializationsKeys,
		IsInEcosystem: false,
		...mapQueryParams(urlParams),
	};
	const fetchAdvisorsData = useEcoInviteAdvisorsData(params);
	const dispatch = useAppDispatch();
	const columns: ColumnsProps[] = [
		{
			name: 'user',
			title: 'User',
			flexGrow: 2,
			minWidth: 216,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'email',
			title: 'Email',
			flexGrow: 1,
			minWidth: 190,
			align: 'left',
			sortable: false,
			verticalAlign: 'middle',
		},
		{
			name: 'company',
			title: 'Company',
			flexGrow: 2,
			minWidth: 160,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
		{
			name: 'specializations',
			title: 'Roles available',
			flexGrow: 2,
			minWidth: 270,
			align: 'left',
			sortable: false,
			verticalAlign: 'middle',
		},
	];
	const tableColumns = columns.map((item, index) => {
		const switchParam = param => {
			switch (param) {
				case 'user':
					return (
						<Cell dataKey="user">
							{rowData => (
								<UserCellItem
									lastName={rowData?.lastName}
									firstName={rowData?.firstName}
									avatarImage={rowData?.profileImage}
									avatarSize={40}
									isDeleted={false}
									restoreToken={rowData?.token}
								/>
							)}
						</Cell>
					);
				case 'email':
					return (
						<Cell dataKey="email">
							{rowData => (
								<a
									className="pf-tableText"
									href={`mailto:${rowData?.contactEmail || rowData?.email}`}>
									{rowData?.contactEmail || rowData?.email}
								</a>
							)}
						</Cell>
					);
				case 'company':
					return (
						<Cell dataKey="company">
							{rowData => <div className="pf-tableText">{rowData?.companyName}</div>}
						</Cell>
					);
				case 'specializations':
					return (
						<Cell dataKey="specializations">
							{rowData => (
								<SpecializationsCellItem
									advisorSpecializationTypes={rowData?.advisorSpecializationTypes}
									token={rowData?.token}
								/>
							)}
						</Cell>
					);
				default:
					return null;
			}
		};

		return (
			<Column
				key={item.name}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						{item.sortable ? <PFSortArrow /> : null}
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;
		try {
			setIsLoading(true);
			await fetchAdvisorsData();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const firstRender = async () => {
		dispatch(setCurrentPageTitle('My Network'));
		try {
			setIsLoading(true);
			if (isCompletedProfile(profile?.advisor)) {
				await fetchAdvisorsData();
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const emptyPageParam = {
		className: '',
		description: 'No results were found. Refine your filters.',
		isButton: false,
	};
	const searchEmptyParam = {
		className: '',
		description: `No results were found for your search.\n Refine your request`,
		isButton: false,
	};

	const addToMyEcoAction = async (tokens: string[]) => {
		try {
			setIsSubmittingAddToMyEco(true);
			await ecosystemService.addAdvisorsToEco({advisorPartTokens: tokens});

			Amplitude.track('Added_Advisor_To_Ecosystem', {
				advisors: tokens,
			});

			dispatch(removeAdvisorsItem({tokens}));
			dispatch(removeCheckedItems(tokens));
			ToastDispatcher.success(
				`${tokens.length} advisor${tokens.length > 1 ? 's' : ''} added to Your Network`,
			);
		} catch (e) {
			ToastDispatcher.error(
				`An advisor${
					tokens.length > 1 ? 's' : ''
				} has not been added to Your Network. Please try again.`,
			);
			throw e;
		} finally {
			setIsSubmittingAddToMyEco(false);
		}
	};

	const tableBarButtons = [
		<AddToMyEcoButton
			key="AddToMyEcoButton"
			disabled={isSubmittingAddToMyEco || !checkedKeys.length}
			action={() => addToMyEcoAction(checkedKeys)}
		/>,
	];

	useEffect(() => {
		firstRender();
	}, [searchProp, resetProp, reverseProp, profile, checkedSpecializationsKeys]);

	useEffect(() => {
		return () => {
			dispatch(cleanUpEcoAdvisorsData());
			dispatch(cleanedUpTableData());
			dispatch(SpecFilterActions.setSpecialization(0));
		};
	}, []);

	return (
		<div className="inviteToEcosystem">
			<div className="specializationFilterBar">
				<div className="backButton-wrapper">
					<PFButton
						className="w-auto px-0 backButton"
						prefixIcon={<ReactSVG wrapper="span" className="me-2" src={iconBack} />}
						variant="plain"
						onClick={() => navigate(PORTAL_PATHS.SIDEBAR.MY_NETWORK)}
						type="button">
						Back
					</PFButton>
				</div>
				<EcosystemFilter />
			</div>

			<PFTableBar
				tableData={tableData}
				checkedKeys={checkedKeys}
				tableBarButtons={tableBarButtons}
				modal="ecosystemAdvisors"
			/>
			<PFTable
				tableColumns={tableColumns}
				tableData={tableData}
				isLoading={isLoading}
				modal="inviteToEcosystem"
				fetchMoreData={fetchMoreData}
				fullTableDataLength={fullTableDataLength}
				checkedKeys={checkedKeys}
				update={update}
				updateCallback={() => {}}
				parseUrlQuery={parseUrlQuery}
				updateDataForUseOnModal={updateData}
				emptyComponent={
					<EmptyLayout
						emptyPageParam={emptyPageParam}
						searchEmptyParam={searchEmptyParam}
					/>
				}
			/>

			<button
				type="button"
				className="newAdvisor_button add-new_button"
				onClick={() => dispatch(InviteAdvisorModalActions.setOpen('My Network'))}>
				<ReactSVG wrapper="span" className="icon_plus" src={iconPlus} />
				<span className="newAdvisor_button_text">Invite New Advisors</span>
			</button>
		</div>
	);
};

export default InviteToEcosystem;
