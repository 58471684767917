import React, {FC, FocusEvent, KeyboardEvent, useRef, useState} from 'react';
import ContentEditable, {ContentEditableEvent} from 'react-contenteditable';
import {useNavigate} from 'react-router-dom';

import {advisorClientsService} from 'api/services';
import classNames from 'classnames';
import {useErrorHelper} from 'utils';

import './style.scss';

type PropsTypes = {
	userToken: string;
	userNote: string | null;
};

const UserProfileEditableNote: FC<PropsTypes> = ({userToken, userNote}) => {
	const [note, setNote] = useState<{html: string}>({html: userNote ?? ''});
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const contentEditableNoteRef = useRef<HTMLElement>(null);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);

	const saveNote = async (noteContent: string, token: string): Promise<any> => {
		try {
			const body = {
				userToken: token,
				note: noteContent,
			};
			await advisorClientsService.setNote(body);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const onChangeNote = (e: ContentEditableEvent) => {
		setNote({html: e.target.value});
	};

	const onFocusNote = () => {
		setIsFocused(true);
	};

	const onBlurNote = (e: FocusEvent<HTMLDivElement>) => {
		const value = e.target.innerText;
		setIsFocused(false);
		saveNote(value, userToken);
	};

	const onKeyDownNote = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter' && !(e.altKey || e.shiftKey)) {
			if (contentEditableNoteRef && contentEditableNoteRef.current) {
				contentEditableNoteRef.current.blur();
			}
		}
	};

	return (
		<div className="editableNote">
			<ContentEditable
				innerRef={contentEditableNoteRef}
				html={note.html} // innerHTML of the editable div
				disabled={false} // use true to disable editing
				onChange={onChangeNote}
				onFocus={onFocusNote}
				onBlur={onBlurNote}
				onKeyDown={onKeyDownNote}
				tagName="div" // Use a custom HTML tag (uses a div by default)
				className={classNames('editableNote__contentEditable', {
					focused: isFocused,
				})}
				data-text="Click here to add a note. The note will be visible only to you"
			/>
		</div>
	);
};

export default UserProfileEditableNote;
