import React, {FC, ReactNode} from 'react';

import facebookIcon from '../../assets/icon-facebook.svg';
import instagramIcon from '../../assets/icon-instagram.svg';
import linkedInIcon from '../../assets/icon-linkedin.svg';
import twitterIcon from '../../assets/icon-twitter.svg';
import iconYoutube from '../../assets/icon-youtube.svg';
import AdvisorProfileSocialLink from '../AdvisorProfileSocialLink';

import './style.scss';

type PropsTypes = {
	emptyFieldMessage: string | ReactNode;
	linkedin?: string;
	facebook?: string;
	instagram?: string;
	youtube?: string;
	twitter?: string;
};

const AdvisorProfileSocialInfoBloc: FC<PropsTypes> = ({
	emptyFieldMessage,
	linkedin,
	facebook,
	instagram,
	youtube,
	twitter,
}) => {
	const linkFormatter = (str, link) => {
		return link
			?.replace('http://', '')
			.replace('https://', '')
			.replace('www.', '')
			.replace(str, '');
	};

	const isSocialLinks = !!linkedin || !!facebook || !!instagram || !!youtube || !!twitter;

	return (
		<div className="social-bloc__wrapper">
			<p className="social-bloc__label">Social Links</p>
			<div className="social-bloc__social">
				{' '}
				{/* if the user has the social link in his profile, only then we render this */}
				{linkedin && (
					<AdvisorProfileSocialLink icon={linkedInIcon} link={linkedin}>
						<span>linkedin.com/</span>
						{linkFormatter('linkedin.com/', linkedin)}
					</AdvisorProfileSocialLink>
				)}
				{facebook && (
					<AdvisorProfileSocialLink icon={facebookIcon} link={facebook}>
						<span>facebook.com/</span>
						{linkFormatter('facebook.com/', facebook)}
					</AdvisorProfileSocialLink>
				)}
				{instagram && (
					<AdvisorProfileSocialLink icon={instagramIcon} link={instagram}>
						<span>instagram.com/</span>
						{linkFormatter('instagram.com/', instagram)}
					</AdvisorProfileSocialLink>
				)}
				{youtube && (
					<AdvisorProfileSocialLink icon={iconYoutube} link={youtube}>
						<span>youtube.com/</span>
						{linkFormatter('youtube.com/', youtube)}
					</AdvisorProfileSocialLink>
				)}
				{twitter && (
					<AdvisorProfileSocialLink icon={twitterIcon} link={twitter}>
						<span>twitter.com/</span>
						{linkFormatter('twitter.com/', twitter)}
					</AdvisorProfileSocialLink>
				)}
				{!isSocialLinks && emptyFieldMessage}
			</div>
		</div>
	);
};

export default AdvisorProfileSocialInfoBloc;
