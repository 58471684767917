import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import {PFButton, PFInput} from '../../../../shared/components';
import './style.scss';
import PORTAL_PATHS from '../../../../const/url-paths/portal';
import iconCopy from '../../../../assets/icon-copy.svg';
import {useAddNewApi, useApiDataByToken, useEditApi} from '../../../../shared';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useHandleCopyApiKey} from '../../../../shared/hooks/clipboard';
import ProfileFormContainer from '../../../../components/ProfileFormContainer';
import {useErrorHelper} from '../../../../utils/futureTS/generalUtils';
import {useForm} from 'react-hook-form';
import FormInputKeys from './constants';
import {generateValidator, validations} from '../../../../utils/futureTS/validations';
import classNames from 'classnames';

const ChangesAPIInstance = () => {
	const apiToken = window.location.hash.slice(1);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const getAPIDataByToken = useApiDataByToken();
	const [apiParams, setApiParams] = useState(null);
	const handleCopyApiKey = useHandleCopyApiKey();
	const addNewAPI = useAddNewApi();
	const editAPI = useEditApi();

	const {register, trigger, watch, formState, setValue, handleSubmit} = useForm({
		mode: 'onChange',
	});

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [hintValue, setHintValue] = useState(0);

	const noteHint = (
		<div className={classNames('counter-hint', {error: hintValue > 115})}>{hintValue}/115</div>
	);

	const fullWidthClass = 'col-12 mb-4';

	const goBack = () => {
		navigate(PORTAL_PATHS.SIDEBAR.API_INSTANCES);
	};

	const onSubmit = async valueObj => {
		const addBody = {
			instanceName: valueObj[FormInputKeys.instanceName],
			notes: valueObj[FormInputKeys.note],
		};
		const editBody = {
			instanceName: valueObj[FormInputKeys.instanceName],
			notes: valueObj[FormInputKeys.note],
			token: apiParams?.token,
		};
		try {
			setIsSubmitting(true);
			if (!!apiParams?.token) {
				await editAPI(editBody);
			} else {
				await addNewAPI(addBody);
			}
			goBack();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === 'note') {
				setHintValue(data.note.length);
				await trigger('note');
			}
			if (name === 'instanceName') {
				await trigger('instanceName');
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	useEffect(() => {
		setValue(FormInputKeys.instanceName, apiParams?.instanceName || '');
		setValue(FormInputKeys.note, apiParams?.notes || '');
	}, [apiParams]);

	const fetchStartParams = useCallback(
		async apiToken => {
			try {
				const startParams = await getAPIDataByToken(apiToken);
				if (startParams) {
					setApiParams(startParams);
				}
			} catch (e) {
				errorHelper(e);
				throw e;
			}
		},
		[apiToken],
	);

	useEffect(() => {
		if (!!apiToken) fetchStartParams(apiToken);
	}, [apiToken]);

	return (
		<div className={'addNewAPIInstance'}>
			<ProfileFormContainer
				title={`${!!apiToken ? 'Edit' : 'Add New'} API Instance`}
				goBack={goBack}>
				<form onSubmit={handleSubmit(onSubmit)} className="pf-profileEdit__form">
					<div className={`${fullWidthClass} instanceName`}>
						<PFInput
							{...register(FormInputKeys.instanceName, {
								validate: generateValidator(
									true,
									validations.textFields.apiInstanceName,
								),
							})}
							showAsterisk={true}
							label={'API Instance Name'}
							placeholder={'Name'}
							defaultValue={apiParams ? apiParams?.instanceName : ''}
							error={
								formState.errors[FormInputKeys.instanceName]?.message &&
								formState.errors[FormInputKeys.instanceName]?.message
							}
						/>
					</div>
					<div className={`${fullWidthClass} note`}>
						<PFInput
							{...register(FormInputKeys.note, {
								validate: generateValidator(
									false,
									validations.textFields.apiInstanceNote,
								),
							})}
							showAsterisk={false}
							label={'Note'}
							placeholder={'Note text'}
							defaultValue={apiParams ? apiParams?.note : ''}
							hint={noteHint}
							error={
								formState.errors[FormInputKeys.note]?.message &&
								formState.errors[FormInputKeys.note]?.message
							}
						/>
					</div>
					{!!apiParams && (
						<div className={'instance-key'}>
							<CopyToClipboard text={apiParams.key} onCopy={handleCopyApiKey}>
								<ReactSVG wrapper="div" className="icon-copy" src={iconCopy} />
							</CopyToClipboard>
							<CopyToClipboard text={apiParams.key} onCopy={handleCopyApiKey}>
								<div className={`pf-tableText instance-key__text`}>
									{apiParams.key}
								</div>
							</CopyToClipboard>
						</div>
					)}
					<div className="addNewAPIInstance__buttons-wrapper">
						<PFButton
							onClick={goBack}
							className="pf-profileEdit__form-button "
							variant="secondary"
							type="button">
							Cancel
						</PFButton>
						<PFButton
							disabled={!formState.isValid || isSubmitting}
							className="pf-profileEdit__form-button pf-profileEdit__form-button--submit"
							onClick={() => {}}
							type="submit">
							{!!apiParams ? 'Save' : 'Add'}
						</PFButton>
					</div>
				</form>
			</ProfileFormContainer>
		</div>
	);
};

export default ChangesAPIInstance;
