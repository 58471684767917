import React, {FC, ReactNode} from 'react';
import {useMediaQuery} from 'react-responsive';
import {ReactSVG} from 'react-svg';

import {tooltipIcon} from 'assets';
import classNames from 'classnames';
import {Tooltip, Whisper} from 'rsuite';

import './style.scss';

type PropsTypes = {
	tooltipBody: ReactNode;
	arrow: boolean;
	className?: {
		clickAria?: string;
		tooltipBody?: string | ReactNode;
	};
};

const PFTooltip: FC<PropsTypes> = ({tooltipBody, arrow, className}) => {
	const trigger = useMediaQuery({query: '(max-width: 992px)'}) ? 'click' : 'hover';
	return (
		<Whisper
			enterable
			preventOverflow
			trigger={trigger}
			placement="auto"
			controlId="control-auto"
			speaker={
				<Tooltip
					arrow={arrow}
					className={classNames('infoTooltip tooltipBody', {
						[`${className?.tooltipBody}`]: className?.tooltipBody,
					})}>
					<div>{tooltipBody}</div>
				</Tooltip>
			}>
			<div
				id="control-auto"
				className={classNames('infoTooltip-clickAria', {
					[`${className?.clickAria}`]: className?.clickAria,
				})}>
				<ReactSVG src={tooltipIcon} className="infoTooltip-clickAria__icon" />
			</div>
		</Whisper>
	);
};

export default PFTooltip;
