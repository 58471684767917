import {SET_CURRENT_PAGE_TITLE, SET_SIDEBAR, SET_SIDE_MODAL, RESET_UI} from '../types/Types';

export const setSidebar = isOpen => {
	return {
		type: SET_SIDEBAR,
		payload: isOpen,
	};
};

export const setSideModal = isOpen => {
	return {
		type: SET_SIDE_MODAL,
		payload: isOpen,
	};
};

export const setCurrentPageTitle = title => {
	return {
		type: SET_CURRENT_PAGE_TITLE,
		payload: title,
	};
};

export const openSidebar = () => setSidebar(true);

export const closeSidebar = () => setSidebar(false);
export const resetUi = () => ({
	type: RESET_UI,
});
