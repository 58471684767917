import React, {useEffect, useState} from 'react';

import styles from './Content.module.scss';
import {ReactSVG} from 'react-svg';
import {PFButton, PFInput, PFLink, PFPopup} from 'shared/components';
import {iconBack} from 'assets';
import {useLocation, useNavigate} from 'react-router';
import {useForm} from 'react-hook-form';
import {Amplitude, ToastDispatcher, generateValidator, hints, validations} from 'utils';
import {profileService, usersService} from 'api/services';
import {AxiosError} from 'axios';
import {EyeIcon} from './EyeIcon';
import {useBooleanState} from 'shared';
import {PATHS} from 'const';

type FormFields = {
	code: string;
	password: string;
	passwordConfirmation: string;
};

enum ResponseCodes {
	Success = 0,
	WrongCodeLimit = 1,
	WrongCodeAttemts = 2,
	Error = 3,
}

export const Content: React.FC<{}> = ({}) => {
	const navigate = useNavigate();
	const {search} = useLocation();
	const params = Object.fromEntries(new URLSearchParams(search).entries());

	const {
		register,
		formState: {errors, isValid, touchedFields},
		handleSubmit,
		getValues,
		setValue,
		watch,
		trigger,
	} = useForm<FormFields>({mode: 'onChange'});

	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	const [isCodeSecured, toggleIsCodeSecured] = useBooleanState(true);

	const [modalMessage, setModalMessage] = useState<string>('');
	const [modalSubtitle, setModalSubtitle] = useState<string>('');

	const hideModal = () => {
		setModalMessage('');
	};

	const handleSubmitModal = () => {
		hideModal();
	};

	const onSubmit = async (fields: FormFields) => {
		try {
			if (!params.e) {
				return;
			}

			setModalSubtitle('');
			setIsProcessing(true);

			const response = await profileService.setPasswordByCode({
				code: fields.code,
				username: params.e,
				password: fields.password,
				isFromPortal: true,
			});

			const data: {
				message: string;
				type: ResponseCodes;
				count?: number;
			} = response.data;

			if (data.type === ResponseCodes.Success) {
				Amplitude.track('Set_Password');
				ToastDispatcher.success('New password has been set. Now you can log in.');
				navigate(PATHS.PORTAL.PORTAL_BASE_URL);
				return;
			}

			if (data.type === ResponseCodes.WrongCodeAttemts) {
				setModalSubtitle(`${data.count} attempt${data.count !== 1 ? 's' : ''} left`);
			}
			setModalMessage(data.message);

			Amplitude.track('Detected_Error', {error_name: data.message});
		} catch (err) {
			const message =
				(err as AxiosError<{message?: string}>)?.response?.data?.message ||
				'Something went wrong. Please try again later.';

			Amplitude.track('Detected_Error', {error_name: message});
			setModalMessage(message);
		} finally {
			setIsProcessing(false);
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === 'password' && touchedFields.passwordConfirmation) {
				await trigger('passwordConfirmation');
			}
		});

		return () => subscription.unsubscribe();
	}, [watch, trigger, touchedFields.passwordConfirmation]);

	return (
		<>
			<section className={styles.container}>
				<div className={styles.contentContainer}>
					<article className={styles.content}>
						<div className={styles.title}>Set New Password</div>
						<div className={styles.subtitle}>
							Create a new password for entering the portal
						</div>

						<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
							<PFInput
								{...register('code', {
									required: true,
									validate: validations.code,
									onChange: e => {
										const value = e.target.value.replace(/[^0-9]/g, '');
										setValue('code', value);
									},
								})}
								//@ts-ignore
								showAsterisk
								error={errors.code?.message}
								label="5-Digit code"
								maxLength="5"
								type={'text'}
								autoComplete="off"
							/>
							<PFInput
								{...register('password', {
									required: true,
									validate: generateValidator(
										hints.password.required,
										validations.password,
									),
								})}
								//@ts-ignore
								showAsterisk
								hint="Password should consist of at least 8 characters and be the mix of letters, digits and special symbols"
								error={errors.password?.message}
								label="Password"
								icon={
									<EyeIcon closed={isCodeSecured} onClick={toggleIsCodeSecured} />
								}
								type={isCodeSecured ? 'password' : 'text'}
								autoComplete={'new-password'}
							/>
							<PFInput
								{...register('passwordConfirmation', {
									required: true,
									validate: value =>
										value === getValues('password') ||
										'The passwords do not match!',
								})}
								//@ts-ignore
								showAsterisk
								error={errors.passwordConfirmation?.message}
								label="Confirm password"
								icon={
									<EyeIcon closed={isCodeSecured} onClick={toggleIsCodeSecured} />
								}
								type={isCodeSecured ? 'password' : 'text'}
								autoComplete={'new-password'}
							/>
							<PFButton
								type="submit"
								disabled={isProcessing || !isValid}
								className={styles.button}>
								Set
							</PFButton>
						</form>
					</article>
				</div>
			</section>

			<PFPopup
				isShow={!!modalMessage}
				handleClose={hideModal}
				submitBtnText={'Ok'}
				isShowCancelBtn={false}
				primaryVariant="primary"
				isCloseButton={false}
				title={modalMessage}
				subtitle={modalSubtitle}
				onSubmit={handleSubmitModal}
			/>
		</>
	);
};
