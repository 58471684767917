import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSwipeable} from 'react-swipeable';
import {ReactSVG} from 'react-svg';
import {PFAvatar, PFButton, PFLink, PFLogoLink, PFNavigation} from '../index';
import {
	InviteAdvisorModalActions,
	setIsOpenReferPopup,
	setOpenDeepLinkPopup,
	setSidebar,
	setSideModal,
	setTableReset,
} from '../../../store/actions';
import {useSignOut} from '../../hooks/auth';
import {
	BIG_MOBILE_SCREEN_SIZE,
	LARGE_SCREEN_SIZE,
	PRIVACY_POLICY_LINK,
	TERMS_OF_USE_LINK,
} from '../../../const';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import urlPaths from '../../../api/url-paths';
import closeIcon from '../../../assets/icon-close.svg';
import iconProfile from '../../../assets/header/profile.svg';
import iconLogout from '../../../assets/header/logout.svg';
import manageSubscriptionIcon from '../../../assets/header/manage-subscription-icon.svg';
import banner from '../../../assets/icon-banner.svg';
import PFDropdownItem from '../PF-DropdownItem';
import classNames from 'classnames';
import './style.scss';
import eduHab from '../../../assets/icon-edu-hub.svg';
import iconHelp from '../../../assets/icon-help.svg';
import {GetIsSidebarOpen, GetIsSideModalOpen} from '../../../store/selectors/UISetings';
import {accountSettings, inviteNewAdvisorIcon, profileLink, referBusiness} from '../../../assets';
import {useAppDispatch, useAppSelector} from '../../../store';
import {SubscriptionSelectors, myProfileSelectors} from '../../../store/selectors';
import {AdvisorsRole} from '../../../types/enums';
import {UpgradePlanButton} from 'components/ui/upgrade-plan';
import {Amplitude} from 'utils';

export default function PFMobileMenu({className, isDeepLink}) {
	const dispatch = useAppDispatch();
	const isSidebarOpen = GetIsSidebarOpen();
	const isSideModalOpen = GetIsSideModalOpen();
	const auth = useAppSelector(state => state.general.auth);
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const role = profile?.roles; // ! new
	const navigate = useNavigate();
	const prefix = <ReactSVG src={closeIcon} />;
	const isSmallScreen = window.innerWidth < LARGE_SCREEN_SIZE;
	const {showConfirm, ConfirmModal} = useSignOut(navigate);

	const hasSubscription = useAppSelector(SubscriptionSelectors.selectHasSubscription);

	const swipeHandlers = useSwipeable({
		trackMouse: true,
		onSwipedLeft: () => dispatch(setSidebar(false)),
	});

	const toggle = () => {
		dispatch(setSidebar(!isSidebarOpen));
	};

	const showConfirmModal = () => {
		toggle();
		showConfirm();
	};

	const goToProfile = () => {
		if (isSmallScreen) {
			toggle();
		}
		navigate(PORTAL_PATHS.OTHER_PATHS.MY_PROFILE);
	};

	const goToEditLoginSettings = () => {
		navigate(PORTAL_PATHS.OTHER_PATHS.LOGIN_SETTINGS_EDIT);
		toggle();
	};

	const goToAccountSettings = () => {
		if (isSmallScreen) {
			toggle();
		}
		navigate(PORTAL_PATHS.OTHER_PATHS.ACCOUNT_SETTINGS);
	};

	const goToManageSubscription = () => {
		if (isSmallScreen) {
			toggle();
		}
		Amplitude.track('Opened_Upgrade', {
			source: 'Navigation',
		});
		navigate(PORTAL_PATHS.OTHER_PATHS.MANAGE_SUBSCRIPTION);
	};

	const showFreshChatWidget = () => {
		toggle();

		Amplitude.track('Help/FAQ');
		window.fcWidget.open();
	};

	const showInviteAdvisorModal = () => {
		toggle();
		dispatch(InviteAdvisorModalActions.setOpen('Navigation'));
	};

	useEffect(() => {
		if (
			isSidebarOpen &&
			isSideModalOpen &&
			Math.max(window.screen.width, window.innerWidth) < BIG_MOBILE_SCREEN_SIZE
		) {
			dispatch(setSideModal(!isSideModalOpen));
		}
	}, [isSidebarOpen]);

	const smallScreenHandler = () => (isSmallScreen ? toggle() : null);

	const copyDeepLinkOnClick = () => {
		smallScreenHandler();
		dispatch(setOpenDeepLinkPopup({advisorToken: profile.advisor.token, isMyLink: true}));
	};

	const openReferPopup = () => {
		toggle();
		dispatch(setIsOpenReferPopup('Menu'));
	};

	return (
		<>
			{profile && (
				<div
					{...swipeHandlers}
					className={classNames('pf-sidebar', className, {
						'pf-sidebar--collapsed': !isSidebarOpen,
					})}>
					<div className="pf-sidebar__header">
						<PFLogoLink to={PORTAL_PATHS.PORTAL_BASE_URL} settingClasses="sidebar" />
						<PFButton
							onClick={toggle}
							prefixIcon={prefix}
							variant="plain"
							className="pf-sidebar__close-button d-xl-none"
						/>
					</div>
					<div className="pf-sidebar__container">
						<PFNavigation
							onNavItemClick={() => {
								toggle();
								dispatch(setTableReset());
							}}
						/>
					</div>
					<div className="banner__hr" />
					{auth && !role.includes(AdvisorsRole.SuperAdmin) && (
						<div className="d-xl-none pf-sidebar__profile">
							<div className=" pf-sidebar__profile-item pf-sidebar__profile-item--header">
								<PFAvatar
									src={
										profile?.advisor?.profileImage &&
										`${urlPaths.BASE_IMAGES_URL}${profile?.advisor?.profileImage}`
									}
									size={24}
								/>
								<span>{profile?.firstName + ' ' + profile?.lastName}</span>
							</div>
							<div className="pf-sidebar__profile-item" onClick={goToProfile}>
								<PFDropdownItem icon={iconProfile}>My Profile</PFDropdownItem>
							</div>
							{isDeepLink && (
								<div
									className=" pf-sidebar__profile-item"
									onClick={copyDeepLinkOnClick}>
									<PFDropdownItem icon={profileLink} className="shareProfileLink">
										Share My Profile Link
									</PFDropdownItem>
								</div>
							)}
							<div className=" pf-sidebar__profile-item" onClick={openReferPopup}>
								<PFDropdownItem icon={referBusiness}>Refer Business</PFDropdownItem>
							</div>
							<div className="pf-sidebar__profile-item" onClick={goToAccountSettings}>
								<PFDropdownItem icon={accountSettings}>
									Account Settings
								</PFDropdownItem>
							</div>
							<div className=" pf-sidebar__profile-item" onClick={showConfirmModal}>
								<PFDropdownItem icon={iconLogout}>Logout</PFDropdownItem>
							</div>

							<div className="banner__hr" />

							{(role.includes(AdvisorsRole.Advisor) ||
								role.includes(AdvisorsRole.AccountAdministrator) ||
								role.includes(AdvisorsRole.Administrator)) && (
								<>
									{hasSubscription ? (
										<div
											className=" pf-sidebar__profile-item"
											onClick={goToManageSubscription}>
											<PFDropdownItem icon={manageSubscriptionIcon}>
												Manage Subscription
											</PFDropdownItem>
										</div>
									) : (
										<UpgradePlanButton onClick={goToManageSubscription} />
									)}

									<div className="banner__hr" />
								</>
							)}

							<div className="pf-sidebar__profile-item">
								<PFDropdownItem icon={iconHelp} onClick={showFreshChatWidget}>
									Help/FAQ
								</PFDropdownItem>
							</div>
							<div className="banner">
								<div
									className="banner-main-wrapper"
									style={{backgroundImage: `url(${banner})`}}>
									<div className="banner-main">
										<h2 className={'banner-title'}>Coming Soon:</h2>
										<PFDropdownItem icon={eduHab} className={'eduHab'}>
											Edu Hub
										</PFDropdownItem>
									</div>
								</div>
							</div>
							<div className="pf-sidebar__profile-item">
								<PFDropdownItem
									icon={inviteNewAdvisorIcon}
									onClick={showInviteAdvisorModal}>
									Invite New Advisors
								</PFDropdownItem>
							</div>
							{/* Временно убрали, пока не реализован функционал кнопок*/}
							{/*<div className=" pf-sidebar__profile-item disabled">*/}
							{/*	<PFDropdownItem icon={iconImport} className={'disabled'}>*/}
							{/*		Import Contacts*/}
							{/*	</PFDropdownItem>*/}
							{/*</div>*/}
							{/*<div className=" pf-sidebar__profile-item disabled">*/}
							{/*	<PFDropdownItem icon={iconExport} className={'disabled'}>*/}
							{/*		Export Contacts*/}
							{/*	</PFDropdownItem>*/}
							{/*</div>*/}
							<div className="banner__hr" />
							<div className={'dropdown-footer'}>
								<PFDropdownItem>
									<PFLink href={TERMS_OF_USE_LINK} target="_blank">
										Terms of Use
									</PFLink>
								</PFDropdownItem>
								<PFDropdownItem>
									<PFLink href={PRIVACY_POLICY_LINK} target="_blank">
										Privacy Policy
									</PFLink>
								</PFDropdownItem>
							</div>
						</div>
					)}
					{auth && role.includes(AdvisorsRole.SuperAdmin) && (
						<div className="d-xl-none pf-sidebar__profile">
							<div
								className=" pf-sidebar__profile-item pf-sidebar__profile-item-edit"
								onClick={goToEditLoginSettings}>
								Edit Login Settings
							</div>
							<div className=" pf-sidebar__profile-item" onClick={showConfirmModal}>
								<PFDropdownItem icon={iconLogout}>Logout</PFDropdownItem>
							</div>
						</div>
					)}
					<ConfirmModal />
				</div>
			)}
		</>
	);
}
