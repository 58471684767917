import React from 'react';
import icon from './subscription-active-icon.svg';

import styles from './Content.module.scss';
import {ReactSVG} from 'react-svg';
import {PFButton, PFLink} from 'shared/components';
import {iconBack} from 'assets';
import {useNavigate} from 'react-router';
import {PORTAL_PATHS, PUBLIC_PATHS} from 'const';
import {Amplitude} from 'utils';

export const Content: React.FC<{
	isAuth: boolean;
}> = ({isAuth}) => {
	const navigate = useNavigate();

	return (
		<section className={styles.container}>
			<div className={styles.contentContainer}>
				{isAuth && (
					<PFButton
						type="button"
						variant="plain"
						prefixIcon={<ReactSVG wrapper="span" src={iconBack} />}
						onClick={() => {
							Amplitude.track('Opened_Upgrade', {
								source: 'Subscription Status',
							});

							navigate(PORTAL_PATHS.OTHER_PATHS.MANAGE_SUBSCRIPTION);
						}}
						className={styles.back}>
						Back to Subscriptions
					</PFButton>
				)}

				<article className={styles.content}>
					<ReactSVG src={icon} wrapper="span" className={styles.icon} />

					<div className={styles.title}>Your Subscription is Active</div>
					<div className={styles.subtitle}>
						{isAuth
							? 'Thanks for subscribing! You will receive a payment confirmation email. Enjoy your unlimited account!'
							: 'Thank you for subscribing. You will receive a payment confirmation and an account setup invitation.'}
					</div>

					<PFButton
						type="button"
						onClick={() => {
							if (isAuth) {
								Amplitude.track('Opened_Upgrade', {
									source: 'Subscription Status',
								});
							}

							navigate(
								isAuth
									? PORTAL_PATHS.OTHER_PATHS.MANAGE_SUBSCRIPTION
									: PUBLIC_PATHS.SIGN_IN,
							);
						}}
						className={styles.button}>
						{isAuth ? 'Proceed' : 'Ok'}
					</PFButton>

					<div className={styles.contacts}>
						<div className={styles.hint}>
							{isAuth
								? "Haven't received an email?"
								: "Haven't received an invitation?"}
						</div>

						<PFLink
							href="mailto:support@pocketfinance.com"
							onClick={() => {
								Amplitude.track('Contacted_Support', {
									source: 'Subscription Status',
								});
							}}
							className={styles.link}>
							Contact the administrator
						</PFLink>
					</div>
				</article>
			</div>
		</section>
	);
};
