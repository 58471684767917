import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ReactSVG} from 'react-svg';
import plus from '../../../../assets/icon-plus.svg';
import PORTAL_PATHS from '../../../../const/url-paths/portal';
import {PFButton, PFContactsBlock, PFSortArrow, PFTable} from '../../../../shared/components';
import iconBack from '../../../../assets/icon-arrow-back.svg';
import iconEdit from '../../../../assets/buttons/icon-pencil.svg';
import iconInvite from '../../../../assets/icon-invite.svg';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import dot from '../../../../assets/icon-dots.svg';
import iconUnblock from '../../../../assets/icon-unblock.svg';
import iconBlock from '../../../../assets/icon-block.svg';
import iconDelete from '../../../../assets/icon-delete.svg';
import iconAdmin from '../../../../assets/icon-administrator.svg';
import iconDeeplink from '../../../../assets/icon-deeplink.svg';
import iconChangeCompany from '../../../../assets/icon-changeCompany.svg';
import generalAdmin from '../../../../assets/star.svg';
import {
	useBlockedCompany,
	useBlockedPopup,
	useChangeCompanyPopup,
	useCompany,
	useCompanyUsers,
	useDeletedPopup,
	useIsSuspendUserPopup,
	useResetIsNewCompany,
} from '../../../../shared/hooks/companies';
import {
	setCurrentPageTitle,
	cleanedUpCompaniesData,
	setCompaniesProfileData,
	cleanedUpTableData,
	setCheckedData,
} from '../../../../store/actions';
import {Table} from 'rsuite';
import * as usersService from '../../../../api/services/users';
import {Amplitude, findByToken} from '../../../../utils';
import {useDeletedUserPopup, useRestoreUser} from '../../../../shared/hooks/users';
import * as advisorService from '../../../../api/services/advisor';
import {
	useErrorHelper,
	getCamelCaseToText,
	getFullName,
	mapQueryParams,
	parseUrlQuery,
	ToastDispatcher,
} from '../../../../utils';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useHandleCopyRegistrationLink} from '../../../../shared/hooks/clipboard';
import PFTableBar from '../../../../shared/components/PF-TableBar';
import './style.scss';
import {useCompanyAdministrators} from '../../../../shared/hooks';
import {
	DeletedPage,
	SearchEmptyPage,
	SuperAdminEmptyPage,
	UserCellItem,
} from '../../../../components';
import {deletedIconForRestorePage} from '../../../../assets';
import URL from '../../../../api/url-paths';
import {useAppSelector} from '../../../../store';
import {companySelectors, myProfileSelectors, tableSelectors} from '../../../../store/selectors';
import InviteAdvisorsPopup from './components/InviteAdvisorsPopup';
import urlPaths from '../../../../api/url-paths';
import {DEFAULT_COMPANY_AVATAR} from '../../../../const';
import s from '../../../../shared/components/PF-CompanyDescription/style.module.scss';
import PFAvatar from '../../../../shared/components/PF-Avatar';
import * as companiesService from '../../../../api/services/companies';
import {AdvisorsRole} from '../../../../types/enums';

const CompanyProfile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userRole = useAppSelector(myProfileSelectors.selectMyRole);
	const companyToken = !!window.location.hash.slice(1)
		? window.location.hash.slice(1)
		: userRole === AdvisorsRole.AccountAdministrator || userRole === AdvisorsRole.Administrator
		? useAppSelector(myProfileSelectors.selectMyCompanyToken) //props.user?.data?.advisor?.companyToken
		: null;
	const [selectedCompany, setSelectedCompany] = useState({});
	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;
	const editIcon = <ReactSVG wrapper="span" className="me-2" src={iconEdit} />;
	const blockCompany = useBlockedCompany();
	const fetchCompany = useCompany();
	const [isLoading, setIsLoading] = useState(true);
	const [update, setUpdate] = useState(true);
	const [updateParam, setUpdateParam] = useState('');
	const {Column, HeaderCell, Cell} = Table;
	const tableData = useAppSelector(companySelectors.selectCompanyUsers); // props.companies.companyUsers;
	const fullTableDataLength = useAppSelector(companySelectors.selectCompanyFullCount); //props.companies.fullCount;
	const checkedKeys = useAppSelector(tableSelectors.selectTableCheckedData);
	const searchProp = useAppSelector(tableSelectors.selectTableSearchData);
	const resetProp = useAppSelector(tableSelectors.selectTableResetData);
	const reverseProp = useAppSelector(tableSelectors.selectTableReverseData);
	const handleCopyRegistrationLink = useHandleCopyRegistrationLink();
	const goToEditCompanyData = () =>
		navigate(`${PORTAL_PATHS.OTHER_PATHS.EDIT_COMPANY_DATA}#${companyToken}`);
	const {showChangeCompanyConfirm, ConfirmChangeCompanyModal} = useChangeCompanyPopup();
	const urlParams = parseUrlQuery(document.location);
	const [isEmptyPage, setIsEmptyPage] = useState(false);
	const resetIsNewCompany = useResetIsNewCompany();
	const [isDeletedCompany, setIsDeletedCompany] = useState(false);
	const restoreUser = useRestoreUser();
	const getCompanyAdministrators = useCompanyAdministrators();
	const [admins, setAdmins] = useState(null);
	const errorHelper = useErrorHelper(navigate);
	const [isOpenInviteAdvisorsPopup, setIsOpenInviteAdvisorsPopup] = useState(false);

	const params = {
		companyToken,
		...mapQueryParams(urlParams),
	};

	const fetchRestoreUser = async token => {
		try {
			await restoreUser(token);
			const foundDeletedUser = findByToken(tableData, token);
			if (foundDeletedUser) foundDeletedUser.isDeleted = false;
			updateData();
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const fetchRestoreCompany = async token => {
		try {
			await companiesService.restoreCompanies([token]);
			setIsDeletedCompany(false);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const fetchCompanyUsersData = useCompanyUsers(params);
	const [columns] = useState([
		{
			name: 'user',
			title: 'User',
			flexGrow: 1,
			minWidth: 200,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'email',
			title: 'Email',
			flexGrow: 1,
			minWidth: 180,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'companyRole',
			title: 'Company role',
			flexGrow: 1,
			minWidth: 100,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
	]);

	const tableColumns = columns.map((item, index) => {
		const switchParam = param => {
			switch (param) {
				case 'user':
					return (
						<Cell dataKey="LastName">
							{rowData => (
								<UserCellItem
									lastName={rowData?.lastName}
									firstName={rowData?.firstName}
									avatarImage={rowData?.advisor?.profileImage}
									avatarSize={40}
									//! restore={fetchRestoreUser} пока нет рестора
									isDeleted={rowData?.isDeleted}
									restoreToken={rowData?.token}
								/>
							)}
						</Cell>
					);
				case 'email':
					return (
						<Cell dataKey="Email">
							{rowData => (
								<div className="tableEmailCell">
									<a
										href={`mailto:${
											rowData?.advisor?.contactEmail ||
											rowData?.advisor?.email
										}`}
										className={`pf-tableText`}>
										{rowData?.advisor?.contactEmail || rowData?.advisor?.email}
									</a>
									{userRole !== AdvisorsRole.Advisor &&
										!rowData?.isEmailConfirmed && (
											<p className="unconfirmedEmail">Unconfirmed</p>
										)}
								</div>
							)}
						</Cell>
					);
				case 'companyRole':
					return (
						<Cell dataKey="Company role">
							{rowData => (
								<div className={`pf-tableText`}>
									{getCamelCaseToText(rowData?.roles[0])}
								</div>
							)}
						</Cell>
					);
				default:
					break;
			}
		};

		return (
			<Column
				key={index}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						{<PFSortArrow />}
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;
		try {
			setIsLoading(true);
			await fetchCompanyUsersData();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const fetchIsSuspendUsers = isBlocked => {
		const count = checkedKeys.length;
		const toastMessage = `${count} user${count > 1 ? 's' : ''} ${
			isBlocked ? 'suspended' : 'reactivated'
		}`;

		try {
			Promise.all(
				checkedKeys.map(token => {
					const body = {
						token,
						isBlocked,
					};
					return usersService.setBlockedUser(body);
				}),
			)
				.then(response => {
					ToastDispatcher.success(toastMessage);
					setUpdateParam(isBlocked ? 'suspended' : 'reactivated');
					updateData();
				})
				.catch(e => {
					errorHelper(e);
				});
		} catch (e) {
			throw e;
		}
	};

	const fetchDeletedUsers = checkedKeys => {
		const count = checkedKeys.length;
		const toastMessage = `${count > 1 ? count : ''} ${count > 1 ? 'u' : 'U'}ser${
			count > 1 ? 's' : ''
		} deleted`;
		try {
			Promise.all(checkedKeys.map(item => usersService.deleteAccount({userToken: item})))
				.then(response => {
					ToastDispatcher.success(toastMessage);
					setUpdateParam('deleted');
					updateData();
				})
				.catch(e => {
					errorHelper(e);
				});
		} catch (e) {
			throw e;
		}
	};

	const updateCallback = () => {
		switch (updateParam) {
			case 'suspended': {
				checkedKeys.forEach(key => {
					const foundBlockedUser = findByToken(tableData, key);
					if (foundBlockedUser) foundBlockedUser.isBlocked = true;
				});
				setUpdateParam('');
				break;
			}
			case 'reactivated': {
				checkedKeys.forEach(key => {
					const foundBlockedUser = findByToken(tableData, key);
					if (foundBlockedUser) foundBlockedUser.isBlocked = false;
				});
				setUpdateParam('');
				break;
			}
			case 'deleted': {
				checkedKeys.forEach(key => {
					const foundDeletedUser = findByToken(tableData, key);
					if (foundDeletedUser) foundDeletedUser.isDeleted = true;
				});
				dispatch(setCheckedData([])); //! new
				setUpdateParam('');
				break;
			}
			default: {
				return;
			}
		}
	};

	const ChangeCompanyButton = () => {
		return (
			<PFButton
				type={'button'}
				prefixIcon={
					<ReactSVG
						wrapper="span"
						className="pf-icon-request-report"
						src={iconChangeCompany}
					/>
				}
				className={'pf-consumer-block-button'}
				onClick={showChangeCompanyConfirm}>
				<span className={'button-text'}>Change Company</span>
			</PFButton>
		);
	};

	const DeleteButton = () => {
		return (
			<PFButton
				type={'button'}
				prefixIcon={
					<ReactSVG
						wrapper="span"
						className="pf-icon-request-report pf-icon-delete"
						src={iconDelete}
					/>
				}
				className={'pf-consumer-delete-button'}
				onClick={showDeletedUserConfirm}>
				<span className={'button-text'}>Delete</span>
			</PFButton>
		);
	};

	const BlockButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG wrapper="span" className="pf-icon-request-report" src={iconBlock} />
				}
				className={'pf-consumer-block-button'}
				onClick={showIsSuspendUserConfirm}
				type="button">
				<span className={'button-text'}>Suspend</span>
			</PFButton>
		);
	};

	const UnblockButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG wrapper="span" className="pf-icon-request-report" src={iconUnblock} />
				}
				className={'pf-consumer-block-button'}
				onClick={() => fetchIsSuspendUsers(false)}
				type="button">
				<span className={'button-text'}>Reactivate</span>
			</PFButton>
		);
	};

	const goBack = () => {
		dispatch(cleanedUpCompaniesData());
		dispatch(cleanedUpTableData());
		navigate(PORTAL_PATHS.SIDEBAR.COMPANIES);
	};

	const handleBlocked = async () => {
		const body = {
			token: selectedCompany.token,
			isBlocked: !selectedCompany.isBlocked,
		};
		try {
			await blockCompany(
				body,
				selectedCompany.name,
				() => (selectedCompany.isBlocked = !selectedCompany.isBlocked),
			);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const onDeleteCompany = async () => {
		try {
			await companiesService.deleteCompanies([selectedCompany.token]);
			ToastDispatcher.success(`Company deleted`);
			setIsDeletedCompany(true);
		} catch (e) {
			errorHelper(e);
		}
	};

	const fetchChangeUsersCompany = async (checkedKeys, companyToken, companyName) => {
		const count = checkedKeys.length;
		const toastMessage = `Company changed for ${count} user${count > 1 ? 's' : ''}`;
		const advisorTokens = [];
		tableData.forEach(item =>
			checkedKeys.includes(item.token) ? advisorTokens.push(item.advisor.token) : null,
		);
		try {
			await advisorService.updateAdvisorCompany({userTokens: checkedKeys, companyToken});
			ToastDispatcher.success(toastMessage);
			checkedKeys.forEach(key => {
				const foundUser = findByToken(tableData, key);
				if (foundUser) foundUser.advisor.companyName = companyName;
			});
			dispatch(setCheckedData([]));
			updateData();
		} catch (e) {
			throw e;
		}
	};

	const {showConfirm, ConfirmModal} = useBlockedPopup();
	const {showDeleteConfirm, ConfirmDeleteModal} = useDeletedPopup();
	const {showDeletedUserConfirm, ConfirmDeleteUserModal} = useDeletedUserPopup();
	const {showIsSuspendUserConfirm, ConfirmIsSuspendUserModal} = useIsSuspendUserPopup();

	const showConfirmModal = () => {
		if (selectedCompany.isBlocked) {
			handleBlocked();
		} else {
			showConfirm();
		}
	};

	const goToAddNewUser = () => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.ADD_NEW_ADVISOR}#${companyToken}`);
	};

	const emptyPageParam = {
		title: 'The List is Empty',
		subtitle: 'Click the button to add new users:',
		button: {
			variant: 'primary', // "secondary" or "primary"
			onClick: goToAddNewUser,
			icon: false, // false or component
			isShowIcon: true, // true or false
			text: 'Add New User',
		},
		className: 'pb-5 pb-lg-1',
	};

	const deletePageProps = {
		className: 'delete-company',
		srcImage: deletedIconForRestorePage,
		title: 'Company Deleted',
		subtitle: `You have just deleted ${selectedCompany?.name}. If you leave this page, it will be impossible to restore this profile.`,
		buttonVariant: 'primary',
		goBackCallback: goBack,
		restoreCallback: () => fetchRestoreCompany(companyToken),
	};

	const searchValue = useSelector(state => state.table.search);
	const searchEmptyParam = {
		variant: 'secondary',
		className: '',
		pageName: 'Company profile',
		buttonText: 'Back to Company profile',
	};
	const tableBarButtons = [
		<BlockButton key={1} />,
		<UnblockButton key={2} />,
		<ChangeCompanyButton key={3} />,
		<DeleteButton key={4} />,
	];
	if (userRole !== AdvisorsRole.SuperAdmin) {
		tableBarButtons.pop();
		tableBarButtons.pop();
	}

	const getCompanyInfo = async () => {
		if (
			userRole === AdvisorsRole.AccountAdministrator ||
			userRole === AdvisorsRole.Administrator
		) {
			dispatch(setCurrentPageTitle('My Company'));
		} else {
			dispatch(setCurrentPageTitle('Companies'));
		}

		try {
			setIsLoading(true);

			const CompanyUsersData = await fetchCompanyUsersData();
			const data = await fetchCompany(companyToken);
			const companyAdministrators = await getCompanyAdministrators(companyToken);
			setAdmins(companyAdministrators);
			setSelectedCompany(data);
			dispatch(setCompaniesProfileData(data));
			if (Boolean(CompanyUsersData.fullCount)) {
				setIsEmptyPage(false);
			} else {
				setIsEmptyPage(true);
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const handleCopyRegistrationLinkPress = (text, result) => {
		handleCopyRegistrationLink(text, result);
		Amplitude.track('Copied_Registration_Link');
	};

	useEffect(() => {
		getCompanyInfo();
	}, [searchProp, resetProp, reverseProp]);

	useEffect(() => {
		Amplitude.track('Opened_My_Company');
	}, []);

	useEffect(() => {
		return () => {
			dispatch(cleanedUpCompaniesData());
			dispatch(cleanedUpTableData());
		};
	}, []);

	const resetIsNewCompanyCallback = async () => {
		try {
			await resetIsNewCompany(selectedCompany.token);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	useEffect(() => {
		if (
			!!Object.keys(selectedCompany).length &&
			selectedCompany?.isNewCompany &&
			userRole === AdvisorsRole.SuperAdmin
		) {
			resetIsNewCompanyCallback();
		}
	}, [selectedCompany, companyToken]);

	return (
		<>
			{!isDeletedCompany && (
				<div className={`companyProfile`}>
					<div className={`companyInfo`}>
						<div className={`companyInfo_wrapper`}>
							{userRole === AdvisorsRole.SuperAdmin && (
								<PFButton
									type={'button'}
									className="w-auto px-0 mb-2"
									prefixIcon={backIcon}
									variant="plain"
									onClick={goBack}>
									Back
								</PFButton>
							)}

							<div className={`pf_company_description`}>
								<PFAvatar
									src={
										(selectedCompany?.image &&
											`${urlPaths.BASE_IMAGES_URL}${selectedCompany?.image}`) ||
										DEFAULT_COMPANY_AVATAR
									}
									size={60}
									defaultAvatar={DEFAULT_COMPANY_AVATAR}
									variant="rounded-square"
									className={'companyLogo'}
								/>
								<h3>{selectedCompany?.name}</h3>
							</div>

							<PFButton
								type={'button'}
								className={`w-auto h-auto companyInfo_wrapper__edit-button`}
								prefixIcon={editIcon}
								variant="plain"
								onClick={goToEditCompanyData}>
								Edit Company Profile
							</PFButton>

							<PFContactsBlock
								location={selectedCompany?.location}
								streetAddress={selectedCompany?.streetAddress}
								postalCode={selectedCompany?.postalCode}
								email={selectedCompany?.contactInfo}
								phone={selectedCompany?.phoneNumber}
								isOnMobile={true}
							/>

							<div className={`btnContainer`}>
								<button
									className={`consumerInfoBtn`}
									onClick={() => setIsOpenInviteAdvisorsPopup(true)}>
									<ReactSVG wrapper="div" src={iconInvite} />
									<span>Invite Users</span>
								</button>
								<DropdownButton
									title={<ReactSVG wrapper="div" src={dot} />}
									id="bg-nested-dropdown">
									{userRole === AdvisorsRole.SuperAdmin && (
										<Dropdown.Item
											onClick={() => showConfirmModal()}
											eventKey="1">
											<div className={`dropdownItemText__block`}>
												<ReactSVG
													wrapper="div"
													src={
														selectedCompany.isBlocked
															? iconUnblock
															: iconBlock
													}
												/>
												<span>
													{selectedCompany.isBlocked
														? 'Reactivate Company'
														: 'Suspend Company'}
												</span>
											</div>
										</Dropdown.Item>
									)}
									<Dropdown.Item eventKey="1">
										<CopyToClipboard
											text={`${URL.SERVER_URL}/sign-up?ct=${companyToken}`}
											onCopy={handleCopyRegistrationLinkPress}>
											<div className={`dropdownItemText__block`}>
												<ReactSVG wrapper="div" src={iconDeeplink} />
												<span>Copy Registration Link</span>
											</div>
										</CopyToClipboard>
									</Dropdown.Item>
									{userRole === AdvisorsRole.SuperAdmin && <hr />}
									{userRole === AdvisorsRole.SuperAdmin && (
										<Dropdown.Item onClick={showDeleteConfirm} eventKey="2">
											<div className={`dropdownItemText__delete`}>
												<ReactSVG wrapper="div" src={iconDelete} />
												<span>Delete</span>
											</div>
										</Dropdown.Item>
									)}
								</DropdownButton>
							</div>

							{admins && !!admins.length && (
								<div className={'additionalInfoWrapper'}>
									<div className={'additionalInfoTitle'}>
										<ReactSVG
											wrapper="span"
											className="adminIcon"
											src={iconAdmin}
										/>
										<div className="text">Administrators</div>
									</div>
									{admins.map((item, index) => {
										return (
											<div className={'admin-wrapper'} key={index}>
												<p className="name">
													{getFullName(item.firstName, item.lastName)}
													{item.role ===
														AdvisorsRole.AccountAdministrator && (
														<ReactSVG
															wrapper="span"
															className="generalAdminIcon"
															src={generalAdmin}
														/>
													)}
												</p>
												{item?.email && (
													<p className="mail">
														<a href={`mailto:${item.email}`}>
															{item.email}
														</a>
													</p>
												)}
												{item?.phoneNumber && (
													<p className="phone">{item.phoneNumber}</p>
												)}
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>

					<div className={`companyUsers`}>
						<PFTableBar
							tableData={tableData}
							checkedKeys={checkedKeys}
							modal={'companyProfile'}
							age={'Company Profile'}
							tableBarButtons={tableBarButtons}
						/>
						<PFTable
							tableColumns={tableColumns}
							tableData={tableData}
							isLoading={isLoading}
							modal={'companyProfile'}
							fetchMoreData={fetchMoreData}
							fullTableDataLength={fullTableDataLength}
							checkedKeys={checkedKeys}
							update={update}
							updateCallback={updateCallback}
							updateDataForUseOnModal={updateData}
							urlParams={urlParams}
							parseUrlQuery={parseUrlQuery}
							emptyComponent={
								searchValue ? (
									<SearchEmptyPage {...searchEmptyParam} />
								) : (
									<SuperAdminEmptyPage {...emptyPageParam} />
								)
							}
						/>
						<button
							className={`newCompany_button add-new_button ${
								isEmptyPage ? 'd-none' : ''
							}`}
							onClick={goToAddNewUser}>
							<ReactSVG wrapper="span" className={`icon_plus`} src={plus} />
							<span className={`newCompany_button_text`}>Add New User</span>
						</button>
					</div>

					<ConfirmModal onSubmit={handleBlocked} className={'pf-confirmBlockedModal'} />
					<ConfirmDeleteModal
						className={'pf-confirmDeleteModal'}
						checkedKeys={[selectedCompany?.token]}
						name={selectedCompany?.name}
						onSubmit={onDeleteCompany}
					/>
					<ConfirmDeleteUserModal
						onSubmit={() => fetchDeletedUsers(checkedKeys)}
						checkedKeys={checkedKeys}
					/>
					{companyToken && (
						<InviteAdvisorsPopup
							className={'pf-confirmSendInviteModal'}
							companyToken={companyToken}
							isOpen={isOpenInviteAdvisorsPopup}
							onClose={() => setIsOpenInviteAdvisorsPopup(false)}
						/>
					)}
					<ConfirmIsSuspendUserModal
						onSubmit={() => fetchIsSuspendUsers(true)}
						className={'pf-confirmIsActiveUserModal'}
						checkedKeys={checkedKeys}
					/>
					<ConfirmChangeCompanyModal
						className={'pf-confirmChangeCompany'}
						checkedKeys={checkedKeys}
						onSubmit={fetchChangeUsersCompany}
					/>
				</div>
			)}
			{isDeletedCompany && <DeletedPage {...deletePageProps} />}
		</>
	);
};

export default CompanyProfile;
