import {
	SET_COMPANIES,
	SET_COMPANY_PROFILE_DATA,
	CLEANED_UP_COMPANIES_DATA,
	CLEANED_UP_COMPANY_PROFILE_DATA,
	SET_COMPANY_USERS_DATA,
} from '../types/Types';

const INITIAL_STATE = {
	companyUsers: [],
	companies: [],
	companyProfileData: {},
	fullCount: 0,
};

const companiesReducer = (state = INITIAL_STATE, {payload, type}) => {
	switch (type) {
		case SET_COMPANY_USERS_DATA:
			return {
				...state,
				companyUsers: payload.body,
				fullCount: payload.fullCount,
			};
		case SET_COMPANIES:
			return {
				...state,
				companies: payload.body,
				fullCount: payload.fullCount,
			};

		case CLEANED_UP_COMPANIES_DATA:
			return {
				companyUsers: [],
				companies: [],
				companyProfileData: state.companyProfileData,
				fullCount: 0,
			};

		case SET_COMPANY_PROFILE_DATA:
			return {
				...state,
				companyProfileData: payload,
			};

		case CLEANED_UP_COMPANY_PROFILE_DATA:
			return {
				...state,
				companyProfileData: {},
			};

		default:
			return state;
	}
};

export default companiesReducer;
