const findByToken = (data, token) => {
	return data.find(item => item.token === token);
};

const findByAdvisorToken = (data, token) => {
	return data.find(item => item.advisor.token === token);
};

const findIndexByToken = (data, token) => {
	const res = data.findIndex(item => item.token === token);
	return res === -1 ? null : res;
};

export {findByToken, findIndexByToken};
