const FormInputKeys = {
	name: 'name',
	locationToken: 'locationToken',
	streetAddress: 'streetAddress',
	postalCode: 'postalCode',
	phoneNumber: 'phoneNumber',
	contactInfo: 'contactInfo',
	Administrator: 'Administrator',
	companyLogo: 'companyLogo',
};

export default FormInputKeys;
