import React, {useEffect} from 'react';
import {setCurrentPageTitle} from '../../../store/actions';
import {useDispatch} from 'react-redux';

export default function Meetings() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setCurrentPageTitle('Meetings'));
	}, []);

	return <div>Meetings</div>;
}
