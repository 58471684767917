import React, {FC, ReactNode} from 'react';

import {getClassNamesWithVariant} from 'utils';

import './style.scss';

type PropsTypes = {
	children: ReactNode | string;
	variant?: string;
	className?: string;
};

const PFBadge: FC<PropsTypes> = ({children, variant, className, ...props}) => {
	return (
		<div className={getClassNamesWithVariant('pf-badge', variant || '', className)} {...props}>
			{children}
		</div>
	);
};

export default PFBadge;
