import React, {useState} from 'react';
import {useForm} from 'react-hook-form';

import {opportunitiesService} from 'api/services';
import classNames from 'classnames';
import PFButton from 'shared/components/PF-Button';
import PFPopup from 'shared/components/PF-Popup';
import PFTextarea from 'shared/components/PF-Textarea';
import {Amplitude, generateValidator, ToastDispatcher, validations} from 'utils';

const SetOpportunityNoteModal: React.FC<{
	userToken: string;
	token: string;
	note: string | null;
	visible: boolean;
	className?: string;
	onCancel: () => void;
	updateCallback?: () => void;
}> = ({userToken, token, note, visible, className, onCancel, updateCallback}) => {
	const {register, formState, handleSubmit} = useForm({
		mode: 'onChange',
	});

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [hintValue, setHintValue] = useState(note?.length || 0);
	const descriptionHint = (
		<div className={classNames('counter-hint', {error: hintValue > 92})}>{hintValue}/92</div>
	);

	const submitForm = async valueObj => {
		const body = {
			token,
			note: valueObj.note ? valueObj.note : '',
		};
		const toastsMessage = note ? 'Note updated' : 'Note added';

		try {
			setIsSubmitting(true);
			await opportunitiesService.setOpportunitiesNote(body);

			Amplitude.track('Added_Opportunities_Note', {
				client: userToken,
			});

			ToastDispatcher.success(toastsMessage);
			onCancel();
			updateCallback?.();
			// eslint-disable-next-line no-useless-catch
		} catch (e: any) {
			ToastDispatcher.error('Failed to delete account. Please try again');
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<PFPopup
			isShow={visible}
			handleClose={onCancel}
			isShowFooter={false}
			submitBtnText="Save"
			primaryVariant="primary"
			isCloseButton
			isShowCancelBtn={false}
			title={`${note ? 'Edit' : 'Add'} Note`}
			onSubmit={() => false}
			className={{root: className}}>
			<form onSubmit={handleSubmit(submitForm)} className="opportunitiesNotePopup__form">
				<div className="col-12 mb-4 note">
					<PFTextarea
						{...register('note', {
							validate: generateValidator(
								true,
								validations.textFields.opportunitiesNote,
							),
							onChange: evt => {
								setHintValue(evt.target.value.length);
							},
						})}
						// @ts-ignore
						showAsterisk={false}
						label={false}
						placeholder="Enter your note"
						defaultValue={note}
						hint={descriptionHint}
						error={formState.errors?.note?.message && formState.errors?.note?.message}
					/>
				</div>
				<div className="d-flex justify-content-center">
					<div className="d-flex w-100 m-auto pf-modal__footer-inner">
						<PFButton
							disabled={!formState.isValid || isSubmitting}
							onClick={() => false}
							className="pf-profileEdit__form-button pf-profileEdit__form-button--submit pf-edit-email-submit"
							type="submit">
							Save
						</PFButton>
						<PFButton onClick={onCancel} variant="secondary" type="button">
							Cancel
						</PFButton>
					</div>
				</div>
			</form>
		</PFPopup>
	);
};

export default SetOpportunityNoteModal;
