import React from 'react';
import classNames from 'classnames';
import './style.scss';

const PFTextarea = React.forwardRef(
	({className, type, label, labelSufix, showAsterisk, icon, error, hint, ...props}, ref) => {
		return (
			<div
				className={classNames(
					'pf-textarea',
					{
						'pf-textarea--error': error,
					},
					className,
				)}>
				<div className="pf-textarea__label-wrapper">
					<label className="pf-textarea__label">
						{showAsterisk && <span className="pf-textarea__label-asterisk">*</span>}
						{label}
					</label>
					{labelSufix}
				</div>
				<div className="pf-textarea__field-wrapper">
					<textarea ref={ref} className="pf-textarea__field" type={type} {...props} />
					{icon && <span className="pf-textarea__field-icon">{icon}</span>}
				</div>
				<div
					className={classNames('pf-textarea__hint', {
						'pf-textarea__hint--error': error,
					})}>
					{error || hint}
				</div>
			</div>
		);
	},
);

PFTextarea.defaultProps = {
	type: 'text',
};
export default PFTextarea;
