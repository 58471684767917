import {greenOpportunity, greyOpportunity} from 'assets';
import moment from 'moment';
import {OpportunityTypes} from 'types/enums/opportunities';

export const getOptionsByEnum = (Enum): {value: number; label: string}[] => {
	const keys: number[] = Object.keys(Enum)
		.map(key => Enum[key])
		.filter(item => typeof item === 'number');
	return keys.map(item => {
		return {value: item, label: Enum[item]};
	});
};

export const parseDate = (
	date: string | null | undefined,
): {day: number; month: number; year: number} | null => {
	if (date) {
		const momentDate = moment.utc(date).local(); // .format('MMM DD, YY')
		return {day: momentDate.date() + 1, month: momentDate.month(), year: momentDate.year()};
	}
	return null;
};

export const parseDate1 = (
	date: string | null | undefined,
): {day: number; month: number; year: number} | null => {
	if (date) {
		const year = parseInt(date.substring(0, 4), 10);
		const month = parseInt(date.substring(5, 7), 10) - 1;
		const day = parseInt(date.substring(8, 10), 10);

		return {day, month, year};
	}
	return null;
};

export const getUTSDate = (dateValues: {year?: number; month?: number; date?: number}) => {
	if (
		dateValues.year === undefined ||
		dateValues.month === undefined ||
		dateValues.date === undefined
	) {
		return null;
	}

	return moment().set(dateValues).format('YYYY-MM-DD');
};

export const getOpportunityIcon = (type: OpportunityTypes): string => {
	return type === 3 ? greyOpportunity : greenOpportunity;
};

export const findPointOnCircle = (
	angle: number,
	radius: number,
	pointOffset: number = 0,
): {x: number; y: number} => {
	const radian = (angle * Math.PI) / 180;
	const correctedRadius = radius - pointOffset;
	const x = Math.abs(correctedRadius * Math.cos(radian));
	const y = Math.abs(correctedRadius * Math.sin(radian));
	return {x, y};
};
