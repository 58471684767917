import React from 'react';

import {BeneficiaryType} from 'types';

import styles from './BeneficiariesInfoItem.module.scss';

type Props = {
	beneficiaries: BeneficiaryType[] | null;
};

const BeneficiariesInfoItem: React.FC<Props> = ({beneficiaries}) => {
	const renderBeneficiaries = () => {
		if (beneficiaries === null) {
			return 'None';
		}

		if (beneficiaries.length === 0) {
			return 'n/a';
		}

		return beneficiaries.map((beneficiary, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<div key={`${index}`}>
				<div>{beneficiary.fullName || 'n/a'}</div>
				<div className={styles.proportion}>
					{beneficiary.proportion !== null ? `${beneficiary.proportion}%` : 'n/a'}
				</div>
			</div>
		));
	};

	return (
		<div className={styles.container}>
			<div className={styles.text}>Beneficiaries</div>
			<div className={`${styles.text} ${styles.beneficiary}`}>{renderBeneficiaries()}</div>
		</div>
	);
};

export default BeneficiariesInfoItem;
