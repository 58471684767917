import React, {FC, useEffect, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useForm} from 'react-hook-form';
import {ReactSVG} from 'react-svg';

import {emailService} from 'api/services';
import {copyProfileLink} from 'assets';
import classNames from 'classnames';
import {SPECIALIZATION_OPTIONS_TYPES} from 'const';
import PFButton from 'shared/components/PF-Button';
import PFInput from 'shared/components/PF-Input';
import PFLoader from 'shared/components/PF-Loader';
import PFPopup from 'shared/components/PF-Popup';
import {useAdvisorInfo, useHandleCopyDeepLink, useNoAuthorizedAdvisorInfo} from 'shared/hooks';
import {useAppDispatch, useAppSelector} from 'store';
import {setCloseDeepLinkPopup, setDeepLinkSpecialization, setDeepShortLink} from 'store/actions';
import {
	selectCopyDeepLinkPopupToken,
	selectDeepLinkSpecCheckedKeys,
	selectDeepShortLink,
	selectIsAuth,
	selectIsMyLinkCopyDeepLinkPopup,
	selectIsOpenCopyDeepLinkPopup,
} from 'store/selectors';
import {AdvisorType} from 'types';
import {Amplitude, generateValidator, ToastDispatcher, validations} from 'utils';

import {createShortLink, DynamicLinkService} from '../../api/dynamicLinkService';

import SpecializationItem from './SpecItem';

import './style.scss';

type PropsTypes = {
	className?: string;
};

const CopyDeepLinkPopup: FC<PropsTypes> = ({className}) => {
	const dispatch = useAppDispatch();
	const isOpen: boolean = useAppSelector(selectIsOpenCopyDeepLinkPopup);
	const isMyLink: boolean = useAppSelector(selectIsMyLinkCopyDeepLinkPopup);
	const advisorToken = useAppSelector(selectCopyDeepLinkPopupToken);
	const checkedSpec = useAppSelector(selectDeepLinkSpecCheckedKeys);
	const shortLink = useAppSelector(selectDeepShortLink);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadAdvisor, setIsLoadAdvisor] = useState(false);
	const handleCopyDeepLink = useHandleCopyDeepLink();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [profileData, setProfileData] = useState<AdvisorType | null>(null);
	const fetchNoAuthorizedAdvisorInfo = useNoAuthorizedAdvisorInfo();
	const auth = useAppSelector(selectIsAuth);
	const {formState, handleSubmit, watch, trigger, setValue, register} = useForm({
		mode: 'onChange',
	});

	const getAdvisorInfo = async () => {
		try {
			const data = await fetchNoAuthorizedAdvisorInfo(advisorToken);
			setIsLoading(true);
			await dispatch(setDeepLinkSpecialization(data.advisorSpecializationTypes));
			setProfileData(data);
			// eslint-disable-next-line no-useless-catch
		} catch (e) {
			throw e;
		} finally {
			setIsLoadAdvisor(false);
		}
	};

	const copyDeepLink = async specializationTypes => {
		const longLink = new DynamicLinkService()
			.addIosBundleId()
			.addAndroidPackageName()
			.addAndroidFallbackUrl(
				`https://app.pocketfinance.com/eco-advisor-profile#${profileData?.token}`,
			)
			.addIosFallbackUrl(
				`https://app.pocketfinance.com/eco-advisor-profile#${profileData?.token}`,
			)
			.addOtherFallbackUrl(
				`https://app.pocketfinance.com/eco-advisor-profile#${profileData?.token}`,
			)
			.addDeepLink('add', profileData?.token, specializationTypes)
			.getLongLink();
		try {
			const sLink = await createShortLink(longLink);
			dispatch(setDeepShortLink(sLink));
			// eslint-disable-next-line no-useless-catch
		} catch (e) {
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const handleClose = () => {
		dispatch(setCloseDeepLinkPopup());
		dispatch(setDeepShortLink(''));
		setValue('email', '');
		setProfileData(null);
	};

	const handleCopyDeepLinkPress = (text: any, result: any) => {
		handleCopyDeepLink(text, result);
		Amplitude.track('Shared_Profile_Link', {way_of_sharing: 'copy link', isMyLink});
	};

	const submitForm = async valueObj => {
		const requestBody = {
			email: valueObj.email,
			deepLink: shortLink,
			advisorTokenWhoseLink: advisorToken,
			types: checkedSpec.length
				? checkedSpec
				: SPECIALIZATION_OPTIONS_TYPES.map(item => item.type),
		};
		try {
			setIsSubmitting(true);
			await emailService.sendDeepLink(requestBody);
			handleClose();

			Amplitude.track('Shared_Profile_Link', {way_of_sharing: 'send email', isMyLink});
			ToastDispatcher.success('Email sent');
		} catch (e) {
			ToastDispatcher.error('Email has not been sent. Please, try again.');
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === 'email') {
				await trigger('email');
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	useEffect(() => {
		if (profileData) {
			if (checkedSpec.length) {
				dispatch(setDeepShortLink(''));
				const specializationTypes = checkedSpec.join(',');

				if (specializationTypes) {
					copyDeepLink(specializationTypes);
				} else {
					setIsLoading(false);
				}
			} else {
				dispatch(setDeepShortLink(''));
				const specializationTypes = SPECIALIZATION_OPTIONS_TYPES.map(
					item => item.type,
				).join(',');
				copyDeepLink(specializationTypes);
			}
		}
	}, [checkedSpec, profileData]);

	useEffect(() => {
		if (advisorToken) {
			setIsLoadAdvisor(true);
			getAdvisorInfo();
		}
	}, [advisorToken]);

	return (
		<PFPopup
			isShow={isOpen}
			handleClose={handleClose}
			isShowFooter={false}
			submitBtnText="Save"
			primaryVariant="primary"
			isCloseButton
			isShowCancelBtn={false}
			onSubmit={() => false}
			title="Share Advisor Profile Link"
			subtitle="Check which roles you want to be in, and share your profile link via email or copy the link"
			className={{
				root: `deepLinkPopup ${className || ''}`,
				paper: 'deepLinkPopup__paper',
				contentBody: 'deepLinkPopup__contentBody',
			}}>
			<div className="deepLinkPopup__contentWrapper">
				<div className="main">
					<form onSubmit={handleSubmit(submitForm)} className="deepLink-form">
						<div className={classNames('specializationsBlock', {mb0: !auth})}>
							{!isLoadAdvisor ? (
								<div className="specializationsBlock__content">
									<SpecializationItem
										type={0}
										name="All"
										checked={!checkedSpec.length}
										disabled={isLoading}
										className="specializationItem-all"
									/>
									{SPECIALIZATION_OPTIONS_TYPES.map(item => (
										<SpecializationItem
											key={`FilterItem-${item.type}`}
											type={item.type}
											name={item.name}
											checked={checkedSpec.includes(item.type)}
											disabled={isLoading}
										/>
									))}
								</div>
							) : (
								<PFLoader />
							)}
						</div>
						{auth && (
							<div className="col-12 emailField">
								<PFInput
									{...register('email', {
										validate: generateValidator(true, validations.email),
									})}
									// @ts-ignore
									showAsterisk={false}
									label="Email"
									placeholder="Enter recipient’s email"
									type="email"
									defaultValue=""
									error={
										formState.errors?.email?.message &&
										formState.errors?.email?.message
									}
								/>
							</div>
						)}
						{auth && (
							<PFButton
								disabled={!formState.isValid || isSubmitting || !shortLink}
								onClick={() => false}
								className="sendButton"
								type="submit">
								Send
							</PFButton>
						)}
					</form>
				</div>
				<div className="footer">
					<CopyToClipboard text={shortLink} onCopy={handleCopyDeepLinkPress}>
						<button
							onClick={() => {}}
							disabled={!shortLink}
							type="button"
							className="copyProfileButton">
							<ReactSVG className="copyProfileButton__icon" src={copyProfileLink} />
							<div className="copyProfileButton__text">Copy Profile Link</div>
						</button>
					</CopyToClipboard>
				</div>
			</div>
		</PFPopup>
	);
};

export default CopyDeepLinkPopup;
