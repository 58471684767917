import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ReferBusinessPopupState {
	isOpen: boolean;
	userFlowName: 'Current' | 'Menu' | 'Network';
	clientsCheckedKeys: string[];
	advisorCheckedKeys: string[];
}
type SetCheckedKeyActionType = string | 0;

type RemoveCheckedKeyActionType = string;

type SetUserFlowNameActionType = 'Current' | 'Menu' | 'Network';

const initialState: ReferBusinessPopupState = {
	isOpen: false,
	userFlowName: 'Menu',
	clientsCheckedKeys: [],
	advisorCheckedKeys: [],
};

export const referBusinessPopupSlice = createSlice({
	name: 'referBusinessPopup',
	initialState,
	reducers: {
		setIsOpenReferPopup: (state, action: PayloadAction<SetUserFlowNameActionType>) => {
			state.userFlowName = action.payload;
			state.isOpen = true;
		},
		setCloseReferPopup: state => {
			state.isOpen = false;
		},
		setReferClientsCheckedKey: (state, action: PayloadAction<SetCheckedKeyActionType>) => {
			if (action.payload === 0) {
				state.clientsCheckedKeys = [];
			} else {
				state.clientsCheckedKeys = [...state.clientsCheckedKeys, action.payload];
			}
		},
		removeReferClientsCheckedKey: (
			state,
			action: PayloadAction<RemoveCheckedKeyActionType>,
		) => {
			state.clientsCheckedKeys = state.clientsCheckedKeys.filter(
				type => type !== action.payload,
			);
		},
		setReferAdvisorCheckedKey: (state, action: PayloadAction<SetCheckedKeyActionType>) => {
			if (action.payload === 0) {
				state.advisorCheckedKeys = [];
			} else {
				state.advisorCheckedKeys = [...state.advisorCheckedKeys, action.payload];
			}
		},
		removeReferAdvisorCheckedKey: (
			state,
			action: PayloadAction<RemoveCheckedKeyActionType>,
		) => {
			state.advisorCheckedKeys = state.advisorCheckedKeys.filter(
				type => type !== action.payload,
			);
		},
		resetReferBusinessPopup: state => {
			state.isOpen = false;
			state.userFlowName = 'Menu';
			state.clientsCheckedKeys = [];
			state.advisorCheckedKeys = [];
		},
	},
});

export default referBusinessPopupSlice.reducer;
