import React, {CSSProperties, FC, ReactComponentElement, ReactNode} from 'react';
import {ReactSVG} from 'react-svg';

import iconInf from '../../../assets/invitation/icon-info.svg';

import './style.scss';

type PropsType = {
	children: ReactNode;
	className?: string | undefined;
	imageComponent?: ReactComponentElement<any> | undefined;
	style?: CSSProperties | undefined;
};

const PFNote: FC<PropsType> = ({children, className = '', imageComponent, style}) => {
	const defaultNoteIcon = <ReactSVG src={iconInf} className="infoImg" />;
	return (
		<div className={`pfNote ${className}`} style={style}>
			<div className="pfNote__img">{imageComponent || defaultNoteIcon}</div>
			<div className="pfNote__message">{children}</div>
		</div>
	);
};

export default PFNote;
