import React, {FC, ReactNode} from 'react';

import NothingFoundAnimation from '../../../../../shared/components/PF-NothingFoundAnimation';

import style from './style.module.scss';

type EmptyScreenPropsTypes = {
	description: string | ReactNode;
};

const EmptyScreen: FC<EmptyScreenPropsTypes> = ({description}) => {
	return (
		<div className={style.emptyPage__container}>
			<div className={style.emptyPage__main}>
				<NothingFoundAnimation width={120} height={120} />
				<h2>{description}</h2>
			</div>
		</div>
	);
};

export default EmptyScreen;
