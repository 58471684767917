import React from 'react';

import {opportunitiesService} from 'api/services';
import {AxiosResponse} from 'axios';
import {OpportunityType, SetOpportunitiesFetchType} from 'types';
import {ToastDispatcher} from 'utils';

export const useOpportunitiesByToken = () => {
	return async (token: string): Promise<OpportunityType[]> => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response: AxiosResponse<OpportunityType[]> =
				await opportunitiesService.getOpportunitiesByToken(token);
			return response.data;
		} catch (e: any) {
			throw e;
		}
	};
};

export const useSearchOpportunities = () => {
	return async (params): Promise<OpportunityType[]> => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response: AxiosResponse<OpportunityType[]> =
				await opportunitiesService.searchOpportunities(params);
			return response.data;
		} catch (e: any) {
			throw e;
		}
	};
};

export const useSetOpportunities = () => {
	return async (body: SetOpportunitiesFetchType, toastsMessage: string) => {
		// eslint-disable-next-line no-useless-catch
		try {
			await opportunitiesService.setOpportunities(body);
			ToastDispatcher.success(toastsMessage);
		} catch (e) {
			throw e;
		}
	};
};
