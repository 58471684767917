export function formatNumberThousandsWithDelimiter(val: string | number, fraction = 2): string {
	let value = val;

	if (typeof value === 'string') {
		value = Number(value);
	}

	if (typeof value === 'number') {
		const parts = value.toFixed(fraction).split('.');
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

		return parts.join('.');
	}

	return (0).toFixed(fraction);
}
