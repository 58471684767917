import {
	DisabilityTermEnum,
	InsuranceDurationEnum,
	InsuranceFrequencyOfPaymentEnum,
	InsurancePayerEnum,
	InsurancePolicyPayerEnum,
	InsuranceRiderEnum,
} from 'types/enums/insurance';
import {getDays, getYears} from 'utils';

enum FormInputKeys {
	typeByDuration = 'typeByDuration',
	isCashInThePolicy = 'isCashInThePolicy',
	amountCashInThePolicy = 'amountCashInThePolicy', // * Cash worth
	isLoanAgainstPolicy = 'isLoanAgainstPolicy',
	amountLoanAgainstPolicy = 'amountLoanAgainstPolicy', // * Outstanding loan value approx.
	policyRenewalDate = 'policyRenewalDate', //  '2022-07-07T14:14:29.346Z'
	month = 'month',
	day = 'day',
	year = 'year',
	amountOfCoverage = 'amountOfCoverage', // * What is the amount of the coverage?
	insuranceCompany = 'insuranceCompany',
	policyNumber = 'policyNumber', // * Policy Number
	insured = 'insured', // * Who is the policy for?
	insurancePayer = 'insurancePayer',
	policyPayer = 'policyPayer',
	customPolicyPayer = 'customPolicyPayer', // * Who? Person name
	paymentFrequency = 'paymentFrequency',
	amountOfPayment = 'amountOfPayment', // * Payment amount
	riders = 'riders',
	wasOverwrittenByUser = 'wasOverwrittenByUser',
	wasUpdatedByAdvisor = 'wasUpdatedByAdvisor',
	customInsuranceCompanyName = 'customInsuranceCompanyName',
	isExistsBeneficiary = 'isExistsBeneficiary',
	beneficiaries = 'beneficiaries',
	beneficiaryFullName = 'beneficiaryFullName',
	beneficiaryProportion = 'beneficiaryProportion',
	trusteeName = 'trusteeName',
	purposeOfPlan = 'purposeOfPlan',
}

export default FormInputKeys;

export const MONTHS = [
	{
		value: 0,
		label: 'January',
	},
	{
		value: 1,
		label: 'February',
	},
	{
		value: 2,
		label: 'March',
	},
	{
		value: 3,
		label: 'April',
	},
	{
		value: 4,
		label: 'May',
	},
	{
		value: 5,
		label: 'June',
	},
	{
		value: 6,
		label: 'July',
	},
	{
		value: 7,
		label: 'August',
	},
	{
		value: 8,
		label: 'September',
	},
	{
		value: 9,
		label: 'October',
	},
	{
		value: 10,
		label: 'November',
	},
	{
		value: 11,
		label: 'December',
	},
];

export const YEARS = getYears(10);
export const DAYS = getDays(31);

export const PAYMENT_FREQUENCY_OPTIONS = [
	{
		value: String(InsuranceFrequencyOfPaymentEnum.Monthly),
		label: 'Monthly',
	},
	{
		value: String(InsuranceFrequencyOfPaymentEnum.Quarterly),
		label: 'Quarterly',
	},
	{
		value: String(InsuranceFrequencyOfPaymentEnum.Yearly),
		label: 'Yearly',
	},
	{
		value: String(InsuranceFrequencyOfPaymentEnum.NoLongerPayingIntoThisPolicy),
		label: 'No longer paying into this policy',
	},
	{
		value: String(InsuranceFrequencyOfPaymentEnum.ThisIsGroupCoverage),
		label: 'This is group coverage (comes off my paycheques)',
	},
	{
		value: String(InsuranceFrequencyOfPaymentEnum.PaidInFull),
		label: 'Paid in full',
	},
	{
		value: String(InsuranceFrequencyOfPaymentEnum.Lapsed),
		label: 'Lapsed',
	},
	{
		value: String(InsuranceFrequencyOfPaymentEnum.Other),
		label: 'Other',
	},
];

export const POLICY_PAYER_OPTIONS = [
	{
		value: String(InsurancePolicyPayerEnum.TheInsured),
		label: 'The insured',
	},
	{
		value: String(InsurancePolicyPayerEnum.SomeoneElse),
		label: 'Someone else',
	},
];

export const DISABILITY_TERM_OPTIONS = [
	{
		value: String(DisabilityTermEnum.ShortTerm),
		label: 'Short-term',
	},
	{value: String(DisabilityTermEnum.LongTerm), label: 'Long-term'},
];

export const RIDER_OPTIONS = [
	{
		value: String(InsuranceRiderEnum.ChildRider),
		label: 'Child rider',
	},
	{
		value: String(InsuranceRiderEnum.DisabilityWaiverRider),
		label: 'Disability waiver rider',
	},
	{
		value: String(InsuranceRiderEnum.ReturnOfPremium),
		label: 'Return of premium',
	},
	{
		value: String(InsuranceRiderEnum.ReturnOfPremiumOnDeath),
		label: 'Return of premium on death',
	},
	{
		value: String(InsuranceRiderEnum.AccidentalDeathAndDismemberment),
		label: 'Accidental death and dismemberment',
	},
	{
		value: String(InsuranceRiderEnum.CriticalIllness),
		label: 'Critical illness',
	},
];

export const TYPE_BY_DURATION_OPTIONS = [
	{
		value: String(InsuranceDurationEnum.Term),
		label: 'Term',
	},
	{
		value: String(InsuranceDurationEnum.Permanent),
		label: 'Permanent',
	},
];

export const INSURANCE_PAYER_OPTIONS = [
	{
		value: String(InsurancePayerEnum.Employer),
		label: 'Employer',
	},
	{
		value: String(InsurancePayerEnum.Independent),
		label: 'Independently',
	},
];
