import * as React from 'react';
import {FC, ReactNode} from 'react';
import {Accordion} from 'react-bootstrap';

import './style.scss';

type PropsTypes = {
	header?: ReactNode | string;
	children?: ReactNode;
};

const PFAccordion: FC<PropsTypes> = ({header, children}) => {
	return (
		<Accordion bsPrefix="pf-accordion__wrapper" defaultActiveKey="0" flush>
			<Accordion.Item eventKey="0" bsPrefix="pf-accordion__item">
				{header && <Accordion.Header>{header}</Accordion.Header>}
				<Accordion.Body>{children}</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

export default PFAccordion;
