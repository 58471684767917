import {SET_USER, UNSET_USER} from '../types/Types';

const INITIAL_STATE = {
	auth: null,
};

const GeneralReducer = (state = INITIAL_STATE, {payload, type}) => {
	switch (type) {
		case SET_USER:
			return {...state, auth: payload};
		case UNSET_USER:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default GeneralReducer;
