import React from 'react';
import classNames from 'classnames';
import './style.scss';

const PFInput = React.forwardRef(
	(
		{
			className,
			type,
			label,
			labelSufix,
			showAsterisk,
			icon,
			onIconClick,
			error,
			hint,
			...props
		},
		ref,
	) => {
		const hasErrorMessage = !!error && typeof error === 'string';
		const hasHintMessage = !!hint;

		return (
			<div
				className={classNames(
					'pf-input',
					{
						'pf-input--error': error,
					},
					className,
				)}>
				<div className="pf-input__label-wrapper">
					<label className="pf-input__label">
						{showAsterisk && <span className="pf-input__label-asterisk">*</span>}
						{label}
					</label>
					{labelSufix}
				</div>
				<div className="pf-input__field-wrapper">
					<input ref={ref} className="pf-input__field" type={type} {...props} />
					{icon && (
						<span onClick={onIconClick} className="pf-input__field-icon">
							{icon}
						</span>
					)}
				</div>
				{(hasErrorMessage || hasHintMessage) && (
					<div
						className={classNames('pf-input__hint', {
							'pf-input__hint--error': error,
						})}>
						{error || hint}
					</div>
				)}
			</div>
		);
	},
);

PFInput.defaultProps = {
	type: 'text',
};
export default PFInput;
