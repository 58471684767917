import React, {useEffect, useMemo} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {COMPANY_OPTIONS} from 'const';
import {useBooleanState} from 'shared';
import PFButton from 'shared/components/PF-Button';
import PFInput from 'shared/components/PF-Input';
import PFRadioGroup from 'shared/components/PF-RadioGroup';
import PFSelect from 'shared/components/PF-Select';
import {DisabilityInsuranceType} from 'types';
import {DisabilityTermEnum, InsurancePayerEnum} from 'types/enums';
import {convertToNumberOrNull, getAmountCommasValue, getAmountInsuranceValue} from 'utils';

import {DISABILITY_TERM_OPTIONS, INSURANCE_PAYER_OPTIONS} from '../constants';

import {FormInputKeys, fullWidthInputClass, halfWidthInputClass} from './constants';

import styles from './styles.module.scss';

type Props = {
	insurance: DisabilityInsuranceType;
	onSave: (insurance: DisabilityInsuranceType) => Promise<void>;
	onCancel: () => void;
};

const DisabilityInsuranceForm: React.FC<Props> = ({insurance, onSave, onCancel}) => {
	const companies = useMemo(() => {
		const options = [...COMPANY_OPTIONS].filter(option => !option.isNew);

		if (insurance.customInsuranceCompanyName) {
			options.push({
				value: insurance.customInsuranceCompanyName,
				label: insurance.customInsuranceCompanyName,
				isNew: true,
			});
		}

		return options;
	}, [insurance.customInsuranceCompanyName]);

	const [isSubmitting, toggleIsSubmitting] = useBooleanState(false);

	const {register, setValue, handleSubmit, watch, trigger, formState, getValues, control} =
		useForm({
			mode: 'onChange',
		});

	const onSubmit: SubmitHandler<{}> = async formObject => {
		const newInsurance: DisabilityInsuranceType = {
			token: insurance.token,
			userToken: insurance.userToken,
			amountOfCoverage: convertToNumberOrNull(
				formObject[FormInputKeys.amountOfCoverage] &&
					formObject[FormInputKeys.amountOfCoverage].replace(/,/g, ''),
			),
			insuranceCompany:
				typeof formObject[FormInputKeys.insuranceCompany] === 'number'
					? formObject[FormInputKeys.insuranceCompany]
					: null,
			insured: formObject[FormInputKeys.insured],
			insurancePayer: Number(formObject[FormInputKeys.insurancePayer]),
			disabilityTerm: Number(formObject[FormInputKeys.disabilityTerm]),
			customInsuranceCompanyName:
				typeof formObject[FormInputKeys.insuranceCompany] === 'string'
					? formObject[FormInputKeys.insuranceCompany]
					: null,
			purposeOfPlan: formObject[FormInputKeys.purposeOfPlan] || null,
		};

		try {
			toggleIsSubmitting();

			await onSave(newInsurance);

			toggleIsSubmitting();
			onCancel();
		} catch (err: any) {
			toggleIsSubmitting();
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === FormInputKeys.insuranceCompany) {
				await trigger(FormInputKeys.insuranceCompany);
			}
			if (name === FormInputKeys.insured) {
				await trigger(FormInputKeys.insured);
			}
			if (name === FormInputKeys.insurancePayer) {
				await trigger(FormInputKeys.insurancePayer);
			}
			if (name === FormInputKeys.disabilityTerm) {
				await trigger(FormInputKeys.disabilityTerm);
			}
		});

		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	useEffect(() => {
		setValue(
			FormInputKeys.amountOfCoverage,
			insurance.amountOfCoverage && getAmountCommasValue(insurance.amountOfCoverage),
		);
		setValue(FormInputKeys.purposeOfPlan, insurance.purposeOfPlan);
		setValue(
			FormInputKeys.insuranceCompany,
			insurance.insuranceCompany || insurance.customInsuranceCompanyName,
		);
		setValue(FormInputKeys.insured, insurance?.insured);
		setValue(
			FormInputKeys.insurancePayer,
			(typeof insurance?.insurancePayer === 'number' && String(insurance?.insurancePayer)) ||
				String(InsurancePayerEnum.Employer),
		);
		setValue(
			FormInputKeys.disabilityTerm,
			(typeof insurance?.disabilityTerm === 'number' && String(insurance?.disabilityTerm)) ||
				String(DisabilityTermEnum.ShortTerm),
		);
	}, [insurance]);

	return (
		<form className={styles.form}>
			<h4 className="mt-0">Is this through your employer or independently purchased?</h4>
			<div className={`${styles.insurancePayer}`}>
				<PFRadioGroup
					control={control}
					name={FormInputKeys.insurancePayer}
					variant="horizontal"
					defaultValue={
						getValues(FormInputKeys.insurancePayer) ||
						(insurance &&
							typeof insurance?.insurancePayer === 'number' &&
							String(insurance?.insurancePayer))
					}
					options={INSURANCE_PAYER_OPTIONS}
				/>
			</div>
			<h4 className="mt-0">Select disability term</h4>
			<div className={`${styles.disabilityTerm}`}>
				<PFRadioGroup
					control={control}
					name={FormInputKeys.disabilityTerm}
					variant="horizontal"
					defaultValue={
						getValues(FormInputKeys.disabilityTerm) ||
						(insurance &&
							typeof insurance?.disabilityTerm === 'number' &&
							String(insurance?.disabilityTerm))
					}
					options={DISABILITY_TERM_OPTIONS}
				/>
			</div>

			<PFInput
				{...register(FormInputKeys.amountOfCoverage, {
					required: false,
					onChange: e => {
						// experimental
						const newValue = getAmountInsuranceValue(e.target.value);
						e.target.value = newValue;
						setValue(FormInputKeys.amountOfCoverage, newValue);
					},
				})}
				// @ts-ignore
				showAsterisk={false}
				label="What is the amount of the coverage?"
				placeholder="Enter amount, $"
				defaultValue={insurance.amountOfCoverage}
				className={halfWidthInputClass}
			/>
			<div className={fullWidthInputClass}>
				<PFSelect
					{...register(FormInputKeys.insuranceCompany, {
						required: false,
					})}
					// @ts-ignore
					showAsterisk={false}
					defaultValue={
						companies.find(item => item.value === insurance.insuranceCompany) ||
						companies.find(
							item => item.value === insurance.customInsuranceCompanyName,
						) ||
						''
					}
					label="Insurance company"
					placeholder="Select company from the list or type your own option"
					disabled={false}
					isMulti={false}
					closeMenuOnSelect
					isAsync={false}
					isClearable
					onChange={value => {
						if (value) {
							if (typeof value.value === 'number') {
								setValue(FormInputKeys.insuranceCompany, value.value);
							}
							if (typeof value.value === 'string') {
								setValue(FormInputKeys.insuranceCompany, value.value);
							}
						} else {
							setValue(FormInputKeys.insuranceCompany, null);
						}
					}}
					getOptionValue={option => option.value}
					options={companies}
					formatOptionLabel={option => option.label}
					defaultOptions
				/>
			</div>
			<div className={`${fullWidthInputClass} insured`}>
				<PFInput
					{...register(FormInputKeys.insured, {
						required: false,
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Who is the policy for?"
					placeholder="Who is the insured?"
					defaultValue={
						getValues(FormInputKeys.insured) || (insurance && insurance?.insured) || ''
					}
					error={
						formState.errors[FormInputKeys.insured]?.message &&
						formState.errors[FormInputKeys.insured]?.message
					}
				/>
			</div>
			<PFInput
				{...register(FormInputKeys.purposeOfPlan, {
					required: false,
				})}
				// @ts-ignore
				label="What is the purpose of this plan?"
				placeholder="Enter the purpose"
				defaultValue={insurance.purposeOfPlan}
				className={fullWidthInputClass}
			/>
			<div className={styles.buttons}>
				<PFButton
					onClick={onCancel}
					className={styles.cancel}
					variant="secondary"
					type="button">
					Cancel
				</PFButton>
				<PFButton
					disabled={!formState.isValid || isSubmitting}
					className={styles.submit}
					type="button"
					onClick={handleSubmit(onSubmit)}>
					Save
				</PFButton>
			</div>
		</form>
	);
};

export default DisabilityInsuranceForm;
