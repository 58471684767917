import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {setCurrentPageTitle} from '../../../../store/actions';
import {Table} from 'rsuite';
import {useBlockedPopup, useCompanies, useDeletedPopup} from '../../../../shared/hooks/companies';
import {PFSortArrow, PFTable} from '../../../../shared/components';
import PFButton from '../../../../shared/components/PF-Button';
import {ReactSVG} from 'react-svg';
import iconBlock from '../../../../assets/icon-block.svg';
import iconUnblock from '../../../../assets/icon-unblock.svg';
import iconDelete from '../../../../assets/icon-delete.svg';
import plus from '../../../../assets/icon-plus.svg';
import PORTAL_PATHS from '../../../../const/url-paths/portal';
import {useNavigate} from 'react-router-dom';
import * as companiesService from '../../../../api/services/companies';
import {
	cleanedUpCompaniesData,
	setCheckedData,
	cleanedUpTableData,
} from '../../../../store/actions';
import {
	mapQueryParams,
	parseUrlQuery,
	ToastDispatcher,
	useErrorHelper,
	findByToken,
} from '../../../../utils';
import './style.scss';
import {SnackActionButton, SuperAdminEmptyPage} from '../../../../components';
import SearchEmptyPage from '../../../../components/EmptyPages/EmptySearch';
import PFTableBar from '../../../../shared/components/PF-TableBar';
import RestoreButton from '../../../../components/RestoreButton';
import {PFAvatar} from '../../../../shared/components';
import {DEFAULT_COMPANY_AVATAR} from '../../../../const';
import urlPaths from '../../../../api/url-paths';

const Companies = props => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [update, setUpdate] = useState(true);
	const [updateParam, setUpdateParam] = useState('');
	const {Column, HeaderCell, Cell} = Table;
	const tableData = props.companies.companies;
	const fullTableDataLength = props.companies.fullCount;
	const checkedKeys = props.table.checkedData;
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const goToAddNewCompany = () => navigate(PORTAL_PATHS.OTHER_PATHS.ADD_NEW_COMPANY);
	const urlParams = parseUrlQuery(document.location);
	const [isEmptyPage, setIsEmptyPage] = useState(false);

	const params = {
		...mapQueryParams(urlParams),
	};
	const restore = async token => {
		try {
			await companiesService.restoreCompanies([token]);
			const foundDeletedCompany = findByToken(tableData, token);
			if (foundDeletedCompany) foundDeletedCompany.isDeleted = false;
			updateData();
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const fetchCompaniesData = useCompanies(params);
	const [columns] = useState([
		{
			name: 'company',
			title: 'Company',
			flexGrow: 2,
			minWidth: 230,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'contact info',
			title: 'Contact info',
			flexGrow: 1,
			minWidth: 180,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'administrator',
			title: 'Administrator',
			flexGrow: 1,
			minWidth: 160,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'location',
			title: 'Location',
			flexGrow: 1,
			minWidth: 160,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'number of Users',
			title: 'Number of Users',
			flexGrow: 1,
			minWidth: 200,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
	]);

	const tableColumns = columns.map((item, index) => {
		const switchParam = param => {
			switch (param) {
				case 'company':
					return (
						<Cell dataKey="Name">
							{rowData => (
								<div className={'name-group'}>
									<PFAvatar
										src={
											rowData?.image &&
											`${urlPaths.BASE_IMAGES_URL}${rowData?.image}`
										}
										size={20}
										defaultAvatar={DEFAULT_COMPANY_AVATAR}
										variant={'rounded-square'}
										className="companyLogo"
									/>
									<div className={`name`}>
										<p className={'pf-tableText'}>{rowData?.name}</p>
										<RestoreButton
											isDeleted={rowData.isDeleted}
											onClick={restore}
											token={rowData?.token}
										/>
									</div>
								</div>
							)}
						</Cell>
					);
				case 'contact info':
					return (
						<Cell dataKey="ContactInfo">
							{rowData => (
								<a
									className={`pf-tableText`}
									href={`mailto:${rowData?.contactInfo}`}>
									{rowData?.contactInfo}
								</a>
							)}
						</Cell>
					);
				case 'administrator':
					return (
						<Cell dataKey="AdministratorName">
							{rowData => (
								<div className={`pf-tableText`}>{rowData?.administratorName}</div>
							)}
						</Cell>
					);
				case 'location':
					return (
						<Cell dataKey="Location">
							{rowData => (
								<div className={`pf-tableText`}>{rowData?.location?.name}</div>
							)}
						</Cell>
					);
				case 'number of Users':
					return (
						<Cell dataKey="UsersCount">
							{rowData => <div className={`pf-tableText`}>{rowData?.usersCount}</div>}
						</Cell>
					);
				default:
					break;
			}
		};

		return (
			<Column
				key={index}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						{<PFSortArrow />}
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;
		try {
			setIsLoading(true);
			await fetchCompaniesData();
		} catch (e) {
			errorHelper(e, true);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const updateCallback = () => {
		if (updateParam === 'blocked') {
			checkedKeys.forEach(key => {
				const foundBlockedCompany = findByToken(tableData, key);
				if (foundBlockedCompany) foundBlockedCompany.isBlocked = true;
			});
			setUpdateParam('');
		} else if (updateParam === 'unblocked') {
			checkedKeys.forEach(key => {
				const foundBlockedCompany = findByToken(tableData, key);
				if (foundBlockedCompany) foundBlockedCompany.isBlocked = false;
			});
			setUpdateParam('');
		} else if (updateParam === 'deleted') {
			checkedKeys.forEach(key => {
				const foundDeletedCompany = findByToken(tableData, key);
				if (foundDeletedCompany) foundDeletedCompany.isDeleted = true;
			});
			dispatch(setCheckedData([]));
			setUpdateParam('');
		}
	};

	const {showConfirm, ConfirmModal} = useBlockedPopup();
	const {showDeleteConfirm, ConfirmDeleteModal} = useDeletedPopup();

	const firstRender = async () => {
		dispatch(setCurrentPageTitle('Companies'));

		try {
			setIsLoading(true);
			const CompaniesData = await fetchCompaniesData();
			if (Boolean(CompaniesData.fullCount)) {
				setIsEmptyPage(false);
			} else {
				setIsEmptyPage(true);
			}
		} catch (e) {
			errorHelper(e, true);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const fetchBlockedCompanies = (checkedKeys, isBlocked) => {
		const count = checkedKeys.length;
		const toastMessage = `${count} compan${count > 1 ? 'ies' : 'y'} ${
			isBlocked ? 'suspended' : 'reactivated'
		}`;
		try {
			Promise.all(
				checkedKeys.map(item => {
					const body = {
						token: item,
						isBlocked,
					};
					return companiesService.setBlockedCompany(body);
				}),
			)
				.then(response => {
					ToastDispatcher.success(toastMessage);
					setUpdateParam(isBlocked ? 'blocked' : 'unblocked');
					updateData();
				})
				.catch(err => {
					ToastDispatcher.error(`${err.message}`);
				});
		} catch (err) {
			throw err?.response?.data;
		}
	};

	const fetchDeletedCompanies = async checkedKeys => {
		const count = checkedKeys.length;
		const toastMessage = `${count} compan${count > 1 ? 'ies' : 'y'} deleted`;
		try {
			await companiesService.deleteCompanies(checkedKeys);
			ToastDispatcher.success(toastMessage, snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Undo"
					onClickAction={async () => {
						try {
							await companiesService.restoreCompanies(checkedKeys); //!
							await firstRender();
							ToastDispatcher.success('Companies restored');
						} catch (e) {
							ToastDispatcher.error('Error');
						}
					}}
				/>
			));
			setUpdateParam('deleted');
			updateData();
		} catch (e) {
			ToastDispatcher.error(`${e.message}`);
			throw e;
		}
	};

	const BlockButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG wrapper="span" className="pf-icon-request-report" src={iconBlock} />
				}
				className={'pf-consumer-block-button'}
				onClick={showConfirm}>
				<span className={'button-text'}>Suspend</span>
			</PFButton>
		);
	};

	const UnblockButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG wrapper="span" className="pf-icon-request-report" src={iconUnblock} />
				}
				className={'pf-consumer-block-button'}
				onClick={() => fetchBlockedCompanies(checkedKeys, false)}>
				<span className={'button-text'}>Reactivate</span>
			</PFButton>
		);
	};

	const DeleteButton = () => {
		return (
			<PFButton
				prefixIcon={
					<ReactSVG
						wrapper="span"
						className="pf-icon-request-report pf-icon-delete"
						src={iconDelete}
					/>
				}
				className={'pf-consumer-delete-button'}
				onClick={showDeleteConfirm}>
				<span className={'button-text'}>Delete</span>
			</PFButton>
		);
	};

	const emptyPageParam = {
		title: 'The List is Empty',
		subtitle: 'Click the button to add a new company:',
		button: {
			variant: 'secondary', // "secondary" or "primary"
			onClick: goToAddNewCompany,
			icon: false, // false or component
			isShowIcon: true, // true or false
			text: 'Add New Company',
		},
		className: '',
	};

	const searchValue = useSelector(state => state.table.search);
	const searchEmptyParam = {
		variant: 'secondary',
		className: '',
		pageName: 'Companies',
		buttonText: 'Back to Companies',
	};
	const tableBarButtons = [
		<BlockButton key={1} />,
		<UnblockButton key={2} />,
		<DeleteButton key={3} />,
	];

	useEffect(() => {
		firstRender();
	}, [props.table.search, props.table.reset, props.table.reverse]);

	useEffect(() => {
		return () => {
			dispatch(cleanedUpCompaniesData());
			dispatch(cleanedUpTableData());
		};
	}, []);

	return (
		<div className={'companies-page'}>
			<PFTableBar
				tableData={tableData}
				checkedKeys={checkedKeys}
				tableBarButtons={tableBarButtons}
			/>
			<PFTable
				tableColumns={tableColumns}
				tableData={tableData}
				isLoading={isLoading}
				modal={'companies'}
				fetchMoreData={fetchMoreData}
				fullTableDataLength={fullTableDataLength}
				checkedKeys={checkedKeys}
				update={update}
				updateCallback={updateCallback}
				updateDataForUseOnModal={updateData}
				urlParams={urlParams}
				parseUrlQuery={parseUrlQuery}
				emptyComponent={
					searchValue ? (
						<SearchEmptyPage {...searchEmptyParam} />
					) : (
						<SuperAdminEmptyPage {...emptyPageParam} />
					)
				}
			/>
			<button
				className={`pf-newCompany-button add-new_button ${isEmptyPage ? 'd-none' : ''}`}
				onClick={goToAddNewCompany}>
				<ReactSVG wrapper="span" className="pf-icon-plus" src={plus} />
				<span className={'pf-newCompany-button-text'}>Add New Company</span>
			</button>
			<ConfirmModal
				onSubmit={() => fetchBlockedCompanies(checkedKeys, true)}
				checkedKeys={checkedKeys}
				className={'pf-confirmBlockedModal'}
			/>
			<ConfirmDeleteModal
				className={'pf-confirmDeleteModal'}
				checkedKeys={checkedKeys}
				onSubmit={() => fetchDeletedCompanies(checkedKeys)}
			/>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		companies: state.companies,
		table: state.table,
	};
};

export default connect(mapStateToProps)(Companies);
