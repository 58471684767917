import React, {FC, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

import {emailService} from 'api/services';
import {PFButton, PFInput, PFPopup} from 'shared/components';
import {AdvisorsRole} from 'types/enums';
import {Amplitude, generateValidator, ToastDispatcher, validations} from 'utils';

import './style.scss';

type PropsTypes = {
	companyToken: string;
	isOpen: boolean;
	onClose: () => void;
	className?: string;
};

const InviteAdvisorsPopup: FC<PropsTypes> = ({companyToken, isOpen, onClose, className}) => {
	const {formState, handleSubmit, watch, trigger, register, setValue} = useForm({
		mode: 'onChange',
	});

	const [isSubmitting, setIsSubmitting] = useState(false);

	const FormInputKeys = {
		emailsList: 'emailsList',
		inviteAs: 'inviteAs,',
	};

	const usersRole = [{role: AdvisorsRole.Advisor}];

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === FormInputKeys.emailsList) {
				await trigger(FormInputKeys.emailsList);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	useEffect(() => {
		setValue(FormInputKeys.emailsList, '');
		setValue(FormInputKeys.inviteAs, usersRole[0]?.role);
	}, []);

	const submit = async valueObj => {
		const body = {
			companyToken,
			emails: valueObj[FormInputKeys.emailsList]
				.replace(/^,+|,+$/g, '')
				.split(',')
				.map(i => i.trim()),
		};
		try {
			setIsSubmitting(true);
			const emailsTest = body.emails.find(
				item => !/^[a-zA-Z0-9_~.+-]+@[a-zA-Z0-9-]+\.[A-Za-z]+$/.test(item),
			);
			if (!emailsTest) {
				await emailService.inviteAdvisors(body);
				ToastDispatcher.success(
					`${body.emails.length} people will receive your invitation`,
				);

				Amplitude.track('Invited_New_Advisor', {
					source: 'Company Page',
				});

				setValue(FormInputKeys.emailsList, '');
				onClose();
			} else {
				ToastDispatcher.error(`The email ${emailsTest} should look like email@domain.com`);
			}
			// eslint-disable-next-line no-useless-catch
		} catch (e: any) {
			ToastDispatcher.error(`${e?.response?.data?.message}`);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<PFPopup
			isShow={isOpen}
			handleClose={onClose}
			isShowFooter={false}
			submitBtnText="Send"
			primaryVariant="primary"
			isCloseButton
			isShowCancelBtn={false}
			title="Send invite"
			subtitle={false}
			onSubmit={() => false}
			className={{root: `inviteAdvisorsPopup ${className || ''}`}}>
			<form onSubmit={handleSubmit(submit)} className="inviteAdvisorsPopup__form">
				<div className="row">
					<div className="col-12">
						<PFInput
							{...register(FormInputKeys.emailsList, {
								validate: generateValidator(
									true,
									validations.textFields.emailsList,
								),
							})}
							// @ts-ignore
							showAsterisk={false}
							label="Email, comma separated"
							placeholder="Email, comma separated"
							defaultValue=""
							error={
								formState.errors[FormInputKeys.emailsList]?.message &&
								formState.errors[FormInputKeys.emailsList]?.message
							}
						/>
					</div>
				</div>
				<div className="footer">
					<PFButton
						disabled={!formState.isValid || isSubmitting}
						className="pf-profileEdit__form-button pf-profileEdit__form-button--submit"
						type="submit">
						Send
					</PFButton>
				</div>
			</form>
		</PFPopup>
	);
};

export default InviteAdvisorsPopup;
