import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {HASHTAG_TYPES, PORTAL_PATHS} from 'const';
import PFButton from 'shared/components/PF-Button';
import PFStepsContainer from 'shared/components/PF-StepsContainer';
import {isPhoneEmpty, matchYouTubeLink, ToastDispatcher, useErrorHelper} from 'utils';

import ContactInformationFormBloc from '../ContactInformationFormBloc';
import LoginDataFormBloc from '../LoginDataFormBloc';
import {SnackActionButton} from '../Snackbar';
import UserDataFormBloc from '../UserDataFormBloc';

import FormInputKeys from './constants';

import './style.scss';

const AddNewAdvisorForm = ({selectedCompany, addNewAdvisor, goBack}) => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const [companyImage, setCompanyImage] = useState<string | null>(null);
	const formProps = useForm({
		mode: 'onChange',
	});
	const {
		register,
		trigger,
		getValues,
		watch,
		formState,
		setValue,
		control,
		unregister,
		setError,
		handleSubmit,
		...props
	} = formProps;

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [hintValue, setHintValue] = useState(0);

	const onSubmit = async valueObj => {
		const {...values} = valueObj;

		const hashtagTokens = Object.values(HASHTAG_TYPES)
			.map(tag => {
				return (
					// if the hashtag.key exists, return it, otherwise if it's undefined, we'll print out the default value (filtering out to just the token)
					values[tag.key] && values[tag.key]?.map(item => item.token)
				);
			})
			.flat()
			.filter(Boolean);

		const getVideoPresentationLink = str => {
			const youTubeLinkMatchArray = matchYouTubeLink(str);
			if (youTubeLinkMatchArray && !!youTubeLinkMatchArray.length) {
				return youTubeLinkMatchArray[5].length
					? youTubeLinkMatchArray[0].slice(0, -youTubeLinkMatchArray[5].length)
					: youTubeLinkMatchArray[0];
			}
			return null;
		};

		const profile = {
			// using default values we got from fetchProfile API call
			firstName: valueObj[FormInputKeys.firstName].trim(),
			lastName: valueObj[FormInputKeys.lastName].trim(),
			description: valueObj[FormInputKeys.description].trim(),
			profileImage: valueObj[FormInputKeys.avatar],
			isActive: true,
			reasonInactive: null,
			email: valueObj[FormInputKeys.email],
			password: null,
			webSite: '',
			phoneNumber: isPhoneEmpty(valueObj[FormInputKeys.phoneNumber])
				? '' // ph# is not required, so send an empty string if it's empty.
				: valueObj[FormInputKeys.phoneNumber]?.formattedValue ||
				  valueObj[FormInputKeys.phoneNumber], // if the phoneNumber has been changd, we send a formatted ph#, otherwise the existing ph#.
			calendlyLink: valueObj[FormInputKeys.calendlyLink],
			instagram: valueObj[FormInputKeys.instagram],
			linkedin: valueObj[FormInputKeys.linkedin],
			youtube: valueObj[FormInputKeys.youtube],
			facebook: valueObj[FormInputKeys.facebook],
			twitter: valueObj[FormInputKeys.twitter],
			videoPresentationLink: getVideoPresentationLink(
				valueObj[FormInputKeys.videoPresentationLink],
			),
			certificateTokens: valueObj[FormInputKeys.financialCertificates]?.map(
				item => item.token,
			),
			locationToken: valueObj[FormInputKeys.location]?.token, // if the user doesn't change their location, we pass their current location's token.
			companyToken: Object.keys(selectedCompany).length
				? selectedCompany.token
				: (!valueObj[FormInputKeys.newCompanyCheck] &&
						valueObj[FormInputKeys.company]?.token) ||
				  null, //!
			companyName: valueObj[FormInputKeys.newCompanyCheck]
				? valueObj[FormInputKeys.companyName]
				: null, // !
			companyLocationToken: valueObj[FormInputKeys.newCompanyCheck]
				? valueObj[FormInputKeys.companyLocationToken]?.token
				: null, // !
			companyImage: valueObj[FormInputKeys.newCompanyCheck]
				? valueObj[FormInputKeys.companyLogo]
				: null, // !
			hashtagTokens,
			/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
			specializations: null,
			specializationTypes: valueObj[FormInputKeys.specializationValue]?.map(
				item => item.type,
			),
			contactEmail: valueObj[FormInputKeys.contactEmail],
		};

		try {
			setIsSubmitting(true);
			const res = await addNewAdvisor(profile);
			ToastDispatcher.success('New user added and will get an email!', snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Open profile"
					onClickAction={() =>
						navigate(
							`${PORTAL_PATHS.OTHER_PATHS.ADVISOR_PROFILE}#${res?.data?.advisorToken}&${res?.data?.userToken}`,
						)
					}
				/>
			));
			goBack();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === FormInputKeys.specializationValue) {
				await trigger(FormInputKeys.specializationValue);
			}
			if (name === FormInputKeys.description) {
				setHintValue(data.description.length);
				await trigger(FormInputKeys.description);
			}
			if (name === FormInputKeys.company) {
				setCompanyImage(data.company?.image); //!
				await trigger(FormInputKeys.company);
			}
			if (name === FormInputKeys.videoPresentationLink) {
				await trigger(FormInputKeys.videoPresentationLink);
			}
			if (name === FormInputKeys.location) {
				await trigger(FormInputKeys.location);
			}
			if (name === FormInputKeys.financialCertificates) {
				await trigger(FormInputKeys.financialCertificates);
			}
			if (name === FormInputKeys.contactEmail) {
				await trigger(FormInputKeys.contactEmail);
			}
			if (name === FormInputKeys.newCompanyCheck) {
				//!
				await trigger(FormInputKeys.newCompanyCheck);
			}
			if (name === FormInputKeys.companyLocationToken) {
				//!
				await trigger(FormInputKeys.companyLocationToken);
			}
			if (name === FormInputKeys.companyName) {
				//!
				await trigger(FormInputKeys.companyName);
			}
			if (name === FormInputKeys.companyLogo) {
				//!
				await trigger(FormInputKeys.companyLogo);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	useEffect(() => {
		// тут ставить стартовые значения, что бы корректно работала валидация
		if (selectedCompany && !!Object.keys(selectedCompany).length) {
			setValue(FormInputKeys.company, {
				token: selectedCompany?.token,
				name: selectedCompany?.name,
				image: selectedCompany?.image,
			});
			setCompanyImage(selectedCompany?.image); //!
		}
	}, [selectedCompany]);

	const [step, setStep] = useState(0);

	const handleNext = () => {
		if (step < 2) setStep(step + 1);
	};

	const handleBack = () => {
		if (step > 0) setStep(step - 1);
	};

	const onCancelOrPrev = () => {
		if (step > 0) {
			handleBack();
		} else {
			goBack();
		}
	};

	return (
		<form className="pf-profileEdit__form">
			<PFStepsContainer
				labels={['User Data', 'Contact Information', 'Login Data']}
				step={step}>
				<UserDataFormBloc
					formProps={formProps}
					hintValue={hintValue}
					selectedCompany={selectedCompany}
					companyImage={companyImage}
				/>
				<ContactInformationFormBloc formProps={formProps} />
				<LoginDataFormBloc formProps={formProps} />
			</PFStepsContainer>
			<div className="d-flex justify-content-end">
				<PFButton
					onClick={onCancelOrPrev}
					className="pf-profileEdit__form-button"
					variant="secondary"
					type="button">
					{step > 0 ? 'Prev' : 'Cancel'}
				</PFButton>
				{step < 2 ? (
					<PFButton
						onClick={handleNext}
						disabled={!formState.isValid || isSubmitting}
						className="pf-profileEdit__form-button pf-profileEdit__form-button--submit"
						type="button">
						Next
					</PFButton>
				) : (
					<PFButton
						disabled={!formState.isValid || isSubmitting}
						className="pf-profileEdit__form-button pf-profileEdit__form-button--submit"
						type="button"
						onClick={handleSubmit(onSubmit)}>
						Create
					</PFButton>
				)}
			</div>
		</form>
	);
};

export default AddNewAdvisorForm;
