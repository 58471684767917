import React, {FC} from 'react';

import {SPECIALIZATION_OPTIONS_TYPES} from 'const';
import PFAvatar from 'shared/components/PF-Avatar';
import PFCheckbox from 'shared/components/PF-Checkbox';

import urlPaths from '../../../../api/url-paths';

import style from './style.module.scss';

type VirtuosoItemPropsTypes = {
	avatarUrl: string;
	token: string;
	firstName: string;
	lastName: string;
	checkedKeys: string[];
	handleCheckboxClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>, token: string) => void;
	advisorSpecializationTypes?: any;
};

const VirtuosoItem: FC<VirtuosoItemPropsTypes> = ({
	avatarUrl,
	token,
	firstName,
	lastName,
	checkedKeys,
	handleCheckboxClick,
	advisorSpecializationTypes,
}) => {
	return (
		<div key={token} className={style.virtuosoCurrentItem}>
			<PFCheckbox
				name={token}
				registerControl={false}
				value={token}
				checked={checkedKeys.includes(token)}
				onClick={e => handleCheckboxClick(e, token)}
				className={style.checkbox}
				id={token}
			/>
			<PFAvatar
				src={avatarUrl ? `${urlPaths.BASE_IMAGES_URL}${avatarUrl}` : ''}
				size={40}
				className={style.avatar}
			/>
			<div className={style.info}>
				<div className={style.name}>{`${firstName} ${lastName}`}</div>
				{advisorSpecializationTypes && (
					<div className={style.specialization}>
						{advisorSpecializationTypes?.map((item, index) => {
							if (index !== advisorSpecializationTypes.length - 1) {
								return `${
									SPECIALIZATION_OPTIONS_TYPES.find(spec => {
										return spec.type === item;
									})?.name
								}, `;
							}
							return SPECIALIZATION_OPTIONS_TYPES.find(spec => {
								return spec.type === item;
							})?.name;
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default VirtuosoItem;
