import React, {FC, ReactNode, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {iconViewAll} from 'assets';
import {PORTAL_PATHS} from 'const';
import {PFLink, useGetInsurances} from 'shared';
import {InsuranceType} from 'types';
import {useErrorHelper} from 'utils';

import {
	CriticalIllnessInsuranceEmptyItem,
	CriticalIllnessInsuranceItem,
	DisabilityInsuranceEmptyItem,
	DisabilityInsuranceItem,
	LifeInsuranceEmptyItem,
	LifeInsuranceItem,
} from '../UserProfileInsuranceItems';

import './style.scss';

type PropsTypes = {
	userToken: string;
	isShortView: boolean;
	setInsurancesCount?: (number) => void;
	maxItems?: number | undefined;
};

const UserProfileInsuranceBloc: FC<PropsTypes> = ({
	userToken,
	isShortView,
	setInsurancesCount,
	maxItems,
}) => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const fetchInsurances = useGetInsurances();
	const [insurances, setInsurance] = useState<InsuranceType | null>(null);
	const [updateInsurances, setUpdateInsurances] = useState<boolean>(false);

	const emptyInsurance = (
		<div className="emptyInsurance">
			This section is empty.{' '}
			<PFLink
				className="emptyInsurance__link"
				to={`${PORTAL_PATHS.OTHER_PATHS.VIEW_ALL_INSURANCES_DATA}?token=${userToken}`}>
				Click here
			</PFLink>{' '}
			to add the insurance information yourself.
		</div>
	);

	const handleAddLifeInsuranceClick = (): void => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.ADD_NEW_LIFE_INSURANCE}#${userToken}`);
	};

	const handleAddCriticalIllnessInsuranceClick = (): void => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.ADD_CRITICAL_ILLNESS_INSURANCE}#${userToken}`);
	};

	const handleAddDisabilityInsuranceClick = (): void => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.ADD_DISABILITY_INSURANCE}#${userToken}`);
	};

	const getInsurances = async () => {
		try {
			const res = await fetchInsurances(userToken);
			setInsurance(res);
			const count =
				(res.criticalIllnessInsuranceModel &&
				Array.isArray(res.criticalIllnessInsuranceModel)
					? res.criticalIllnessInsuranceModel.length
					: 0) +
				(res.disabilityInsuranceModel && Array.isArray(res.disabilityInsuranceModel)
					? res.disabilityInsuranceModel.length
					: 0) +
				(res.lifeInsuranceModel && Array.isArray(res.lifeInsuranceModel)
					? res.lifeInsuranceModel.length
					: 0);
			setInsurancesCount?.(count);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const getView = (insurancesData: InsuranceType, isShort: boolean): ReactNode[] | ReactNode => {
		let insuranceItems: ReactNode[] = [];

		if (isShort) {
			if (insurancesData?.lifeInsuranceModel?.length) {
				insuranceItems = insuranceItems.concat(
					insurancesData?.lifeInsuranceModel.map(item => {
						return (
							<LifeInsuranceItem
								key={`${item.token}-compact`}
								insurance={item}
								isCompact
								containerClassName="insurance-compact-item"
							/>
						);
					}),
				);
			}
			if (insurancesData?.criticalIllnessInsuranceModel?.length) {
				insuranceItems = insuranceItems.concat(
					insurancesData?.criticalIllnessInsuranceModel.map(item => {
						return (
							<CriticalIllnessInsuranceItem
								key={`${item.token}-compact`}
								insurance={item}
								isCompact
								containerClassName="insurance-compact-item"
							/>
						);
					}),
				);
			}
			if (insurancesData?.disabilityInsuranceModel?.length) {
				insuranceItems = insuranceItems.concat(
					insurancesData?.disabilityInsuranceModel.map(item => {
						return (
							<DisabilityInsuranceItem
								key={`${item.token}-compact`}
								insurance={item}
								isCompact
								containerClassName="insurance-compact-item"
							/>
						);
					}),
				);
			}
			if (maxItems) {
				insuranceItems = insuranceItems.slice(0, maxItems);
			}
		} else {
			if (insurancesData?.lifeInsuranceModel && insurancesData?.lifeInsuranceModel.length) {
				insuranceItems = insuranceItems.concat(
					insurancesData?.lifeInsuranceModel.map(item => {
						return (
							<LifeInsuranceItem
								key={item.token}
								insurance={item}
								onRefreshInsurances={getInsurances}
								containerClassName="insurance-item"
							/>
						);
					}),
				);
			}
			if (
				insurancesData?.criticalIllnessInsuranceModel &&
				insurancesData?.criticalIllnessInsuranceModel.length
			) {
				insuranceItems = insuranceItems.concat(
					insurancesData?.criticalIllnessInsuranceModel.map(item => {
						return (
							<CriticalIllnessInsuranceItem
								key={item.token}
								insurance={item}
								onRefreshInsurances={getInsurances}
								containerClassName="insurance-item"
							/>
						);
					}),
				);
			}
			if (
				insurancesData?.disabilityInsuranceModel &&
				insurancesData?.disabilityInsuranceModel.length
			) {
				insuranceItems = insuranceItems.concat(
					insurancesData?.disabilityInsuranceModel.map(item => {
						return (
							<DisabilityInsuranceItem
								key={item.token}
								insurance={item}
								onRefreshInsurances={getInsurances}
								containerClassName="insurance-item"
							/>
						);
					}),
				);
			}
			if (
				!insurancesData?.lifeInsuranceModel ||
				insurancesData?.lifeInsuranceModel.length < 3
			) {
				insuranceItems.push(
					<LifeInsuranceEmptyItem
						key="LifeInsuranceEmptyItem"
						isHintVisible={
							!insurancesData?.lifeInsuranceModel ||
							insurancesData?.lifeInsuranceModel.length === 0
						}
						onAddClick={handleAddLifeInsuranceClick}
						containerClassName="insurance-item"
					/>,
				);
			}
			if (
				!insurancesData?.criticalIllnessInsuranceModel ||
				insurancesData?.criticalIllnessInsuranceModel.length < 3
			) {
				insuranceItems.push(
					<CriticalIllnessInsuranceEmptyItem
						key="CriticalIllnessInsuranceEmptyItem"
						isHintVisible={
							!insurancesData?.criticalIllnessInsuranceModel ||
							insurancesData?.criticalIllnessInsuranceModel.length === 0
						}
						onAddClick={handleAddCriticalIllnessInsuranceClick}
						containerClassName="insurance-item"
					/>,
				);
			}
			if (
				!insurancesData?.disabilityInsuranceModel ||
				insurancesData?.disabilityInsuranceModel.length < 3
			) {
				insuranceItems.push(
					<DisabilityInsuranceEmptyItem
						key="DisabilityInsuranceEmptyItem"
						isHintVisible={
							!insurancesData?.disabilityInsuranceModel ||
							insurancesData?.disabilityInsuranceModel.length === 0
						}
						onAddClick={handleAddDisabilityInsuranceClick}
						containerClassName="insurance-item"
					/>,
				);
			}
		}
		return insuranceItems.length ? insuranceItems : emptyInsurance;
	};

	const goToViewAll = (): void => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.VIEW_ALL_INSURANCES_DATA}?token=${userToken}`);
	};

	useEffect(() => {
		getInsurances();
	}, [userToken, updateInsurances]);

	return (
		<div className="insurance">
			{insurances && getView(insurances, isShortView)}

			{isShortView && (
				<div className="insurance__viewAllButton">
					<button onClick={goToViewAll} type="button">
						View All
						<ReactSVG wrapper="span" className="pf-arrowSvg" src={iconViewAll} />
					</button>
				</div>
			)}
		</div>
	);
};

export default UserProfileInsuranceBloc;
