import React from 'react';
import completeProfile from '../../../assets/emptyPages/completeProfile.svg';
import './style.scss';

const CompleteProfilePage = ({title, subtitle, button, className}) => {
	return (
		<div className={`completeProfile__container ${className ? className : ''}`}>
			<div className={`completeProfile__container__wrapper`}>
				<div className={'completeProfile__header'}>
					<img src={completeProfile} />
					<h1>{title}</h1>
					<h2>{subtitle}</h2>
				</div>
				<div className={'completeProfile__main'}>
					{button && (
						<button
							className={`completeButton ${button?.variant ? button?.variant : ''}`}
							onClick={button.onClick}>
							<span className={'completeButton-text'}>{button.text}</span>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default CompleteProfilePage;
