import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import ROUTES from '../../routes';
import './style.scss';
import {isSuitableRole} from '../../utils';
import InviteNewAdviserModal from '../../components/InviteNewAdviserPopup';
import {useAppSelector} from '../../store';
import {selectIsOpenInviteAdvisorModal} from '../../store/selectors';
import {CopyDeepLinkPopup, ReferBusinessPopup, ClientTagsPopup} from '../../components';
import {AdvisorsRole} from '../../types/enums';

export default function View({isAuth, role}) {
	const isOpenInviteAdvisor = useAppSelector(selectIsOpenInviteAdvisorModal);
	//This function checks that if the route is a common route or the user isn't authenticated and it's not private,
	//or if the user's role matches the component, then the component can be rendered.

	const routeFilter = route => {
		if (route.common || (!isAuth && !route.private)) {
			return true;
		}

		return route?.role && role ? isSuitableRole(route?.role, role) : false;
	};

	const firstPage = ROUTES.find(route => {
		if (isAuth) {
			if (role.includes(AdvisorsRole.SuperAdmin)) {
				return route.path === '/portal/companies';
			} else if (
				role.includes(AdvisorsRole.Advisor) ||
				role.includes(AdvisorsRole.AccountAdministrator) ||
				role.includes(AdvisorsRole.Administrator)
			) {
				// ! new 25.05.2022
				return route.path === '/portal/current';
			} else {
				return route.path === '/confirm';
			}
		} else {
			return route.private === false;
		}
	});

	//when the route is "/" aka default page, we render the first item that matches the user's role in the list.
	// const firstPage = Routes.find(routeFilter);
	//storing an array of routes that are filtered to include the routes using the routefilter function
	const RoutesList = ROUTES.filter(routeFilter);

	return (
		<main className="pf-view">
			<Routes>
				<Route exact path="/" element={<Navigate to={firstPage.path} />} />
				{RoutesList.map((route, index) => {
					return (
						<Route
							key={index}
							path={route.path}
							element={route.element}
							exact={route.exact}
						/>
					);
				})}
				<Route path="*" element={<Navigate to={isAuth ? '/error' : firstPage.path} />} />
			</Routes>
			{isAuth && role !== AdvisorsRole.SuperAdmin && isOpenInviteAdvisor && (
				<InviteNewAdviserModal />
			)}
			<CopyDeepLinkPopup />
			<ReferBusinessPopup />
			<ClientTagsPopup />
		</main>
	);
}
