enum OpportunityTypes {
	LargeCashDeposit = 1,
	ChildTaxDeposit = 2,
	NewAccountConnected = 3,
	MaturityOfLifeInsurance = 4,
	MaturityOfIllnessInsurance = 5,
	MaturityOfDisabilityInsurance = 6,
}

export {OpportunityTypes};
