import React, {FC} from 'react';
import {UseFormReturn} from 'react-hook-form';

import PFInput from 'shared/components/PF-Input';
import PFRadioGroup from 'shared/components/PF-RadioGroup';
import {
	InsuranceDurationEnum,
	InsuranceFrequencyOfPaymentEnum,
	InsurancePolicyPayerEnum,
	InsuranceRiderEnum,
	LifeInsuranceType,
} from 'types';
import {getAmountInsuranceValue} from 'utils';

import PFCheckbox from '../../../../shared/components/PF-Checkbox';
import FormInputKeys, {
	PAYMENT_FREQUENCY_OPTIONS,
	POLICY_PAYER_OPTIONS,
	RIDER_OPTIONS,
} from '../constants';

type PropsTypes = {
	formProps: UseFormReturn;
	lifeInsurance?: LifeInsuranceType;
};

const DetailsLifeInsuranceFormBloc: FC<PropsTypes> = ({lifeInsurance, formProps}) => {
	const fullClass = 'col-12 mb-4';
	const halfClass = 'col-lg-6 mb-4';

	return (
		<>
			<h4 className="mt-0">
				Who owns the policy?
				<br />
				(Who is paying for the policy?)
			</h4>
			<div className={`${fullClass} policyPayer`}>
				<PFRadioGroup
					control={formProps.control}
					name={FormInputKeys.policyPayer}
					variant="horizontal"
					// @ts-ignore
					disabled={false}
					defaultValue={
						formProps.getValues(FormInputKeys.policyPayer) ||
						(lifeInsurance &&
							typeof lifeInsurance?.policyPayer === 'number' &&
							String(lifeInsurance?.policyPayer)) ||
						'1'
					}
					options={POLICY_PAYER_OPTIONS}
					error={
						formProps.formState.errors[FormInputKeys.policyPayer]?.message &&
						formProps.formState.errors[FormInputKeys.policyPayer]?.message
					}
				/>
			</div>
			{formProps.getValues(FormInputKeys.policyPayer) ===
				String(InsurancePolicyPayerEnum.SomeoneElse) && (
				<div className={`${fullClass} customPolicyPayer`}>
					<PFInput
						{...formProps.register(FormInputKeys.customPolicyPayer, {
							required: false,
						})}
						// @ts-ignore
						showAsterisk={false}
						label="Who?"
						placeholder="Person name"
						defaultValue={
							formProps.getValues(FormInputKeys.customPolicyPayer) ||
							(lifeInsurance && lifeInsurance?.customPolicyPayer) ||
							''
						}
						error={
							formProps.formState.errors[FormInputKeys.customPolicyPayer]?.message &&
							formProps.formState.errors[FormInputKeys.customPolicyPayer]?.message
						}
					/>
				</div>
			)}
			<h4>Payment frequency</h4>
			<div className={`${fullClass} paymentFrequency`}>
				<PFRadioGroup
					control={formProps.control}
					name={FormInputKeys.paymentFrequency}
					// @ts-ignore
					disabled={false}
					variant="vertical"
					defaultValue={
						formProps.getValues(FormInputKeys.paymentFrequency) ||
						(lifeInsurance &&
							typeof lifeInsurance?.paymentFrequency === 'number' &&
							String(lifeInsurance?.paymentFrequency))
					}
					options={
						formProps.getValues(FormInputKeys.typeByDuration) ===
						String(InsuranceDurationEnum.Term)
							? PAYMENT_FREQUENCY_OPTIONS
							: PAYMENT_FREQUENCY_OPTIONS.filter(
									item =>
										item.value !==
										String(InsuranceFrequencyOfPaymentEnum.Quarterly),
							  )
					}
					error={
						formProps.formState.errors[FormInputKeys.isCashInThePolicy]?.message &&
						formProps.formState.errors[FormInputKeys.isCashInThePolicy]?.message
					}
				/>
			</div>
			{!(
				formProps.getValues(FormInputKeys.paymentFrequency) ===
					String(InsuranceFrequencyOfPaymentEnum.NoLongerPayingIntoThisPolicy) ||
				formProps.getValues(FormInputKeys.paymentFrequency) ===
					String(InsuranceFrequencyOfPaymentEnum.ThisIsGroupCoverage)
			) && (
				<div className={`${halfClass} amountOfPayment`}>
					<PFInput
						{...formProps.register(FormInputKeys.amountOfPayment, {
							required: false,
						})}
						// @ts-ignore
						showAsterisk={false}
						label="Payment amount"
						placeholder="Enter amount, $"
						onChange={e => {
							const newValue = getAmountInsuranceValue(e.target.value);
							e.target.value = newValue;
							formProps.setValue(FormInputKeys.amountOfPayment, newValue);
						}}
						defaultValue={
							formProps.getValues(FormInputKeys.amountOfPayment) ||
							(lifeInsurance && lifeInsurance?.amountOfPayment) ||
							''
						}
						error={
							formProps.formState.errors[FormInputKeys.amountOfPayment]?.message &&
							formProps.formState.errors[FormInputKeys.amountOfPayment]?.message
						}
					/>
				</div>
			)}
			<h4>Are there any riders on the policy?</h4>
			<div className={`${halfClass} riders`}>
				{RIDER_OPTIONS.map(item => {
					const selectedRiders = formProps.getValues(FormInputKeys.riders) || [];
					const riderItem = selectedRiders.find(s => String(s.rider) === item.value);

					return (
						<React.Fragment key={item.value}>
							<PFCheckbox
								name={FormInputKeys.riders}
								checked={!!riderItem}
								onClick={() => {
									if (riderItem) {
										formProps.setValue(
											FormInputKeys.riders,
											selectedRiders.filter(s => s.rider !== riderItem.rider),
										);
									} else {
										formProps.setValue(FormInputKeys.riders, [
											...selectedRiders,
											{rider: Number(item.value), coverageAmount: null},
										]);
									}
								}}
								label={item.label}
								key={item.value}
								id={item.value}
							/>
							{!!riderItem &&
								(riderItem.rider === InsuranceRiderEnum.ChildRider ||
									riderItem.rider ===
										InsuranceRiderEnum.AccidentalDeathAndDismemberment) && (
									<div className={`${fullClass}`}>
										<PFInput
											// @ts-ignore
											showAsterisk={false}
											label="Coverage amount"
											placeholder="Enter amount"
											value={
												riderItem.coverageAmount !== null
													? getAmountInsuranceValue(
															riderItem.coverageAmount,
													  )
													: ''
											}
											onChange={e => {
												const newValue = getAmountInsuranceValue(
													e.target.value,
												);

												const newRiders = selectedRiders.map(r => {
													if (r.rider === riderItem.rider) {
														return {
															rider: riderItem.rider,
															coverageAmount: newValue,
														};
													}

													return r;
												});

												formProps.setValue(FormInputKeys.riders, newRiders);
											}}
										/>
									</div>
								)}
						</React.Fragment>
					);
				})}
			</div>
		</>
	);
};

export default DetailsLifeInsuranceFormBloc;
