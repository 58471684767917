import {
	SET_COMPANY_USERS_DATA,
	CLEANED_UP_COMPANIES_DATA,
	SET_COMPANIES,
	SET_COMPANY_PROFILE_DATA,
	CLEANED_UP_COMPANY_PROFILE_DATA,
} from '../types/Types';

export const setCompanies = companies => {
	return {
		type: SET_COMPANIES,
		payload: companies,
	};
};

export const setCompaniesProfileData = companyData => {
	return {
		type: SET_COMPANY_PROFILE_DATA,
		payload: companyData,
	};
};

export const cleanedUpCompaniesData = () => {
	return {
		type: CLEANED_UP_COMPANIES_DATA,
	};
};

export const setCompanyUsers = users => {
	return {
		type: SET_COMPANY_USERS_DATA,
		payload: users,
	};
};

export const cleanedUpCompanyProfileData = () => {
	return {
		type: CLEANED_UP_COMPANY_PROFILE_DATA,
	};
};

export const companiesProfileSelector = state => state.companies.companyProfileData;
