import {
	SET_ADVISOR_CLIENTS_DATA,
	CLEANED_UP_ADVISOR_CLIENTS_DATA,
	SET_CHECKED_DATA,
	SET_SELECTED_TABLE_ROW_DATA,
} from '../types/Types';

export const setAdvisorClientsData = users => {
	return {
		type: SET_ADVISOR_CLIENTS_DATA,
		payload: users,
	};
};

export const cleanedUpAdvisorClientsData = () => {
	return {
		type: CLEANED_UP_ADVISOR_CLIENTS_DATA,
	};
};

export const setSelectedTableRowData = user => {
	return {
		type: SET_SELECTED_TABLE_ROW_DATA,
		payload: user,
	};
};

export const setCheckedData = checkedKeys => {
	return {
		type: SET_CHECKED_DATA,
		payload: checkedKeys,
	};
};
