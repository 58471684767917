import {DisabilityTermEnum, InsuranceCompanyEnum, InsurancePayerEnum} from 'types';

export const FORM_TITLE = 'Add Disability Insurance Information';

export class DefaultDisabilityInsurance {
	userToken: string;

	amountOfCoverage: number | null = null;

	insuranceCompany: InsuranceCompanyEnum | null = null;

	insurancePayer: InsurancePayerEnum | null = null;

	disabilityTerm: DisabilityTermEnum | null = null;

	customInsuranceCompanyName: string | null = null;

	insured: string | null = null;

	purposeOfPlan: string | null = null;

	token: string | null = null;

	constructor(userToken: string) {
		this.userToken = userToken;
	}
}
