import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const signIn = ({username, password}) => {
	return httpClient.post(`${URL.BASE_URL}/${URL.AUTH.INDEX}`, {
		username,
		password,
		isFromPortal: true,
	});
};
