import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import PFButton from 'shared/components/PF-Button';
import PFTabsContainer from 'shared/components/PF-TabsContainer';
import {
	InsuranceDurationEnum,
	InsuranceFrequencyOfPaymentEnum,
	InsurancePayerEnum,
	InsurancePolicyPayerEnum,
} from 'types/enums';
import {
	Amplitude,
	convertToNumberOrNull,
	getAmountCommasValue,
	getUTSDate,
	parseDate,
	useErrorHelper,
} from 'utils';

import FormInputKeys from '../constants';
import DetailsLifeInsuranceFormBloc from '../DetailsLifeInsuranceFormBloc';
import GeneralLifeInsuranceFormBloc from '../GeneralLifeInsuranceFormBloc';

import './style.scss';

const EditLifeInsuranceForm = ({lifeInsurance, userToken, editInsurance, goBack}) => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const formProps = useForm({
		mode: 'onChange',
	});
	const {
		register,
		trigger,
		getValues,
		watch,
		formState,
		setValue,
		control,
		unregister,
		setError,
		handleSubmit,
		...props
	} = formProps;

	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = async valueObj => {
		const month = valueObj[FormInputKeys.month];
		const day = valueObj[FormInputKeys.day];
		const year = valueObj[FormInputKeys.year];

		const isCashInThePolicy =
			valueObj[FormInputKeys.typeByDuration] === String(InsuranceDurationEnum.Permanent)
				? valueObj[FormInputKeys.isCashInThePolicy] === 'true'
				: false;
		const amountCashInThePolicy =
			valueObj[FormInputKeys.typeByDuration] === String(InsuranceDurationEnum.Permanent) &&
			isCashInThePolicy
				? parseFloat(
						valueObj[FormInputKeys.amountCashInThePolicy] &&
							valueObj[FormInputKeys.amountCashInThePolicy].replace(/,/g, ''),
				  )
				: null;
		const isLoanAgainstPolicy =
			valueObj[FormInputKeys.typeByDuration] === String(InsuranceDurationEnum.Permanent)
				? valueObj[FormInputKeys.isLoanAgainstPolicy] === 'true'
				: false;
		const amountLoanAgainstPolicy =
			valueObj[FormInputKeys.typeByDuration] === String(InsuranceDurationEnum.Permanent) &&
			isLoanAgainstPolicy
				? parseFloat(
						valueObj[FormInputKeys.amountLoanAgainstPolicy] &&
							valueObj[FormInputKeys.amountLoanAgainstPolicy].replace(/,/g, ''),
				  )
				: null;
		const policyRenewalDate =
			valueObj[FormInputKeys.typeByDuration] === String(InsuranceDurationEnum.Term)
				? getUTSDate({year, month, date: day})
				: null;
		const customPolicyPayer =
			valueObj[FormInputKeys.policyPayer] === String(InsurancePolicyPayerEnum.SomeoneElse)
				? valueObj[FormInputKeys.customPolicyPayer]
				: null;
		const amountOfPayment = !(
			valueObj[FormInputKeys.paymentFrequency] ===
				String(InsuranceFrequencyOfPaymentEnum.NoLongerPayingIntoThisPolicy) ||
			valueObj[FormInputKeys.paymentFrequency] ===
				String(InsuranceFrequencyOfPaymentEnum.ThisIsGroupCoverage)
		)
			? parseFloat(
					valueObj[FormInputKeys.amountOfPayment] &&
						valueObj[FormInputKeys.amountOfPayment].replace(/,/g, ''),
			  )
			: null;
		const riders = Array.isArray(valueObj[FormInputKeys.riders])
			? valueObj[FormInputKeys.riders].map(rider => ({
					rider: rider.rider,
					coverageAmount:
						rider.coverageAmount === null || rider.coverageAmount === ''
							? null
							: Number(rider.coverageAmount.replaceAll(',', '')),
			  }))
			: [];
		const beneficiaries =
			valueObj[FormInputKeys.beneficiaries]
				.map(b => ({
					fullName: b[FormInputKeys.beneficiaryFullName] || null,
					proportion: convertToNumberOrNull(b[FormInputKeys.beneficiaryProportion]),
				}))
				.filter(b => b.fullName !== null || b.proportion !== null) || [];

		const data = {
			userToken,
			typeByDuration: Number(valueObj[FormInputKeys.typeByDuration]),
			isCashInThePolicy,
			amountCashInThePolicy,
			isLoanAgainstPolicy,
			amountLoanAgainstPolicy,
			policyRenewalDate,
			amountOfCoverage: parseFloat(
				valueObj[FormInputKeys.amountOfCoverage] &&
					valueObj[FormInputKeys.amountOfCoverage].replace(/,/g, ''),
			),
			insuranceCompany:
				typeof valueObj[FormInputKeys.insuranceCompany] === 'number'
					? valueObj[FormInputKeys.insuranceCompany]
					: null,
			insurancePayer: Number(valueObj[FormInputKeys.insurancePayer]),
			policyNumber: valueObj[FormInputKeys.policyNumber],
			insured: valueObj[FormInputKeys.insured],
			isExistsBeneficiary: !!beneficiaries?.length,
			beneficiaries,
			trusteeName: valueObj[FormInputKeys.trusteeName],
			purposeOfPlan: valueObj[FormInputKeys.purposeOfPlan],
			policyPayer: Number(valueObj[FormInputKeys.policyPayer]),
			customPolicyPayer,
			paymentFrequency: Number(valueObj[FormInputKeys.paymentFrequency]),
			amountOfPayment,
			riders,
			token: lifeInsurance.token,
			customInsuranceCompanyName:
				typeof valueObj[FormInputKeys.insuranceCompany] === 'string'
					? valueObj[FormInputKeys.insuranceCompany]
					: null,
		};

		try {
			setIsSubmitting(true);
			await editInsurance(data);

			Amplitude.track('Edited_Insurance', {
				type: 'Life Insurance',
				data,
			});

			goBack();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === FormInputKeys.typeByDuration) {
				setValue(
					FormInputKeys.paymentFrequency,
					(typeof lifeInsurance?.paymentFrequency === 'number' &&
						String(lifeInsurance?.paymentFrequency)) ||
						String(InsuranceFrequencyOfPaymentEnum.Monthly),
				);
				await trigger(FormInputKeys.typeByDuration);
			}
			if (name === FormInputKeys.month) {
				await trigger(FormInputKeys.month);
			}
			if (name === FormInputKeys.day) {
				await trigger(FormInputKeys.day);
			}
			if (name === FormInputKeys.year) {
				await trigger(FormInputKeys.year);
			}
			if (name === FormInputKeys.isCashInThePolicy) {
				await trigger(FormInputKeys.isCashInThePolicy);
			}
			if (name === FormInputKeys.amountCashInThePolicy) {
				await trigger(FormInputKeys.amountCashInThePolicy);
			}
			if (name === FormInputKeys.isLoanAgainstPolicy) {
				await trigger(FormInputKeys.isLoanAgainstPolicy);
			}
			if (name === FormInputKeys.amountLoanAgainstPolicy) {
				await trigger(FormInputKeys.amountLoanAgainstPolicy);
			}
			if (name === FormInputKeys.policyRenewalDate) {
				await trigger(FormInputKeys.policyRenewalDate);
			}
			if (name === FormInputKeys.amountOfCoverage) {
				await trigger(FormInputKeys.amountOfCoverage);
			}
			if (name === FormInputKeys.insuranceCompany) {
				await trigger(FormInputKeys.insuranceCompany);
			}
			if (name === FormInputKeys.insurancePayer) {
				await trigger(FormInputKeys.insurancePayer);
			}
			if (name === FormInputKeys.policyNumber) {
				await trigger(FormInputKeys.policyNumber);
			}
			if (name === FormInputKeys.insured) {
				await trigger(FormInputKeys.insured);
			}
			if (name === FormInputKeys.policyPayer) {
				await trigger(FormInputKeys.policyPayer);
			}
			if (name === FormInputKeys.customPolicyPayer) {
				await trigger(FormInputKeys.customPolicyPayer);
			}
			if (name === FormInputKeys.paymentFrequency) {
				await trigger(FormInputKeys.paymentFrequency);
			}
			if (name === FormInputKeys.amountOfPayment) {
				await trigger(FormInputKeys.amountOfPayment);
			}
			if (name === FormInputKeys.riders) {
				await trigger(FormInputKeys.riders);
			}
			if (name?.includes(FormInputKeys.beneficiaryProportion)) {
				await trigger(FormInputKeys.beneficiaries);
			}
			if (name === FormInputKeys.trusteeName) {
				await trigger(FormInputKeys.trusteeName);
			}
			if (name === FormInputKeys.purposeOfPlan) {
				await trigger(FormInputKeys.purposeOfPlan);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	useEffect(() => {
		// тут ставить стартовые значения, что бы корректно работала валидация
		setValue(
			FormInputKeys.typeByDuration,
			(typeof lifeInsurance?.typeByDuration === 'number' &&
				String(lifeInsurance?.typeByDuration)) ||
				String(InsuranceDurationEnum.Term),
		);
		setValue(
			FormInputKeys.isCashInThePolicy,
			typeof lifeInsurance?.isCashInThePolicy === 'boolean' &&
				String(lifeInsurance?.isCashInThePolicy),
		);
		setValue(
			FormInputKeys.amountCashInThePolicy,
			lifeInsurance?.amountCashInThePolicy &&
				getAmountCommasValue(lifeInsurance?.amountCashInThePolicy),
		);
		setValue(
			FormInputKeys.isLoanAgainstPolicy,
			typeof lifeInsurance?.isLoanAgainstPolicy === 'boolean' &&
				String(lifeInsurance?.isLoanAgainstPolicy),
		);
		setValue(
			FormInputKeys.amountLoanAgainstPolicy,
			lifeInsurance?.amountLoanAgainstPolicy &&
				getAmountCommasValue(lifeInsurance?.amountLoanAgainstPolicy),
		);
		setValue(FormInputKeys.policyRenewalDate, lifeInsurance?.policyRenewalDate);
		if (lifeInsurance?.policyRenewalDate) {
			const lifeInsuranceDate = parseDate(lifeInsurance?.policyRenewalDate);
			setValue(FormInputKeys.month, lifeInsuranceDate?.month);
			setValue(FormInputKeys.day, lifeInsuranceDate?.day);
			setValue(FormInputKeys.year, lifeInsuranceDate?.year);
		}
		setValue(
			FormInputKeys.amountOfCoverage,
			lifeInsurance?.amountOfCoverage &&
				getAmountCommasValue(lifeInsurance?.amountOfCoverage),
		);
		setValue(
			FormInputKeys.insuranceCompany,
			lifeInsurance?.customInsuranceCompanyName || lifeInsurance?.insuranceCompany,
		);
		setValue(
			FormInputKeys.customInsuranceCompanyName,
			lifeInsurance?.customInsuranceCompanyName,
		);
		setValue(FormInputKeys.policyNumber, lifeInsurance?.policyNumber);
		setValue(FormInputKeys.insured, lifeInsurance?.insured);
		setValue(
			FormInputKeys.policyPayer,
			(typeof lifeInsurance?.policyPayer === 'number' &&
				String(lifeInsurance?.policyPayer)) ||
				String(InsurancePolicyPayerEnum.TheInsured),
		);
		setValue(FormInputKeys.customPolicyPayer, lifeInsurance?.customPolicyPayer);
		setValue(
			FormInputKeys.paymentFrequency,
			(typeof lifeInsurance?.paymentFrequency === 'number' &&
				String(lifeInsurance?.paymentFrequency)) ||
				String(InsuranceFrequencyOfPaymentEnum.Monthly),
		);
		setValue(
			FormInputKeys.amountOfPayment,
			lifeInsurance?.amountOfPayment && getAmountCommasValue(lifeInsurance?.amountOfPayment),
		);
		setValue(
			FormInputKeys.riders,
			(Array.isArray(lifeInsurance.riders) &&
				lifeInsurance.riders.map(item => ({
					rider: item.rider,
					coverageAmount:
						item.coverageAmount !== null ? String(item.coverageAmount) : null,
				}))) ||
				[],
		);
		setValue(
			FormInputKeys.beneficiaries,
			lifeInsurance.beneficiaries.length > 0
				? lifeInsurance.beneficiaries.map(b => ({
						[FormInputKeys.beneficiaryFullName]: b.fullName,
						[FormInputKeys.beneficiaryProportion]: b.proportion,
				  }))
				: [],
		);
		setValue(FormInputKeys.trusteeName, lifeInsurance?.trusteeName);
		setValue(FormInputKeys.purposeOfPlan, lifeInsurance?.purposeOfPlan);
		setValue(
			FormInputKeys.insurancePayer,
			(typeof lifeInsurance?.insurancePayer === 'number' &&
				String(lifeInsurance?.insurancePayer)) ||
				String(InsurancePayerEnum.Employer),
		);
	}, [lifeInsurance]);

	return (
		<form className="addNewInsurance__form">
			<PFTabsContainer
				labels={['General', 'Details']}
				className="addNewInsurance__tabsContainer">
				<GeneralLifeInsuranceFormBloc lifeInsurance={lifeInsurance} formProps={formProps} />
				<DetailsLifeInsuranceFormBloc lifeInsurance={lifeInsurance} formProps={formProps} />
			</PFTabsContainer>
			<div className="d-flex justify-content-end">
				<PFButton
					onClick={goBack}
					className="addNewInsurance__form-button"
					variant="secondary"
					type="button">
					Cancel
				</PFButton>
				<PFButton
					disabled={!formState.isValid || isSubmitting}
					className="addNewInsurance__form-button addNewInsurance__form-button--submit"
					type="button"
					onClick={handleSubmit(onSubmit)}>
					Save
				</PFButton>
			</div>
		</form>
	);
};

export default EditLifeInsuranceForm;
