import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import PFButton from 'shared/components/PF-Button';
import PFCopyright from 'shared/components/PF-Copyright';

import iconBack from '../../assets/icon-arrow-back.svg';

import './style.scss';

type PropsTypes = {
	title: string;
	goBack: () => void;
	children?: React.ReactNode;
};

const ProfileFormContainer: FC<PropsTypes> = ({title, goBack, children}) => {
	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;

	return (
		<div className="pf-profileForm">
			<div className="pf-profileForm__container">
				<div className="pf-profileForm__wrapper">
					<div className="pf-profileForm__head">
						<PFButton
							className="goBack-button"
							prefixIcon={backIcon}
							variant="plain"
							onClick={goBack}
							type="button">
							Back
						</PFButton>
					</div>
					<h3 className="pf-profileForm__title">{title}</h3>
					{children}
				</div>
			</div>
			<PFCopyright />
		</div>
	);
};

export default ProfileFormContainer;
