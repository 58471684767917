import React, {FC, useEffect, useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {notificationsService} from 'api/services';
import classNames from 'classnames';
import moment from 'moment';
import PFAvatar from 'shared/components/PF-Avatar';
import {AdvisorNotificationEnum, NotificationType} from 'types';
import {useErrorHelper} from 'utils';

import urlPaths from '../../../../api/url-paths';
import closeIcon from '../../../../assets/icon-close.svg';
import {notificationItemProps} from '../constants';

import {ScheduleMeetingAction} from './components';

import './style.scss';

type PropsTypes = {
	data: NotificationType;
	onCloseNotificationCenter?: () => void;
	updateNotificationsCount?: () => void;
	className?: string;
};

const NotificationItem: FC<PropsTypes> = ({
	data,
	onCloseNotificationCenter,
	updateNotificationsCount,
	className,
}) => {
	const navigate: NavigateFunction = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const [isActive, setIsActive] = useState<boolean>(false);

	const [btnName, setBtnName] = useState<string>('');

	const onMarkAsRead = async token => {
		try {
			await notificationsService.markIsActionNotifications([token]);
			updateNotificationsCount?.();
			setIsActive(true);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	useEffect(() => {
		const btnNameObj =
			notificationItemProps[`${data.groupType}`][`${data.type}`]?.actionButtonName;

		const getBtnName = async () => {
			const btnNameResult =
				typeof btnNameObj === 'function'
					? await btnNameObj(data.userTokenFrom)
					: btnNameObj || '';

			setBtnName(btnNameResult);
		};

		getBtnName();
	}, [data.groupType, data.type]);

	return (
		<div
			className={classNames(`notifications-item ${className || ''}`, {
				inactive: isActive,
				newItem: data.isNew,
			})}>
			<div className="notifications-item__avatar-wrapper">
				<PFAvatar
					src={
						(data?.userImageFrom &&
							`${urlPaths.BASE_IMAGES_URL}${data.userImageFrom}`) ||
						notificationItemProps[`${data.groupType}`][`${data.type}`]?.avatar ||
						''
					}
					size={50}
					className="notifications-item__avatar-wrapper__avatarComponent"
					avatarStatusIcon={
						notificationItemProps[`${data.groupType}`][`${data.type}`]?.icon || null
					}
				/>
				<ReactSVG
					src={notificationItemProps[`${data.groupType}`][`${data.type}`]?.icon}
					className="notifications-item__avatar-wrapper__onlyIcon"
				/>
			</div>
			<div className="notifications-item__body">
				{data.title && <div className="notifications-item__body-title">{data.title}</div>}
				{data.createdAt && (
					<div
						className={classNames('notifications-item__body-amountTime', {
							newItem: data.isNew,
						})}>
						{moment.utc(data.createdAt).local().fromNow()}
					</div>
				)}
				{data.text && (
					<div className="notifications-item__body-description">{data.text}</div>
				)}
				{data.type === AdvisorNotificationEnum.PotentialAdvisorClientMeetingRequest ? (
					<ScheduleMeetingAction
						notification={data}
						closeNotificationCenter={onCloseNotificationCenter}
					/>
				) : (
					notificationItemProps[`${data.groupType}`][`${data.type}`]?.isAction &&
					!!btnName && (
						<button
							type="button"
							onClick={() => {
								notificationItemProps[`${data.groupType}`][`${data.type}`]?.action(
									navigate,
									data.userTokenFrom,
									() => {
										onCloseNotificationCenter?.();
									},
								);
							}}
							className={classNames('notifications-item__body-actionButton', {
								newItem: data.isNew,
							})}>
							{btnName}
						</button>
					)
				)}
			</div>
			{!isActive && data.isActive && (
				<div className="notifications-item__action-wrapper">
					<button
						className="notifications-item__action-wrapper__closeActionButton"
						type="button"
						onClick={() => onMarkAsRead(data.token)}>
						<ReactSVG
							src={closeIcon}
							className={classNames(
								'notifications-item__action-wrapper__closeActionButton__icon',
								{
									newItem: data.isNew,
								},
							)}
						/>
					</button>
				</div>
			)}
		</div>
	);
};

export default NotificationItem;
