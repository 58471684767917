import classNames from 'classnames';
import moment from 'moment';
import {formatNumberThousandsWithDelimiter} from './formatNumberThousandsWithDelimiter';
import {
	DEFAULT_PAGE,
	DEFAULT_PREDICATE,
	DEFAULT_REVERSE,
	DEFAULT_SEARCH_TEXT,
	DEFAULT_SKIP,
	DEFAULT_TAKE,
} from '../const';
import convertToNumberOrNull from './convertToNumberOrNull';
import {
	findPointOnCircle,
	generateCopyToClipboardHandler,
	generateValidator,
	getAmountCommasValue,
	getAmountInsuranceValue,
	getCamelCaseToText,
	getDefaultAvatar,
	getFullName,
	getOpportunityIcon,
	getOptionsByEnum,
	getSpecializationName,
	getUTSDate,
	hints,
	isSocialLinks,
	matchYouTubeLink,
	parseDate,
	parseDate1,
	Storage,
	useErrorHelper,
	validations,
	isCompletedProfile,
} from './futureTS';
import getCroppedImg, {getPhotoCompressionRatio, generateBlobImage} from './image/cropImage';
import imageCompressor from './imageCompressor';
import momentUpdate from './moment';
import {findByToken, findIndexByToken} from './table';

export {ToastDispatcher} from './ToastDispatcher';
export * from './checkExtendedProfileView';

export {
	formatNumberThousandsWithDelimiter,
	findByToken,
	findIndexByToken,
	momentUpdate,
	imageCompressor,
	getCroppedImg,
	getPhotoCompressionRatio,
	generateBlobImage,
	convertToNumberOrNull,
	findPointOnCircle,
	generateCopyToClipboardHandler,
	generateValidator,
	getAmountCommasValue,
	getAmountInsuranceValue,
	getCamelCaseToText,
	getDefaultAvatar,
	getFullName,
	getOpportunityIcon,
	getOptionsByEnum,
	getSpecializationName,
	getUTSDate,
	hints,
	isSocialLinks,
	matchYouTubeLink,
	parseDate,
	parseDate1,
	Storage,
	useErrorHelper,
	validations,
	isCompletedProfile,
};

export const getVariant = (baseClassName, ...variants) =>
	variants.map(variant => `${baseClassName}--${variant}`).join(' ');

/**  We use this function to get a className
 *
 * @param {string} elementClassName  First argument is the parent class name
 * @param {string} variant second argument is the name of the variant
 * @param {string | object} extraClassName third argument is any extra class names we might want to add like (margin-top, display block, etc)
 * @returns a string with all three parts of the final className appended together.
 */

export const getClassNamesWithVariant = (elementClassName, variant, extraClassName) =>
	classNames(elementClassName, getVariant(elementClassName, variant), extraClassName);

/**
 *
 * @param  {...string} names first name middle name and last name if they have
 * @returns
 */
export const joinName = (...names) => names.join(' ');

export const renderLocation = location => {
	if (location) {
		return [location?.name, location?.state?.name].filter(Boolean).join(', ');
	}
	return null;
};

export const isPhoneEmpty = value => {
	if (value?.value === '' || value?.value === '+' || value?.value === '+' + value?.dialCode) {
		return true;
	}
	return false;
};

export const buildURLParams = (page, itemsCount, predicate, reverse, searchText) => {
	const buildPage = page;
	const buildItemsCount = itemsCount;
	const buildPredicate = predicate ? predicate : '';
	const buildReverse = reverse === true || reverse === false ? reverse : false;
	const buildSearchText = searchText ? searchText : '';
	return `page=${buildPage}&itemsCount=${buildItemsCount}&predicate=${buildPredicate}&reverse=${buildReverse}&searchText=${buildSearchText}`;
};

export const updateURL = (page, itemsCount, predicate, reverse, searchText) => {
	if (window.history.pushState) {
		let baseUrl =
			window.location.protocol + '//' + window.location.host + window.location.pathname;
		let hash = window.location.hash;
		let newUrl =
			baseUrl +
			'?' +
			buildURLParams(page, itemsCount, predicate, reverse, searchText) +
			`${hash ? hash : ''}`;
		window.history.pushState(null, null, newUrl);
	} else {
		console.warn('History API не поддерживается');
	}
};

export const parseUrlQuery = (location, callback) => {
	const data = {};

	if (location.search) {
		const pair = location.search.substr(1).split('&');

		for (let i = 0; i < pair.length; i++) {
			const param = pair[i].split('=');
			data[param[0]] = decodeURIComponent(param[1]);
		}
	}

	if (callback) {
		return callback(data);
	}

	return data;
};

export const buildDefaultURLParams = params => {
	return {
		page: !!params.searchText
			? DEFAULT_PAGE
			: isNaN(Number(params.page))
			? DEFAULT_PAGE
			: Number(params.page),
		itemsCount: isNaN(Number(params.itemsCount)) ? DEFAULT_TAKE : Number(params.itemsCount),
		predicate: !!params.predicate ? params.predicate : DEFAULT_PREDICATE,
		reverse:
			!!params.reverse && (params.reverse === 'true' || params.reverse === 'false')
				? params.reverse
				: DEFAULT_REVERSE,
		searchText: !!params.searchText ? params.searchText : DEFAULT_SEARCH_TEXT,
	};
};

export const mapQueryParams = params => {
	if (Object.keys(params).length === 0) {
		return {
			skip: DEFAULT_SKIP,
			take: DEFAULT_TAKE,
			predicate: DEFAULT_PREDICATE,
			reverse: DEFAULT_REVERSE,
			searchText: DEFAULT_SEARCH_TEXT,
		};
	} else {
		const {page, itemsCount, predicate, reverse, searchText} = buildDefaultURLParams(params);
		return {
			skip: (page - 1) * itemsCount,
			take: itemsCount,
			predicate,
			reverse,
			searchText,
		};
	}
};

export const isSuitableRole = function suitableRole(routeRole, userRole) {
	// ! new
	const newUserRole = [...userRole];
	const res = routeRole.includes(newUserRole.pop());
	if (!res && !!newUserRole.length) {
		return suitableRole(routeRole, newUserRole);
	} else return res;
};

export const getDays = daysNum => {
	const days = [];

	for (let i = 1; i <= daysNum; i += 1) {
		days.push({value: i, label: `${i}`});
	}

	return days;
};

export const getYears = (yearsNum, shouldReverse = true) => {
	const years = [];
	const dateStart = moment().subtract(yearsNum, 'y');
	const dateEnd = moment().add(100, 'y');

	while (dateEnd.diff(dateStart, 'years') >= 0) {
		years.push({value: parseInt(dateStart.format('YYYY')), label: dateStart.format('YYYY')});
		dateStart.add(1, 'year');
	}

	if (shouldReverse) {
		years.reverse();
	}

	return years;
};

export * from './Amplitude';
