import React, {useEffect, useState} from 'react';
import CompleteProfilePage from '../CompleteProfile';
import SearchEmptyPage from '../EmptySearch';
import AdvisorEmptyPage from '../Advisor';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from '../../../store';
import {myProfileSelectors, specFilterSelectors, tableSelectors} from '../../../store/selectors';
import {isCompletedProfile} from '../../../utils';

const EmptyLayout = ({emptyPageParam, searchEmptyParam}) => {
	const navigate = useNavigate();
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const searchValue = useAppSelector(tableSelectors.selectTableSearchData);
	const [layout, setLayout] = useState(null);
	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	const checkedSpecializationsKeys = useAppSelector(
		specFilterSelectors.selectSpecFilterCheckedKeys,
	);

	const completeProfilePageParam = {
		className: '',
		title: 'Complete Your Profile',
		subtitle:
			'Mobile app user’s can’t see you in the marketplace until you complete your profile. Click the button below to do this:',
		button: {
			text: 'Complete Your Profile',
			variant: 'primary',
			onClick: () => navigate(PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT),
		},
	};

	const getLayout = advisor => {
		if (!isCompletedProfile(advisor)) {
			return <CompleteProfilePage {...completeProfilePageParam} />;
		} else {
			if (searchValue) {
				return emptyPageParam && <SearchEmptyPage {...searchEmptyParam} />;
			} else if (checkedSpecializationsKeys.length) {
				return emptyPageParam && <SearchEmptyPage {...emptyPageParam} />;
			} else {
				return emptyPageParam && <AdvisorEmptyPage {...emptyPageParam} />;
			}
		}
	};

	useEffect(() => {
		if (profile && profile.advisor) {
			const layout = getLayout(profile.advisor);
			setLayout(layout);
		}
	}, [profile]);

	return <>{layout && layout}</>;
};

export default EmptyLayout;
