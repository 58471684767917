enum AdvisorNotificationEnum {
	ToDoIsDeleted = 1,
	ToDoIsCompleted = 2,
	ToDoIsRestored = 3,
	ToDoIsDismissed = 4,

	OpportunityIsDismissed = 5,
	OpportunityIsActioned = 6,
	OpportunityIsRestored = 7,
	OpportunityIsDismissedByOtherAdvisor = 8,
	OpportunityIsActionedByOtherAdvisor = 9,
	OpportunityIsRestoredByOtherAdvisor = 10,
	OpportunityLargeCashDeposit = 11,
	OpportunityChildTaxDeposit = 12,
	OpportunityNewAccountConnected = 13,

	InsuranceInfoRequest = 14,

	ClientIsDeleted = 15,

	AdvisorIsActive = 16,
	AdvisorIsInactive = 17,
	AdvisorChangeEmail = 18,

	AdvisorClientAddRole = 19,
	AdvisorClientRemoveRole = 20,
	AdvisorClientFinReportReceived = 21,
	AdvisorClientMeetingRequest = 22,
	ReferringToBusiness = 23,

	CompanyIsDeleted = 24,

	PotentialAdvisorClientMeetingRequest = 25,
}

enum AdvisorNotificationGroupEnum {
	SuperAdminActivity = 1,
	Client = 2,
	Advisor = 3,
	Opportunity = 4,
	Insurance = 5,
	ToDo = 6,
	AdvisorClient = 7,
}

export {AdvisorNotificationEnum, AdvisorNotificationGroupEnum};
