import {COMPANY_OPTIONS} from 'const';
import {InsuranceCompanyEnum} from 'types/enums/insurance';

export default function getCompanyName(
	predefinedCompanyValue: InsuranceCompanyEnum | null,
	customCompanyName: string | null,
): string | null {
	if (predefinedCompanyValue) {
		const companyOption = COMPANY_OPTIONS.find(
			option => option.value === predefinedCompanyValue,
		);

		if (companyOption) {
			return companyOption.label;
		}
	}

	return customCompanyName || null;
}
