import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import upgradePlanBadgeIcon from 'assets/header/upgrade-plan-badge-icon.svg';
import {PORTAL_PATHS} from 'const';
import {PFButton, PFPopup} from 'shared/components';
import {Amplitude} from 'utils';

import styles from './UpgradePlanModal.style.module.scss';

export const UpgradePlanModal: React.FC<{
	visible: boolean;
	numberOfLeadsMore: number;
	onCancel: () => void;
}> = ({visible, numberOfLeadsMore, onCancel}) => {
	const navigate = useNavigate();

	const handleCancel = () => {
		onCancel();
	};

	const handleConfirm = async () => {
		Amplitude.track('Opened_Upgrade', {
			source: 'Leads',
		});

		navigate(PORTAL_PATHS.OTHER_PATHS.MANAGE_SUBSCRIPTION);
	};

	return (
		<PFPopup
			isShow={visible}
			handleClose={handleCancel}
			isShowFooter={false}
			isCloseButton
			title={null}
			onSubmit={() => false}
			className={{
				paper: styles.paper,
			}}>
			<div className={styles.title}>You Have {numberOfLeadsMore} More Leads</div>
			<div className={styles.description}>
				Only 50 leads are shown in your Leads list. Upgrade to Unlimited Access Plan to see
				all the others.
			</div>

			<PFButton
				onClick={handleConfirm}
				type="button"
				variant="primary"
				hasArrow
				prefixIcon={
					<ReactSVG
						wrapper="span"
						className="pf-icon-accept"
						src={upgradePlanBadgeIcon}
					/>
				}>
				Upgrade Now
			</PFButton>
		</PFPopup>
	);
};
