export const LARGE_SCREEN_SIZE = 992; //992 is the a conventional design standard for the division between large and small screens
export const MOBILE_SCREEN_SIZE = 466;
export const BIG_MOBILE_SCREEN_SIZE = 569;

//UI const
export const HEADER_HEIGHT = 60;
export const MOBILE_HEADER_HEIGHT = 50;
export const PAGINATION_BAR_HEIGHT = 60;

export const DEFAULT_TAKE = 25;
export const DEFAULT_SKIP = 0;
export const DEFAULT_PAGE = 1;
export const DEFAULT_DISPLAY_LENGTH = 25;
export const DEFAULT_PREDICATE = '';
export const DEFAULT_REVERSE = false;
export const DEFAULT_AVATAR =
	"data:image/svg+xml;charset=UTF-8,%3csvg width='140' height='140' viewBox='0 0 140 140' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='70' cy='70' r='70' fill='%23EEEFF1'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.1797 102.202C34.3137 87.806 51.0603 78.4 70.0037 78.4C88.945 78.4 105.69 87.8039 115.824 102.198C105.69 116.594 88.9437 126 70.0003 126C51.059 126 34.3141 116.596 24.1797 102.202Z' fill='white'/%3e%3ccircle cx='70.0023' cy='47.6' r='19.6' fill='white'/%3e%3c/svg%3e";
export const DEFAULT_AVATAR_BLUE =
	"data:image/svg+xml;charset=UTF-8,%3csvg width='140' height='140' viewBox='0 0 140 140' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='70' cy='70' r='70' fill='%23C9DBFF'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.1797 102.202C34.3137 87.806 51.0603 78.4 70.0037 78.4C88.945 78.4 105.69 87.8039 115.824 102.198C105.69 116.594 88.9437 126 70.0003 126C51.059 126 34.3141 116.596 24.1797 102.202Z' fill='white'/%3e%3ccircle cx='70.0023' cy='47.6' r='19.6' fill='white'/%3e%3c/svg%3e";
export const DEFAULT_COMPANY_AVATAR =
	"data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 35H37.5L28.125 22.5L20.625 32.5L15 25L7.5 35ZM3 45C1.34315 45 0 43.6569 0 42V3C0 1.34315 1.34315 0 3 0H42C43.6569 0 45 1.34315 45 3V42C45 43.6569 43.6569 45 42 45H3Z' fill='%23EEEFF1'/%3E%3C/svg%3E";
export const DEFAULT_SEARCH_TEXT = '';

export const NULL_TOKEN = '00000000-0000-0000-0000-000000000000';
