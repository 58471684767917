import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import urlPaths from 'api/url-paths';
import classNames from 'classnames';
import {DEFAULT_COMPANY_AVATAR} from 'const';
import {AdvisorsRole} from 'types/enums';
import {renderLocation, useErrorHelper} from 'utils';

import iconEdit from '../../../assets/buttons/icon-pencil.svg';
import iconCompany from '../../../assets/icon-company.svg';
import iconEmail from '../../../assets/icon-email.svg';
import iconLocation from '../../../assets/icon-location.svg';
import iconPhone from '../../../assets/icon-phone-without-circle.svg';
import {useAppSelector} from '../../../store';
import {myProfileSelectors} from '../../../store/selectors';
import {selectMyRole} from '../../../store/selectors/myProfile';
import {GetCurrentPage} from '../../../store/selectors/UISetings';
import {useEditEmailPopup, useUpdateUserEmail} from '../../hooks';
import PFAvatar from '../PF-Avatar';
import PFButton from '../PF-Button';

import './style.scss';

type PropsTypes = {
	location?: {name: string | null; state?: {name: string | null} | null} | null;
	email?: string | null;
	phone?: string | null;
	streetAddress?: string | null;
	postalCode?: string | null;
	updateDataForUseOnModal?: () => void | null;
	token?: string | null;
	companyName?: string | null;
	companyImage?: string | null | undefined;
	className?: string | null;
	isEmailConfirmed?: boolean | undefined;
};

const PFContactsBlock: FC<PropsTypes> = ({
	location,
	email,
	phone,
	streetAddress,
	postalCode,
	updateDataForUseOnModal,
	token,
	companyName,
	companyImage,
	className,
	isEmailConfirmed,
}) => {
	const accountRole = useAppSelector(myProfileSelectors.selectMyRole);
	const currentPage = GetCurrentPage();
	const editIcon = <ReactSVG wrapper="span" className="pf-edit-email" src={iconEdit} />;
	const {showEditEmailConfirm, ConfirmEditEmailModal} = useEditEmailPopup();
	const updateUserEmail = useUpdateUserEmail();
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const onSubmit = async (mail = email) => {
		try {
			const body = {
				email: mail,
				token,
			};
			await updateUserEmail(body, updateDataForUseOnModal);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};
	return (
		<div
			className={classNames('pf-contacts', {
				[`${className}`]: !!className,
			})}>
			{companyName && (
				<div className="pf-companyName pf-contacts__item">
					{companyImage ? (
						<PFAvatar
							src={`${urlPaths.BASE_IMAGES_URL}${companyImage}`}
							size={20}
							defaultAvatar={DEFAULT_COMPANY_AVATAR}
							variant="rounded-square"
							className="pf-contacts__companyLogo"
						/>
					) : (
						<ReactSVG wrapper="div" className="pf-contacts__icon" src={iconCompany} />
					)}
					<span>{`${companyName}`}</span>
				</div>
			)}
			{(location?.name || location?.state?.name) && (
				<div className="pf-contacts__item">
					<ReactSVG wrapper="div" className="pf-contacts__icon" src={iconLocation} />
					<span>{renderLocation(location)}</span>
				</div>
			)}
			{streetAddress && (
				<div className="pf-contacts__streetAddress pf-contacts__item">
					<span>{streetAddress}</span>
				</div>
			)}
			{postalCode && (
				<div className="pf-contacts__postalCode pf-contacts__item">
					<span>{postalCode}</span>
				</div>
			)}
			{email && (
				<div className="pf-contacts__email pf-contacts__item">
					<div className="emailWrapper">
						<ReactSVG wrapper="div" className="pf-contacts__icon" src={iconEmail} />
						<a href={`mailto:${email}`}>{email}</a>
						{currentPage === 'Consumers' && (
							<PFButton
								type="button"
								className="w-auto h-auto p-0"
								prefixIcon={editIcon}
								variant="plain"
								onClick={showEditEmailConfirm}
							/>
						)}
					</div>
					{accountRole === AdvisorsRole.SuperAdmin &&
						typeof isEmailConfirmed === 'boolean' &&
						!isEmailConfirmed && <p className="unconfirmedEmail">Unconfirmed</p>}
				</div>
			)}
			{phone && (
				<div className="pf-contacts__item">
					<ReactSVG wrapper="div" className="pf-contacts__icon" src={iconPhone} />
					<span>{phone}</span>
				</div>
			)}
			{currentPage === 'Consumers' && (
				<ConfirmEditEmailModal
					className="pf-edit_user_email"
					onSubmit={onSubmit}
					email={email}
					modalTitle="Edit user email"
				/>
			)}
		</div>
	);
};

export default PFContactsBlock;
