import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export type GetNotificationsParamsType = {
	skip: number;
	take: number;
	isNew?: boolean;
	isActive: boolean;
	isDeleted?: boolean;
	predicate?: 'groupTypes' | 'types' | 'isActive' | 'createdAt';
	reverse?: boolean;
	groupTypes?: number;
	types?: number;
	tokenFrom?: string | null;
};

export enum NotificationReturnStateEnum {
	ReturnIsNew = 1,
	ReturnIsRead = 2,
	ReturnAll = 3,
}

export const getNotifications = (params: GetNotificationsParamsType) =>
	httpClient.get(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}/search`, {params});

export const getAllNotifications = (state: NotificationReturnStateEnum) =>
	httpClient.get(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}/?returnState=${state}`);

export const getCountIsNewNotifications = () =>
	httpClient.get(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}/countIsNew`);

export const resetIsNewNotification = (body: string[]) =>
	httpClient.post(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}/resetIsNew`, body);

export const markIsUnreadNotification = (body: string[]) =>
	httpClient.post(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}/markIsUnread`, body);

export const markIsActionNotifications = (body: string[]) =>
	httpClient.post(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}/markIsAction`, body);

export const resetIsActionNotifications = (body: string[]) =>
	httpClient.post(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}/resetIsAction`, body);

export const deleteNotificationByToken = (body: string[]) =>
	httpClient.delete(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}`, {data: body});

export const restoreIsDeletedNotification = (body: string[]) =>
	httpClient.post(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}/restore`, body);

export const deleteAllNotification = () =>
	httpClient.delete(`${URL.BASE_URL}/${URL.NOTIFICATIONS.INDEX}/deleteRead`);
