import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import {PORTAL_PATHS, PUBLIC_PATHS} from 'const';
import PFButton from 'shared/components/PF-Button';
import PFLogoLink from 'shared/components/PF-LogoLink';

import './style.scss';

type PropsTypes = {};

const InvitationHeader: FC<PropsTypes> = () => {
	const navigate = useNavigate();
	return (
		<header className="invitationHeader">
			<PFLogoLink to={PORTAL_PATHS.PORTAL_BASE_URL} settingClasses="invitationHeader-logo" />
			<div className="buttonsContainer">
				<div className="text">Are You an Advisor?</div>
				<PFButton
					className="signUpButton"
					onClick={() => navigate(PUBLIC_PATHS.SIGN_UP)}
					variant="primary"
					type="button">
					Sign Up
				</PFButton>
				<PFButton
					className="signInButton"
					onClick={() => navigate(PUBLIC_PATHS.SIGN_IN)}
					variant="secondary"
					type="button">
					Sign In
				</PFButton>
			</div>
		</header>
	);
};

export default InvitationHeader;
