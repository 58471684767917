import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {usersService} from 'api/services';
import {PFButton, PFPopup} from 'shared/components';
import {useSignOut} from 'shared/hooks';
import {Amplitude, ToastDispatcher} from 'utils';

import FullNameCheckInput from './FullNameCheckInput';

import styles from './delete-account-modal.style.module.scss';

const DeleteAccountModal: React.FC<{
	visible: boolean;
	userToken: string;
	fullName: string;
	onCancel: () => void;
}> = ({visible, userToken, fullName, onCancel}) => {
	const navigate = useNavigate();
	const {signOut} = useSignOut(navigate);

	const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleFullNameMatch = (match: boolean) => {
		setIsConfirmDisabled(!match);
	};

	const handleCancel = () => {
		setIsConfirmDisabled(true);
		onCancel();
	};

	const handleConfirm = async () => {
		try {
			setIsSubmitting(true);

			await usersService.deleteAccount({userToken});

			Amplitude.track('Deleted_Account');

			ToastDispatcher.success('Your account has been permanently deleted');
			setIsSubmitting(false);
			handleCancel();

			Amplitude.track('Signed_Out');
			Amplitude.reset();

			signOut();
		} catch (err: any) {
			setIsSubmitting(false);
			ToastDispatcher.error('Failed to delete account. Please try again');
		}
	};

	return (
		<PFPopup
			isShow={visible}
			handleClose={handleCancel}
			isShowFooter={false}
			isCloseButton
			title={null}
			onSubmit={() => false}>
			<div className={styles.title}>Delete Your Account?</div>
			<div className={`${styles.description} ${styles.warning}`}>
				You won’t be able to restore your account.
			</div>
			<div className={`${styles.description}`}>To continue, please enter your full name:</div>

			<FullNameCheckInput fullName={fullName} onMatch={handleFullNameMatch} />

			<div className="d-flex justify-content-center">
				<div className="d-flex w-100 m-auto pf-modal__footer-inner">
					<PFButton
						disabled={isConfirmDisabled || isSubmitting}
						onClick={handleConfirm}
						type="button"
						variant="alert"
						className="pf-profileEdit__form-button pf-profileEdit__form-button--submit pf-edit-email-submit">
						Delete
					</PFButton>
					<PFButton onClick={handleCancel} variant="secondary" type="button">
						Cancel
					</PFButton>
				</div>
			</div>
		</PFPopup>
	);
};

export default DeleteAccountModal;
