import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserProfileDataType} from 'types';

interface UserProfileState {
	userProfileData: UserProfileDataType | null;
}

type SetUserProfileDataActionType = UserProfileDataType;

type SetClientsTagsActionType = {
	userToken: string;
	tags: string[];
};

const initialState: UserProfileState = {
	userProfileData: null,
};

export const userProfileSlice = createSlice({
	name: 'userProfile',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setUserProfileData: (state, action: PayloadAction<SetUserProfileDataActionType>) => {
			state.userProfileData = action.payload;
		},
		cleanUpUserProfileData: state => {
			state.userProfileData = initialState.userProfileData;
		},
		setUserProfileTags: (state, action: PayloadAction<SetClientsTagsActionType>) => {
			if (
				state.userProfileData &&
				state.userProfileData.user.token === action.payload.userToken &&
				state.userProfileData?.customClientTags
			) {
				state.userProfileData.customClientTags = action.payload.tags;
			}
		},
	},
});

export default userProfileSlice.reducer;
