import React from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import '../PF-Input/style.scss';

//This component is for input fields where we want to strictly control how/what the user enters. (Such as phone numbers)
const PFMaskInput = React.forwardRef(
	({className, type, label, labelSufix, showAsterisk, icon, error, hint, ...props}, ref) => {
		return (
			<div
				className={classNames(
					'pf-input pf-input--mask',
					{
						'pf-input--error': error,
					},
					className,
				)}>
				<div className="pf-input__label-wrapper">
					<label className="pf-input__label">
						{showAsterisk && <span className="pf-input__label-asterisk">*</span>}
						{label}
					</label>
					{labelSufix}
				</div>
				<div className="pf-input__field-wrapper">
					<InputMask ref={ref} className="pf-input__field" type={type} {...props} />
					{icon && <span className="pf-input__field-icon">{icon}</span>}
				</div>
				<p
					className={classNames('pf-input__hint', {
						'pf-input__hint--error': error,
					})}>
					{error || hint}
				</p>
			</div>
		);
	},
);

PFMaskInput.defaultProps = {
	type: 'text',
	maskChar: ' ',
};
export default PFMaskInput;
