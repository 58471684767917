import React, {FC} from 'react';

import {closeSnackbar, SnackbarKey} from 'notistack';

import styles from './styles.module.scss';

type PropsTypes = {
	id: SnackbarKey;
	buttonText: string;
	onClickAction: () => void;
};

const SnackActionButton: FC<PropsTypes> = ({id, buttonText, onClickAction}) => {
	return (
		<button
			className={styles.undoActionButton}
			type="button"
			onClick={() => {
				onClickAction();
				closeSnackbar(id);
			}}>
			{buttonText}
		</button>
	);
};

export default SnackActionButton;
