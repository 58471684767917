import {SetTodoType, UpdateTodoType} from 'types';

import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

export const getTodoByToken = (userToken: string) =>
	httpClient.get(`${URL.BASE_URL}/${URL.TODO.INDEX}?userToken=${userToken}`);

export const setTodo = (body: SetTodoType) =>
	httpClient.post(`${URL.BASE_URL}/${URL.TODO.INDEX}`, body);

export const updateTodo = (body: UpdateTodoType) =>
	httpClient.put(`${URL.BASE_URL}/${URL.TODO.INDEX}`, body);

export const deleteTodoByToken = (todoToken: string) =>
	httpClient.delete(`${URL.BASE_URL}/${URL.TODO.INDEX}?token=${todoToken}`);

export const setCompleteTodo = (todoToken: string) =>
	httpClient.post(`${URL.BASE_URL}/${URL.TODO.COMPLETE}?token=${todoToken}`);

export const setDismissTodo = (todoToken: string) =>
	httpClient.post(`${URL.BASE_URL}/${URL.TODO.DISMISS}?token=${todoToken}`);

export const setResetToCurrentTodo = (todoToken: string) =>
	httpClient.post(`${URL.BASE_URL}/${URL.TODO.DISMISS}?token=${todoToken}`);
