import {PORTAL_PATHS} from 'const';
import React, {useEffect, useState} from 'react';
import {PFCopyright, PFLoader, PFLogoLink} from 'shared/components';

import styles from './styles.module.scss';
import {Content} from './Content';
import {advisorService} from 'api/services';

const SubscriptionStatus: React.FC = () => {
	const [isAuth, setIsAuth] = useState<boolean | null>(null);

	useEffect(() => {
		const fetch = async () => {
			try {
				await advisorService.getAdvisor();

				setIsAuth(true);
			} catch (err) {
				setIsAuth(false);
			}
		};

		fetch();
	}, []);
	return (
		<div className={styles.container}>
			{isAuth !== null ? (
				<>
					<header className={styles.header}>
						<PFLogoLink to={PORTAL_PATHS.PORTAL_BASE_URL} />
					</header>

					<Content isAuth={isAuth} />
					<PFCopyright />
				</>
			) : (
				<PFLoader />
			)}
		</div>
	);
};

export {SubscriptionStatus};
