import React from 'react';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {ReactSVG} from 'react-svg';

import {iconDots} from 'assets';
import {getAmountCommasValue} from 'utils';

import './InsuranceInfoHeader.scss';
import styles from './InsuranceInfoHeader.module.scss';

type Props = {
	title: string;
	icon: React.ReactNode;
	company: string | null;
	amount: number | null;
	date: Date | string | null;
	actions?: {
		name: string;
		handler: () => void;
	}[];
};

const dateFormatter = new Intl.DateTimeFormat('en-US', {
	month: 'short',
	day: '2-digit',
	year: 'numeric',
});

const InsuranceInfoHeader: React.FC<Props> = ({title, icon, company, amount, date, actions}) => {
	const dateView =
		// eslint-disable-next-line no-nested-ternary
		date === null ? (
			'n/a'
		) : typeof date === 'string' ? (
			<span className={styles.dateValue}>{date}</span>
		) : (
			<>
				Until
				<span className={styles.dateValue}>{dateFormatter.format(date)}</span>
			</>
		);

	return (
		<div className={styles.container}>
			<div className={styles.icon}>{icon}</div>
			<div className={styles.infoContainer}>
				<div>
					<div className={styles.title}>{title}</div>
					<div className={styles.company}>{company === null ? 'n/a' : company}</div>
				</div>
				<div>
					<div className={styles.amount}>
						{amount === null ? 'n/a' : getAmountCommasValue(amount.toFixed(0))}
					</div>
					<div className={styles.date}>{dateView}</div>
				</div>
			</div>
			{actions !== undefined && actions?.length > 0 && (
				<div className={styles.actions}>
					<DropdownButton
						align="end"
						title={<ReactSVG wrapper="div" src={iconDots} />}
						id="bg-nested-dropdown"
						className="insurance-dropdown-button">
						{actions.map((action, index) => (
							<Dropdown.Item
								onClick={action.handler}
								eventKey={`${index + 1}`}
								key={action.name}>
								<div>{action.name}</div>
							</Dropdown.Item>
						))}
					</DropdownButton>
				</div>
			)}
		</div>
	);
};

export default InsuranceInfoHeader;
