import React, {FC, ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {PORTAL_PATHS} from 'const';
import {PFButton} from 'shared/components';
import {Amplitude} from 'utils';

import iconBack from '../../../assets/icon-arrow-back.svg';

import './style.scss';

type PropsTypes = {
	userToken: string;
	title: string;
	children: ReactNode;
};

const ViewAll: FC<PropsTypes> = ({userToken, title, children}) => {
	const navigate = useNavigate();
	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;

	const goBack = () => {
		Amplitude.track('Opened_Client_Profile', {
			source: title,
		});
		navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
	};

	return (
		<div className="view-all">
			<div className="view-all__container">
				<PFButton
					className="w-auto px-0 view-all__container-backButton"
					prefixIcon={backIcon}
					variant="plain"
					onClick={goBack}
					type="button">
					Back
				</PFButton>
				<div className="view-all__container-title">{title}</div>
				{children}
			</div>
		</div>
	);
};

export default ViewAll;
