import React from 'react';

import PFCompanyDescription from 'shared/components/PF-CompanyDescription';
import PFContactsBlock from 'shared/components/PF-ContactsBlock';
import {useAppSelector} from 'store';
import {tableSelectors} from 'store/selectors';

import AdditionalCompaniesInfo from './AdditionalCompaniesInfo';

const CompaniesModal = ({updateDataForUseOnModal}) => {
	const rowData = useAppSelector(tableSelectors.selectSelectedTableRow);
	return (
		<>
			<PFCompanyDescription name={rowData.name} logo={rowData?.image} />
			<PFContactsBlock
				location={rowData?.location}
				streetAddress={rowData?.streetAddress}
				postalCode={rowData?.postalCode}
				email={rowData?.contactInfo}
				phone={rowData?.phoneNumber}
			/>
			<AdditionalCompaniesInfo
				token={rowData.token}
				isBlocked={rowData.isBlocked}
				administratorName={rowData?.administratorName}
				companyName={rowData?.name}
				updateDataForUseOnModal={updateDataForUseOnModal}
			/>
		</>
	);
};

export default CompaniesModal;
