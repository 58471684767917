import React, {FC} from 'react';

import PFAvatar from 'shared/components/PF-Avatar';
import PFCheckbox from 'shared/components/PF-Checkbox';
import {useAppDispatch, useAppSelector} from 'store';
import {removeRecommendCheckedKey, setRecommendCheckedKey} from 'store/actions';
import {selectRecommendPopupCheckedKeys} from 'store/selectors';
import {CurrentUser} from 'types';

import urlPaths from '../../../../../api/url-paths';
import {DEFAULT_AVATAR_BLUE} from '../../../../../const';

import style from './style.module.scss';

type VirtuosoItemPropsTypes = {
	data: CurrentUser;
};

const VirtuosoItem: FC<VirtuosoItemPropsTypes> = ({data}) => {
	const dispatch = useAppDispatch();
	const checkedKeys = useAppSelector(selectRecommendPopupCheckedKeys);

	const handleCheckboxClick = e => {
		if (e.target.checked) {
			dispatch(setRecommendCheckedKey(data.token));
		} else {
			dispatch(removeRecommendCheckedKey(data.token));
		}
	};

	return (
		<div key={data.token} className={style.virtuosoCurrentItem}>
			<PFCheckbox
				name={data.token}
				registerControl={false}
				value={data.token}
				checked={checkedKeys.includes(data.token)}
				onClick={handleCheckboxClick}
				className={style.virtuosoCurrentItem__checkbox}
				id={data.token}
			/>
			<PFAvatar
				src={
					(data?.profileImage && `${urlPaths.BASE_IMAGES_URL}${data?.profileImage}`) ||
					DEFAULT_AVATAR_BLUE
				}
				size={40}
				className={style.virtuosoCurrentItem__avatar}
			/>
			<div
				className={
					style.virtuosoCurrentItem__name
				}>{`${data.firstName} ${data.lastName}`}</div>
		</div>
	);
};

export default VirtuosoItem;
