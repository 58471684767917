import PORTAL_PATHS from '../const/url-paths/portal';
import PUBLIC_PATHS from '../const/url-paths/public';
import COMMON_PATHS from '../const/url-paths/common';

import {
	Error,
	Current,
	Leads,
	Meetings,
	MyProfile,
	MyProfileEdit,
	AddNewCompany,
	AddNewAdvisor,
	AdvisorProfile,
	Advisors,
	APIInstances,
	Companies,
	CompanyProfile,
	Consumers,
	EditCompanyData,
	EditLoginSettings,
	AdvisorProfileEdit,
	ConfirmEmail,
	Invitation,
	ResetPassword,
	RestorePassword,
	SetPassword,
	SignIn,
	SignUp,
	ChangesAPIInstance,
	UserProfile,
	ViewAllTeam,
	ViewAllReports,
	ViewAllPersonalData,
	ViewAllInsurance,
	AddNewLifeInsurance,
	EditLifeInsurance,
	AccountSettings,
	AddCriticalIllnessInsurance,
	EditCriticalIllnessInsurance,
	AddDisabilityInsurance,
	EditDisabilityInsurance,
	ViewAllOpportunities,
	ViewAllTodos,
	ViewArchiveNotifications,
	MyEcosystem,
	InviteToEcosystem,
	EcoAdvisorProfile,
	ReferredToMe,
	ManageSubscription,
	SubscriptionStatus,
	RestorePasswordByEmail,
	SetPasswordByCode,
} from '../pages';
import {AdvisorsRole} from '../types/enums';

const {
	SIDEBAR: {
		CURRENT,
		LEADS,
		MEETINGS,
		COMPANIES,
		ADVISORS,
		CONSUMERS,
		API_INSTANCES,
		MY_COMPANY,
		MY_NETWORK,
		REFERRED_TO_ME,
	},
	OTHER_PATHS: {
		MY_PROFILE,
		MY_PROFILE_EDIT,
		LOGIN_SETTINGS_EDIT,
		ADD_NEW_COMPANY,
		COMPANY_PROFILE,
		EDIT_COMPANY_DATA,
		ADD_NEW_ADVISOR,
		ADVISOR_PROFILE,
		ADVISOR_PROFILE_EDIT,
		ADD_API_INSTANCE,
		EDIT_API_INSTANCE,
		USER_PROFILE,
		VIEW_ALL_TEAM,
		VIEW_ALL_REPORTS,
		VIEW_ALL_PERSONAL_DATA,
		VIEW_ALL_INSURANCES_DATA,
		ADD_NEW_LIFE_INSURANCE,
		EDIT_LIFE_INSURANCE,
		ADD_CRITICAL_ILLNESS_INSURANCE,
		EDIT_CRITICAL_ILLNESS_INSURANCE,
		ADD_DISABILITY_INSURANCE,
		EDIT_DISABILITY_INSURANCE,
		ACCOUNT_SETTINGS,
		VIEW_ALL_OPPORTUNITIES_DATA,
		VIEW_ALL_TODO_DATA,
		VIEW_ARCHIVE_NOTIFICATIONS,
		INVITE_TO_ECOSYSTEM,
		ECO_ADVISOR_PROFILE,
		SUBSCRIPTION_STATUS,
		MANAGE_SUBSCRIPTION,
		RESTORE_PASSWORD,
		SET_PASSWORD_BY_CODE,
	},
} = PORTAL_PATHS;

const {SIGN_IN, SIGN_UP, RESET_PASSWORD, INVITATION, SET_PASSWORD} = PUBLIC_PATHS;
const {ERROR, CONFIRM_EMAIL} = COMMON_PATHS;

const ROUTES = [
	{
		path: SIGN_IN,
		element: <SignIn />,
		exact: true,
		private: false,
	},
	{
		path: SIGN_UP,
		element: <SignUp />,
		private: false,
		exact: false,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: RESET_PASSWORD,
		element: <ResetPassword />,
		exact: true,
		private: false,
	},
	{
		path: SET_PASSWORD,
		element: <SetPassword />,
		exact: true,
		private: false,
	},
	{
		path: INVITATION,
		element: <Invitation />,
		exact: true,
		private: false,
		role: [
			AdvisorsRole.Advisor,
			AdvisorsRole.SuperAdmin,
			AdvisorsRole.AccountAdministrator,
			AdvisorsRole.Administrator,
		],
	},
	{
		path: CURRENT,
		element: <Current />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: MEETINGS,
		element: <Meetings />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: LEADS,
		element: <Leads />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: MY_COMPANY,
		element: <CompanyProfile />,
		exact: true,
		private: true,
		role: [AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: MY_PROFILE,
		element: <MyProfile />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: MY_PROFILE_EDIT,
		element: <MyProfileEdit />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: USER_PROFILE,
		element: <UserProfile />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: ADD_NEW_LIFE_INSURANCE,
		element: <AddNewLifeInsurance />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: ADD_CRITICAL_ILLNESS_INSURANCE,
		element: <AddCriticalIllnessInsurance />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: ADD_DISABILITY_INSURANCE,
		element: <AddDisabilityInsurance />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: EDIT_CRITICAL_ILLNESS_INSURANCE,
		element: <EditCriticalIllnessInsurance />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: EDIT_DISABILITY_INSURANCE,
		element: <EditDisabilityInsurance />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: EDIT_LIFE_INSURANCE,
		element: <EditLifeInsurance />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: VIEW_ALL_TEAM,
		element: <ViewAllTeam />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: VIEW_ALL_REPORTS,
		element: <ViewAllReports />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: VIEW_ALL_PERSONAL_DATA,
		element: <ViewAllPersonalData />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: VIEW_ALL_INSURANCES_DATA,
		element: <ViewAllInsurance />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: VIEW_ALL_OPPORTUNITIES_DATA,
		element: <ViewAllOpportunities />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: VIEW_ALL_TODO_DATA,
		element: <ViewAllTodos />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: VIEW_ARCHIVE_NOTIFICATIONS,
		element: <ViewArchiveNotifications />,
		exact: true,
		private: true,
		role: [
			AdvisorsRole.Advisor,
			AdvisorsRole.AccountAdministrator,
			AdvisorsRole.Administrator,
			AdvisorsRole.SuperAdmin,
		],
	},
	{
		path: ACCOUNT_SETTINGS,
		element: <AccountSettings />,
		exact: true,
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},

	{
		path: COMPANIES,
		element: <Companies />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},
	{
		path: ADVISORS,
		element: <Advisors />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},
	{
		path: CONSUMERS,
		element: <Consumers />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},
	{
		path: API_INSTANCES,
		element: <APIInstances />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},

	{
		path: ADD_API_INSTANCE,
		element: <ChangesAPIInstance />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},

	{
		path: EDIT_API_INSTANCE,
		element: <ChangesAPIInstance />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},

	{
		path: CONFIRM_EMAIL,
		element: <ConfirmEmail />,
		exact: true,
		common: true,
	},

	{
		path: ERROR,
		element: <Error />,
		common: true,
	},

	{
		path: LOGIN_SETTINGS_EDIT,
		element: <EditLoginSettings />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},

	{
		path: ADD_NEW_COMPANY,
		element: <AddNewCompany />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},

	{
		path: COMPANY_PROFILE,
		element: <CompanyProfile />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},

	{
		path: EDIT_COMPANY_DATA,
		element: <EditCompanyData />,
		className: '',
		private: true,
		role: [
			AdvisorsRole.SuperAdmin,
			AdvisorsRole.AccountAdministrator,
			AdvisorsRole.Administrator,
		],
	},

	{
		path: ADD_NEW_ADVISOR,
		element: <AddNewAdvisor />,
		className: '',
		private: true,
		role: [
			AdvisorsRole.SuperAdmin,
			AdvisorsRole.AccountAdministrator,
			AdvisorsRole.Administrator,
		],
	},
	{
		path: ADVISOR_PROFILE,
		element: <AdvisorProfile />,
		className: '',
		private: true,
		role: [
			AdvisorsRole.SuperAdmin,
			AdvisorsRole.AccountAdministrator,
			AdvisorsRole.Administrator,
		],
	},
	{
		path: ADVISOR_PROFILE_EDIT,
		element: <AdvisorProfileEdit />,
		className: '',
		private: true,
		role: [AdvisorsRole.SuperAdmin],
	},
	{
		path: MY_NETWORK,
		element: <MyEcosystem />,
		className: '',
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: INVITE_TO_ECOSYSTEM,
		element: <InviteToEcosystem />,
		className: '',
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: ECO_ADVISOR_PROFILE,
		element: <EcoAdvisorProfile />,
		className: '',
		exact: true,
		private: false,
		role: [
			AdvisorsRole.Advisor,
			AdvisorsRole.AccountAdministrator,
			AdvisorsRole.Administrator,
			AdvisorsRole.SuperAdmin,
		],
	},
	{
		path: SUBSCRIPTION_STATUS,
		element: <SubscriptionStatus />,
		exact: true,
		private: false,
		role: [
			AdvisorsRole.Advisor,
			AdvisorsRole.AccountAdministrator,
			AdvisorsRole.Administrator,
			AdvisorsRole.SuperAdmin,
		],
	},
	{
		path: RESTORE_PASSWORD,
		element: <RestorePasswordByEmail />,
		exact: true,
		private: false,
		role: [
			AdvisorsRole.Advisor,
			AdvisorsRole.AccountAdministrator,
			AdvisorsRole.Administrator,
			AdvisorsRole.SuperAdmin,
		],
	},
	{
		path: SET_PASSWORD_BY_CODE,
		element: <SetPasswordByCode />,
		exact: true,
		private: false,
		role: [
			AdvisorsRole.Advisor,
			AdvisorsRole.AccountAdministrator,
			AdvisorsRole.Administrator,
			AdvisorsRole.SuperAdmin,
		],
	},
	{
		path: REFERRED_TO_ME,
		element: <ReferredToMe />,
		className: '',
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: MANAGE_SUBSCRIPTION,
		element: <ManageSubscription />,
		className: '',
		private: true,
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
];

export default ROUTES;
