import {ReferringToBusinessesType} from '../../../types/enums';

enum FormInputKeys {
	whoIsReferred = 'whoIsReferred',
	firstName = 'firstName',
	lastName = 'lastName',
	email = 'email',
	phoneNumber = 'phoneNumber',
	description = 'description',
	referringToBusinessesType = 'referringToBusinessesType',
}

export const REFERRING_TO_BUSINESSES_OPTIONS_TYPES = [
	{
		type: ReferringToBusinessesType.Mortgage,
		name: 'Mortgage',
	},
	{
		type: ReferringToBusinessesType.Lending,
		name: 'Lending',
	},
	{
		type: ReferringToBusinessesType.Insurance,
		name: 'Insurance',
	},
	{
		type: ReferringToBusinessesType.Investment,
		name: 'Investment',
	},
	{
		type: ReferringToBusinessesType.RealEstate,
		name: 'Real Estate',
	},
	{
		type: ReferringToBusinessesType.LegalServices,
		name: 'Legal Services',
	},
	{
		type: ReferringToBusinessesType.HomeAuto,
		name: 'Home & Auto',
	},
	{
		type: ReferringToBusinessesType.RestructuringRestoration,
		name: 'Restructuring/Restoration',
	},
	{
		type: ReferringToBusinessesType.FinancialPlanning,
		name: 'Financial Planning',
	},
];

export default FormInputKeys;
