import classNames from 'classnames';
import './style.scss';
import PFLink from '../PF-Link';
import React from 'react';
import {Amplitude} from 'utils';

const PFContactAdministrator = ({className}) => {
	return (
		<div className={classNames('pf-contact__links', className)}>
			<span className={'d-none d-lg-block'}>Need help?</span>
			<PFLink
				href="mailto:support@pocketfinance.com"
				onClick={() => {
					Amplitude.track('Contacted_Support', {
						source: 'Set Password',
					});
				}}
				className="pf-contact__link">
				Contact the administrator
			</PFLink>
			<span className="px-4 d-lg-none">|</span>
			<PFLink href="//pocketfinance.com" className="pf-contact__link d-lg-none">
				About
			</PFLink>
		</div>
	);
};

export default PFContactAdministrator;
