import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LidsItem} from 'types';

interface LeadsState {
	leads: LidsItem[];
	fullCount: number;
}
type SetLeadsDataActionType = {
	body: any[];
	fullCount: number;
};

type RemoveLeadsItemActionType = {
	tokens: string[];
};

const initialState: LeadsState = {
	leads: [],
	fullCount: 0,
};

export const leadsSlice = createSlice({
	name: 'leads',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		// Use the PayloadAction type to declare the contents of `action.payload`
		setLeadsData: (state, action: PayloadAction<SetLeadsDataActionType>) => {
			state.leads = action.payload.body;
			state.fullCount = action.payload.fullCount;
		},
		cleanUpLeadsData: state => {
			state.leads = initialState.leads;
			state.fullCount = initialState.fullCount;
		},
		removeLeadsItem: (state, action: PayloadAction<RemoveLeadsItemActionType>) => {
			state.leads = state.leads.filter(lead => !action.payload.tokens.includes(lead.token));
		},
	},
});

export default leadsSlice.reducer;
