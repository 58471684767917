import React from 'react';
import sortArrow from '../../../assets/icon-arrow-sort.svg';
import './style.scss';

const PFSortArrow = () => {
	return (
		<div className="pf-sortArrows">
			<img src={sortArrow} className="up pf-sortArrow" alt="arrow up" />
		</div>
	);
};

export default PFSortArrow;
