import React from 'react';

import moment from 'moment';
import PFContactsBlock from 'shared/components/PF-ContactsBlock';
import PFUserDescription from 'shared/components/PF-UserDescription';
import {useAppSelector} from 'store';
import {tableSelectors} from 'store/selectors';
import {LidsItem} from 'types';
import {getFullName} from 'utils';

import AdditionalLeadsInfo from './AdditionalLeadsInfo';

const LeadsModal = ({hasCurrentCountRestriction}) => {
	const rowData: LidsItem = useAppSelector(tableSelectors.selectTableModalData);

	return (
		<>
			<PFUserDescription
				name={getFullName(rowData.firstName, rowData.lastName)}
				avatarPhoto={rowData?.profileImage}>
				Last viewed:{' '}
				{rowData.lastVisited ? moment.utc(rowData.lastVisited).local().fromNow() : 'Never'}
			</PFUserDescription>
			<PFContactsBlock
				phone={rowData?.phoneNumber}
				email={rowData?.email}
				location={{name: rowData?.city, state: {name: rowData?.province}}}
			/>
			<AdditionalLeadsInfo
				userToken={rowData.token}
				hasCurrentCountRestriction={hasCurrentCountRestriction}
			/>
		</>
	);
};

export default LeadsModal;
