import {useDispatch} from 'react-redux';
import * as usersService from '../../../api/services/users';
import {setConsumersData} from '../../../store/actions';

export const useConsumersData = params => {
	const dispatch = useDispatch();

	return async (payload = params) => {
		try {
			const response = await usersService.getUsers(params);
			dispatch(setConsumersData(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};
