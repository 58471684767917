import React, {FC} from 'react';

import classNames from 'classnames';
import {useAppDispatch} from 'store';
import {removeDeepLinkSpecialization, setDeepLinkSpecialization} from 'store/actions';

import './style.scss';

type SpecializationItemPropsTypes = {
	type: number;
	name: string;
	checked: boolean;
	disabled?: boolean;
	className?: string;
};

const SpecializationItem: FC<SpecializationItemPropsTypes> = ({
	type,
	name,
	checked,
	disabled = false,
	className,
}) => {
	const dispatch = useAppDispatch();

	const handleClick = itemType => {
		if (itemType === 0 && !checked) {
			dispatch(setDeepLinkSpecialization(0));
			return;
		}
		if (checked) {
			dispatch(removeDeepLinkSpecialization(itemType));
		} else {
			dispatch(setDeepLinkSpecialization([itemType]));
		}
	};

	return (
		<button
			type="button"
			className={classNames(`specialization-item ${className || ''}`, {checked})}
			disabled={disabled}
			onClick={() => handleClick(type)}>
			{name}
		</button>
	);
};

export default SpecializationItem;
