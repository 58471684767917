import {advisorService, usersService} from 'api/services';
import {useAppDispatch} from 'store';
import {setAdvisorClientsData} from 'store/actions';
import {ToastDispatcher} from 'utils';

export const useCompanyAdvisors = () => {
	return async payload => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await usersService.getUsers(payload);
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useCompanyAdministrators = () => {
	return async token => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await advisorService.getCompanyAdministrators(token);
			if (response.data.length) {
				return response.data.sort((a, b) => {
					const nameA = a.role.toLowerCase();
					const nameB = b.role.toLowerCase();
					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				});
			}
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};

export const useSignUpAdvisor = body => {
	return async (payload = body) => {
		// eslint-disable-next-line no-useless-catch
		try {
			await advisorService.signUpAdvisor(payload);
			ToastDispatcher.success('Email sent');
		} catch (e) {
			throw e;
		}
	};
};

export const useAdvisorsData = params => {
	const dispatch = useAppDispatch();

	return async (payload = params) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await usersService.getUsers(params);
			dispatch(setAdvisorClientsData(response.data));
			return response.data;
		} catch (e) {
			throw e;
		}
	};
};
