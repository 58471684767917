enum Statuses {
	QuizStatus = 1,
	MeetingRequestReceived = 2,
	MeetingRequestSent = 3,
	UpcomingMeetingBooked = 4,
	SummaryReportReceived = 5,
	SummaryReportRequested = 6,
	CalendarInviteSent = 7,
	CalendarRequestReceived = 8,
	AttentionPossibleRisk = 9,
	NoActiveForALongTime = 10,
	NoReportRequestedYet = 11,
	InsuranceInfoRequestReceived = 12,
}

export {Statuses};
