import {enqueueSnackbar, SnackbarAction} from 'notistack';

export class ToastDispatcher {
	public static success(message: string, action?: SnackbarAction) {
		enqueueSnackbar(message, {action});
	}

	public static error(message: string) {
		enqueueSnackbar(message, {variant: 'error'});
	}
}
