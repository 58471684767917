import React, {useCallback, useEffect, useRef, useState} from 'react';

import {usersService} from 'api/services';
import classNames from 'classnames';
import PFLink from 'shared/components/PF-Link';
import {ToastDispatcher} from 'utils';

import './style.scss';

const useCountdown = (initialSecondsLeft: number) => {
	const intervalId = useRef<NodeJS.Timer | null>(null);
	const [secondsLeft, setSecondsLeft] = useState(initialSecondsLeft);

	const stopCountdown = useCallback(() => {
		if (intervalId.current !== null) {
			clearInterval(intervalId.current);
		}

		setSecondsLeft(0);
	}, []);

	const startCountdown = useCallback(
		(newSecondsLeft: number) => {
			stopCountdown();

			setSecondsLeft(newSecondsLeft);

			if (newSecondsLeft > 0) {
				intervalId.current = setInterval(() => {
					setSecondsLeft(seconds => {
						if (seconds === 1) {
							stopCountdown();
						}

						return seconds - 1;
					});
				}, 1000);
			}
		},
		[stopCountdown],
	);

	return {
		secondsLeft,
		startCountdown,
		stopCountdown,
	};
};

const COUNTDOWN_SECONDS_AMOUNT = 60;

const ResendButton: React.FC<{
	email: string;
}> = ({email}) => {
	const {secondsLeft, startCountdown, stopCountdown} = useCountdown(COUNTDOWN_SECONDS_AMOUNT);

	const handleResendEmail = async () => {
		try {
			startCountdown(COUNTDOWN_SECONDS_AMOUNT);
			await usersService.resendEmailConfirmation(email);

			ToastDispatcher.success('Email sent. Please check your inbox.');
		} catch (err) {
			ToastDispatcher.error('Email has not been sent. Please try again.');
			stopCountdown();
		}
	};

	useEffect(() => {
		startCountdown(COUNTDOWN_SECONDS_AMOUNT);

		return stopCountdown;
	}, [startCountdown, stopCountdown]);

	const buttonText = `Resend${secondsLeft > 0 ? ` (${secondsLeft}) ` : ''}`;

	return (
		<PFLink
			onClick={secondsLeft > 0 ? undefined : handleResendEmail}
			className={classNames('completeSignUp__footer-link', {
				'completeSignUp__footer-link-disabled': secondsLeft > 0,
			})}>
			{buttonText}
		</PFLink>
	);
};

export default ResendButton;
