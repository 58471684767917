import {useDispatch} from 'react-redux';

import {AUTH_TOKEN, REFRESH_TOKEN} from 'api/httpClient/apiConst';
import {NULL_TOKEN, PATHS, REGEX_FOR_YOUTUBE_LINK} from 'const';
import {Amplitude} from 'utils/Amplitude';
import {ToastDispatcher} from 'utils/ToastDispatcher';

import {resetMyProfile, resetUi, SignOut} from '../../store/actions';

import Storage from './localStorage';

export const getFullName = (firstName: string | null, lastName: string | null) =>
	!firstName && !lastName ? 'User without name' : `${firstName || ''} ${lastName || ''}`;

export const generateCopyToClipboardHandler = (onSuccess: () => void, onError: () => void) => {
	return (text, result) => {
		if (result) {
			onSuccess();
		} else onError();
	};
};

export const getDefaultAvatar = () => {
	const colors = ['AFF4D8', 'C4EFB0', 'C9DBFF', 'E3DACC', 'E7C9FF', 'FDDDBF', 'FFD7DD', 'FFE8AE'];
	const randomIndex = Math.floor(Math.random() * colors.length);
	const randomColor = colors[randomIndex];
	return `data:image/svg+xml;charset=UTF-8,%3csvg width='140' height='140' viewBox='0 0 140 140' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='70' cy='70' r='70' fill='%23${randomColor}'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.1797 102.202C34.3137 87.806 51.0603 78.4 70.0037 78.4C88.945 78.4 105.69 87.8039 115.824 102.198C105.69 116.594 88.9437 126 70.0003 126C51.059 126 34.3141 116.596 24.1797 102.202Z' fill='white'/%3e%3ccircle cx='70.0023' cy='47.6' r='19.6' fill='white'/%3e%3c/svg%3e`;
};

export const getCamelCaseToText = (camelCaseText: string): string => {
	return camelCaseText.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
};

export const matchYouTubeLink = (str: string | null): RegExpMatchArray | null => {
	if (str) {
		return str.match(REGEX_FOR_YOUTUBE_LINK);
	}
	return null;
};

export const isSocialLinks = (advisor): boolean => {
	return (
		!!advisor.linkedin ||
		!!advisor.facebook ||
		!!advisor.instagram ||
		!!advisor.youtube ||
		!!advisor.twitter
	);
};

export const useErrorHelper = (navigate): any => {
	const dispatch = useDispatch();
	const cache = new Map();

	function helper(e, toErrorPage: boolean, message: string): any {
		if (
			e?.response?.status === 401 ||
			e?.response?.data?.message === 'Refresh token was expired.'
		) {
			dispatch(SignOut());
			dispatch(resetMyProfile());

			Amplitude.track('Signed_Out');
			Amplitude.reset();

			dispatch(resetUi());
			Storage.removeItem(AUTH_TOKEN);
			Storage.removeItem(REFRESH_TOKEN);
			Storage.cleanStorage();
			if (navigate) navigate('/');
			// @ts-ignore
			window.fcWidget.destroy();
			return;
		}
		if (toErrorPage) {
			if (navigate) navigate(PATHS.COMMON.ERROR);
		}

		const messageText = message || e?.response?.data?.message || e?.message;

		Amplitude.track('Detected_Error', {error_name: messageText || 'Unknown error'});

		if (messageText) {
			ToastDispatcher.error(`${messageText}`);
		}
		throw e;
	}
	return helper;
};

export const getSpecializationName = (
	data: {type: number; name: string}[],
	specializationType: number,
) => {
	return data.find(spec => {
		return spec.type === specializationType;
	})?.name;
};

export const getAmountCommasValue = (value: string | number): string => {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getAmountInsuranceValue = (value: string | number): string => {
	const cleanValue = value.toString().replace(/[^\d.]/g, '');
	return getAmountCommasValue(cleanValue);
};

export const isCompletedProfile = (advisor): boolean => {
	return (
		advisor &&
		advisor?.profileImage &&
		advisor?.advisorSpecializationTypes.length !== 0 &&
		advisor?.companyToken &&
		advisor?.companyToken !== NULL_TOKEN
	);
};
