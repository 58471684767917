import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {EditProfileForm, ProfileFormContainer} from 'components';
import {useAdvisorInfo, useUpdateUserProfile} from 'shared/hooks';
import {setCurrentPageTitle} from 'store/actions';
import {AdvisorType} from 'types/advisor';
import {useErrorHelper} from 'utils';

import './style.scss';

const AdvisorProfileEdit = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const advisorToken = window.location.hash.slice(1);
	const fetchAdvisorInfo = useAdvisorInfo();
	const errorHelper = useErrorHelper(navigate);

	const [selectedAdvisor, setSelectedAdvisor] = useState<AdvisorType | null>(null);
	const updateProfile = useUpdateUserProfile();

	const title = 'Edit User Profile';
	const goBack = (): void => {
		navigate(-1);
	};

	const callback = useCallback(async () => {
		try {
			const data = await fetchAdvisorInfo(advisorToken);
			setSelectedAdvisor(data);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	}, [advisorToken]);

	useEffect(() => {
		dispatch(setCurrentPageTitle('Advisors'));
		callback();
	}, []);

	return (
		<div className="advisorProfileEdit">
			<ProfileFormContainer title={title} goBack={goBack}>
				{selectedAdvisor && (
					<EditProfileForm
						advisor={selectedAdvisor}
						updateProfile={updateProfile}
						goBack={goBack}
					/>
				)}
			</ProfileFormContainer>
		</div>
	);
};

export default AdvisorProfileEdit;
