import React, {FC} from 'react';
import {useDispatch} from 'react-redux';

import NothingFoundAnimation from 'shared/components/PF-NothingFoundAnimation';
import {setTableReset} from 'store/actions';

import './style.scss';

type PropsTypes = {
	variant?: 'primary' | 'secondary';
	className?: string;
	pageName?: string;
	buttonText?: string;
	description?: boolean | string;
	isButton?: boolean;
};

const SearchEmptyPage: FC<PropsTypes> = ({
	variant = 'primary',
	className,
	pageName,
	buttonText,
	description = false,
	isButton = true,
}) => {
	const dispatch = useDispatch();

	return (
		<div className={`emptySearchPage__container ${className || ''}`}>
			<div className="emptySearchPage__header">
				<NothingFoundAnimation width={120} height={120} />
				<h1>Nothing Found</h1>
				{description ? (
					<h2>{description}</h2>
				) : (
					<h2>
						No results were found for your search. Refine your <br /> request or return
						to the {pageName && pageName} page
					</h2>
				)}
			</div>
			{isButton && (
				<div className="emptySearchPage__main">
					<button
						type="button"
						className={`emptyButton ${variant || ''}`}
						onClick={() => {
							dispatch(setTableReset());
						}}>
						{buttonText && buttonText}
					</button>
				</div>
			)}
		</div>
	);
};

export default SearchEmptyPage;
