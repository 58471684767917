import React, {FC, useState} from 'react';
import Cropper from 'react-easy-crop';

import Slider from '@mui/material/Slider';
import {CroppedAreaType} from 'types';

import './style.scss';

type PropsTypes = {
	image: string;
	cropShape: 'rect' | 'round';
	onCropComplete: (
		croppedAreaPercentage: CroppedAreaType,
		croppedAreaPixels: CroppedAreaType,
	) => void;
	showGrid?: boolean;
};

const PFImageCropper: FC<PropsTypes> = ({image, cropShape, onCropComplete, showGrid = true}) => {
	const [crop, setCrop] = useState<{x: number; y: number}>({x: 0, y: 0});
	const [zoomCrop, setZoomCrop] = useState<number | number[]>(1);

	return (
		<div className="container-cropper">
			<div className="cropper">
				{typeof zoomCrop === 'number' && (
					<Cropper
						image={image}
						crop={crop}
						zoom={zoomCrop}
						aspect={1}
						cropShape={cropShape}
						showGrid={showGrid}
						onCropChange={setCrop}
						onZoomChange={setZoomCrop}
						onCropComplete={onCropComplete}
					/>
				)}
			</div>

			<div className="slider">
				{typeof zoomCrop === 'number' && (
					<Slider
						min={1}
						max={3}
						step={0.1}
						classes={{
							colorPrimary: 'slider__range-line',
							thumbColorPrimary: 'slider__dot',
						}}
						value={zoomCrop}
						onChange={(e, zoom) => setZoomCrop(zoom)}
					/>
				)}
			</div>
		</div>
	);
};

export default PFImageCropper;
