import {RootState} from '../store';

export const selectReferPopupClientsCheckedKeys = (state: RootState) =>
	state.referBusinessPopup.clientsCheckedKeys;

export const selectReferPopupAdvisorCheckedKeys = (state: RootState) =>
	state.referBusinessPopup.advisorCheckedKeys;

export const selectIsOpenReferPopup = (state: RootState) => state.referBusinessPopup.isOpen;

export const selectUserFlowNameReferPopup = (state: RootState) =>
	state.referBusinessPopup.userFlowName;
