import {referBusinessPopupSlice} from '../reducers/ReferBusinessPopupReducer';

export const {
	setReferClientsCheckedKey,
	removeReferClientsCheckedKey,
	setIsOpenReferPopup,
	setCloseReferPopup,
	resetReferBusinessPopup,
	setReferAdvisorCheckedKey,
	removeReferAdvisorCheckedKey,
} = referBusinessPopupSlice.actions;
